import Cookies from 'universal-cookie';

import { Common } from './';

const cookies = new Cookies();

const set = (name: string, value: string, path = '/', maxAge = 2592000) => {
  cookies.set(name, value, { path, maxAge });
};

const get = (name: string) => {
  const param = cookies.get(name);

  return Common.checkType(param);
};

export default {
  set,
  get,
};
