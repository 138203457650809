import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router';
import { includes } from 'lodash-es';

import PageWrap from '../../utils/PageWrap';

import imagesConfig, { flags, type } from '../../../assets/images';

import styles from './style.scss';
import { Image } from '@common/MyGallery';
import classNames from 'classnames';

const PortfolioCategorized = () => {
  const [unmount, setUnmount] = useState<string | null>(null);
  const [activeFlags, setActiveFlags] = useState<string[]>([]);
  const title = window.location.pathname.replace(/\/portfolio\//g, '');

  const history = useHistory();

  useEffect(() => {
    setActiveFlags([title]);

    if (!includes(flags, title)) {
      history.push('/projects');
    }
  }, [history, title]);

  const images = (type: string): Image[] => {
    return (imagesConfig as Image[]).filter(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (img) => activeFlags.some((flag) => img[flag]) && img.type === type,
    );
  };

  const renderFlags = () => {
    const onFlag = (f: string) => setActiveFlags([f]);

    return flags.map((f) => (
      <div
        key={f}
        className={classNames(styles.flag, includes(activeFlags, f) && styles.active)}
        onClick={() => onFlag(f)}
      >
        {/** <Icon icon="tag"/> **/}
        {f}
      </div>
    ));
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <PageWrap unmount={unmount}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <button className={styles.backButton} id="go-back" onClick={() => setUnmount('/')}>
            Back
          </button>
          <div className={styles.title}>
            <div className={styles.flags}>{renderFlags()}</div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.grid}>
            {images(type.landscape).map((img) => (
              <div key={img.src} className={styles.gridItem}>
                <img src={img.src} alt="" className={styles.image} />
              </div>
            ))}
          </div>
          <div className={styles.gridPortrait}>
            {images(type.portrait).map((img) => (
              <div key={img.src} className={styles.gridItemPortrait}>
                <img src={img.src} alt="" className={styles.image} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageWrap>
  );
};

export default withRouter(PortfolioCategorized);
