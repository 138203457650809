import React from 'react';

import { WikiConfigItem } from '@ts/Wiki';

import styles from './style.scss';

type Props = {
  data: WikiConfigItem;
  showExtras: boolean;
};

const App = ({ data }: Props) => (
  <div className={styles.wrap}>
    <div className={styles.name}>{data.name}</div>
    {data.promotion && <div className={styles.promotion}>{data.promotion}</div>}
    <div className={styles.description}>{data.description}</div>
    {data.features && (
      <div className={styles.bodyBlock}>
        {data.features.title && <div className={styles.bodyBlockTitle}>{data.features.title}</div>}
        <div className={styles.bodyBlockList}>
          {data.features.list.map((feature, index) => (
            <div className={styles.bodyBlockListItem} key={index}>
              <div className={styles.bodyBlockListItemDot} />
              <span>{feature}</span>
            </div>
          ))}
        </div>
        {data.promotion}
      </div>
    )}
    {data.usage && (
      <div className={styles.wikiBodyBlock}>
        {data.usage.title && <div className={styles.wikiBodyBlockTitle}>{data.usage.title}</div>}
        <div className={styles.wikiBodyBlockList}>
          {data.usage.list.map((item) => (
            <div className={styles.wikiBodyBlockListItem} key={item.text + item.description}>
              <div className={styles.wikiBodyBlockListItemDot} />
              <div>
                <span className={styles.wikiBodyBlockListItemText}>{item.text}</span>
                <span className={styles.wikiBodyBlockListItemDescription}>{item.description}</span>
              </div>
            </div>
          ))}
        </div>
        {data.promotion}
      </div>
    )}
    {data.tags && (
      <div className={styles.wikiTags}>
        {data.tags.map((tag) => (
          <div key={tag} className={styles.tag}>
            {tag}
          </div>
        ))}
      </div>
    )}
    {data.link && (
      <div className="wiki-pages">
        {data.link.map((web: string) => (
          <div key={web}>
            <a className={styles.page} href={web} target="_blank" rel="noopener noreferrer">
              {web}
            </a>
          </div>
        ))}
      </div>
    )}
  </div>
);

export default App;
