import React, { useState } from 'react';
import PropTypes from 'prop-types';

const VoteItem = ({ title, onClick = () => true, children, onAdd }) => {
  const [adding, setAdding] = useState(false);
  const [name, setName] = useState('');

  if (onAdd) {
    return (
      <div className="voter add">
        <div className="voter-name add" onClick={() => setAdding(true)}>{title}</div>
        <div className={`voter-add ${adding ? '' : 'hidden'}`}>
          <input
            className="voter-add-input"
            onChange={(e) => setName(e.target.value)}
            value={name}/>
          <button
            className={`voter-add-button ${name.length ? 'active' : ''}`}
            onClick={() => {
              setName('');
              onAdd(name);
            }}
          >Add</button>
        </div>
      </div>
    );
  }

  return (
    <div className="voter" onClick={onClick}>
      <div className="voter-name">{title}</div>
      {children}
    </div>
  );
};

VoteItem.propTypes = {
  title: PropTypes.string,
  onClickTitle: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.any,
  onAdd: PropTypes.func,
};

export default VoteItem;
