import React, { Component } from 'react';
import PageWrap from '../../../utils/PageWrap';

import { kebabCase } from 'lodash-es';

import { imgPath } from '../../../../api/api';

import App from './components/App';

import config from '../../../../assets/apps';

import './style.css';

class Develop extends Component {
  state = {};

  interval = null;

  componentDidMount() {
    this.activeSkills();
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  activeSkills() {
    this.interval = setInterval(() => this.nextSkill(), 460);
  }

  nextSkill() {
    const next = this.state.activeSkill + 1;

    this.setState({ activeSkill: next });
  }

  getLink(app, extension) {
    if (!app) {
      return `${imgPath}/develop/no-icon.png`;
    }

    const id = kebabCase(app.id || app.name);

    if (extension) {
      return `${imgPath}/develop/${id}-${extension}.png`;
    }

    return `${imgPath}/develop/${id}.png`;
  }

  icons(extension) {
    const icons = config.apps.slice(0, config.apps.length - 3).map((app) => this.getLink(app, extension));

    return (
      <div className="develop-intro-icons">
        {icons.map((icon) => (
          <div className="develop-intro-icon" key={icon}>
            <img className="develop-intro-icon-image" src={icon} onError={(e) => e.target.src = this.getLink()}/>
          </div>
        ))}
      </div>
    );
  }

  get intro() {
    return (
      <div className="develop-intro">
        <div className="develop-intro-text">App full pack</div>
        {this.icons()}
        {this.icons('dark')}
      </div>
    );
  }

  get apps() {
    return config.apps.map((app, index) => <App app={app} key={index} showExtras/>);
  }

  render() {
    const { unmount } = this.state;

    return (
      <PageWrap unmount={unmount}>
        <div className="develop">
          {this.intro}
          {this.apps}
        </div>
      </PageWrap>

    );
  }
}

export default Develop;
