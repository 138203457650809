import React, { useEffect, useState } from 'react';
import { snakeCase, upperFirst } from 'lodash-es';

import API from '../../../../api';

import { Cookie } from '../../../../functions';

import VotesLayout from './../layout';
import VoteItem from '../components/VoteItem';
import CatchKeyPress from '../../../common/CatchKeyPress';

import './style.css';

const Votes = () => {
  const [unmount, setUnmount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const userKey = Cookie.get('userKey');

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const data = await API.get('/votes/config');

      setData(data);
      setIsLoading(false);
    } catch (error) {
      API.err(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onAdd = async (name) => {
    const id = snakeCase(name);

    try {
      await API.post('/votes/types', { id, name, userKey });
      await fetchData();
    } catch (error) {
      API.err(error);
    }
  };

  return (
    <VotesLayout loading={isLoading} titleExtend="For anything" unmount={unmount}>
      <div className="vote-types">
        {data.map((item) => (
          <VoteItem key={item.id} title={upperFirst(item.id)} onClick={() => setUnmount(`/voting/${item.id}`)} />
        ))}
        {userKey && <VoteItem onAdd={onAdd} title="Add vote type" />}
      </div>
      <CatchKeyPress
        onRedirect={setUnmount}
        onRedirectBack
        items={data.map((item) => ({ name: item.name, path: `/voting/${item.id}` }))}
      />
    </VotesLayout>
  );
};

export default Votes;
