import React, { useContext, useEffect, useState } from 'react';

import API from '../../../../../api';

import GamesLayout from '../../layout';

import { GamesContext } from '../../../../../context/gamesData';
import { endpoints } from '../../../../../api/endpoints';
import { Common } from '../../../../../functions';

import './style.css';

const Records = () => {
  const [data] = useContext(GamesContext);
  const [gameStats, setGameStats] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showBody, setShowBody] = useState(false);

  const loadData = async () => {
    try {
      const data = await API.get(endpoints.game.stats.allBySeason(1));

      setGameStats(data);
      setDataLoaded(true);
    } catch (err) {
      // No handle
    }

    console.log(gameStats);
  };

  const playersWithPoints = data.users.map((u) => ({ points: gameStats?.points[u._id] || 0, ...u }));
  const sortedPlayers = Common.sort(playersWithPoints, 'points').reverse();

  useEffect(() => {
    loadData();

    setTimeout(() => setShowBody(true), 1400);
  }, []);

  const renderCardBody = (pos) => {
    const player = sortedPlayers[pos - 1];

    if (!player) return null;

    return (
      <>
        <div/>
        <div className="leaderboard-card-body-pos">{pos}</div>
        <div>
          <div className="leaderboard-card-body-points-label">points</div>
          <div className="leaderboard-card-body-points">{player.points}</div>
        </div>
        <div className="leaderboard-card-body-name">{player.username}</div>
      </>
    );
  };

  return (
    <GamesLayout>
      {dataLoaded &&
      <div className="leaderboard-wrap">
        <div className="leaderboard-title">
          Not implemented
        </div>
      </div>
      }
    </GamesLayout>
  );
};

export default Records;
