import React, { useState } from 'react';

import { uploadPath } from '@api/api';
import { Time } from '@functions';

import Loading from '@common/Loading';

import Comments from './Comments';

import './style.css';
import { F1Post } from '@ts/F1';

type Props = {
  post: F1Post;
  onUpdate: () => void;
};

const Post = ({ post, onUpdate }: Props) => {
  const [loading, setLoading] = useState(true);

  const image = `${uploadPath}${post.image}`;

  const content =
    post.content?.startsWith('http') || post.content?.startsWith('www') ? (
      <a className="new-post-link" href={post.content} target="_blank" rel="noreferrer">
        {post.content}
      </a>
    ) : (
      post.content
    );

  return (
    <div className="news-post">
      <div className="news-post-body">
        <div className="news-post-header">
          <div className="news-post-header-user">
            {/** <img className="news-post-header-user-image"></img> **/}
            <div className="news-post-header-user-name">{post.user.username}</div>
          </div>
          <div className="news-post-header-actions">
            <div className="news-post-header-date">{Time.concrete(post.createdAt)}</div>
          </div>
        </div>
        {post.image && (
          <div className="news-post-image-wrap">
            <img
              alt="post"
              className={`news-post-image ${!loading ? 'loaded' : ''}`}
              src={image}
              onLoad={() => setLoading(false)}
            />
            {loading && (
              <div className="news-post-image-loader">
                <Loading color="#E00600" />
              </div>
            )}
          </div>
        )}
        <div className="news-post-content">{content}</div>
      </div>
      <div className="news-post-footer">
        <Comments post={post} onAdd={onUpdate} />
      </div>
    </div>
  );
};

export default Post;
