import React from 'react';
import { ButtonBase, Icon } from '@mui/material';
import { styled } from '@mui/material/styles';

import classNames from 'classnames';

import './style.css';

const ButtonBaseStyled = styled(ButtonBase)({
  color: '#ddd',
  padding: '2px 7px',
  borderRadius: '4px',
  backgroundColor: '#ffffff11',
  '&:focus': {
    backgroundColor: '#ffffff22',
  },
});

type Props = {
  onClick?: () => void;
  className?: string;
  classNameLabel?: string;
  icon?: string;
  active?: boolean;
  disabled?: boolean;
  children: JSX.Element | string;
};

const Tag = ({ onClick, className, classNameLabel, active, icon, disabled, children }: Props) => {
  const t = (
    <ButtonBaseStyled
      className={classNames('mui-tag', className, { active })}
      onClick={() => onClick && onClick()}
      disabled={disabled}
    >
      {icon && <Icon className="mui-tag-icon">{icon}</Icon>}
      <div className={classNames('mui-tag-label', classNameLabel)}>{children}</div>
    </ButtonBaseStyled>
  );

  return t;
};

export default Tag;
