import React, { MouseEvent } from 'react';

import Icon from '@common/Icon';

import './style.css';

type Props = {
  title?: string;
  text?: string;
  button?: string;
  onClick?: (e: MouseEvent) => void;
  warning?: boolean;
  icon?: string;
  highlight?: string;
  iconColor?: string;
};

const Notice = ({ title, text, button, onClick, warning, icon, highlight, iconColor }: Props) => {
  return (
    <div className={`f1-notice ${warning ? 'warning' : ''}`} onClick={onClick}>
      {icon && (
        <div className="f1-notice-icon">
          <Icon size={28} color={iconColor}>
            {icon}
          </Icon>
        </div>
      )}
      <div className="f1-notice-body">
        {title && <div className="f1-notice-header">{title}</div>}
        <div className="f1-notice-content">
          <div>
            {text && <div className="f1-notice-text">{text}</div>}
            {highlight && <div className="f1-notice-highlight">{highlight}</div>}
          </div>
          <div className="f1-notice-actions">
            {button && <button className="f1-notice-button">{button}</button>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notice;
