import React, { useState } from 'react';

import { General, Hash } from '../../../../functions';

import PageWrap from '../../../utils/PageWrap';
import CatchKeyPress from '../../../common/CatchKeyPress';

import './style.css';

const Version = () => {
  const [unmount, setUnmount] = useState();

  const { logs, version } = General.version( { spread: true, module: Hash.build().module });

  return (
    <PageWrap unmount={unmount}>
      <div className="version-wrap">
        <div className="version-header">
          <div>Version preview</div>
          <div>{version}</div>
        </div>
        <div className="version-body">
          {logs.map((log, index) => (
            <div className={`version-item ${log.match ? 'hightlight' : ''} ${log.major ? 'major' : (log.minor ? 'minor' : '')}`} key={index}>
              <div className="version-item-version">{log.version}</div>
              <div className="version-item-tags">{log.tags.join(', ') }</div>
            </div>
          ))}
        </div>
      </div>
      <CatchKeyPress onRedirect={setUnmount} onRedirectBack/>
    </PageWrap>
  );
};

export default Version;
