import React, { useContext, useMemo, useState } from 'react';
import { ButtonBase, Collapse, Dialog, Fade, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { DrinksContext } from '@context/drinksData';
import API from '../../../../../api';
import { endpoints } from '@api/endpoints';

import { User } from '../../../../../functions';

import DrinkLayout from '../../layout';
import DarkListItem from '../../../../common/DarkListItem';
import DarkCircleIconButton from '../../../../common/DarkCircleIconButton';

import { History } from 'history';
import { DrinkType } from '@ts/Drink';

import { translate } from '@localise/index';

import './style.css';
import Adapters from '@functions/adapters';
import { FieldOptions } from '@pages/Drinks/components/SessionEdit';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background: linear-gradient(#111, #222);
  }

  .MuiDialog-container {
    background: rgba(255, 255, 255, 0.8);
  }

  @media (max-width: 600px) {
    .MuiDialog-paper {
      flex: 1;
      margin: 0;
    }
  }
`;

export const StyledButtonBase = styled(ButtonBase)`
  color: #ccc;
  font-size: 16px;
  font-family: 'Varem', sans-serif;
  padding: 8px 20px;
  letter-spacing: 1px;
  border-radius: 6px;
  margin-left: 10px;
  transition: 0.3s ease all;
  text-transform: uppercase;

  &:hover {
    color: white;
  }
`;

export const StyledButtonBaseActive = styled(StyledButtonBase)`
  background: #ffffff22;

  &:hover {
    background: black;
  }

  &:disabled {
    opacity: 0.3;
  }
`;

export const StyledTextField = styled(TextField)`
  .MuiInputLabel-root {
    color: #888;
    font-size: 15px;
    font-family: 'Varem', sans-serif;
  }

  .MuiInputLabel-root .Mui-focused {
    color: #bbb;
  }

  .MuiInput-input {
    color: #ccc;
    font-size: 18px;
    padding: 0 0 2px;
  }

  .MuiInput-input:focus {
    color: white;
  }

  .MuiInput-input::placeholder {
    color: #555;
  }

  .MuiInput-underline::before {
    border-bottom-color: #555;
  }

  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom-color: #666;
  }

  .MuiInput-underline::after {
    border-bottom-color: white;
  }
`;

type Props = {
  history: History;
};

const Types = ({ history }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState<Partial<DrinkType & { isDrink: boolean }>>({
    isDrink: true,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, dataLoaded] = useContext(DrinksContext);

  const alcoholInGrams = useMemo(() => {
    if (values?.alcoholPercentage && values.alcoholInDcl) {
      return (Number(values?.alcoholPercentage) * Number(values.alcoholInDcl) * 0.789)
        .toFixed(3)
        .toString();
    }
    return null;
  }, [values?.alcoholPercentage, values.alcoholInDcl]);

  const hasEditRight = User.hasRight('drinks:edit');

  const drinkTypes = data.drinkTypes as DrinkType[];

  const onCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => setValues({ isDrink: true }), 300);
  };

  const onSave = async () => {
    try {
      if (values._id) {
        await API.put(
          endpoints.drink.type.update(values._id as string),
          Adapters.cleanObject({ ...values, alcoholInGrams }),
        );
      } else {
        await API.post(endpoints.drink.type.add, { ...values, alcoholInGrams });
      }

      setIsOpen(false);
    } catch (err) {
      API.err(err);
    }
  };

  const updateValue = (field: FieldOptions, value: string | number) => {
    const newValues = { ...values };

    if (field.type === 'number' && value !== '') {
      const val = Number(value);

      if (!isNaN(val) && field.min && field.min > val) {
        return;
      }

      if (!isNaN(val) && field.max && field.max < val) {
        return;
      }
    }

    if (field.name === 'name') {
      newValues.id = (value as string).toUpperCase().replace(/\s/g, '_');
    }

    if (field.name === 'id') {
      value = (value as string).toUpperCase().replace(/\s+/g, '_');
    }

    newValues[field.name] = value;

    setValues(newValues);
  };

  const field = (field: FieldOptions) => (
    <div className="drink-type-field" key={field.name}>
      <StyledTextField
        InputLabelProps={{
          shrink: true,
        }}
        variant="standard"
        fullWidth
        id={`drink-field-${field.name}`}
        onChange={(e) => updateValue(field, e.target.value)}
        value={field.value || values[field.name]}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        label={field.label || (translate.drinks.types[field.name] as string) || field.name}
        placeholder={field.placeholder}
        type={field.type}
        helperText={field.helper}
        autoComplete="off"
      />
    </div>
  );

  return (
    <DrinkLayout loading={!dataLoaded} header={translate.drinks.types.title}>
      <StyledDialog className="" open={isOpen} onClose={onCloseModal}>
        <div className="drink-type-form">
          <div className="drink-type-form-title">
            {values._id ? translate.drinks.types.edit : translate.drinks.types.add}
          </div>
          {field({ name: 'name' })}
          {field({ name: 'alcoholType', placeholder: 'RUM' })}
          {field({
            name: 'alcoholPercentage',
            placeholder: '40',
            min: 1,
            max: 100,
            type: 'number',
          })}
          {field({
            name: 'alcoholInDcl',
            placeholder: '0.4',
            min: 0,
            max: 100,
            type: 'number',
            helper: alcoholInGrams ? `${alcoholInGrams} grams of alcohol` : '',
          })}
          <Collapse in={!!values.isDrink}>
            <>
              {field({ name: 'mixedWith', placeholder: 'COLA' })}
              {field({ name: 'mixedRatio', placeholder: '0,18', type: 'number', min: 0, max: 1 })}
            </>
          </Collapse>
          <div className="drink-type-form-actions">
            <StyledButtonBase onClick={onCloseModal}>Cancel</StyledButtonBase>
            <StyledButtonBaseActive disabled={!values.id || !values.name} onClick={onSave}>
              Save
            </StyledButtonBaseActive>
          </div>
        </div>
      </StyledDialog>
      <div className="drink-types-body">
        {drinkTypes.map((type, index) => (
          <Fade
            key={type._id}
            in={!!drinkTypes.length}
            mountOnEnter
            style={{ transitionDelay: `${240 * index + 'ms'}`, transitionDuration: '1250ms' }}
          >
            <div className="drink-type-item">
              <DarkListItem
                label={type.name}
                onClick={() => {
                  setValues(type);
                  setIsOpen(true);
                }}
              />
            </div>
          </Fade>
        ))}
        <Fade
          in
          mountOnEnter
          style={{
            transitionDelay: `${240 * drinkTypes.length + 'ms'}`,
            transitionDuration: '1250ms',
          }}
        >
          <div className="drink-type-item">
            <DarkListItem label="Add" fade onClick={() => setIsOpen(true)} />
          </div>
        </Fade>
      </div>
      <Fade in>
        <div className="drink-types-footer">
          <DarkCircleIconButton icon="arrow_back" onClick={() => history.push('/drinks')} />
        </div>
      </Fade>
    </DrinkLayout>
  );
};

export default Types;
