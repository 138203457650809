import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Fade } from '@mui/material';

import { User } from '@functions';

import Authorization from '@pages/Drinks/components/Authorization';
import Loading from '@common/Loading';

import './style.css';

type Props = {
  children: JSX.Element | JSX.Element[];
  loading?: boolean;
  header?: string | JSX.Element;
  customHeader?: JSX.Element;
  headerExtend?: JSX.Element;
  onUserClick?: () => void;
  onCornerClick?: () => void;
};

const DrinkLayout = ({
  children,
  loading,
  header,
  headerExtend,
  onUserClick,
  onCornerClick,
  customHeader,
}: Props) => {
  const history = useHistory();

  const onClick = () => {
    if (onUserClick) onUserClick();
    else history.push('/picks');
  };

  const body = !User.hasAccess() ? (
    <Authorization />
  ) : (
    <div className="drink-wrap">
      {/** Menu **/}
      <div className="drink-body">
        <Fade in={!loading}>
          {!customHeader ? (
            <div className="drink-header">
              <div className="drink-header-user" onClick={() => onClick()}>
                {User.username()}
              </div>
              {headerExtend && <div className="drink-header-extend">{headerExtend}</div>}
              <div className="drink-header-action" onClick={onCornerClick}>
                {header}
              </div>
            </div>
          ) : (
            customHeader
          )}
        </Fade>
        {loading ? (
          <Loading size={30} color="#222" />
        ) : (
          <Fade in={!loading}>
            <div className={classNames('drink-content')}>{children}</div>
          </Fade>
        )}
        <div />
      </div>
    </div>
  );

  return <div className="drink-container">{body}</div>;
};

export default DrinkLayout;
