import React, { useRef, useEffect, useState, useContext, KeyboardEvent } from 'react';
import { addDays, isAfter } from 'date-fns';
import { includes, toLower } from 'lodash-es';

import API from '@api/index';
import { F1Context } from '@context/f1Data';
import { endpoints } from '@api/endpoints';
import { translate, useTranslate } from '@localise/index';

import { Common, User } from '@functions';

import Button from '../../../components/Button';

import { F1Circuit, F1Race } from '@ts/F1';

import './style.css';

type Props = {
  season: string;
};

const NewEvent = ({ season }: Props) => {
  const [races, setRaces] = useState<F1Race[]>([]);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState('');
  const [error, setError] = useState(false);
  const [circuit, setCircuit] = useState<F1Circuit | null>(null);

  const inputRef = useRef(null);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [data] = useContext(F1Context);

  const { circuits } = data;

  const fetchData = async () => {
    try {
      const data = await API.post(endpoints.f1.races.search, {
        season: Common.getYear().toString(),
      });

      /**
      const processData = data.map((d) => ({ ...d, sprintFormat: d.hasSprintRace, hasSprintRace: undefined, season: '2021', circuit: d.circuit.toString() }));

      console.log(processData);

      processData.forEach((d) => API.post(endpoints.f1.races.add, d));
      **/

      setRaces(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const futureRaces = races.filter((r) => isAfter(addDays(new Date(r.raceDate), 1), new Date()));

  const createEvent = async () => {
    if (!circuit || !date) return;

    try {
      const data = {
        season,
        raceDate: date,
        circuit: circuit.id,
        place: circuit.competition.name,
        userKey: User.hasAccess(),
      };

      await API.post(endpoints.f1.races.add, data);

      setCircuit(null);
      setDate('');

      fetchData();
    } catch (err) {
      setError(error);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 8) {
      setCircuit(null);
    } else if (event.keyCode === 13) {
      createEvent();
    }
  };

  const selectCircuit = (circuit: F1Circuit) => {
    setSearch('');
    setCircuit(circuit);
  };

  const filteredCircuits = (circuits || []).filter((circuit: F1Circuit) => {
    if (search === '') return false;

    const compareInfo = [
      circuit.competition.location.country,
      circuit.competition.location.city,
      circuit.name,
    ].filter(Boolean);

    return compareInfo.some((info) => includes(toLower(info), toLower(search)));
  });

  const useDimensions =
    inputRef &&
    inputRef.current &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    inputRef.current.getBoundingClientRect();

  let list = null;

  if (useDimensions && search) {
    const dimensions = useDimensions as {
      x: number;
      y: number;
      width: number;
      height: number;
    };

    if (filteredCircuits && filteredCircuits.length) {
      list = (
        <ul
          className="f1-admin-new-event-picker"
          style={{
            left: dimensions.x,
            top: dimensions.y + dimensions.height + 4,
            width: dimensions.width,
          }}
        >
          {filteredCircuits.map((c: F1Circuit) => (
            <li
              key={c.id}
              className="f1-admin-new-event-picker-item"
              onClick={() => selectCircuit(c)}
            >
              <div className="f1-admin-new-event-picker-competition">{c.competition.name}</div>
              <div className="f1-admin-new-event-picket-circuit">{c.name}</div>
            </li>
          ))}
        </ul>
      );
    }
  }

  return (
    <>
      <div className="f1-admin-new-event">
        <div className="f1-admin-new-event-header">
          <div className="f1-admin-new-event-title">{translate.f1.add.createEvent}</div>
        </div>
        <div className="f1-admin-new-event-body">
          <div className="f1-admin-new-event-fields">
            <label className="f1-admin-new-event-label">
              <input
                type="date"
                value={date}
                className="f1-admin-new-event-input"
                onChange={(e) => setDate(e.target.value)}
                min="2019-01-01"
              />
            </label>
            <label className="f1-admin-new-event-label">
              <input
                value={circuit ? `${circuit.competition.name}, ${circuit.name}` : search}
                className="f1-admin-new-event-input"
                placeholder={translate.f1.add.searchCircuit}
                onKeyDown={handleKeyDown}
                onChange={(e) => setSearch(e.target.value)}
                min="2019-01-01"
                ref={inputRef}
              />
              {list}
            </label>
          </div>
        </div>
        <div className="f1-admin-new-event-footer">
          <a
            className="f1-link"
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.formula1.com/en/racing/2021.html"
          >
            {useTranslate('f1.add.schedule', { year: 2021 })}
          </a>
          <Button
            onClick={() => createEvent()}
            className="f1-admin-add-event-button"
            disabled={!circuit || !date}
          >
            {translate.f1.add.addEvent}
          </Button>
        </div>
      </div>
      <div className="f1-admin-event-list">
        {futureRaces.map((race) => (
          <div className="f1-admin-event" key={race._id}>
            <div className="f1-admin-event-place">{race.place}</div>
            <div className="f1-admin-event-date">{race.raceDate}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default NewEvent;
