// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wiki-wrap-HDcgj{display:flex;flex-direction:column}.wiki-header-Wawj\\+{background:#222;min-height:40px}.wiki-header-title-4Jmse{font-size:18px;color:#fff;padding:10px 20px}.wiki-container-7wpMO{display:flex;min-height:calc(100svh - 47px)}.wiki-content-IElLh{display:flex;flex-direction:column;min-height:100%;background:#fff;color:#555;font-size:15px;font-family:\"Open Sans\",sans-serif;flex:1}.wiki-menu-Ir0zD{display:flex;flex-direction:column;min-width:240px;background:#333}.wiki-menu-item-b\\+2pu{display:flex;align-items:center;padding:14px 20px;color:#ddd;cursor:pointer}.wiki-menu-item-b\\+2pu:hover{background:#282828;color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wiki-wrap": "wiki-wrap-HDcgj",
	"wiki-header": "wiki-header-Wawj+",
	"wiki-header-title": "wiki-header-title-4Jmse",
	"wiki-container": "wiki-container-7wpMO",
	"wiki-content": "wiki-content-IElLh",
	"wiki-menu": "wiki-menu-Ir0zD",
	"wiki-menu-item": "wiki-menu-item-b+2pu"
};
export default ___CSS_LOADER_EXPORT___;
