// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-h5-1K{display:flex;min-height:100dvh;background:#ccc;gap:40px;padding-left:40px}.body-YZCT7{display:flex;flex-direction:column;flex:1}.head-8\\+LMU{display:flex;justify-content:center;margin-top:20px}@media(max-width: 1024px){.wrap-h5-1K{gap:20px;padding-left:20px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-h5-1K",
	"body": "body-YZCT7",
	"head": "head-8+LMU"
};
export default ___CSS_LOADER_EXPORT___;
