import React from 'react';
import PropTypes from 'prop-types';
import MaterialButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const MenuButtonStyled = styled(MaterialButton)({
  'color': '#ddd',
  'borderRadius': 0,
  'padding': '10px 30px',
  'width': '100%',
  'justifyContent': 'flex-start',
  'fontFamily': 'Varem',
  'fontSize': 16,
  '&:hover': {
    color: 'white',
    backgroundColor: '#ffffff11',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    color: 'white',
    backgroundColor: '#ffffff11',
    borderColor: '#005cbf',
  },
  '&:focus': {
    backgroundColor: '#ffffff11',
  },
});

const MenuButton = ({ label, onClick, active, disabled }) => {
  return (<MenuButtonStyled onClick={onClick} disabled={disabled}>{label}</MenuButtonStyled>);
};

MenuButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
};

export default MenuButton;
