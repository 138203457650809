// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stats-BlB3H{position:relative;padding-top:12px}.statsInfo--H0Xg{top:0;width:100%;display:flex;padding:8px 0;justify-content:space-evenly}.stat-vuAXr{color:#333;display:flex;flex-direction:column;align-items:center}.statLabel-kCW7u{font-size:10px;opacity:.4}.statsType-r-ZSZ{display:flex;justify-content:center;margin:4px 0 12px}.multiPickerButton-ivHM0{border:2px solid #eee !important;padding:2px 12px !important;border-right:1px solid #eee !important;color:#aaa;text-transform:uppercase;letter-spacing:1px;font-size:11px;font-weight:500;font-family:\"Poppins\",sans-serif}.multiPickerButton-ivHM0:first-child{border-top-left-radius:6px !important;border-bottom-left-radius:6px !important}.multiPickerButton-ivHM0:last-child{border-top-right-radius:6px !important;border-bottom-right-radius:6px !important;border-right:2px solid #eee !important}.multiPickerButton-ivHM0.selected-MhIHF{color:#333;background:#eee}@media(max-width: 1024px){.statsInfo--H0Xg{padding:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stats": "stats-BlB3H",
	"statsInfo": "statsInfo--H0Xg",
	"stat": "stat-vuAXr",
	"statLabel": "statLabel-kCW7u",
	"statsType": "statsType-r-ZSZ",
	"multiPickerButton": "multiPickerButton-ivHM0",
	"selected": "selected-MhIHF"
};
export default ___CSS_LOADER_EXPORT___;
