// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-KPi0D{flex:1;display:flex;align-items:center;justify-content:center}.content-OBkLZ{display:flex;flex-direction:column}.areaWrap-y5jml{display:flex;justify-content:center;margin-bottom:100px}.area-fIaqE{display:flex;flex-direction:column;background:#18181b;border-radius:20px;min-width:650px;box-shadow:0 10px 40px}.areaBody-uPOzA{display:flex;padding:20px 20px 20px 30px}.areaFilters-B4G7N{display:flex;gap:10px;border-top:2px solid hsla(0,0%,100%,.0666666667);padding:10px 16px 6px 20px}.iconButton-lfrzO{display:flex;justify-content:center;align-items:center;min-width:54px;min-height:54px;border-radius:10px}.iconButton-lfrzO.active-nriFO{background:rgba(56,149,211,.1333333333) !important}.iconButton-lfrzO.active-nriFO .icon-8Rfk8{color:#3895d3 !important}.filterButton-8U0Qj{opacity:.3;transition:.3s ease all}.active-nriFO{opacity:1}.icon-8Rfk8{color:#fff}.area-fIaqE input{font-family:\"Poppins\",sans-serif}.list-xQImz{display:flex;flex-direction:column;gap:8px}.todo-6kICb{display:flex;align-items:center;justify-content:space-between;padding:4px 4px 4px 20px;border-radius:10px;border:3px solid #fff;color:#222}.todoButton-Eq6MT{padding:10px !important;border-radius:6px !important;background:#3895d3 !important;color:#fff !important}.todo-6kICb.active-nriFO{background:#333 !important;color:#fff !important;transition:.3s ease all;border:3px solid rgba(0,0,0,0)}.todo-6kICb.active-nriFO .todoButton-Eq6MT{background:rgba(0,0,0,0) !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-KPi0D",
	"content": "content-OBkLZ",
	"areaWrap": "areaWrap-y5jml",
	"area": "area-fIaqE",
	"areaBody": "areaBody-uPOzA",
	"areaFilters": "areaFilters-B4G7N",
	"iconButton": "iconButton-lfrzO",
	"active": "active-nriFO",
	"icon": "icon-8Rfk8",
	"filterButton": "filterButton-8U0Qj",
	"list": "list-xQImz",
	"todo": "todo-6kICb",
	"todoButton": "todoButton-Eq6MT"
};
export default ___CSS_LOADER_EXPORT___;
