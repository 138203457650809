import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Fade, ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import { groupBy } from 'lodash-es';

import { DrinksContext } from '@context/drinksData';
import API from '@api/index';
import { endpoints } from '@api/endpoints';

import { translate } from '@localise/index';

import { Time, User } from '@functions';

import DrinkLayout from '../../layout/index';
import DarkCircleIconButton from '@common/DarkCircleIconButton';
import DarkListItemDetailed, { DarkListItemType } from '@common/DarkListItemDetailed';
import SessionEdit from '@pages/Drinks/components/SessionEdit';
import Loading from '@common/Loading';

import { History } from 'history';
import { DrinkSession, DrinkType } from '@ts/Drink';

import './style.css';
import SessionStats from '@pages/Drinks/components/SessionStats';

type Props = {
  history: History;
};

const ButtonBaseStyled = styled(ButtonBase)({
  color: '#ddd',
  padding: '4px 12px',
  borderRadius: '6px',
  textTransform: 'uppercase',
  letterSpacing: 1,
  fontSize: 16,
  fontWeight: 500,
  margin: '0 4px',
  '&:hover': {
    color: '#222',
  },
  '&.active': {
    color: '#444',
  },
  '&.fullWidth': {
    color: '#aaa',
    width: '100%',
    padding: '8px 12px',
    background: '#eee',
  },
});

const defaultLimit = 20;

const DrinksHistory = ({ history }: Props) => {
  const [sessions, setSessions] = useState<DrinkSession[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState<Partial<DrinkSession> | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const [showForAll, setShowForAll] = useState(false);

  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, dataLoaded] = useContext(DrinksContext);

  // const hasEditRight = User.hasRight('drinks:edit');

  const drinkTypes = data.drinkTypes as DrinkType[];

  useEffect(() => {
    setOffset(0);
    loadData(0);
    setLoadingMore(false);
  }, [showForAll]);

  const loadData = async (offset: number, isLoad?: boolean, isRefresh?: boolean) => {
    if (isLoad) {
      setOffset(offset);
      setLoadingMore(true);
    } else {
      setLoading(true);
    }

    const params: Record<string, unknown> = { offset, limit: defaultLimit };

    if (!showForAll) {
      params.players = [User.id()];
    }

    try {
      const { rows, total } = await API.post(endpoints.drink.session.search, params);

      if (isRefresh) {
        const actualSessions = [...sessions];
        actualSessions.splice(offset, defaultLimit, ...rows);
        setSessions(actualSessions);
      } else if (isLoad) {
        setSessions((sessions) => [...sessions, ...rows]);
      } else {
        setSessions(rows);
      }
      setTotal(total);
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
    setLoadingMore(false);
  };

  const onCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => setValues({}), 300);
  };

  const hasMorePosts = total > defaultLimit + offset;

  return (
    <DrinkLayout
      loading={!dataLoaded && loading}
      header="History"
      headerExtend={
        <div className="drink-history-header-row">
          <ButtonBaseStyled
            className={classNames(!showForAll && 'active')}
            onClick={() => setShowForAll(!showForAll)}
          >
            Mine
          </ButtonBaseStyled>
          <ButtonBaseStyled
            className={classNames(showForAll && 'active')}
            onClick={() => setShowForAll(!showForAll)}
          >
            All
          </ButtonBaseStyled>
        </div>
      }
    >
      <>
        <SessionStats showForAll={showForAll} />
        <div className="drink-history-body">
          {sessions.map((session) => {
            const alcTypes = groupBy(session.drinks, 'type._id');

            const label =
              Object.keys(alcTypes).length > 1 ? 'Combined' : session.drinks[0].type.name;

            const groupedPlayers = groupBy(session.drinks, 'player');

            const players: DarkListItemType[] = [];

            Object.keys(groupedPlayers).forEach((p) => {
              const username = session.players.find((player) => player._id === p)?.username;

              players.push({
                label: username as string,
                value: groupedPlayers[p].length,
                // drinks: groupedPlayers[p],
              });
            });

            return (
              <Fade
                key={session._id}
                in={!!drinkTypes.length}
                mountOnEnter
                style={
                  {
                    // transitionDelay: `${200 * (index % defaultLimit) + 'ms'}`,
                    // transitionDuration: '1200ms',
                  }
                }
              >
                <div className="drink-type-item">
                  <DarkListItemDetailed
                    date={Time.formatDate(session.date)}
                    count={session.drinks.length}
                    countHelper={
                      session.isLive && session.minutesPerDrink
                        ? `${session.minutesPerDrink} min per drink`
                        : undefined
                    }
                    label={label}
                    active={session.state !== 'FINISHED'}
                    items={players}
                    actions={[
                      session.state === 'FINISHED'
                        ? {
                            icon: 'edit',
                            onClick: () => {
                              setValues(session);
                              setIsOpen(true);
                            },
                          }
                        : {
                            icon: 'visibility',
                            onClick: () => history.push('/drinks/current'),
                          },
                    ]}
                  />
                </div>
              </Fade>
            );
          })}
          {(hasMorePosts || loadingMore) &&
            (loadingMore ? (
              <Loading size={30} color="#222" />
            ) : (
              <div className="drink-session-history-more">
                <ButtonBaseStyled
                  className="fullWidth"
                  onClick={() => loadData(offset + defaultLimit, true)}
                >
                  {translate.loadMore}
                </ButtonBaseStyled>
              </div>
            ))}
        </div>
        <Fade in>
          <div className="drink-history-footer">
            <DarkCircleIconButton icon="add" off onClick={() => setIsOpen(true)} />
            <DarkCircleIconButton icon="arrow_back" onClick={() => history.push('/drinks')} />
          </div>
        </Fade>
        <SessionEdit
          isOpen={isOpen}
          onClose={() => onCloseModal()}
          onUpdate={() => loadData(offset, true, true)}
          values={values}
        />
      </>
    </DrinkLayout>
  );
};

export default DrinksHistory;
