import React, { useState } from 'react';

import { imgPath } from '@api/api';

import { F1Team } from '@ts/F1';

type Props = {
  team: F1Team;
};

const Team = ({ team }: Props) => {
  const [loading, setLoading] = useState(true);

  return (
    <div className="f1-team" key={team.position}>
      <div className="f1-team-container">
        <div>
          <div className="f1-team-name">{team.team.name.replace(/F1|Scuderia|Team/g, '')}</div>
          <div className="f1-team-full-name">{team.team.name}</div>
        </div>
        <div className="f1-team-points">{team.points} PTS</div>
      </div>
      <img
        alt="team"
        className={`f1-team-image ${loading ? 'loading' : ''}`}
        onLoad={() => setLoading(false)}
        src={`${imgPath}/${team.image.link}`}
      />
    </div>
  );
};

export default Team;
