import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';

import { UnsafeAny } from '@ts/General';

import ScrollDown from '@layout/ScrollDown';
import ImagesContent, { ImageContentItem } from '@pages/AnalogPortfolio/ImagesContent';

import imgHomeIntro from '@images/analog-home-intro.webp';
import imgIcelandIntro from '@images/analog-iceland-intro.webp';

import styles from './style.scss';
import imagesConfig from '@pages/AnalogPortfolio/images-config';

type ConfigItemIntroSection = {
  main: string;
  text: string;
};

type ConfigItem = {
  id: string;
  title: string;
  titleMuted?: string;
  introSections?: ConfigItemIntroSection[];
  background?: string;
  backgroundOpacity?: number;
  divided?: boolean;
  withDivider?: boolean;
  backgroundClassName?: string;
  images: {
    getImage: (image: string) => string;
    items: ImageContentItem[];
  };
};

const configuration = [
  {
    id: 'alps',
    title: 'Alps',
    introSections: [
      { main: '01', text: 'Dolomites' },
      { main: '02', text: 'Südtirol' },
      { main: '03', text: 'Austrian alps' },
    ],
    divided: true,
    images: imagesConfig.alps,
  },
  {
    id: 'iceland',
    title: 'Iceland',
    background: imgIcelandIntro,
    backgroundClassName: styles.right,
    images: imagesConfig.iceland,
  },
  {
    id: 'home',
    title: 'Home',
    titleMuted: 'Land',
    background: imgHomeIntro,
    backgroundOpacity: 0.3,
    backgroundClassName: styles.full,
    images: imagesConfig.homeland,
  },
];

const AnalogDetail = () => {
  const { params } = useRouteMatch();

  const config: ConfigItem = configuration.find((item) => item.id === (params as UnsafeAny).type)!;

  const renderIntro = () => {
    return (
      <div className={classNames(styles.intro, config.divided && styles.divided)}>
        <div className={styles.introPart}>
          <div className={styles.introHeader}>
            <h1 className={styles.introTitle}>
              {config.title}
              {config.titleMuted && (
                <span className={styles.introTitleMuted}>{config.titleMuted}</span>
              )}
            </h1>
            <p className={styles.introSubtitle}>Analog photography</p>
          </div>
        </div>
        {config.withDivider && <div className={styles.introDivider} />}
        <div className={styles.introPart}>
          {config.introSections && (
            <div className={styles.introSections}>
              {config.introSections.map((section, index) => (
                <div key={index} className={styles.introSection}>
                  <p className={styles.introSectionMain}>{section.main}</p>
                  <p className={styles.introSectionText}>{section.text}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        {config.background && (
          <img
            className={classNames(styles.introBackground, config.backgroundClassName)}
            src={config.background}
            alt={config.id}
          />
        )}
        <ScrollDown type="ROUNDED" text="Explore" offset={8} />
      </div>
    );
  };

  return (
    <div className={styles.wrap}>
      {renderIntro()}
      <div className={styles.divider} />
      <div className={styles.content}>
        <ImagesContent config={config.images} />
      </div>
    </div>
  );
};

export default AnalogDetail;
