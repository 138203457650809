import { includes, toLower } from 'lodash-es';

import versionTracker, { settings } from '@assets/version';
import { Log, VersionParams } from '@ts/General';

const version = (params: VersionParams = {}, logs: Log[] = [...versionTracker]) => {
  let v = [0, 0, 0]; // start on version 0.0.0

  // Dynamically set actual version by version logs
  logs.forEach((l) => {
    if (settings.major(l)) {
      v = [v[0] + 1, 0, 0];
      l.major = true;
    } else if (settings.minor(l)) {
      v = [v[0], v[1] + 1, 0];
      l.minor = true;
    } else {
      v[2] += 1;
    }

    l.match = !!(params.module && l.tags.some((t) => includes(toLower(t), toLower(params.module))));
    l.version = v.join('.');
  });

  const finalV = v.join('.');

  if (params.spread) {
    return { version: finalV, logs };
  }

  if (params.withoutPrefix) {
    return finalV;
  }

  return `v${finalV}`;
};

export default {
  version,
};
