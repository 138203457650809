import React from 'react';
import { ButtonBase } from '@mui/material';

import { styled } from '@mui/material/styles';

import './style.css';
import classNames from 'classnames';

const DarkCircleButtonStyled = styled(ButtonBase)({
  color: '#ccc',
  minWidth: '200px',
  minHeight: '200px',
  fontFamily: 'Open sans',
  fontSize: 14,
  borderRadius: 100,
  background: '#222',
  border: `3px solid #CCC`,
  boxShadow: `0 2px 20px 0 rgba(0,0,0,0.50)`,
  transition: `.25s ease all`,
  textTransform: 'uppercase',
  flexDirection: 'column',
  letterSpacing: 3,
  whiteSpace: 'initial',
  margin: 10,
  ['&:hover']: {
    color: 'white',
  },
  ['&:active']: {},
  ['&:focus']: {},
  ['&:disabled']: {
    opacity: 0.4,
  },
  ['&.active']: {
    position: 'relative',
    zIndex: 1,
    border: '6px solid #3895D3',
    color: 'white',
    margin: 0,
  },
  ['&.loading']: {
    pointerEvents: 'none',
  },
});

type Props = {
  onClick?: (e: MouseEvent) => void;
  label?: string;
  subLabel?: string;
  subLabel2?: JSX.Element | string;
  highlight?: string;
  children?: string;
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
  count?: number;
};

const DarkCircleButton = ({
  onClick,
  label,
  subLabel,
  subLabel2,
  highlight,
  active,
  disabled,
  loading,
  count,
}: Props) => {
  const body = (
    <DarkCircleButtonStyled
      className={active ? 'active' : ''}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      onClick={!loading && onClick ? onClick : null}
      disabled={disabled}
    >
      {count !== undefined && <div className="dark-circle-button-count">{count}</div>}
      {highlight && <div className="dark-circle-button-highlight">{highlight}</div>}
      <div>{label}</div>
      {subLabel && <div className="dark-circle-button-sub-label">{subLabel}</div>}
      {subLabel2 && <div className="dark-circle-button-sub-label-2">{subLabel2}</div>}
      <div className={classNames('drink-circle-button-loading-wrap', { loading })} />
    </DarkCircleButtonStyled>
  );

  if (active) {
    return (
      <div className="drink-card">
        <div className="drink-card-content">{body}</div>
      </div>
    );
  }

  return body;
};

export default DarkCircleButton;
