import React from 'react';

import './style.css';

type Props = {
  open: boolean;
  submitText?: string;
  closeText?: string;
  onSubmit?: () => void;
  onExited?: () => void;
  onClose?: () => void;
  title: string;
  titleExtension: string;
  switchPriority?: boolean;
  content: JSX.Element | string;
  fullSize?: boolean;
};

const Modal = ({
  onExited = () => true,
  onClose,
  onSubmit,
  title,
  content,
  submitText = 'Yes',
  closeText = 'No',
  open,
  fullSize,
  titleExtension,
}: Props) => {
  return (
    <div
      className={`modal-wrap ${open ? 'open' : ''} ${fullSize ? 'full-size' : ''}`}
      onClick={onExited}
    >
      <div className="modal">
        <div className="modal-container">
          <div className="modal-title-wrap">
            <div className="modal-title">
              <div>{title}</div>
              {titleExtension && <div className="modal-title-extension">{titleExtension}</div>}
            </div>
          </div>
          <div className="modal-content">{content}</div>
        </div>
        {(onSubmit || onClose) && (
          <div className="modal-controls">
            <button onClick={onSubmit} className="modal-controls-button">
              {submitText}
            </button>
            <button onClick={onClose} className="modal-controls-button">
              {closeText}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
