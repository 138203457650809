import React, { useEffect } from 'react';
import classNames from 'classnames';
import { ButtonBase, Fade } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { camelCase } from 'lodash-es';

import { Common, Hooks } from '@functions';
import hooks from '@functions/hooks';

import styles from './style.scss';

type Props = {
  type: 'SQUARED_BLACK' | 'ROUNDED';
  text?: string;
  offset?: number;
};

const ScrollDown = ({ type, text = 'Explore more', offset }: Props) => {
  const activeGapMin = 10;
  const activeGapMax = window.innerHeight - 300;

  const scrollY = hooks.useScrollY();

  console.log(camelCase(type));

  const prevScrollY = Hooks.prevValue(scrollY) as number | undefined;

  useEffect(() => {
    if (window.innerWidth > 1360) {
      Common.typingCallBack(() => {
        if (scrollY > activeGapMin && scrollY < activeGapMax) {
          if ((prevScrollY || 0) < scrollY) {
            window.scrollTo({
              top: window.innerHeight + (offset || 0),
              behavior: 'smooth',
            });
          } else {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }
        }
      }, 600);
    }
  }, [scrollY]);

  return (
    <Fade in={scrollY < 100}>
      <div>
        <div className={classNames(styles.scrollBanner, styles[camelCase(type)])}>
          <ButtonBase
            onClick={() =>
              window.scrollTo({
                top: window.innerHeight + (offset || 0),
                behavior: 'smooth',
              })
            }
            classes={{ root: styles.scrollButton }}
          >
            {type !== 'ROUNDED' && (
              <KeyboardArrowDown classes={{ root: styles.scrollButtonIcon }} />
            )}
            {text}
          </ButtonBase>
        </div>
      </div>
    </Fade>
  );
};

export default ScrollDown;
