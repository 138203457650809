export default {
  results: 'Results',
  noItems: 'No items',
  notAvailable: 'n/a',
  loadMore: 'Load more',
  f1: {
    title: '',
    qualifying: 'Qualifying',
    sprintQualifying: 'The sprint',
    race: 'Race',
    menu: {
      news: 'News',
      voting: 'Voting',
      results: 'Results',
      drivers: 'Drivers',
      teams: 'Teams',
      circuits: 'Circuits',
      admin: 'Admin',
    },
    add: {
      addEvent: 'Add event',
      createEvent: 'Create event',
      schedule: 'Schedule ${year}',
      searchCircuit: 'Search circuit',
    },
    posts: {
      voting: {
        following: 'Vote for following event in',
        last: 'View results from last event',
        greetings: 'Thank you for voting',
      },
      add: {
        addPost: 'Add post',
        attachImage: 'Attach image',
        imageAttached: 'Image attached',
        contentPlaceholder: 'What do you think...',
      },
      comment: 'Comment',
      addComment: 'Add comment',
    },
    voting: {
      currentSession: 'Voting for current session',
      previousSession: 'Previous session results',
      seasonInPreparation: 'Season in preparation',
      position: 'Position ${isModalOpen} in ${type}',
      table: {
        order: 'Order',
        voted: 'Voted',
        match: 'Match',
        results: 'Results',
        powerRank: 'Power rank',
      },
      action: {
        setRaceResults: 'Set up race results',
        setQualifyingResults: 'Set up qualifying results',
        setSprintResults: 'Set up sprint results',
        addRaceTipsPrematurely: 'Add race tips prematurely',
        selectDriver: 'Click to select driver',
        saveTips: 'Save & Lock your ${type} tips',
        editVotesOrResults: 'Edit votes or results',
        cancelEditing: 'Cancel editing',
      },
      error: {
        DRIVER_SELECTED_TWICE: 'Some of the drivers are in your vote list twice!',
      },
      expandView: {
        show: 'Expand detail view',
        hide: 'Hide detail view',
      },
    },
    unit: {
      kms: 'Kms',
      pts: 'PTS',
      points: 'points',
    },
  },
  clipboard: {
    title: 'Clipboard',
    noClips: 'No clips',
    numberOfClips: '${count} Clips',
  },
  games: {
    gamingDay: 'Gaming day',
    menu: {
      dashboard: 'Dashboard',
      leaderboard: 'Leaderboard',
      stats: 'Stats',
      records: 'Records',
      types: 'Types',
    },
    season: 'Season',
    session: 'Session',
    startNewSession: 'Start new session',
    startingSession: 'Starting session',
    selectPlayers: 'Select players',
    players: 'Players',
    gamesAvailable: 'Games available',
    continue: 'Continue',
    confirmPlayers: 'Confirm players',
    types: {
      edit: 'Edit game type',
      add: 'Add game type',

      name: 'Name',
      id: 'ID',
      minPlayers: 'Min players',
      maxPlayers: 'Max players',
      unlimited: 'Unlimited',
    },
  },
  drinks: {
    menu: {
      dashboard: 'Dashboard',
      leaderboard: 'Leaderboard',
      stats: 'Stats',
      records: 'Records',
      types: 'Types',
    },
    season: 'Season',
    session: 'Session',
    startNewSession: 'Start new session',
    startingSession: 'Starting session',
    selectPlayers: 'Select players',
    players: 'Players',
    gamesAvailable: 'Drinks available',
    continue: 'Continue',
    confirmPlayers: 'Confirm players',
    sessions: {
      edit: 'Edit session',
      add: 'Add session',
    },
    types: {
      title: 'Drink types',
      edit: 'Edit drink type',
      add: 'Add drink type',

      name: 'Name',
      id: 'ID',
      alcoholType: 'Alcohol type',
      alcoholPercentage: 'Alcohol percentage',
      alcoholInDcl: 'Alcohol (dcl)',
      mixedWith: 'Mixed with',
      mixedRatio: 'Mixed ratio (%)',
      createdAt: 'Created at',
    },
  },
  pick: {
    types: {
      title: 'Pick types',
      add: 'Add pick type',
      edit: 'Edit pick type',

      name: 'Pick option',
      category: 'Category',
      chance: 'Chance',
    },
  },
};
