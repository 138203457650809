import { useEffect } from 'react';
import ReactGa from 'react-ga';

import { Env } from '@functions/index';

import settings from '../../assets/settings';

import './style.css';

type Props = {
  children: JSX.Element;
};

const Layout = ({ children }: Props) => {
  useEffect(() => {
    if (Env.isProduction()) {
      // eslint-disable-next-line import/no-named-as-default-member
      ReactGa.initialize(settings.googleAnalyticsKey);
    }
  });

  return children;
};

export default Layout;
