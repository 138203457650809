// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-new-screen-Rjcyt{position:fixed;top:0;left:0;width:100%;height:100%;transform:translateY(100%);transition:.7s ease all;background:#222;z-index:10}.wrap-new-screen-Rjcyt.active-\\+HDnA{transform:translateY(0)}@keyframes animate-9C86-{0%{opacity:0}60%{transform:translateX(0) scale(1.3)}to{opacity:1;transform:translateX(0) scale(1)}}.wrap-XRkAJ.unmount-FUOAW{animation:unmount-FUOAW .7s ease forwards;overflow:hidden}.wrap-XRkAJ.animated-Mu9Hd{animation:mount-5OFsV .4s ease forwards;overflow:hidden}.wrap-XRkAJ.fixed-fOWxk{overflow:hidden}.wrap-XRkAJ{width:100vw;height:100svh}.base-4NpOT{height:100svh}@keyframes unmount-FUOAW{to{transform:translateY(-10%);opacity:0}}@keyframes mount-5OFsV{from{opacity:0}to{opacity:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-new-screen": "wrap-new-screen-Rjcyt",
	"active": "active-+HDnA",
	"wrap": "wrap-XRkAJ",
	"unmount": "unmount-FUOAW",
	"animated": "animated-Mu9Hd",
	"mount": "mount-5OFsV",
	"fixed": "fixed-fOWxk",
	"base": "base-4NpOT",
	"animate": "animate-9C86-"
};
export default ___CSS_LOADER_EXPORT___;
