import React from 'react';
import PropTypes from 'prop-types';
import MaterialButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ButtonControllerStyled = styled(MaterialButton)({
  'color': '#ddd',
  'borderRadius': '100%',
  'width': '450px',
  'height': '450px',
  'fontFamily': 'Varem',
  'fontSize': 26,
  '&:hover': {

  },
  '&:active': {

  },
  '&:focus': {

  },
});

const ButtonController = ({ label, onClick, disabled, children }) => {
  return (<ButtonControllerStyled onClick={onClick} disabled={disabled}>{label || children}</ButtonControllerStyled>);
};

ButtonController.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

export default ButtonController;
