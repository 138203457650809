import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import API from '@api/index';

import { endpoints } from '@api/endpoints';

import Layout from '@pages/Pick/layout';
import DarkCircleButton from '@common/DarkCircleButton';
import PickSummary from '@pages/Pick/components/PickSummary';

import { PickType } from '@ts/Pick';

import { translate } from '@localise/index';

import { User } from '@functions';

import styles from './style.scss';

const PicksOverview = ({}) => {
  const [lastPick, setLastPick] = useState<PickType | null>(null);

  const history = useHistory();

  const onPick = async (category: string) => {
    try {
      const resp = await API.post(endpoints.pick.random, { category, user: User.id() });
      setLastPick(resp.picked as PickType);
    } catch (err) {
      API.err(err);
    }
  };

  if (lastPick) {
    return <PickSummary data={lastPick} onClose={() => setLastPick(null)} />;
  }

  return (
    <Layout header={translate.pick.types.title} onCornerClick={() => history.push('/picks/types')}>
      <div className="drink-overview">
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        <DarkCircleButton label="Pick" subLabel="clan" onClick={(_e) => onPick('NG')} />
      </div>
    </Layout>
  );
};

export default PicksOverview;
