import React from 'react';
import { ButtonBase } from '@mui/material';

import { styled } from '@mui/material/styles';
import classNames from 'classnames';

const DarkListItemStyled = styled(ButtonBase)({
  color: '#ddd',
  fontFamily: 'Open sans',
  fontSize: 14,
  fontWeight: 'bold',
  background: '#222',
  transition: `.25s ease all`,
  textTransform: 'uppercase',
  letterSpacing: 3,
  minHeight: '54px',
  whiteSpace: 'initial',
  // margin: '2px 20px',
  // borderRadius: 15,
  borderBottom: '2px solid #555',
  ['&:hover']: {
    color: 'white',
  },
  ['&:active']: {},
  ['&:focus']: {},
  ['&:disabled']: {
    opacity: 0.4,
  },
  ['&.active']: {
    background: '#222',
  },
  ['&.fade']: {
    background: '#ddd',
    color: '#222',
    borderBottom: 'none',
  },
  ['&.white']: {
    background: 'transparent',
    color: '#222',
    borderBottom: 'none',
  },
  ['&.rounded']: {
    margin: '0 20px',
    borderRadius: 100,
    marginBottom: '4px',
  },
});

type Props = {
  onClick?: (e: MouseEvent) => void;
  label?: string;
  subLabel?: string;
  children?: string;
  active?: boolean;
  fade?: boolean;
  white?: boolean;
  disabled?: boolean;
  rounded?: boolean;
};

const DarkListItem = ({
  onClick,
  label,
  subLabel,
  active,
  disabled,
  fade,
  white,
  rounded,
}: Props) => {
  return (
    <DarkListItemStyled
      className={classNames('', { active, fade, white, rounded })}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      onClick={onClick ? onClick : null}
      disabled={disabled}
    >
      <div>{label}</div>
      {subLabel && <div>{subLabel}</div>}
    </DarkListItemStyled>
  );
};

export default DarkListItem;
