// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-S2u04{background:#fff;min-height:100svh}.header-wi6BQ{background:#333;color:#fff;display:flex;justify-content:space-between}.imageWrap-1FUzD{aspect-ratio:16/9;overflow:hidden}.image-JmRan{width:100%}.body-9dG6H{padding:40px 0;display:flex;flex-direction:column;align-items:center}.grid-Czzlw,.gridPortrait-isI\\+l{display:grid;grid-template-columns:400px 400px 400px;grid-template-rows:auto;column-gap:30px;row-gap:30px}.gridPortrait-isI\\+l{margin-top:30px;grid-template-columns:228px 228px 228px 228px 228px}.gridItem-a6RWL{aspect-ratio:16/9;overflow:hidden}.gridItemPortrait-mdeqf{aspect-ratio:3/4;overflow:hidden}.backButton-ab9nb{color:#fff;cursor:pointer;padding:10px 20px;background:#333;display:flex;justify-content:center;align-items:center;text-transform:uppercase}.flags-hHJuV{display:flex}.flag-abGeT{display:flex;align-items:center;padding:14px 20px;color:#555;font-weight:bolder;text-transform:uppercase;font-size:12px;cursor:pointer}.flag-abGeT:hover{color:#aaa}.flag-abGeT.active-EaXUH{color:#fff}@media(max-width: 900px){.grid-Czzlw{grid-template-columns:400px 400px}.gridPortrait-isI\\+l{grid-template-columns:228px 228px 228px}}@media(max-width: 700px){.grid-Czzlw{grid-template-columns:400px}.gridPortrait-isI\\+l{grid-template-columns:228px 228px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-S2u04",
	"header": "header-wi6BQ",
	"imageWrap": "imageWrap-1FUzD",
	"image": "image-JmRan",
	"body": "body-9dG6H",
	"grid": "grid-Czzlw",
	"gridPortrait": "gridPortrait-isI+l",
	"gridItem": "gridItem-a6RWL",
	"gridItemPortrait": "gridItemPortrait-mdeqf",
	"backButton": "backButton-ab9nb",
	"flags": "flags-hHJuV",
	"flag": "flag-abGeT",
	"active": "active-EaXUH"
};
export default ___CSS_LOADER_EXPORT___;
