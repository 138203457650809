// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iconButton-AqsVt{border:2px solid #333 !important;background:#333 !important;padding:8px !important;border-radius:100% !important}.iconButton-AqsVt path{transition:.3s ease fill}.iconButton-AqsVt:hover{background:hsla(0,0%,100%,.1254901961)}.iconButton-AqsVt.remove-Hb2uY:hover path{fill:red}.copy-to-clipboard-7jYS0 .iconButton-AqsVt:hover path,.copied-\\+hu0w .iconButton-AqsVt path{fill:#3895d3}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconButton": "iconButton-AqsVt",
	"remove": "remove-Hb2uY",
	"copy-to-clipboard": "copy-to-clipboard-7jYS0",
	"copied": "copied-+hu0w"
};
export default ___CSS_LOADER_EXPORT___;
