import React, { useContext, useEffect, useState } from 'react';
import { groupBy, sum } from 'lodash-es';

import API from '@api/index';

import { F1Context } from '@context/f1Data';

import { endpoints } from '@api/endpoints';

import { Cookie, Common, F1 } from '../../../../../functions';

import F1Layout from '../../layout';
import Season from '../../components/Season';
import Block from './Block';

import f1Image from '../../../../../assets/img/f1.jpg';

import { F1UserRanking, F1Vote } from '@ts/F1';

import './style.css';

const Results = () => {
  const [votes, setVotes] = useState<F1Vote[] | null>(null);
  const [qRanking, setQRanking] = useState<F1UserRanking[]>([]);
  const [rRanking, setRRanking] = useState<F1UserRanking[]>([]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [data] = useContext(F1Context);

  const { users } = data;

  const fetchVotes = async () => {
    const season = Cookie.get('f1-season') || Common.getYear();
    try {
      const data = await API.post(endpoints.f1.votes.search, { season });

      /**
       console.log(JSON.stringify(processData[0]));

       console.log(processData);


       for (const d of processData) {
          try {
            await API.post(endpoints.f1.votes.add, d);
          } catch (e) {
          }
       } **/

      setVotes(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (votes) {
      const dataUsers = groupBy(votes, 'user');
      const resultsQ = groupBy(
        votes.filter((item) => item.type === 'QUALIFYING_RESULTS'),
        'race._id',
      );
      const resultsR = groupBy(
        votes.filter((item) => item.type === 'RACE_RESULTS'),
        'race._id',
      );

      const ranks = Object.keys(dataUsers).map((user) => {
        const races = groupBy(dataUsers[user], 'race._id');

        const points = Object.keys(races).map((r) => {
          const qPoints = F1.getRanking(
            resultsQ[r],
            races[r].filter((item) => item.type === 'QUALIFYING'),
          ).total;
          const rPoints = F1.getRanking(
            resultsR[r],
            races[r].filter((item) => item.type === 'RACE'),
          ).total;

          return { qPoints, rPoints };
        });

        const qPointsTotal = sum(points.map((p) => p.qPoints));
        const rPointsTotal = sum(points.map((p) => p.rPoints));

        return {
          user,
          points,
          qPointsTotal,
          rPointsTotal,
          totalPoints: qPointsTotal + rPointsTotal,
        };
      });

      setQRanking(Common.sort([...ranks], 'qPointsTotal').reverse() as F1UserRanking[]);
      setRRanking(Common.sort([...ranks], 'rPointsTotal').reverse() as F1UserRanking[]);
    }
  }, [votes]);

  useEffect(() => {
    fetchVotes();
  }, []);

  return (
    <F1Layout loading={!votes || !users}>
      <div className="f1-ranking-wrap">
        <Season onChange={fetchVotes} />
        <div className="f1-ranks">
          <div className="f1-ranking">
            {!qRanking.length && !rRanking.length ? (
              <div className="f1-season-wrap">
                <img className="f1-season-image" src={f1Image} alt="season" />
                <div className="f1-season-text">No ranking yet</div>
              </div>
            ) : (
              <>
                <Block ranking={qRanking} users={users} type={F1.QUALIFYING} />
                <Block ranking={rRanking} users={users} type={F1.RACE} primary />
              </>
            )}
          </div>
        </div>
      </div>
    </F1Layout>
  );
};

export default Results;
