import React from 'react';

import logoWhite from '../../../components/f1-logo-white.svg';
import logo from '../../../components/f1-logo.svg';

import { F1UserRanking } from '@ts/F1';
import { User } from '@ts/User';

import './style.css';

type Props = {
  users: User[];
  ranking: F1UserRanking[];
  type: string;
  primary?: boolean;
};

const Block = ({ users, ranking, type, primary }: Props) => {
  const position = (user: F1UserRanking, index: number) => {
    const username = users.find((u: User) => u._id === user.user)?.username;

    return (
      <div key={index} className="f1-user-ranking">
        <div className="f1-user-ranking-position">{index + 1}</div>
        <div className="f1-user-ranking-body">
          <div className="f1-user-ranking-name">{username}</div>
          <div className="f1-user-ranking-points">
            {(type === 'RACE' ? user.rPointsTotal : user.qPointsTotal) || 0} <span>PTS</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`f1-ranking-block ${primary ? 'primary' : ''}`}>
      <div className="f1-ranking-header">
        <img src={primary ? logoWhite : logo} className="f1-ranking-logo" alt="ranking" />
        <div className="f1-ranking-title">{type === 'RACE' ? 'Race' : 'Qualifying'} results</div>
      </div>
      <div className="f1-ranking-body">{ranking.length && ranking.map(position)}</div>
    </div>
  );
};

export default Block;
