import React, { MouseEvent } from 'react';

import './style.css';

type Props = {
  children: string | JSX.Element;
  onClick?: (e: MouseEvent) => true;
  className?: string;
  size?: string | number;
  color?: string;
};

const Icon = ({ children, className = '', onClick, size = 24, color }: Props) => {
  const style: Record<string, unknown> = { fontSize: size };

  if (color) {
    style.color = color;
  }

  const icon = (
    <i className={`material-icons ${className}`} style={style}>
      {children}
    </i>
  );

  if (onClick) {
    return (
      <button onClick={onClick} className="material-icons-button">
        {icon}
      </button>
    );
  }

  return icon;
};

export default Icon;
