import React, { Component, MouseEvent } from 'react';

import { flags, type } from '@assets/images';

import Tag from '@common/Tag';
import { Image } from '@common/MyGallery';

import * as _ from 'lodash-es';
import './style.css';
import Icon from '../SVG';

type Props = {
  images: Image[];
  portrait?: boolean;
  onClick: (e: MouseEvent) => void;
};

type State = {
  config: Image[];
  index: number;
  activeFlag?: string;
};

class MyGalleryTagger extends Component<Props, State> {
  state = {
    index: 0,
    config: [],
  };

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.images && this.props.images) {
      this.setState({ config: this.props.images });
    }
  }

  componentDidMount() {
    this.setState({ config: this.props.images });
  }

  componentWillUnmount() {
    console.log(JSON.stringify(this.state.config));
  }

  get nextNumber() {
    return Math.max(...this.state.config.map((c) => Number(c.src.replace(/[^0-9]/gi, '')))) + 1;
  }

  updateConfig(update: Record<string, boolean>, id: number) {
    if (update.girls) {
      update.people = true;
    }

    if (update.subway) {
      update.city = true;
    }

    const newConfig = [...this.state.config] as Image[];
    const index = newConfig.findIndex((img) => img.id === id);

    if (index !== -1) {
      newConfig[index] = { ...newConfig[index], ...update };

      this.setState({ config: newConfig });
    }
  }

  get diff() {
    const { config } = this.state;

    const added = config.length - this.props.images.length;
    const diff = this.difference([...config].slice(added, config.length), this.props.images).filter(
      Boolean,
    );

    return { added, changed: diff.length };
  }

  difference(object, base) {
    function changes(object, base) {
      return _.transform(object, function (result, value, key) {
        if (!_.isEqual(value, base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
        }
      });
    }

    return changes(object, base);
  }

  addNewImage() {
    const { config } = this.state;

    const newImage = {
      src: `http://dominiklaclavik.com/assets/images/portfolio/previews/IMG-${this.nextNumber}.jpg`,
      id: this.nextNumber,
      type: type.landscape,
    };

    const newConfig = [newImage, ...config];

    this.setState({ config: newConfig });
  }

  get grid() {
    const { activeFlag, config } = this.state;

    let images = config as Image[];

    if (activeFlag) {
      images = config.filter((i) => i[activeFlag]);
    }

    return images.map((img, i) => (
      <div key={img.id} className={`tagger-square ${img.type}`}>
        <img
          alt="config-image"
          onClick={() =>
            this.updateConfig(
              { type: img.type === type.landscape ? type.portrait : type.landscape },
              img.id,
            )
          }
          className="tagger-image"
          src={img.src}
          onError={() => console.log('failed to load image')}
        />
        <div className="tagger-image-controls">
          {flags.map((f) => (
            <div
              key={f}
              className={`tagger-image-flag ${img[f] ? 'active' : ''}`}
              onClick={() => this.updateConfig({ [f]: img[f] ? undefined : true }, img.id)}
            >
              {f}
            </div>
          ))}
        </div>
        <div className={`image-status ${flags.find((f) => img[f]) ? 'active' : ''}`} />
      </div>
    ));
  }

  get status() {
    const addedLabel = `Added ${this.diff.added} images`;
    const changedLabel = `Changed ${this.diff.changed} images`;

    if (this.diff.changed && this.diff.added) return `${addedLabel}  &&  ${changedLabel}`;
    if (this.diff.changed) return changedLabel;
    if (this.diff.added) return addedLabel;
    return 'No changes';
  }

  filter(flag: string | null) {
    this.setState({ activeFlag: this.state.activeFlag === flag ? null : flag });
  }

  render() {
    const { activeFlag } = this.state;

    return (
      <>
        <div
          className={`tagger-console ${this.diff.changed || this.diff.added ? 'active' : ''}`}
          onClick={() => console.log(JSON.stringify(this.state.config))}
        >
          {this.status}
        </div>
        <div className="tagger-categories">
          {flags.map((f) => (
            <div
              className={`tagger-image-flag ${activeFlag === f ? 'active' : ''}`}
              key={f}
              onClick={() => this.filter(f)}
            >
              {f}
            </div>
          ))}
          <div
            className={`tagger-image-flag ${!activeFlag ? 'active' : ''}`}
            key={'all'}
            onClick={() => this.filter(null)}
          >
            Show all
          </div>
        </div>
        <div className="tagger-images-wrap">
          <div className="tagger-add-new-images" onClick={() => this.addNewImage()}>
            <Icon icon="add" width={100} height={100} color="#ddd" />
            <div className="tagger-tag">
              <Tag>Next: {this.nextNumber}</Tag>
            </div>
          </div>
          {this.grid}
        </div>
      </>
    );
  }
}

export default MyGalleryTagger;
