import React, { useContext, useEffect, useState } from 'react';
import { Box, Tabs, Tab, Collapse, Menu, MenuItem } from '@mui/material';
import { Person } from '@mui/icons-material';

import API from '@api/index';
import { endpoints } from '@api/endpoints';

import { Adapters, Common } from '@functions';

import { translate } from '@localise/index';
import {
  StyledButtonBase,
  StyledButtonBaseActive,
  StyledDialog,
  StyledTextField,
} from '@pages/Drinks/pages/Types';
import { Drink, DrinkSession, DrinkSessionRequest } from '@ts/Drink';
import { User, UserSimple } from '@ts/User';

import { DrinksContext } from '@context/drinksData';
import DarkCircleIconButton from '@common/DarkCircleIconButton';
import DarkRoundedButton from '@common/DarkRoundedButton';

import SessionDrinks from '@pages/Drinks/components/SessionEdit/SessionDrinks';

import styles from './style.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (session?: DrinkSession) => void;
  values: Partial<DrinkSession> | null;
};

export type FieldOptions = {
  name: string;
  placeholder?: string;
  min?: number;
  max?: number;
  type?: 'number' | 'date' | 'datetime-local';
  value?: string | number;
  label?: string;
  disabled?: boolean;
  helper?: string;
};

const SessionEditModal = ({ isOpen, onUpdate, onClose, values: propValues }: Props) => {
  const [values, setValues] = useState<Partial<DrinkSession>>({ isLive: false });
  const [tab, setTab] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const [playerAnchorEl, setPlayerAnchorEl] = useState<HTMLButtonElement | null>(null);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, dataLoaded] = useContext(DrinksContext);

  const season = Common.getYear();

  useEffect(() => {
    if (isOpen) {
      setValues(propValues || { isLive: false });
      setTab(0);
    }
  }, [isOpen, propValues?._id]);

  const isEdit = !!values._id;

  const onSave = async () => {
    const dataToSend = {
      ...values,
    } as unknown as DrinkSessionRequest;

    dataToSend.drinks = (values.drinks as Drink[]).map((d) => ({ ...d, type: d.type._id }));
    dataToSend.players = (values.players as UserSimple[]).map((p) => p._id);

    let session;

    try {
      if (isEdit) {
        session = await API.put(
          endpoints.drink.session.update(values._id as string),
          Adapters.cleanObject(dataToSend),
        );
      } else {
        session = await API.post(endpoints.drink.session.add, {
          season,
          ...dataToSend,
          state: 'FINISHED',
        });
      }

      onUpdate(session);
      onClose();
      setDataChanged(false);
    } catch (err) {
      API.err(err);
    }
  };

  const onDeleteSession = async () => {
    try {
      await API.del(endpoints.drink.session.delete(values._id as string));

      onUpdate();
      onClose();
      setDataChanged(false);
    } catch (err) {
      API.err(err);
    }
  };

  const updateValue = (field: FieldOptions, value: string | number) => {
    const newValues = { ...values };

    if (field.type === 'number' && value !== '') {
      const val = Number(value);

      if (!isNaN(val) && field.min && field.min > val) {
        return;
      }

      if (!isNaN(val) && field.max && field.max <= val) {
        return;
      }
    }

    if (field.name === 'name') {
      newValues.id = (value as string).toUpperCase().replace(/\s/g, '_');
    }

    if (field.name === 'id') {
      value = (value as string).toUpperCase().replace(/\s+/g, '_');
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newValues[field.name] = value;

    console.log(newValues);

    setValues(newValues);
    setDataChanged(true);
  };

  const field = (field: FieldOptions) => {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    const value = field.value || values[field.name];

    return (
      <div className="drink-type-field" key={field.name}>
        <StyledTextField
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          fullWidth
          id={`drink-field-${field.name}`}
          onChange={(e) => {
            updateValue(field, e.target.value);
          }}
          value={value}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          label={field.label || translate.drinks.types[field.name] || field.name}
          placeholder={field.placeholder}
          disabled={field.disabled}
          type={field.type}
          autoComplete="off"
        />
      </div>
    );
  };

  const onUpdateDrinks = (drinks: Drink[]) => {
    const newValues = { ...values };

    newValues.drinks = drinks;

    setValues(newValues);
    setDataChanged(true);
  };

  const onAddPlayer = (player: User) => {
    const newValues = { ...values };

    if (newValues.players) {
      newValues.players.push(player);
    } else {
      newValues.players = [player];
    }

    setValues(newValues);
    setDataChanged(true);
  };

  const actualPlayer = values.players && values.players[tab - 1];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const renderMenu = (
    <>
      <Menu
        anchorEl={playerAnchorEl}
        open={!!playerAnchorEl}
        onClose={() => setPlayerAnchorEl(null)}
      >
        {data.users.map((user: User) => (
          <MenuItem
            key={user._id}
            disabled={values?.players?.some((p) => p._id === user._id)}
            onClick={() => {
              onAddPlayer(user);
            }}
          >
            <Box display="flex" alignItems="center">
              <Box minWidth="36px" pt={1}>
                <Person fontSize="small" />
              </Box>
              {user.name}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  return (
    <StyledDialog
      open={isOpen}
      onClose={() => {
        setDataChanged(false);
        onClose();
      }}
      classes={{ container: 'dialog-white-overflow' }}
    >
      <Box display="flex" flexDirection="column" minHeight="600px">
        <Box display="flex" flexDirection="column">
          <Tabs value={tab} onChange={handleChange} scrollButtons="auto" centered>
            <Tab label="Session info" />
            {values.players?.map((user, index) => (
              <Tab label={user.username} value={index + 1} key={index} />
            ))}
          </Tabs>
        </Box>

        <div className={styles.form}>
          <Collapse in={tab === 0}>
            <div className={styles.formTitle}>
              {values._id ? translate.drinks.sessions.edit : translate.drinks.sessions.add}
            </div>
          </Collapse>
          <div className={styles.formBody}>
            {tab === 0 && (
              <>
                {field({ name: 'name' })}
                {field({
                  name: 'date',
                  type: 'datetime-local',
                  disabled: !!values._id && values.state !== 'FINISHED',
                  value:
                    values.date && values.date.length > 16
                      ? new Date(values.date as string).toISOString().slice(0, 16)
                      : undefined,
                })}
                {values.isLive &&
                  values.state === 'FINISHED' &&
                  field({
                    name: 'endTime',
                    type: 'datetime-local',
                    disabled: !!values._id && values.state !== 'FINISHED',
                    value:
                      values.endTime && values.endTime.length > 16
                        ? new Date(values.endTime as string).toISOString().slice(0, 16)
                        : undefined,
                  })}
                <Box mt={16} display="flex" justifyContent="center">
                  <DarkRoundedButton
                    onClick={(e) => setPlayerAnchorEl(e.currentTarget)}
                    label="Add player"
                  />
                </Box>
                {isEdit && values.state === 'FINISHED' && (
                  <Box mt={2} display="flex" justifyContent="center">
                    <DarkCircleIconButton
                      options={[{ label: 'Remove session', onClick: () => onDeleteSession() }]}
                      icon="delete_forever"
                    />
                  </Box>
                )}
              </>
            )}
            {tab !== 0 && (
              <>
                <SessionDrinks
                  session={values as DrinkSession}
                  player={actualPlayer}
                  onUpdate={onUpdateDrinks}
                />
              </>
            )}
          </div>
          <div className={styles.formActions}>
            <StyledButtonBase
              onClick={() => {
                onClose();
                setDataChanged(false);
              }}
            >
              Cancel
            </StyledButtonBase>
            <StyledButtonBaseActive disabled={!dataChanged} onClick={onSave}>
              Save
            </StyledButtonBaseActive>
          </div>
        </div>
      </Box>
      {renderMenu}
    </StyledDialog>
  );
};

export default SessionEditModal;
