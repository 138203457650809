import React, { MouseEvent } from 'react';
import { ButtonBase } from '@mui/material';
import classNames from 'classnames';

import Icon from '../Icon';
import SVG, { isValid } from '../SVG';

import styles from './style.scss';

type Props = {
  icon: string;
  size: string | number;
  onClick?: (e: MouseEvent) => void;
  color?: string;
  children?: string | JSX.Element;
  className?: string;
};

const IconButton = ({ icon, size, className = '', onClick, color = 'white' }: Props) => {
  const iconComponent = isValid(icon) ? (
    <SVG width={size} height={size} icon={icon} color={color} />
  ) : (
    <Icon className={className} size={Number(size) + 4} color={color}>
      {icon}
    </Icon>
  );

  return (
    <ButtonBase classes={{ root: classNames(styles.iconButton, className) }} onClick={onClick}>
      {iconComponent}
    </ButtonBase>
  );
};

export default IconButton;
