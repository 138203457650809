import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';

import API from '@api/index';

import { F1Context } from '@context/f1Data';
import { User, F1 } from '@functions';
import { translate } from '@localise/index';
import { endpoints } from '@api/endpoints';

import F1Layout from '../../layout';
import Post from './components/Post';
import NewPost from './components/NewPost';
import Notice from './components/Notice';

import { History } from 'history';

import { SimpleObject } from '@ts/General';
import { F1Post } from '@ts/F1';

import './style.css';

const updateTime = 60;
const defaultLimit = 12;

type Props = {
  history: History;
};

const News = ({ history }: Props) => {
  const [posts, setPosts] = useState<F1Post[] | null>(null);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [upcoming, setUpcoming] = useState<SimpleObject | null>(null);
  const [recent, setRecent] = useState<SimpleObject | null>(null);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setSelectedSeason] = useContext(F1Context);

  const loadNotices = async () => {
    const user = User.hasAccess();

    if (!user) return;

    try {
      const data = await API.post(endpoints.f1.races.status, { user });

      if (data.upcoming) {
        const voted =
          (data.status.qualifyingVoted && !data.status.qualifyingFinished) || data.status.raceVoted;

        const race = {
          text: !voted ? translate.f1.posts.voting.following : translate.f1.posts.voting.greetings,
          highlight: data.upcoming.place,
          onClick: () => {
            F1.setSeason(data.upcoming.season);
            setSelectedSeason(data.upcoming.season);
            history.push('/f1/vote');
          },
          icon: !voted ? 'history_toggle_off' : 'verified',
          iconColor: '#fff',
        };

        setUpcoming(race as SimpleObject);
      }

      if (data.recent) {
        const race = {
          text: translate.f1.posts.voting.last,
          highlight: data.recent.place,
          onClick: () => {
            F1.setSeason(data.recent.season);
            setSelectedSeason(data.recent.season);
            history.push('/f1/vote');
          },
          icon: 'history',
          iconColor: '#E00600',
        };

        if (data.upcoming && data.upcoming.qualifyingFinished) {
          race.highlight = data.upcoming.place;
        }

        setRecent(race);
      }
    } catch (e) {
      API.err(e);
    }
  };

  const loadPosts = async (offset: number, isLoad?: boolean) => {
    if (isLoad) {
      setOffset(offset);
    }

    const data = {
      offset: !isLoad ? 0 : offset,
      limit: !isLoad ? (offset + 1) * defaultLimit : defaultLimit,
    };

    console.log(data);

    try {
      const rows = await API.get(endpoints.f1.posts.search);

      if (isLoad && posts) {
        setPosts([...posts, ...rows]);
      } else {
        setPosts(rows);
      }
      setTotal(rows.length);
    } catch (e) {
      API.err(e);
    }
  };

  useEffect(() => {
    loadPosts(offset);
    loadNotices();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => loadPosts(offset), updateTime * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [offset]);

  const hasMorePosts = total > defaultLimit * (offset + 1);

  return (
    <F1Layout loading={!posts}>
      <div className="news">
        <div className="news-wrap">
          <div className="news-notices">
            {upcoming && <Notice warning {...upcoming} />}
            {recent && <Notice {...recent} />}
          </div>
          <div className="news-add">
            <NewPost
              onAdd={() => {
                loadPosts(0);
              }}
            />
          </div>
          <div className="news-posts">
            {posts &&
              posts.map((post) => (
                <Post key={post._id} post={post} onUpdate={() => loadPosts(offset)} />
              ))}
            <div className="news-post-extra">
              {hasMorePosts && (
                <button
                  className="news-post-load-more"
                  onClick={() => loadPosts(offset + defaultLimit, true)}
                >
                  {translate.loadMore}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </F1Layout>
  );
};

export default withRouter(News);
