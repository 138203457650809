import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { includes } from 'lodash-es';

import Loading from './../components/Loading';

import bgImg from '../../../../assets/backgrounds/bg_small.jpg';

import './style.css';
import { Env } from '../../../../functions';
import ReactGa from 'react-ga';
import Icon from '../../../common/Icon';

const VotesLayout = ({ loading, title = 'Vote', titleExtend, children, unmount, history }) => {
  const [unmountAnimation, setUnmountAnimation] = useState(false);

  const redirect = (page) => {
    setUnmountAnimation(true);

    // Send page-view to GA
    if (Env.isProduction()) {
      ReactGa.pageview(page);
    }

    // Redirect to page after some timeout
    setTimeout(() => history.push(page), 300);
  };

  useEffect(() => {
    if (unmount) {
      redirect(unmount);
    }
  }, [unmount]);

  const isMainPage = !includes(window.location.pathname.slice(1), '/');

  return (
    <>
      <div className="vote">
        <div className="vote-bg-wrap">
          <img className="vote-bg" src={bgImg}/>
          <div className="vote-bg-overlay"/>
        </div>
        <Loading loading={loading}/>
        <div className={`vote-container ${!loading ? 'loaded' : ''} ${unmountAnimation ? 'unmount' : ''}`}>
          <div className="vote-body">
            <div className="vote-title">
              {!isMainPage &&
              <div className="vote-back-page" onClick={() => redirect('/votes')}>
                <Icon color="#999" size={26}>arrow_back</Icon>
              </div>
              }
              <span className="vote-title-text">{title}</span>
              {titleExtend && <span className="vote-title-extend">{titleExtend}</span>}
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

VotesLayout.propTypes = {
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  title: PropTypes.string,
  titleExtend: PropTypes.string,
  children: PropTypes.any,
  unmount: PropTypes.string,
};

export default withRouter(VotesLayout);
