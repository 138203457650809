import React from 'react';

import { PickType } from '@ts/Pick';

import styles from './style.scss';

type Props = {
  data: PickType;
  onClose: () => void;
};

const pickLabels = [
  'Enjoy',
  'Picked',
  'Selected',
  'Yes!',
  'In style with',
  'Head up!',
  'In love with',
  'No pain!',
  'Forced to',
  'Dreamed',
  'Easy!',
  'Believe in',
  'Friendly',
  'Champion',
  'Traditonal',
  'Jesus!',
];

const onRandomSelect = () => pickLabels[Math.ceil(Math.random() * pickLabels.length) - 1];

const PickSummary = ({ data, onClose }: Props) => {
  return (
    <div onClick={() => onClose()}>
      <div className={styles.wrap}>
        <div className={styles.stats}>
          <div className={styles.stat}>
            <div className={styles.label}>{onRandomSelect()}</div>
            <div className={styles.value}>{data.name}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickSummary;
