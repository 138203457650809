import React from 'react';
import { Input } from '@mui/material';
import classNames from 'classnames';

import styles from './style.scss';

type TextFieldOptions = {
  fullWidth?: boolean;
  autoFocus?: boolean;
};

type Props = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  label?: string;
  id?: string;
  options?: TextFieldOptions;
  placeholder?: string;
  classes?: Record<string, string>;
  inputProps?: Record<string, unknown>;
  onKeyPress?: (event: React.KeyboardEvent) => void;
};

const DarkTextField = ({
  onChange,
  value,
  id,
  // label,
  placeholder,
  name,
  classes = {},
  options = {},
  ...props
}: Props) => {
  return (
    <Input
      onChange={onChange}
      value={value}
      id={id || name ? `input_${id || name}` : undefined}
      name={name}
      classes={{
        ...classes,
        root: classNames(styles.textField, classes.root),
        focused: styles.focused,
        // label: styles.label,
      }}
      placeholder={placeholder}
      fullWidth={options.fullWidth}
      autoFocus={options.autoFocus}
      inputProps={{
        className: styles.input,
        autoComplete: 'off',
        ...(props.inputProps || {}),
      }}
      {...props}
    />
  );
};

export default DarkTextField;
