import React from 'react';
import { ButtonBase, Icon } from '@mui/material';

import { styled } from '@mui/material/styles';
import classNames from 'classnames';

const DarkListItemCircleButtonStyled = styled(ButtonBase)({
  color: 'white',
  minWidth: '44px',
  minHeight: '44px',
  fontFamily: 'Open sans',
  borderRadius: 100,
  background: 'rgba(255,255,255,0.13)',
  transition: `.25s ease all`,
  alignSelf: 'center',
  margin: 10,
  ['&:hover']: {
    color: 'white',
  },
  ['&:active']: {},
  ['&:focus']: {},
  ['&:disabled']: {
    opacity: 0.4,
  },
  ['&.off']: {
    background: 'transparent',
    color: 'white',
  },
  ['&.active']: {},
});

type Props = {
  onClick?: (e: MouseEvent) => void;
  icon?: string;
  children?: string;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  off?: boolean;
};

const DarkListItemCircleButton = ({ onClick, icon, active, disabled, off, className }: Props) => {
  const body = (
    <DarkListItemCircleButtonStyled
      className={classNames(className, { off, active })}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      onClick={onClick ? onClick : null}
      disabled={disabled}
    >
      <Icon>{icon}</Icon>
    </DarkListItemCircleButtonStyled>
  );

  if (active) {
    return (
      <div className="drink-card">
        <div className="drink-card-content">{body}</div>
      </div>
    );
  }

  return body;
};

export default DarkListItemCircleButton;
