import { Log } from '@ts/General';

export const settings = {
  minor: (v: Log) => v.tags.length >= 3 || v.minor,
  major: (v: Log) => v.major || v.tags.length >= 5,
};

export default [
  { tags: ['Home page implemented', 'Portfolio page implemented', 'Packages'], major: true },
  { tags: ['Gallery', 'Animations', 'Exif data'] },
  { tags: ['Projects page implemented'] },
  { tags: ['Gallery improvements'] },
  { tags: ['Responsive'], minor: true },
  { tags: ['Structure', 'Styles'] },
  { tags: ['HTTPS', 'Images'], minor: true },
  { tags: ['File storage', 'File upload'], minor: true },
  { tags: ['File preview'] },
  { tags: ['404 page'] },
  { tags: ['About page implemented', 'Timeline'] },
  { tags: ['Develop page implemented', 'Timeline update', 'Images'] },
  { tags: ['Vision page implemented', 'Vision API'], minor: true },
  { tags: ['Vision categories', 'Vision actions', 'Vision loadings'] },
  { tags: ['Vision subcategories'] },
  { tags: ['Vision post detail', 'Vision styles'] },
  { tags: ['Vision post update', 'Vision PUT API'] },
  { tags: ['Vision cookies', 'Vision sort'] },
  { tags: ['Vision range input', 'Vision reviews', 'Vision multiple file upload'] },
  { tags: ['Vision default categories'] },
  { tags: ['Vision adding post update'] },
  { tags: ['Vision image update', 'Vision error handling'] },
  { tags: ['Added meta tags', 'Deploy script'], minor: true },
  { tags: ['File storage update', 'Files query access'] },
  { tags: ['Vision modal update', 'Vision Prev/Next arrow'] },
  { tags: ['Files storage update', 'Files / Vision access check', 'Eslint rules'] },
  { tags: ['Ipad input focus'] },
  { tags: ['Global styles update'] },
  { tags: ['Files code-style update globally'] },
  { tags: ['Update file structure', 'Added Wiki page'], minor: true },
  { tags: ['Wiki page basics', 'Wiki config'] },
  {
    tags: [
      'Vision page update',
      'Vision page usable for different types',
      'Vision API for different vision types',
    ],
  },
  { tags: ['Structure update', 'Config update'] },
  { tags: ['Deploy scripts updated', 'File storage file deleting'] },
  { tags: ['File storage update', 'File components update'] },
  { tags: ['Removed useless components / pages'] },
  { tags: ['Clipboard page implemented'], minor: true },
  { tags: ['Eslint rules update'], minor: true },
  { tags: ['Clipboard page copying', 'File storage upload update'] },
  { tags: ['Clipboard page copying', 'File storage upload update'] },
  { tags: ['Home page clipboard paste support'], minor: true },
  { tags: ['Clipboard page update'] },
  { tags: ['React GA implemented'], minor: true },
  { tags: ['Table implemented', 'Clipboard navigation'] },
  { tags: ['Clipboard navigation update', 'Navigation actions'] },
  { tags: ['Clipboard table update', 'Table loadings'] },
  { tags: ['Added version system'], minor: true },
  { tags: ['Visions page style update'] },
  { tags: ['Version of web visible'] },
  { tags: ['Version page implemented'] },
  { tags: ['Votes page implemented'], minor: true },
  { tags: ['Shortcut navigation added', 'Fixed vision errors'], minor: true },
  { tags: ['Music page'], minor: true },
  { tags: ['F1 Page', 'F1 Page drivers'], minor: true },
  { tags: ['F1 Page voting'] },
  { tags: ['F1 Pages components', 'F1 Page circuits'] },
  { tags: ['F1 Authorization'] },
  { tags: ['F1 Responsive'], minor: true },
  { tags: ['F1 Results added'] },
  { tags: ['F1 Posts / News added'], minor: true },
  { tags: ['F1 Post comments'] },
  { tags: ['UTF 8 Update'], minor: true },
  { tags: ['Stuff list implemented'], minor: true },
  { tags: ['Migrate to react scripts & react router', 'Remove react-website'], major: true },
  { tags: ['Extended Wiki for JS'] },
  { tags: ['Games page implemented'], minor: true },
  { tags: ['Game stats by games implemented'] },
  { tags: ['Game stats by users implemented'] },
];
