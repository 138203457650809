import React from 'react';

import Icon from '../../../../common/Icon';

import { F1DriverInfo, F1DriverVote } from '@ts/F1';

import './style.css';

type Props = {
  data: F1DriverVote;
  onClick?: (data: F1DriverInfo) => void;
  selectable?: boolean;
};

const Driver = ({ data, onClick, selectable }: Props) => {
  return (
    <div
      className={`f1-driver ${onClick ? 'selectable' : ''}`}
      onClick={() => onClick && onClick(data.driver)}
    >
      <div className="f1-driver-position">{data.position}</div>
      <div className="f1-driver-base">
        <div className="f1-driver-info">
          <div className="f1-driver-name">{data.driver.name}</div>
          <div className="f1-driver-team-name">{data.team.name}</div>
        </div>
        {selectable ? (
          <div className="f1-driver-favorite">
            <Icon size={20}>star</Icon>
          </div>
        ) : (
          <div className="f1-driver-points">
            <span>{data.points || '0'}</span>
            <div className="f1-driver-points-label">points</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Driver;
