import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Box, ButtonBase, Collapse, Fade, IconButton, Menu, MenuItem } from '@mui/material';
import { Check, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { includes, groupBy, uniq } from 'lodash-es';
import { styled } from '@mui/material/styles';

import { DrinksContext } from '@context/drinksData';

import { endpoints } from '@api/endpoints';
import API from '@api/index';

import { Common, User } from '@functions';

import DrinkLayout from '../layout';
import DarkCircleButton from '@common/DarkCircleButton';
import DarkCircleIconButton from '@common/DarkCircleIconButton';
import DarkListItem from '@common/DarkListItem';

import SessionSummary from '@pages/Drinks/components/SessionSummary';
import SessionEdit from '@pages/Drinks/components/SessionEdit';

import { User as UserType } from '@ts/User';
import { Drink, DrinkSession, DrinkType } from '@ts/Drink';
import { UnsafeAny } from '@ts/General';

import './style.css';

type LoadingState = {
  creating?: boolean;
  finishing?: boolean;
  adding?: boolean;
  removing?: boolean;
  leaving?: boolean;
};

type Options = {
  playersTotal: number;
  drinksTotal: number;
  drinkTypesTotal: number;
  drinkTypesNames: string[];
  defaultDrink: DrinkType;
  userDrinksConsumed: number;
  userPromile?: number;
  roundDrinks: Record<string, Drink[]>;
  roundsTotal?: number;
};

const refresh = 30; // in min

const StyledButtonBase = styled(ButtonBase)`
  padding: 10px 20px 8px;
  border-radius: 6px;
`;

const DrinkOverview = () => {
  const [loading, setLoading] = useState<LoadingState>({});

  const [currentSession, setCurrentSession] = useState<DrinkSession | null>(null);
  const [runningSession, setRunningSession] = useState<DrinkSession | null>(null);
  const [preparingSession, setPreparingSession] = useState<boolean>(false);
  const [players, setPlayers] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
  const [selectedDrinkType, setSelectedDrinkType] = useState<DrinkType | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [summary, setSummary] = useState<DrinkSession | null>(null);

  const [pageLoaded, setPageLoaded] = useState(false);

  const [groupAdding, setGroupAdding] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();
  const match = useRouteMatch();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, dataLoaded] = useContext(DrinksContext);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setPlayers([User.id() as string]);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshSession();
    }, refresh * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [currentSession?._id]);

  const loadData = async () => {
    try {
      const drinkSessions = (await API.post(endpoints.drink.session.search, {
        state: 'STARTED',
        season,
      })) as { rows: DrinkSession[] };

      const runningSession = drinkSessions.rows.find((s) =>
        s.players.some((p) => p._id === User.id()),
      );

      const isRunningSessionPaused =
        runningSession &&
        runningSession.pauses.find(
          (pause) => pause.player === User.id() && pause.status === 'STARTED',
        );

      if (includes(match.path, 'current') && runningSession && !isRunningSessionPaused) {
        setCurrentSession(runningSession);
      }

      if (runningSession) {
        setRunningSession(runningSession);
      }
      setPageLoaded(true);
    } catch (err) {}
  };

  const refreshSession = async () => {
    if (currentSession) {
      try {
        const data = await API.get(endpoints.drink.session.get(currentSession._id));

        if (data.state === 'FINISHED') {
          setCurrentSession(null);
        } else {
          setCurrentSession(data);
        }
      } catch (err) {}
    }
  };

  const season = Common.getYear();

  const setPlayer = (id: string) => {
    const newPlayers = [...players];

    if (includes(players, id)) {
      const index = players.findIndex((p) => p === id);
      newPlayers.splice(index, 1);
    } else {
      newPlayers.push(id);
    }

    setPlayers(newPlayers);
  };

  const onStartSession = async () => {
    setLoading({ creating: true });

    try {
      const drinkSession = await API.post(endpoints.drink.session.add, {
        status: 'STARTED',
        players,
        season,
      });

      setPreparingSession(false);
      setCurrentSession(drinkSession);

      if (players.length > 1) {
        setGroupAdding(true);
      }
    } catch (err) {
      console.error(err);
    }

    setLoading({});
  };

  const onFinishSession = async () => {
    if (currentSession) {
      try {
        const session = await API.post(endpoints.drink.session.finish(currentSession._id));

        setCurrentSession(null);
        setPreparingSession(false);
        setRunningSession(null);
        setSummary(session);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const onLeaveSession = async () => {
    if (currentSession) {
      setLoading({ leaving: true });
      try {
        const session = await API.post(
          endpoints.drink.session.leaveByPlayer(
            currentSession._id,
            selectedUser?._id || (User.id() as string),
          ),
        );

        setCurrentSession(null);
        setPreparingSession(false);
        setRunningSession(session);
      } catch (err) {
        console.error(err);
      }

      setLoading({});
    }
  };

  const onResumeSession = async (id: string, pauseId: string) => {
    try {
      await API.post(endpoints.drink.session.resumePause(id, pauseId));
    } catch (err) {
      console.error(err);
    }
  };

  const onAddDrink = async (drinkId: string) => {
    if (currentSession) {
      setLoading({ adding: true });

      const drinks = [];

      if (groupAdding) {
        currentSession.players.forEach((p) => {
          const activePause = currentSession.pauses.find(
            (pause) => pause.player === p._id && pause.status === 'STARTED',
          );

          if (!activePause) {
            drinks.push({ type: drinkId, player: p._id || p });
          }
        });
      } else {
        drinks.push({ type: drinkId, player: selectedUser?._id || User.id() });
      }

      /** Pre-update **/
      setCurrentSession({
        ...currentSession,
        drinks: [
          ...currentSession.drinks,
          ...drinks.map((d) => ({ ...d, createdAt: new Date().toISOString() })),
        ] as Drink[],
      });

      if (!drinks.length) {
        setLoading({});
        return;
      }

      try {
        const drinkSession = await API.post(endpoints.drink.session.addDrinks(currentSession._id), {
          drinks,
        });

        setCurrentSession(drinkSession);
        setSelectedUser(null);
      } catch (err) {
        console.error(err);
        refreshSession();
      }
      setLoading({});
    }
  };

  /*
  const onRemoveLastDrink = async () => {
    if (currentSession) {
      const lastDrink = currentSession.drinks[currentSession.drinks.length - 1]._id;

      try {
        const drinkSession = await API.del(
          endpoints.drink.session.deleteDrinks(currentSession._id),
          {
            drinks: [lastDrink],
          },
        );

        setCurrentSession(drinkSession);
      } catch (err) {
        console.error(err);
      }
    }
  }; */

  const onChangeUser = (move: string) => {
    if (currentSession) {
      const players = currentSession.players.filter((p) => p._id !== User.id());

      const currentIndex = selectedUser ? players.findIndex((p) => p._id === selectedUser._id) : -1;
      let newIndex = move === 'next' ? currentIndex + 1 : currentIndex - 1;

      if (newIndex === -2) {
        newIndex = players.length - 1;
      }

      console.log(newIndex);

      if (!players[newIndex]) {
        setSelectedUser(null);
      } else {
        setSelectedUser(players[newIndex]);
      }
    }
  };

  if (summary) {
    return <SessionSummary data={summary} onClose={() => setSummary(null)} />;
  }

  if (currentSession) {
    const options: Options = {
      playersTotal: currentSession.players.length,
      drinksTotal: currentSession.drinks.length,

      drinkTypesTotal: uniq(currentSession.drinks.map((d) => d.type._id)).length,
      drinkTypesNames: uniq(currentSession.drinks.map((d) => d.type.name)),
      defaultDrink: data.drinkTypes.find((d: DrinkType) => d.isDefault) || data.drinkTypes[0],

      userPromile:
        currentSession?.grams?.promile &&
        currentSession.grams.promile[(selectedUser?._id || User.id()) as string],
      userDrinksConsumed: currentSession.drinks.filter(
        (d) => d.player === (selectedUser?._id || User.id()),
      ).length,
      roundDrinks: groupBy(
        currentSession.drinks.map((s) => ({ ...s, createdAt: s.createdAt.substring(0, 19) })),
        'createdAt',
      ),
    };

    options.roundsTotal = (Object.values(options.roundDrinks as UnsafeAny) as Drink[][]).filter(
      (v) => v?.length > 1,
    )?.length;

    const renderDrinkType = (
      <>
        <StyledButtonBase onClick={(e) => setAnchorEl(e.currentTarget)}>
          <div className="drink-session-header-text">
            {selectedDrinkType?.name || options.defaultDrink?.name}
          </div>
        </StyledButtonBase>
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
          {data.drinkTypes.map((type: DrinkType) => (
            <MenuItem
              key={type.id}
              disabled={type._id === (selectedDrinkType?._id || options.defaultDrink._id)}
              onClick={() => {
                setAnchorEl(null);
                setSelectedDrinkType(type);
              }}
            >
              <Box display="flex" alignItems="center">
                <Box minWidth="36px" pt={1}>
                  {type._id === (selectedDrinkType?._id || options.defaultDrink._id) && (
                    <Check fontSize="small" />
                  )}
                </Box>
                {type.name}
              </Box>
            </MenuItem>
          ))}
        </Menu>
      </>
    );

    return (
      <DrinkLayout
        customHeader={
          <div className="drink-session-header">
            <div className="drink-session-header-row">
              {!groupAdding && (
                <IconButton
                  className="drink-session-header-icon-button"
                  onClick={() => onChangeUser('prev')}
                >
                  <KeyboardArrowLeft className="drink-session-header-icon" />
                </IconButton>
              )}
              <div className="drink-session-header-user">
                {groupAdding ? 'All' : selectedUser?.username || User.username()}
              </div>
              {!groupAdding && (
                <IconButton
                  className="drink-session-header-icon-button"
                  onClick={() => onChangeUser('next')}
                >
                  <KeyboardArrowRight className="drink-session-header-icon" />
                </IconButton>
              )}
            </div>
            {renderDrinkType}
          </div>
        }
      >
        <div className="drink-session">
          <div className="drink-session-controls-wrap">
            <div className="drink-session-controls">
              <div className="drink-session-controls-main">
                <DarkCircleButton
                  onClick={() => onAddDrink(selectedDrinkType?._id || options.defaultDrink._id)}
                  count={groupAdding ? options.roundsTotal : options.userDrinksConsumed}
                  label={groupAdding ? (options.roundsTotal > 1 ? 'Rounds' : 'Round') : undefined}
                  subLabel={groupAdding ? `${options.drinksTotal} Drinks` : `Drinks`}
                  subLabel2={
                    (!!options.userPromile || options.userPromile === 0) && !groupAdding
                      ? `${options.userPromile} P`
                      : undefined
                  }
                  loading={loading.adding}
                />
                <Fade in={!!currentSession.drinks.length}>
                  <div className="drink-session-control-left">
                    <DarkCircleIconButton icon="edit" onClick={() => setIsOpen(true)} />
                  </div>
                </Fade>
                {options.playersTotal !== 1 && (
                  <div className="drink-session-control-right">
                    <DarkCircleIconButton
                      icon="people"
                      onClick={() => {
                        setGroupAdding(!groupAdding);
                        setSelectedUser(null);
                      }}
                      off={!groupAdding}
                    />
                  </div>
                )}
              </div>
              <Collapse in={!!currentSession.minutesPerDrink}>
                <>
                  <div className="drink-session-tempo">{currentSession.minutesPerDrink} min</div>
                  <div className="drink-session-tempo-label">Per drink</div>
                </>
              </Collapse>
            </div>
          </div>
          <div className="drink-session-footer">
            <DarkCircleIconButton
              icon="close"
              disabled={loading.leaving}
              options={[
                {
                  label: 'Leave session',
                  onClick: () => onLeaveSession(),
                  disabled: options.playersTotal === 1,
                },
                { label: 'Finish session', onClick: () => onFinishSession() },
              ]}
              off
            />
          </div>
        </div>
        <SessionEdit
          onUpdate={(session) => session && setCurrentSession(session)}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          values={currentSession}
        />
      </DrinkLayout>
    );
  }

  return (
    <DrinkLayout
      header={preparingSession ? 'Select friends' : 'Drink types'}
      onUserClick={() => setPreparingSession(false)}
      onCornerClick={() => !preparingSession && history.push('/drinks/types')}
      loading={!pageLoaded}
    >
      {preparingSession ? (
        <div className="drink-select-players">
          <div className="drink-select-players-list">
            {data.users.map((user: UserType) => (
              <DarkListItem
                white={!includes(players, user._id)}
                label={user.username}
                onClick={() => setPlayer(user._id)}
                key={user._id}
                rounded
              />
            ))}
          </div>
          <div className="drink-select-players-button">
            <DarkCircleButton
              highlight="Start"
              onClick={() => onStartSession()}
              loading={loading.creating}
              disabled={!players.length}
              subLabel={
                players.length
                  ? players.length < 2
                    ? 'alone'
                    : `${players.length} friends`
                  : undefined
              }
            />
          </div>
        </div>
      ) : (
        <div className="drink-overview">
          <DarkCircleButton
            label="session"
            highlight={runningSession ? 'Join' : 'Start'}
            subLabel={runningSession ? `${runningSession.drinks.length} Drinks` : undefined}
            active={!!runningSession}
            onClick={() => {
              if (runningSession) {
                history.push('/drinks/current');

                const pause = runningSession.pauses.find(
                  (p) => p.status !== 'FINISHED' && p.player === User.id(),
                );

                if (pause) {
                  onResumeSession(runningSession._id, pause._id);
                }

                setCurrentSession(runningSession);
              } else {
                setPreparingSession(true);
              }
            }}
          />
          <DarkCircleButton label="Session" highlight="Add" onClick={() => setIsOpen(true)} />
          <DarkCircleButton label="History" onClick={() => history.push('/drinks/history')} />
        </div>
      )}
      <SessionEdit
        onUpdate={(session) => !!currentSession && session && setCurrentSession(session)}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        values={currentSession || null}
      />
    </DrinkLayout>
  );
};

export default DrinkOverview;
