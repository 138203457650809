import React, { Fragment, useEffect, useState } from 'react';
import API from '../../../../api';
import { groupBy } from 'lodash-es';

import { Common } from '../../../../functions';

import Loading from '../../../common/Loading';
import Icon from '../../../common/Icon';
import AddNew from '../Add';

import './style.css';

const processData = (data) => {
  data = Common.sort(data, 'priority');
  const subItems = Common.sort(data.filter((item) => item.masterId), 'sequence');
  const subItemsGroups = groupBy(subItems, 'masterId');

  Object.keys(subItemsGroups).forEach((masterId) => {
    const items = subItemsGroups[masterId];
    const index = data.findIndex((item) => item.id === Number(masterId));

    if (index !== -1) {
      data[index].items = items;
    }
  });

  return groupBy(data.filter((item) => !item.masterId), 'type');
};

const StuffList = () => {
  const [stuff, setStuff] = useState(null);
  const [pureStuff, setPureStuff] = useState(null);
  const [categories, setCategories] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const loadData = async () => {
    try {
      const data = await API.post('/stuff/search', { active: true });

      setPureStuff(data);
      setStuff(processData(data));
    } catch (e) {
      API.err(e);
    }
  };

  const loadStatic = async () => {
    try {
      const data = await API.get('/stuff/types');

      setCategories(data);
    } catch (e) {
      API.err(e);
    }
  };

  useEffect(() => {
    loadData();
    loadStatic();

    setSelectedItems(JSON.parse(localStorage.getItem('selectedStuff')) || []);
  }, []);

  const onItemClick = (item) => {
    // Selected
    const index = selectedItems.findIndex((i) => i.id === item.id);
    const newSelectedItems = [...selectedItems];

    if (index === -1) {
      newSelectedItems.push(item);
    } else {
      newSelectedItems.splice(index, 1);
    }

    localStorage.setItem('selectedStuff', JSON.stringify(newSelectedItems));
    setSelectedItems(newSelectedItems);
  };

  const singleItem = (item, sub) => {
    const selected = selectedItems.find((i) => i.id === item.id);

    return (
      <div className={`stuff-item ${sub ? 'sub' : ''}`} key={item.id} onClick={() => onItemClick(item)}>
        <Icon
          className="stuff-item-icon"
          size={16}
          color={selected ? '#3895D3' : '#ffffff11'}
        >check_circle</Icon>
        <div className="stuff-item-name">{item.name}</div>
      </div>
    );
  };

  const items = (items) => items.map((item) => {
    return (
      <Fragment key={item.id}>
        {singleItem(item)}
        {item.items && item.items.map((subItem) => singleItem(subItem, true))}
      </Fragment>
    );
  });

  if (!stuff || !categories) {
    return (
      <div className="stuff-wrap">
        <Loading/>
      </div>
    );
  }

  const isActionBarVisible = selectedItems.length;

  return (
    <div className="stuff-wrap">
      <div className="stuff-header">
        <div/>
        <AddNew data={pureStuff} categories={categories} onUpdate={loadData}/>
      </div>
      <div className="stuff-list">
        {stuff && Object.keys(stuff).map((key) =>
          <div className="stuff-block" key={key}>
            <div className="stuff-block-title">{key}</div>
            <div className="stuff-block-items">
              {items(stuff[key])}
            </div>
          </div>,
        )}
      </div>
      {isActionBarVisible &&
      <div className="stuff-actions">
        <div className="stuff-actions-info">{selectedItems.length} Selected</div>
        <div className="stuff-actions-buttons">

        </div>
      </div>
      }
    </div>
  );
};

export default StuffList;
