import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { includes } from 'lodash-es';

import ButtonController from '../ButtonController';

import API from '../../../../../api';
import { translate } from '../../../../../localise';
import { GamesContext } from '../../../../../context/gamesData';
import { endpoints } from '../../../../../api/endpoints';

import ButtonContinue from '../ButtonContinue';
import Player from '../Player';

import './style.css';

const SessionWrap = ({ children, gameSession, onStart, onUpdate }) => {
  const [data] = useContext(GamesContext);
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  const togglePlayer = (id) => {
    if (includes(selectedPlayers, id)) {
      const newSelectedPlayers = [...selectedPlayers];
      const index = newSelectedPlayers.indexOf(id);

      newSelectedPlayers.splice(index, 1);

      setSelectedPlayers(newSelectedPlayers);
    } else {
      setSelectedPlayers([...selectedPlayers, id]);
    }
  };

  const assignPlayers = async () => {
    try {
      const session = await API.post(endpoints.game.session.setPlayers(gameSession._id), {
        players: selectedPlayers,
      });

      onUpdate(session);
    } catch (err) {
      API.err(err);
    }
  };

  const playersSet = !!gameSession?.players?.length;

  const availableGames = useMemo(() => data.gameTypes.filter((gameType) => gameType.type !== 'EXTERNAL_ACTIVITY'));

  const numberOfPlayers = selectedPlayers.length;
  const numberOfAvailableGames = availableGames.filter(
    (gameTypes) => gameTypes.minPlayers <= numberOfPlayers && (!gameTypes.maxPlayers || gameTypes.maxPlayers >= numberOfPlayers),
  ).length;

  return (
    <div>
      <div className={classNames('game-session-wrap', { ['game-session-wrap-running']: gameSession })}>
        {gameSession ?
          <div className="game-session-body">
            <div className="game-session-content">
              <div className="game-session-header">
                <div>
                  <div className="game-session-header-text">{translate.games.startingSession} {gameSession.session}</div>
                  <div className="game-session-header-title">{translate.games.selectPlayers}:</div>
                </div>
              </div>
              <div className="game-session-body">
                <div className="game-session-body-players">
                  <div className="game-session-body-players-row">
                    {data.users.map((user, i) => i % 2 !== 1 &&
                      <div key={user._id} className="game-session-body-player">
                        <Player
                          data={user}
                          onClick={() => togglePlayer(user._id)}
                          selected={includes(selectedPlayers, user._id)}
                        />
                      </div>,
                    )}
                  </div>
                  <div className="game-session-body-players-row">
                    {data.users.map((user, i) => i % 2 === 1 &&
                      <div key={user._id} className="game-session-body-player">
                        <Player
                          data={user}
                          onClick={() => togglePlayer(user._id)}
                          selected={includes(selectedPlayers, user._id)}
                        />
                      </div>,
                    )}
                  </div>
                </div>
              </div>
              <div className="game-session-footer">
              </div>
            </div>
            <div className="game-session-extra-block">
              <div>
                <div className="game-session-extra-title">{numberOfAvailableGames} {translate.games.gamesAvailable}</div>
                <div className="game-session-extra-text">{numberOfPlayers} {translate.games.players}</div>
              </div>
              <div className="game-session-extra-actions">
                <ButtonContinue onClick={assignPlayers} active={!!numberOfAvailableGames} disabled={numberOfPlayers < 2}>
                  {translate.games.confirmPlayers}
                </ButtonContinue>
              </div>
            </div>
          </div>
          :
          <div className="games-body-button-controller-title">
            <ButtonController disabled={playersSet} onClick={onStart}>
              {translate.games.startNewSession}
            </ButtonController>
          </div>
        }
      </div>

    </div>
  );
};

SessionWrap.propTypes = {
  side: PropTypes.any,
  children: PropTypes.any,
  onStart: PropTypes.func,
  gameSession: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

export default SessionWrap;
