import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import MenuButton from '../../components/MenuButton';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import { translate } from '../../../../../localise';

import './style.css';

const menu = [
  { id: 'dashboard', path: '/games' },
  { id: 'leaderboard' },
  { id: 'stats' },
  { id: 'records' },
  { id: 'types' },
];

const Menu = ({ history }) => {
  return (
    <div className="games-menu">
      <div className="games-menu-content">
        <Stack className="games-menu-header">
          <div className="games-menu-header-title">{translate.games.gamingDay}</div>
          <div className="games-menu-header-subtitle">{translate.games.season} 1</div>
        </Stack>
        <Stack alignItems="flex-start" divider={<Divider orientation="horizontal" flexItem />}>
        {menu.map((route) => (
          <MenuButton key={route.id} label={translate.games.menu[route.id]} onClick={() => history.push(route.path || `/games/${route.id}`)}/>
        ))}
        </Stack>
      </div>
    </div>
  );
};

Menu.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Menu);
