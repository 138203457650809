// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu-WguOt{display:flex;flex-direction:column;background:#222;min-width:300px;font-weight:bolder;font-size:12px;border-right:1px solid #333;box-sizing:border-box;justify-content:space-between}.menuItem-5WgtS{display:flex;padding:10px 20px 10px 30px;color:#ccc;border-left:3px solid #333;border-bottom:1px solid hsla(0,0%,100%,.0666666667);cursor:pointer;opacity:0}.menuItem-5WgtS.extra-y-lmF{padding:10px 20px}.menuItem-5WgtS.sub-H6W6r{color:#aaa;padding:0 20px 0 30px;opacity:0;max-height:0;transition:.3s ease all}.menuItem-5WgtS.sub-H6W6r.visible-z\\+uty{max-height:50px;opacity:1;padding:10px 20px 10px 40px}.menuItem-5WgtS.extra-y-lmF.active-Pg4N5{color:#fff;border-left:3px solid red}.menuItem-5WgtS.extra-y-lmF:hover{background:#282828;border-left:3px solid red}.menuItem-5WgtS.sub-H6W6r.active-Pg4N5{color:#fff;border-left:3px solid #eb802f}.menuItem-5WgtS.sub-H6W6r:hover{background:#282828;border-left:3px solid #eb802f}.menuItem-5WgtS.active-Pg4N5{color:#fff;border-left:3px solid #00bfff}.menuItem-5WgtS:hover{background:#282828;border-left:3px solid #00bfff}.vision-menu-icon-dwDEq{display:flex;justify-content:center;padding:30px}@keyframes show-menu-item-ghnNx{to{opacity:1}}.vision-content-lock-9zOnJ{flex:1;display:flex;background:#262626;align-items:center;justify-content:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "menu-WguOt",
	"menuItem": "menuItem-5WgtS",
	"extra": "extra-y-lmF",
	"sub": "sub-H6W6r",
	"visible": "visible-z+uty",
	"active": "active-Pg4N5",
	"vision-menu-icon": "vision-menu-icon-dwDEq",
	"vision-content-lock": "vision-content-lock-9zOnJ",
	"show-menu-item": "show-menu-item-ghnNx"
};
export default ___CSS_LOADER_EXPORT___;
