// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".item-k23bx{display:flex;justify-content:space-between;align-items:center;padding:4px 0;margin-bottom:4px}.itemBody-6XSn3{display:flex;align-items:center;font-size:16px;text-transform:uppercase}.itemHelper-A-hsH{opacity:.2;margin-right:12px;font-size:34px;min-width:26px}.itemTime-IJ76x{font-size:11px;opacity:.3;text-align:left}.itemActions-rILIi{display:flex;align-items:center}.buttonEdit-gs7Yf{border-radius:4px !important;padding-right:12px !important}.actionIcon-ndWjO{opacity:.6;font-size:18px !important;margin-right:12px !important}.textFieldDate-zAq0A{margin:0 40px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "item-k23bx",
	"itemBody": "itemBody-6XSn3",
	"itemHelper": "itemHelper-A-hsH",
	"itemTime": "itemTime-IJ76x",
	"itemActions": "itemActions-rILIi",
	"buttonEdit": "buttonEdit-gs7Yf",
	"actionIcon": "actionIcon-ndWjO",
	"textFieldDate": "textFieldDate-zAq0A"
};
export default ___CSS_LOADER_EXPORT___;
