import { Cookie } from './';
import { User } from '@ts/User';

const userInfo = (): User | null => {
  try {
    return Cookie.get('user') as unknown as User;
  } catch (e) {
    return null;
  }
};

const authorizedUser = (): User => userInfo() as User;

const id = (): string | undefined => userInfo()?._id;

const hasAccess = () => userInfo()?._id;
const hasRight = (right: string, user?: User) =>
  (user || userInfo())?.rights.some((r) => r.name === right);
const isAdmin = () => hasAccess() === 'dl';
const username = () => userInfo()?.username;

export default {
  hasAccess,
  hasRight,
  isAdmin,
  username,
  authorizedUser,
  id,
};
