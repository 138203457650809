import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box, Typography } from '@mui/material';

type Item = {
  id: string | number;
  value?: string | number;
  name?: string;
  label?: string;
  [key: string]: unknown;
};

type Props = {
  label: string;
  onChange: (e: Item) => void;
  selected: string | number;
  items: Item[];
};

const ButtonPicker = ({ onChange, items, selected, label }: Props) => {
  return (
    <div>
      {label && (
        <Box my={2}>
          <Typography
            variant="body1"
            align="center"
            style={{ opacity: 0.7, textTransform: 'uppercase' }}
          >
            {label}
          </Typography>
        </Box>
      )}
      <div>
        <ToggleButtonGroup
          color="primary"
          value={selected}
          exclusive
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          onChange={(e) => onChange(e.target.value)}
        >
          {items.map((item) => (
            <ToggleButton size="small" key={item.value || item.id} value={item.value || item.id}>
              {item.label || item.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default ButtonPicker;
