import React from 'react';
import classNames from 'classnames';

import styles from './style.scss';

type Props = {
  onClick: () => void;
  children?: JSX.Element | string;
  error?: boolean;
  loading: boolean;
  className?: string;
};

const Button = ({ onClick, className, error, loading, children, ...props }: Props) => {
  return (
    <button
      className={classNames(
        styles.button,
        error && styles.error,
        loading && styles.loading,
        className,
      )}
      onClick={onClick}
      {...props}
    >
      <span>{children}</span>
    </button>
  );
};

export default Button;
