import React from 'react';
import classNames from 'classnames';
import { ButtonBase } from '@mui/material';
import { Check } from '@mui/icons-material';
import { getDate } from 'date-fns';

import { Count } from '@ts/Count';

import styles from './style.scss';

type Props = {
  item: Count;
  onClick: () => void;
  counter: number;
  sm?: boolean;
  dark?: boolean;
};

type AlertConfig = {
  type: 'warning' | 'error';
  message: string;
};

const getAlerts = (item: Count, counter: number): AlertConfig | null => {
  if (!counter && item.period?.day && item.type.name === 'TODO') {
    const dayDiff = item.period.day - getDate(new Date());

    if (dayDiff < 4) {
      return { type: 'warning', message: `Till ${item.period?.day}th` };
    }

    if (dayDiff < 0) {
      return { type: 'error', message: `Over ${item.period?.day}th` };
    }
  }

  return null;
};

const CountButton = ({ item, onClick, counter, sm, dark }: Props) => {
  const percentage = item.type.name === 'PERCENTAGE' && counter;
  const alert = getAlerts(item, counter);

  const className = [];
  let content;

  switch (item.type.name) {
    case 'TODO':
      if (counter) {
        className.push(styles.checked);
        content = <Check className={styles.icon} />;
      } else {
        className.push(styles.disabled);
      }
      break;
    case 'COUNTER':
      content = <div className={styles.counter}>{counter}</div>;
      if (!counter) {
        className.push(styles.disabledBorder);
      }
      break;

    case 'GOAL':
      content = <div className={styles.counter}>{counter}</div>;
      className.push(styles.disabledBorder);

      if (counter) {
        className.push(styles.checked);
      }
      break;

    case 'PERCENTAGE':
      const style = { '--percentage': `${percentage}%` } as Record<string, string>;
      content = (
        <div className={styles.percentage}>
          {counter}%<div className={styles.innerCircle} style={style}></div>
        </div>
      );
      className.push(styles.disabledBorder);
      className.push(styles.percentageWrap);
      break;

    case 'TODO2':
      if (counter) {
        className.push(styles.activeBorder);
      } else {
        className.push(styles.disabled);
      }
      break;
  }

  if (dark) className.push(styles.dark);
  if (sm) className.push(styles.sm);
  if (alert?.type === 'warning') className.push(styles.warning);
  if (alert?.type === 'error') className.push(styles.error);

  return (
    <div>
      <ButtonBase classes={{ root: classNames(styles.button, ...className) }} onClick={onClick}>
        <div className={styles.circle}>{content}</div>
        <div className={styles.body}>
          <div className={styles.name}>{item.name}</div>
          {alert && <div className={styles.helper}>{alert?.message}</div>}
        </div>
      </ButtonBase>
    </div>
  );
};

export default CountButton;
