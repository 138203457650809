import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { camelCase } from 'lodash-es';

import { privacyPolicy } from '../../../../../../assets/apps';

import NotFound from '../../../../../utils/NotFound';

import './style.css';

class PrivacyPolicy extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
  };

  state = {
  };

  get filename() {
    return window.location.pathname.replace('/apps/privacy/', '');
  }

  get file() {
    return privacyPolicy[camelCase(this.filename)];
  }
  render() {
    console.log(camelCase(this.filename));

    // if (!this.file) return <NotFound/>;

    return (
      <div className="privacy-wrap">
        <div className="privacy-title">Privacy policy</div>
        {this.file}
      </div>
    );
  }
}

export default PrivacyPolicy;
