import React, { MouseEvent } from 'react';
import { ButtonBase, Icon } from '@mui/material';
import { styled } from '@mui/material/styles';

import './style.css';

type Props = {
  onClick: (e: MouseEvent) => void;
  placeholder?: string;
  required?: boolean;
  error?: boolean;
  loading?: boolean;
  children?: string | JSX.Element;
  className?: string;
  lg?: boolean;
  sm?: boolean;
  disabled?: boolean;
  classNameLabel?: string;
  icon?: string;
};

const ButtonBaseStyled = styled(ButtonBase)({
  color: '#ddd',
  minHeight: 40,
  padding: '0 14px',
  borderRadius: '6px',
  backgroundColor: '#ffffff11',
  '&:hover': {
    color: 'white',
    backgroundColor: '#ffffff11',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    color: 'white',
    backgroundColor: '#ffffff11',
    borderColor: '#005cbf',
  },
  '&:focus': {
    backgroundColor: '#ffffff22',
  },
});

const Button = ({
  onClick,
  className,
  // classNameLabel,
  // error,
  // loading,
  disabled,
  children,
  icon,
  ...props
}: Props) => {
  const t = (
    <ButtonBaseStyled className={className} onClick={onClick} disabled={disabled} {...props}>
      {icon && <Icon className="mui-button-icon">{icon}</Icon>}
      <div className="mui-button-label">{children}</div>
    </ButtonBaseStyled>
  );

  return t;
};

export default Button;
