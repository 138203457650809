import React from 'react';
import IconButton from '@mui/material/IconButton'; // Adjust this import based on your IconButton source
import Add from '@mui/icons-material/Add'; // Adjust this import based on your Add icon source
import Remove from '@mui/icons-material/Remove'; // Adjust this import based on your Remove icon source
import styles from './style.scss'; // Adjust this path based on your styles file location

type Props = {
  count: number;
  onAdd: () => void;
  onRemove: () => void;
  max?: number;
};

const Counter = ({ count, onAdd, onRemove, max }: Props) => {
  return (
    <div className={styles.itemActions}>
      <IconButton onClick={onRemove} disabled={count === 0}>
        <Remove />
      </IconButton>
      <div className={styles.itemCount}>{count}</div>
      <IconButton onClick={onAdd} disabled={max ? count >= max : undefined}>
        <Add />
      </IconButton>
    </div>
  );
};

export default Counter;
