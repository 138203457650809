import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@mui/material';

import { translate } from '../../../../../localise';

import { styled } from '@mui/material/styles';

const ButtonBaseStyled = styled(ButtonBase)({
  color: '#ccc',
  minWidth: '200px',
  fontFamily: 'Varem',
  fontSize: 20,
  borderRadius: 100,
  background: '#ffffff22',
  padding: '14px 20px 12px',
  border: `3px solid #00000028`,
  transition: `.25s ease all`,
  ['&:hover']: {
    color: 'white',
  },
  ['&:active']: {

  },
  ['&:focus']: {

  },
  ['&:disabled']: {
    opacity: 0.4,
  },
  ['&.active']: {
    border: `3px solid rgba(203,0,255,0.76)`,
  },
});

const ButtonContinue = ({ label, onClick, children, active, disabled }) => {
  return (
    <ButtonBaseStyled className={active ? 'active' : ''} onClick={onClick ? onClick : null} disabled={disabled}>
      <span>{children || translate.games.continue}</span>
    </ButtonBaseStyled>
  );
};

ButtonContinue.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  children: PropTypes.any,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ButtonContinue;
