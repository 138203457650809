import { Identifier } from '@ts/General';

export const endpoints = {
  session: {
    login: '/anim/session/login',
    logout: '/anim/session/logout',
  },
  user: {
    list: `/anim/users`,
    add: `/anim/users`,
    update: (id: Identifier) => `/anim/users/${id}`,
    authorize: '/anim/users/authorize',
  },
  f1: {
    races: {
      add: '/anim/f1/races',
      status: '/anim/f1/races/status',
      search: '/anim/f1/races/search',
    },
    votes: {
      add: '/anim/f1/votes',
      search: '/anim/f1/votes/search',
      delete: '/anim/f1/votes',
    },
    posts: {
      add: '/anim/f1/posts',
      search: '/anim/f1/posts',
      uploadFile: '/files/upload-f1-post-image.php',
      addComment: (id: Identifier) => `/anim/f1/posts/${id}/comment`,
      removeComment: (id: Identifier, commentId: Identifier) =>
        `/anim/f1/posts/${id}/comment/${commentId}`,
    },
    data: {
      refresh: '/anim/f1/data/refresh',
      getLast: '/anim/f1/data/last',
    },
  },
  clip: {
    add: '/anim/clips',
    list: '/anim/clips',
    update: (id: Identifier) => `/anim/clips/${id}`,
    archive: (id: Identifier) => `/anim/clips/${id}/archive`,
    delete: (id: Identifier) => `/anim/clips/${id}/delete`,
  },
  vision: {
    list: '/anim/visions',
    search: '/anim/visions/search',
    add: '/anim/visions',
    type: {
      list: '/anim/vision-types',
      add: '/anim/vision-types',
      update: (id: Identifier) => `/anim/vision-types/${id}`,
      delete: (id: Identifier) => `/anim/vision-types/${id}`,
    },
    category: {
      list: '/anim/vision-categories',
      add: '/anim/vision-categories',
      update: (id: Identifier) => `/anim/vision-categories/${id}`,
      delete: (id: Identifier) => `/anim/vision-categories/${id}`,
    },
  },
  game: {
    session: {
      add: '/anim/game-sessions',
      list: '/anim/game-sessions',
      search: '/anim/game-sessions/search',
      get: (id: Identifier) => `/anim/game-sessions/${id}`,
      delete: (id: Identifier) => `/anim/game-sessions/${id}`,
      finish: (id: Identifier) => `/anim/game-sessions/${id}/finish`,
      setPlayers: (id: Identifier) => `/anim/game-sessions/${id}/set-players`,
    },
    stats: {
      allBySeason: (season: Identifier) => `/anim/game-stats/${season}`,
    },
    type: {
      add: '/anim/game-types',
      list: '/anim/game-types',
      update: (id: Identifier) => `/anim/game-types/${id}`,
    },
  },
  drink: {
    session: {
      add: '/anim/drink-sessions',
      list: '/anim/drink-sessions',
      search: '/anim/drink-sessions/search',
      get: (id: string) => `/anim/drink-sessions/${id}`,
      update: (id: string) => `/anim/drink-sessions/${id}`,
      delete: (id: string) => `/anim/drink-sessions/${id}`,
      finish: (id: string) => `/anim/drink-sessions/${id}/finish`,
      setPlayers: (id: string) => `/anim/drink-sessions/${id}/set-players`,

      leaveByPlayer: (id: string, player: string) => `/anim/drink-sessions/${id}/leave/${player}`,
      resumePause: (id: string, pauseId: string) => `/anim/drink-sessions/${id}/resume/${pauseId}`,

      addDrinks: (id: string) => `/anim/drink-sessions/${id}/drinks`,
      deleteDrinks: (id: string) => `/anim/drink-sessions/${id}/drinks`,
    },
    stats: {
      season: (season: Identifier) => `/anim/drink-stats/${season}`,
      byPeriod: `/anim/drink-stats/by-period`,
    },
    type: {
      add: '/anim/drink-types',
      list: '/anim/drink-types',
      update: (id: string) => `/anim/drink-types/${id}`,
    },
  },
  pick: {
    random: '/anim/pick/random',
    type: {
      add: '/anim/pick-types',
      list: '/anim/pick-types',
      update: (id: string) => `/anim/pick-types/${id}`,
      delete: (id: string) => `/anim/pick-types/${id}`,
    },
  },
  film: {
    add: '/anim/films',
    search: '/anim/films/search',
    update: (id: Identifier) => `/anim/films/${id}`,
    delete: (id: Identifier) => `/anim/films/${id}`,
    rate: (id: Identifier) => `/anim/films/${id}/rating`,
    favorite: (id: Identifier) => `/anim/films/${id}/favorite`,
    seen: (id: Identifier) => `/anim/films/${id}/seen`,
    interval: (id: Identifier) => `/anim/films/${id}/interval`,
  },
  countType: {
    list: '/anim/count-types',
    add: '/anim/count-types',
    update: (id: Identifier) => `/anim/count-types/${id}`,
    delete: (id: Identifier) => `/anim/count-types/${id}`,
  },
  countCategory: {
    list: '/anim/count-categories',
    add: '/anim/count-categories',
    update: (id: Identifier) => `/anim/count-categories/${id}`,
    delete: (id: Identifier) => `/anim/count-categories/${id}`,
  },
  count: {
    add: '/anim/counts',
    search: '/anim/counts/search',
    update: (id: Identifier) => `/anim/counts/${id}`,
    delete: (id: Identifier) => `/anim/counts/${id}`,

    markTodo: (id: Identifier, todoId: Identifier) => `/anim/counts/${id}/todo/${todoId}`,
  },
  countCheck: {
    search: '/anim/count-checks/search',
    add: `/anim/count-checks`,
    remove: (id: Identifier) => `/anim/count-checks/${id}`,
  },
  todoCategory: {
    list: '/anim/todo-categories',
    add: '/anim/todo-categories',
    update: (id: Identifier) => `/anim/todo-categories/${id}`,
    delete: (id: Identifier) => `/anim/todo-categories/${id}`,
  },
  todo: {
    add: '/anim/todo',
    search: '/anim/todo/search',
    update: (id: Identifier) => `/anim/todo/${id}`,
    delete: (id: Identifier) => `/anim/todo/${id}`,
    done: (id: Identifier) => `/anim/todo/${id}/done`,
  },

  util: {
    updateDbItems: '/anim/util/update-db-items',
  },
};
