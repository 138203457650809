import React, { useEffect, useState, KeyboardEvent } from 'react';
import { toLower } from 'lodash-es';
import { Dialog } from '@mui/material';

import API from '@api/index';

import { Cookie, User } from '@functions';

import Icon from '@common/Icon';

import { endpoints } from '@api/endpoints';

import { SimpleObject } from '@ts/General';

import styles from './style.scss';

const Authorization = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const [authorized, setAuthorized] = useState<SimpleObject | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (inputValue.length === 2) {
      authorize();
    }
  }, [inputValue]);

  const authorize = async () => {
    try {
      setLoading(true);

      const data = await API.post(endpoints.user.authorize, { key: toLower(inputValue) });

      setAuthorized(data);
    } catch (err) {
      setError(true);
      setLoading(false);
      setInputValue('');
    }
  };

  const setKey = () => {
    if (authorized) {
      Cookie.set('userKey', authorized.userKey as string);
      Cookie.set('userId', authorized._id as string);
      Cookie.set('user', JSON.stringify(authorized));

      setTimeout(() => location.reload(), 50);
    }
  };

  const handleSubmit = (e: KeyboardEvent) => {
    if (e.charCode === 13 && inputValue.length > 1) {
      authorize();
    }
  };

  if (User.hasAccess()) return null;

  return (
    <Dialog fullScreen open>
      <div className={styles.wrap}>
        <div className={styles.body}>
          {!authorized ? (
            <>
              <div className="dark-authorization-title">Set up your key to authorize</div>
              <div className="dark-authorization-input-wrap">
                <label className="dark-authorization-input-label">
                  <Icon size={20} color="#fff">
                    vpn_key
                  </Icon>
                  <input
                    className="dark-authorization-input"
                    value={inputValue}
                    placeholder="Enter your key"
                    onKeyPress={handleSubmit}
                    onChange={(e) => {
                      setInputValue(e.target.value);
                      if (error) setError(false);
                    }}
                  />
                </label>
                <button
                  onClick={authorize}
                  disabled={loading}
                  className={`dark-authorization-button ${inputValue.length > 1 ? 'active' : ''}`}
                >
                  Authorize
                </button>
              </div>
              {error && <div className="dark-authorization-error">Invalid key!</div>}
            </>
          ) : (
            <>
              <div className="dark-authorization-title">Authorized as {authorized.username}</div>
              <button onClick={setKey} className="dark-authorization-button active continue">
                Continue
              </button>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default Authorization;
