import React, { useState } from 'react';

import { imgPath } from '@api/api';
import { F1DriverVote } from '@ts/F1';
import { SimpleObject } from '@ts/General';

type Props = {
  driver: F1DriverVote;
  team: SimpleObject;
};

const Driver = ({ driver, team }: Props) => {
  const [loading, setLoading] = useState(true);

  return (
    <div className="f1-position" key={driver.position}>
      <div className="f1-driver-position-content">
        <div className="f1-driver-position-name">{driver.driver?.name}</div>
        <div className="f1-driver-position-team-name">{team.name as string}</div>
      </div>
      <div>
        <img
          alt="driver"
          className={`f1-driver-position-image ${loading ? 'loading' : ''}`}
          onLoad={() => setLoading(false)}
          src={`${imgPath}/${team.link}`}
        />
      </div>
      <div className="f1-driver-position-points">
        <div className="f1-driver-position-points-extension">PTS</div>
        {driver.points || 0}&nbsp;
      </div>
    </div>
  );
};

export default Driver;
