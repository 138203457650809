import React, { useEffect, useState, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import { includes } from 'lodash-es';

import API from '@api/index';
import { endpoints } from '@api/endpoints';

import { Cookie, User } from '@functions';

import { User as UserType } from '@ts/User';
import { DrinkContext } from '@ts/Drink';

const defaults = {
  users: [],
  drinkTypes: [],
  drinkStats: {},
} as DrinkContext;

export const DrinksContext = createContext(defaults);

type Props = { location: Location; children: JSX.Element | string };

const DrinksDataProvider = ({ location, children }: Props) => {
  const [data, setData] = useState(defaults);
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState(Cookie.get('drink-season') || 1);
  const loadData = async (season?: number) => {
    if (loading) return;

    setLoading(true);

    try {
      const drinkTypes = await API.get(endpoints.drink.type.list);
      const users = await API.get(endpoints.user.list);

      const filteredUsers = users.filter((u: UserType) => User.hasRight('drinks:read', u));

      setData({ drinkTypes, users: filteredUsers });

      /**
      const drinkStats = await API.get(
        endpoints.drink.stats.allBySeason(selectedSeason as unknown as Identifier),
      ); **/

      setData({ drinkTypes, users: filteredUsers, drinkStats: {} });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (includes(location.pathname, '/drink') && !dataLoaded) {
      console.log(location.pathname);

      loadData();
    }
  }, [location.pathname, location.hash, dataLoaded]);

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <DrinksContext.Provider value={[data, setSelectedSeason, setDataLoaded, dataLoaded]}>
        {children}
      </DrinksContext.Provider>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withRouter(DrinksDataProvider);
