import React, { useState, useRef, ChangeEvent } from 'react';

import Icon from '@common/SVG';

import API from '@api/index';
import { filePath } from '@api/api';

import { Common } from '@functions';

import TextField from '@common/TextField';
import Select from '@common/Select';
import Range from '@common/Range';
import Button from '../Button';

import { CategoryWithExtension, name } from '../../pages/Detail';
import { Vision } from '@pages/Vision/components/Content';

import './style.css';
import { SelectChangeEvent } from '@mui/material';

type PropsBody = {
  data: Vision;
  categories: CategoryWithExtension[];
  onEdit: (update: Partial<Vision>) => void;
  editing?: boolean;
  icons?: JSX.Element;
};

export const VisionBody = ({ data, categories, editing, onEdit, icons }: PropsBody) => {
  const [{ title, description, link, category }, setState] = useState(data);

  const onChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    onEdit({ [name]: value });
  };

  return (
    <div>
      <div className="vision-item-info-header">
        <div className="vision-item-info-title">
          <TextField
            name="title"
            value={title || ''}
            placeholder="Add title..."
            onChange={onChange}
            className="vision-item-field"
            lg
          />
        </div>
        <div>{!editing && icons}</div>
      </div>
      {editing ? (
        <Select
          name="category"
          value={category || ''}
          onChange={onChange}
          className="vision-item-select"
        >
          <option value="">Select</option>
          {categories
            .filter((c) => !c.extra)
            .map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
        </Select>
      ) : (
        <div className="row">
          <div className="vision-item-category">{name(data.category)}</div>
        </div>
      )}
      <div className="vision-item-info-body">
        <div className="vision-item-info-description">
          <TextField
            name="description"
            value={description || ''}
            placeholder="Add description"
            onChange={onChange}
            className="vision-item-field"
          />
        </div>
        <div className="vision-item-info-link-wrap">
          {data.link && !editing ? (
            <a
              className="vision-item-info-link"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link}
            </a>
          ) : (
            <TextField
              name="link"
              value={link || ''}
              placeholder="Add link"
              onChange={onChange}
              className="vision-item-field"
              sm
            />
          )}
        </div>
      </div>
    </div>
  );
};

type PropsItem = {
  data: Vision;
  image: string;
  onUpdate: () => void;
  squared?: boolean;
  style?: Record<string, unknown>;
};

const VisionItem = ({ data, image, onUpdate, squared, style }: PropsItem) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [copied, setCopied] = useState(false);

  const [edited, setEdit] = useState(data);

  const [percentage, setPercentage] = useState(data.rating || '');

  const copyRef = useRef(null);

  const isUpdated =
    edited.title !== (data.title || '') ||
    edited.description !== (data.description || '') ||
    edited.link !== (data.link || '');

  const fullImage = `${filePath}/vision/${data.name || data.image}`;

  const onSave = async () => {
    setSaving(true);

    const vision = { ...data, ...edited };

    try {
      await API.put('/vision/list', vision);

      onUpdate();
    } catch (e) {
      API.err(e);
    }

    setSaving(false);
  };

  const onCopy = () => {
    let success;

    if (navigator.clipboard) {
      copyRef.current.select();
      success = document.execCommand('copy');
    }

    if (!success) {
      navigator.clipboard.writeText().then(
        () => (success = true),
        (e) => console.error('Copy failed', e),
      );
    }

    setCopied(success);
  };

  const saveReview = async () => {
    try {
      await API.post('/vision/rate', { id: data.id, rating: percentage });
    } catch (error) {
      API.err(error);
    }
  };

  const onDelete = async () => {
    try {
      await API.del('/vision/list', { id: data.id });

      try {
        await API.get(`/files/delete.php?file=vision/${data.image}`);
      } catch (e) {
        API.err(e);
      }

      onUpdate();
    } catch (error) {
      API.err(error);
    }
  };

  const getColor = () => `percentage-${Math.ceil(percentage / 20) * 20}`;

  const icons = (
    <button className={`vision-item-info-icon ${copied ? 'copied' : ''}`} onClick={onCopy}>
      <Icon icon="link" width={15} height={15} color="#333" />
    </button>
  );

  const modalContent = (
    <div
      className="vision-item-modal-wrap"
      id="modal-wrap"
      onClick={(e) => e.target.id === 'modal-wrap' && setOpen(false)}
    >
      <div className="vision-item-modal-container">
        {/** <div className="vision-item-modal-left">
          <Icon icon="arrowNext" height={30} width={30}/>
        </div> **/}
        <div className={`vision-item-modal ${loaded ? 'loaded' : ''}`}>
          <div className="vision-item-modal-image-wrap">
            <img className="vision-item-modal-image" src={image} onLoad={() => setLoaded(true)} />
          </div>
          <div className="vision-item-modal-info">
            <VisionBody
              data={data}
              onEdit={(change) => setEdit({ ...edited, ...change })}
              icons={icons}
            />
            <div className="vision-item-review">
              <div className={`${`vision-item-review-block`} ${getColor()}`}>
                <Range
                  value={percentage}
                  step={5}
                  onChange={(e) => {
                    setPercentage(e.target.value);
                    Common.typingCallBack(saveReview);
                  }}
                />
                {percentage ? (
                  <div className="vision-item-review-percentage">{percentage}%</div>
                ) : (
                  <div className="vision-item-review-no-rating">No rating</div>
                )}
              </div>
            </div>
            <div className="vision-item-footer">
              {isUpdated && (
                <Button onClick={onSave} loading={saving}>
                  Save post
                </Button>
              )}
            </div>
          </div>
        </div>
        {/** <div className="vision-item-modal-right">
          <Icon icon="arrowNext" height={30} width={30}/>
        </div> **/}
      </div>
      <input value={fullImage} className="hidden-input" ref={copyRef} onChange={() => true} />
    </div>
  );

  let preview = (
    <div className="vision-item" id="vision-item" key={data.id} style={style}>
      <div className="vision-item-body">
        <img className="vision-item-image" src={image} />
        <div className="vision-item-overlay" onClick={() => setOpen(true)} />
      </div>
      <div className="vision-item-options">
        {!menuOpen && (
          <button
            className="vision-item-icon vision-item-icon-menu"
            onClick={(e) => setMenuOpen(true)}
          >
            <Icon icon="menu" width={20} height={20} color="#333" />
          </button>
        )}
        {menuOpen && onDelete && (
          <button className="vision-item-icon" onClick={(e) => onDelete(data.id)}>
            <Icon icon="remove" width={15} height={15} color="#333" />
          </button>
        )}
      </div>
    </div>
  );

  if (squared) {
    preview = (
      <div className="vision-item squared" id="vision-item" key={data.id} style={style}>
        <img className="vision-item-image" src={image} onClick={() => setOpen(true)} />
      </div>
    );
  }

  return (
    <>
      {preview}
      {isOpen && modalContent}
    </>
  );
};

export default VisionItem;
