import React, { useEffect, useState } from 'react';
import { get as g, toLower, upperFirst } from 'lodash-es';

import { Navigate } from '@functions/index';

import Cookie from '@functions/cookie';

import Icon from '../Icon';
import './style.css';

const REDIRECT = 'REDIRECT';
const WAITING = 'WAITING';

type Item = {
  path?: string;
  link?: string;
  name: string;
};

type Props = {
  items?: Item[];
  onRedirect?: (url: string) => void;
  onAction?: (item: Item) => void;
  onRedirectBack?: ((item: Item) => void) | boolean;
  onKeypress?: (key: string) => void;
  redirectTime?: number;
  waitTime?: number;
};

type Gap = {
  id?: ReturnType<typeof setTimeout>;
  type?: string;
  selected?: Item;
};

const CatchKeyPress = ({
  onRedirect,
  onRedirectBack,
  onAction,
  onKeypress,
  items = [],
  redirectTime = 300,
  waitTime = 1500,
}: Props) => {
  const [typedChars, setTypedChars] = useState('');
  const [gap, setGap] = useState<Gap>({});
  const [backActive, setBackActive] = useState(false);

  const userKey = Cookie.get('userKey');

  const checkRedirect = (typedChars: string) => {
    const selected = items.filter((item) => toLower(item.name).startsWith(typedChars));

    // Remove old timeout
    if (gap.id) {
      clearTimeout(gap.id);
    }

    if (selected.length === 1 && selected[0]) {
      setGap({
        id: setTimeout(() => {
          if (onRedirect) onRedirect(selected[0].path as string);
          if (onAction) onAction(selected[0] as Item);
        }, redirectTime),
        type: REDIRECT,
        selected: selected[0],
      });
    } else if (selected.length > 1) {
      setGap({ id: setTimeout(() => setTypedChars(''), waitTime), type: WAITING });
    } else {
      setTypedChars(typedChars.slice(1));
    }
  };

  const navigateUpper = () => onRedirect && onRedirect(Navigate.upper());

  const onKeydown = async (e: KeyboardEvent) => {
    /** Allow only numbers and letters **/
    const isValid = e.code.match(/[0-9A-Za-z]/g);

    if (e.keyCode === 8 && onRedirectBack) {
      if (typedChars.length) {
        setTypedChars(typedChars.slice(0, typedChars.length - 1));
      } else {
        setBackActive(!backActive);
        setTypedChars('');
        setTimeout(
          typeof onRedirectBack === 'function' ? onRedirectBack : navigateUpper,
          redirectTime,
        );
      }
    }

    if (!isValid) {
      setTypedChars('');
      return;
    }

    if (e.code.match(/(Key|Digit)/g)) {
      const key = e.code.replace(/(Key|Digit)/g, '');

      if (typedChars === toLower(key)) return;

      setTypedChars(typedChars + toLower(key));

      if (onKeypress) onKeypress(key);
    }
  };

  /** Update searched items **/
  useEffect(() => {
    if (typedChars.length || gap.id || items?.length) {
      checkRedirect(typedChars);
    }
  }, [typedChars]);

  /** Key down handler **/
  useEffect(() => {
    window.addEventListener('keydown', onKeydown);

    return () => window.removeEventListener('keydown', onKeydown);
  }, [typedChars, backActive]);

  if (!userKey) return null;

  if (items) {
    return (
      <div className={`press-key-modal ${typedChars || backActive ? 'active' : ''}`}>
        <div className="press-key-round">
          <div className="press-key-items">
            {items.map((item) => {
              const active =
                !backActive &&
                (item.path
                  ? g(gap, 'selected.path') === item.path
                  : g(gap, 'selected.name') === item.name);
              const higlighted = active ? typedChars : '';
              const label = item.name.replace(upperFirst(typedChars), '');

              return (
                <div className={`press-key-item ${active ? 'active' : ''}`} key={item.name}>
                  <span className="press-key-item-highlighted">{higlighted}</span>
                  <span>{label}</span>
                </div>
              );
            })}
            {onRedirectBack && (
              <div className={`press-key-item ${backActive ? 'active' : ''}`}>
                <Icon size={60}>west</Icon>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default CatchKeyPress;
