import React from 'react';

import { isArray } from 'lodash-es';

import icons from '../../../assets/icons';

export const isValid = (icon: string) => !!icons[icon];

type Props = {
  icon: string;
  color?: string;
  width?: string | number;
  height?: string | number;
};

const Icon = ({ icon, color, width = 30, height = 30 }: Props): JSX.Element => {
  if (!isValid(icon) || !icons[icon]) {
    return <div>Missing icon</div>;
  }

  const iconData = icons[icon] as { path: string; transform: string; viewBox: string };

  let content = <path d={iconData.path} fill={color ? color : '#fff'} />;

  if (isArray(iconData.path)) {
    content = (
      <g transform={iconData.transform} fill={color ? color : '#fff'}>
        {iconData.path.map((p: string, index: number) => (
          <path key={index} d={p} />
        ))}
      </g>
    );
  }

  return (
    <svg width={width} height={height} viewBox={iconData.viewBox}>
      {content}
    </svg>
  );
};

Icon.propTypes = {};

export default Icon;
