import React, { ChangeEvent } from 'react';
import classNames from 'classnames';

import './style.css';

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  placeholder?: string;
  required?: boolean;
  error?: boolean;
  label?: string | JSX.Element;
  className?: string;
  lg?: boolean;
  sm?: boolean;
  disabled?: boolean;
  classNameLabel?: string;
  name?: string;
};

const TextField = ({
  onChange,
  value,
  label,
  className,
  classNameLabel,
  error,
  placeholder,
  required,
  disabled,
  sm,
  lg,
  ...props
}: Props) => {
  const input = (
    <label className="label">
      {/** iconStart && <Icon>{iconStart}</Icon> **/}
      <input
        className={classNames('text-field', { error, className, disabled, lg, sm })}
        onChange={onChange}
        value={value}
        disabled={disabled}
        placeholder={required && placeholder ? `${placeholder} *` : placeholder}
        {...props}
      />
    </label>
  );

  if (label) {
    return (
      <div className={`text-field-wrap ${classNameLabel || ''}`}>
        <div className={'text-field-label'}>{label}</div>
        {input}
      </div>
    );
  }

  return input;
};

export default TextField;
