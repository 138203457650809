import React, { Component } from 'react';

import { camelCase, kebabCase } from 'lodash-es';

import { imgPath } from '../../../../../api/api';

import PageWrap from '../../../../utils/PageWrap';
import NotFound from '../../../../utils/NotFound';

import App from '../components/App';

import config from '../../../../../assets/apps';

import './style.css';

class DevelopApp extends Component {
  state = {};

  interval = null;

  componentDidMount() {
    this.activeSkills();
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  get filename() {
    return window.location.pathname.replace('/apps/', '');
  }

  get appConfig() {
    return config.apps.find((app) => app.id === camelCase(this.filename));
  }

  activeSkills() {
    this.interval = setInterval(() => this.nextSkill(), 460);
  }

  nextSkill() {
    const next = this.state.activeSkill + 1;

    this.setState({ activeSkill: next });
  }

  get appImages() {
    const images = [];
    const numOfImg = 3;

    for (let i = 1; i <= numOfImg; i++) {
      const id = kebabCase(this.appConfig.id || this.appConfig.name);
      const path = `${imgPath}/develop/app/${id}-${i}.jpg`;
      const img = <img className="app-screens-image" src={path} key={i}/>;

      images.push(img);
    }

    return images;
  }

  render() {
    const { unmount } = this.state;

    if (!this.appConfig) return <NotFound/>;

    return (
      <PageWrap unmount={unmount}>
        <div className="app-developer">
          <div>
            Developer:&nbsp;
            <strong>Dominik Láclavík</strong>
          </div>
          <div>
            Contact:&nbsp;
          <strong>
            <a className="app-developer-mail" href="mailto:dlaclavik@gmail.com">dlaclavik@gmail.com</a>
          </strong>
          </div>
        </div>
        <div className="app-wrap">
          <div className="app-detail">
            <App app={this.appConfig}/>
          </div>
          <div className="app-screens">
            {this.appImages}
          </div>
        </div>
      </PageWrap>
    );
  }
}

export default DevelopApp;
