import { useState, useEffect } from 'react';

const useWidth = () => {
  const [state, setState] = useState({
    windowWidth: window.innerWidth,
  });

  useEffect(() => {
    const resizeHandler = () => {
      const currentWindowWidth = window.innerWidth;

      setState({ windowWidth: currentWindowWidth });
    };
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [state.windowWidth]);

  return state.windowWidth;
};

const useMediaQuery = (minWidth: number) => {
  const [state, setState] = useState({
    windowWidth: window.innerWidth,
    isDesiredWidth: window.innerWidth < minWidth,
  });

  useEffect(() => {
    const resizeHandler = () => {
      const currentWindowWidth = window.innerWidth;

      const isDesiredWidth = currentWindowWidth <= minWidth;
      setState({ windowWidth: currentWindowWidth, isDesiredWidth });
    };
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [state.windowWidth]);

  return state.isDesiredWidth;
};

const breakpoint = {
  XXLARGE: 1624,
  XLARGE: 1366,
  LARGE: 1024,
  MEDIUM: 960,
  SMALL: 768,
  XSMALL: 684,

  MOBILE: 1140,
};

export default {
  useWidth,
  useMediaQuery,
  ...breakpoint,
};
