import React, { ChangeEvent, KeyboardEvent } from 'react';
import { TextField as MaterialTextField } from '@mui/material';

import './style.css';

type TextFieldOptions = {
  shrink?: boolean;
};

type Props = {
  onChange: (value: string) => void;
  value: number | string;
  placeholder?: string;
  required?: boolean;
  error?: boolean;
  loading?: boolean;
  label?: string | JSX.Element;
  className?: string;
  lg?: boolean;
  sm?: boolean;
  disabled?: boolean;
  classNameLabel?: string;
  autoFocus?: boolean;
  fullWidth?: boolean;
  type?: string;
  onKeyPress?: (e: KeyboardEvent) => void;
  options?: TextFieldOptions;
};

const TextField = ({
  onChange,
  value,
  label,
  // className,
  // classNameLabel,
  // error,
  // loading,
  placeholder,
  required,
  disabled,
  options = {},
  // sm,
  // lg,
  ...props
}: Props) => {
  const t = (
    <MaterialTextField
      label={label}
      className="label"
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value as string)}
      value={value}
      disabled={disabled}
      placeholder={required && placeholder ? `${placeholder} *` : placeholder}
      variant="standard"
      required={required}
      InputLabelProps={{
        shrink: options.shrink,
      }}
      {...props}
    />
  );

  return t;
};

export default TextField;
