// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-cCl2x{background:#fff;width:100%;min-height:100svh;display:flex;align-items:center;justify-content:center}.content-Voohq{flex:1;display:flex;min-height:100svh}.nav-2nw93{padding:20px 30px;display:flex;justify-content:center}.navImage-oWiEp{width:30px;opacity:.2}.loader-M93LQ{position:absolute;display:flex;align-items:center;justify-content:center;font-weight:bolder;font-size:11px;text-transform:uppercase;color:#666;transition:.5s ease all}.loader-M93LQ.loaded-BEPnP{transform:scale(0.7);opacity:0}.loaderCircle-Jw8la{position:absolute;animation:rotate-loader-wp\\+\\+2 10s linear infinite}@keyframes rotate-loader-wp\\+\\+2{from{transform:rotate(0)}to{transform:rotate(360deg)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-cCl2x",
	"content": "content-Voohq",
	"nav": "nav-2nw93",
	"navImage": "navImage-oWiEp",
	"loader": "loader-M93LQ",
	"loaded": "loaded-BEPnP",
	"loaderCircle": "loaderCircle-Jw8la",
	"rotate-loader": "rotate-loader-wp++2"
};
export default ___CSS_LOADER_EXPORT___;
