import React, { useEffect, useState } from 'react';
import { ButtonBase, Collapse } from '@mui/material';

import API from '@api/index';
import { endpoints } from '@api/endpoints';

import { Common, Time, User } from '@functions';

import { DrinkStats, DrinkStatsSessionAggregation } from '@ts/Drink';

import styles from './style.scss';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import { flatten, mean, sum } from 'lodash-es';
import classNames from 'classnames';
import { format } from 'date-fns';

type Props = {
  showForAll: boolean;
};

const MONTH = 'month';
const WEEK = 'week';
const YEAR = 'year';

const SessionStats = ({ showForAll }: Props) => {
  const [stats, setStats] = useState<DrinkStats | null>(null);
  const [statsType, setStatsType] = useState<string>(MONTH);

  useEffect(() => {
    loadStats();
  }, [statsType]);

  const loadStats = async () => {
    const filter: Record<string, unknown> = { period: statsType };

    if (!showForAll) {
      filter.player = User.id();
    }

    try {
      const data = await API.post(endpoints.drink.stats.byPeriod, filter);

      setStats(data);
    } catch (error) {
      API.err(error);
    }
  };

  let sessions: DrinkStatsSessionAggregation[] = stats?.sessionsByDate
    ? Common.sort(stats?.sessionsByDate, '_id')
    : [];

  if (!showForAll) {
    sessions = sessions.filter((s) => s.drinksPlayer > 0);
  }

  const getDateTime = (date: string) => {
    if (statsType === YEAR) {
      return format(new Date(date), 'MMM');
    }

    if (statsType === WEEK) {
      return format(new Date(date), 'EEE');
    }

    return Time.formatDate(date as string);
  };

  const options = {
    title: {
      text: '',

      style: {
        color: '#000',
        font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
      },
    },
    subtitle: {
      style: {
        color: '#666666',
        font: 'bold 12px "Trebuchet MS", Verdana, sans-serif',
      },
    },
    chart: {
      zoomType: 'x',

      backgroundColor: {
        fill: '#222',
        stops: [
          [0, 'rgb(255, 255, 255)'],
          [1, 'rgb(240, 240, 255)'],
        ],
      },
    },
    colors: ['#333'],
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
        style: {
          color: '#aaa',
        },
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      gridLineColor: 'transparent',
    },
    xAxis: {
      title: {
        enabled: false,
      },
      labels: {
        enabled: true,
        rotation: -90,
        style: {
          color: '#ddd',
        },
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      gridLineColor: 'transparent',
      categories: sessions.map((s) => getDateTime(s._id as string)),
    },
    series: [
      {
        name: 'Drinks by day',
        // type: 'area',
        data: sessions?.map((s) => [
          Time.formatDate(s._id as string),
          showForAll ? s.drinks : s.drinksPlayer,
        ]),
        lineColor: '#ccc',
        color: '#333',
        /* color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, '#111'],
            [10, 'transparent'],
          ],
        }, */
        dataLabels: {
          enabled: true,
          align: 'right',
          format: '{point.y}', // one decimal
          x: -2,
          y: 0, // 10 pixels down from the top
          style: {
            fontSize: '10px',
            textShadow: 'none',
            color: 'black',
            fontFamily: 'Verdana, sans-serif',
            letterSpacing: -1,
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
  };

  return (
    <Collapse in={!!stats}>
      <div className={styles.stats}>
        <div className={styles.statsInfo}>
          <div className={styles.stat}>
            <div className={styles.statLabel}>Drinks total</div>
            <div className={styles.statValue}>
              {sum(flatten(sessions.map((s) => (showForAll ? s.drinks : s.drinksPlayer))))}
            </div>
          </div>
          <div className={styles.stat}>
            <div className={styles.statLabel}>Sessions in last month</div>
            <div className={styles.statValue}>
              {showForAll ? stats?.sessionsTotal : stats?.sessionsTotalPlayer}
            </div>
          </div>
          <div className={styles.stat}>
            <div className={styles.statLabel}>Avg Tempo</div>
            <div className={styles.statValue}>
              {mean(
                sessions
                  ?.filter(Boolean)
                  .map((s) => (showForAll ? s.minutesPerDrink : s.minutesPerDrinkPlayer)),
              )?.toFixed(1)}
            </div>
          </div>
        </div>
        {stats && <HighchartsReact highcharts={Highcharts} options={options} />}
        <div className={styles.statsType}>
          <ButtonBase
            classes={{
              root: classNames(styles.multiPickerButton, statsType === YEAR && styles.selected),
            }}
            onClick={() => setStatsType(YEAR)}
          >
            <div className={styles.multiPickerButtonLabel}>Year</div>
          </ButtonBase>
          <ButtonBase
            classes={{
              root: classNames(styles.multiPickerButton, statsType === MONTH && styles.selected),
            }}
            onClick={() => setStatsType(MONTH)}
          >
            <div className={styles.multiPickerButtonLabel}>Month</div>
          </ButtonBase>
          <ButtonBase
            classes={{
              root: classNames(styles.multiPickerButton, statsType === WEEK && styles.selected),
            }}
            onClick={() => setStatsType(WEEK)}
          >
            <div className={styles.multiPickerButtonLabel}>Week</div>
          </ButtonBase>
        </div>
      </div>
    </Collapse>
  );
};

export default SessionStats;
