// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".scrollBanner-mhIq8{position:absolute;opacity:0;left:50%;bottom:0;margin-bottom:20px;z-index:1;transform:translate(-50%, 300%);animation:show-C9jFw 2s .4s cubic-bezier(0.51, 0.92, 0.24, 1.15) forwards}.squaredBlack-6yHpo .scrollButton-lt1S5{background:#333 !important;padding:12px 24px 12px 12px !important;color:#fff !important;line-height:1}.squaredBlack-6yHpo .scrollButtonIcon-X0Idl{margin-right:8px}.rounded-wU9ZN .scrollButton-lt1S5{padding:12px 20px 12px 24px !important;color:#333 !important;border:2px solid #333;border-radius:100px;line-height:1;font-size:16px;text-transform:uppercase;letter-spacing:6px;font-weight:500}.rounded-wU9ZN .scrollButtonIcon-X0Idl{margin-right:8px}@keyframes show-C9jFw{to{opacity:1;transform:translate(-50%, 0)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollBanner": "scrollBanner-mhIq8",
	"show": "show-C9jFw",
	"squaredBlack": "squaredBlack-6yHpo",
	"scrollButton": "scrollButton-lt1S5",
	"scrollButtonIcon": "scrollButtonIcon-X0Idl",
	"rounded": "rounded-wU9ZN"
};
export default ___CSS_LOADER_EXPORT___;
