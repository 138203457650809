import React from 'react';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Icon,
  Typography,
} from '@mui/material';
import { UnsafeAny } from '@ts/General';

type MenuItem = UnsafeAny;

type Props = {
  config: MenuItem[];
  title?: string;
  open?: boolean;
  onClose?: () => void;
  onClick?: (item: MenuItem) => void;
  titleExtend?: string;
};

const SideMenu = ({ config, title, titleExtend, open, onClose, onClick }: Props) => {
  const drawer = (
    <div>
      <Toolbar>
        {title && <Typography variant="h5">{title}</Typography>}
        {!titleExtend && <Typography variant="caption">v1.0</Typography>}
      </Toolbar>
      <Divider />
      <List>
        {config.map((item: MenuItem, index: number) =>
          item.type === 'divider' ? (
            <Divider key={item.id || index} />
          ) : (
            <ListItem
              button
              key={item.id || index}
              className={item.className}
              onClick={() => onClick && onClick(item)}
            >
              {item.icon && (
                <ListItemIcon>
                  <Icon>{item.icon}</Icon>
                </ListItemIcon>
              )}
              <ListItemText primary={item.label}>{item.label}</ListItemText>
            </ListItem>
          ),
        )}
      </List>
    </div>
  );

  const drawerWidth = 300;

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        open={typeof open === undefined ? true : open}
        variant="temporary"
        onClose={onClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default SideMenu;
