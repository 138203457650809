import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, TextField } from '@mui/material';
import PageWrap from '../../utils/PageWrap';
import API from '@api/index';
import { endpoints } from '@api/endpoints';
import { useHistory } from 'react-router-dom';
import settings from '@assets/settings';

const Login = (): JSX.Element => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('anim-session')) {
      history.push(localStorage.getItem('redirect') || settings.mainSessionPage);
    }

    /** API.post(endpoints.user.add, {
      email: 'test@gmail.com',
      password: '22726752',
      userKey: 'tp',
      username: 'tg',
      name: 'Test Gm',
    }); **/
  }, []);

  const onSubmit = async () => {
    setLoading(true);

    try {
      const resp = await API.post(endpoints.session.login, { email, password });

      localStorage.setItem('anim-session', JSON.stringify(resp));
      window.dispatchEvent(new Event('storage'));

      history.push(localStorage.getItem('redirect') || settings.mainSessionPage);
    } catch (err) {
      setError(err?.message || err);
      setLoading(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Typography variant="h5" color="primary" textAlign="center">
        Anim login
      </Typography>
      <Box display="flex" flexDirection="column" minWidth={300} mt={4}>
        <TextField
          label="E-mail"
          onChange={(e) => {
            setEmail(e.target.value);
            if (error) setError(null);
          }}
          value={email}
          type="email"
          error={!!error}
          onKeyPress={(e) => e.key === 'Enter' && onSubmit()}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Password"
          onChange={(e) => {
            setPassword(e.target.value);
            if (error) setError(null);
          }}
          value={password}
          type="password"
          error={!!error}
          helperText={error}
          onKeyPress={(e) => e.key === 'Enter' && onSubmit()}
        />
        <Button variant="contained" onClick={onSubmit} sx={{ mt: 2 }} disabled={loading}>
          Confirm
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
