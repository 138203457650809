import React, { useEffect, useState } from 'react';
import { upperFirst } from 'lodash-es';

import API from '../../../../api';

import { Cookie } from '../../../../functions';

import VotesLayout from '../layout';
import Voter from './../components/Voter';
import VoteItem from '../components/VoteItem';

import './style.css';

const Voting = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const type = window.location.pathname.replace(/\/voting\//g, '');

  const userKey = Cookie.get('userKey');

  const fetchData = async () => {
    setIsLoading(true);

    const { rows } = await API.post('/votes/search', { type });

    setData(rows);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onRate = async (id, rating) => {
    try {
      await API.post('/votes/rate', { id, rating });
      await fetchData();
    } catch (error) {
      API.err(error);
    }
  };

  const onAdd = async (name) => {
    try {
      await API.post('/votes/list', { name, type, userKey });
      await fetchData();
    } catch (error) {
      API.err(error);
    }
  };

  return (
    <VotesLayout loading={isLoading} titleExtend={`for ${upperFirst(type)}`}>
      <div className="vote-items">
        {data.map((item) => (
          <Voter data={item} onRate={(rating) => onRate(item.id, rating)} key={item.id}/>
        ))}
        {userKey && <VoteItem onAdd={onAdd} title={`Add new ${type.replace(/s$/g, '')}`}/>}
      </div>
    </VotesLayout>
  );
};

export default Voting;
