import React, { useEffect, useState, KeyboardEvent as ReactKeyboardEvent } from 'react';
import { withRouter } from 'react-router';

import classNames from 'classnames';

import { includes, isNumber, toUpper } from 'lodash-es';

import { Box, Toolbar, Paper, Typography, IconButton, Icon, Collapse } from '@mui/material';

import API from '@api/index';
import { endpoints } from '@api/endpoints';

import { Common, Hash, Time, User } from '@functions';

import SideMenu from '@material/SideMenu';
import TextField from '@material/TextField';
import Table from '@material/Table';
import Button from '@material/Button';
import Select from '@material/Select';
import SearchField from '@material/SearchField';
import Notification from '@material/Notification';
import ButtonPicker from '@material/ButtonPicker';
import RangeSlider from '@material/RangeSlider';
import Favorite from '@material/Favorite';
import Modal from '@material/Modal';
import Tag from '@material/Tag';
import IntervalSelect from '@material/IntervalSelect';

import { History } from 'history';

import './style.css';
import { Film, FilmCategory, FilmFlag, FilmType } from '@ts/Film';
import { Identifier, SimpleObject, UnsafeAny } from '@ts/General';

const menuConfig = [
  { id: 'notices', label: 'To watch', icon: 'history_toggle_off' },
  { type: 'divider' },
  { id: 'films', label: 'Films', icon: 'theaters', filter: { type: 'FILM' } },
  { id: 'series', label: 'Series', icon: 'camera_roll', filter: { type: 'SERIES' } },
  { type: 'divider' },
  { id: 'ranking', label: 'Ranking', icon: 'leaderboard' },
  { id: 'favorites', label: 'Favorites', icon: 'star', filter: { favorite: true } },
  { type: 'divider' },
  { id: 'marvel', label: 'Marvel', extra: true, filter: { categories: ['marvel'] } },
  { id: 'dc', label: 'DC', extra: true, filter: { categories: ['dc'] } },
  { type: 'divider' },
  { id: 'animated', label: 'Animated', extra: true },
  { id: 'space', label: 'Space', extra: true },
  { id: 'mysterious', label: 'Mysterious', extra: true },
];

const lastSeenTypes = [
  { id: 'never', label: 'Never', sub: false },
  { id: 'today', label: 'Today', sub: { days: 0 } },
  { id: 'yesterday', label: 'Yesterday', sub: { days: 1 } },
  { id: 'lastWeek', label: 'Last week', sub: { weeks: 1 } },
  { id: 'lastMonth', label: 'Last month', sub: { months: 1 } },
  { id: 'lastYear', label: 'Last year', sub: { years: 1 } },
  { id: 'custom', label: 'Custom', sub: false },
];

type Props = {
  location: Location;
  history: History;
};

type LoadDataParams = {
  fulltext?: string;
  offset?: number;
  limit?: number;
  sort?: {
    order: string;
    field: string;
  };
};

const Films = ({ location, history }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Film[]>([]);
  const [total, setTotal] = useState(0);
  const [flags, setFlags] = useState<FilmFlag[]>([]);
  const [categories, setCategories] = useState<FilmCategory[]>([]);

  const [search, setSearch] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [sliders, setSliders] = useState<SimpleObject>({});
  const [isAddingOpen, setIsAddingOpen] = useState(false);
  const [isSeenOpen, setIsSeenOpen] = useState(false);
  const [isIntervalOpen, setIsIntervalOpen] = useState(false);
  const [film, setFilm] = useState<Partial<Film>>({ name: '', part: 1, type: 'FILM' });

  // const type = window.location.pathname.replace(/\/voting\//g, '');

  // const userKey = Cookie.get('userKey');

  useEffect(() => {
    loadData();
  }, [location.hash]);

  useEffect(() => {
    Common.typingCallBack(() => loadData({ fulltext: search }), 100);
  }, [search]);

  const loadData = async (params: LoadDataParams = { limit: 10, offset: 0 }) => {
    setIsLoading(true);

    const filter = {
      offset: params.offset,
      limit: params.limit,
      sortDir: params.sort?.order,
      sortBy: params.sort?.field,
      fulltext: params.fulltext,
      ...Hash.build(),
    };

    try {
      const { rows, total } = await API.post(endpoints.film.search, filter);

      setData(rows);
      setTotal(total);
      setIsLoading(false);
    } catch (err) {
      API.err(err);
      setIsLoading(false);
    }
  };

  const loadStatic = async () => {
    setIsLoading(true);

    // const flags = await API.post(endpoints.film.flags.list, {});
    const flags = [
      { id: 'epicSoundtrack', name: 'Epic soundtrack' },
      { id: 'epicVisual', name: 'Epic visual' },
      { id: 'epicAtmosphere', name: 'Epic atmosphere' },
      { id: 'epicStory', name: 'Epic story' },
      { id: '4K', name: '4K' },
    ];

    const categories = [
      { id: 'marvel', name: 'Marvel' },
      { id: 'nolan', name: 'Nolan' },
      { id: 'dc', name: 'DC' },
      { id: 'space', name: 'Space' },
      { id: 'animated', name: 'Animated' },
      { id: 'comedy', name: 'Comedy' },
      { id: 'mysterious', name: 'Mysterious' },
      { id: 'horror', name: 'Horror' },
      { id: 'document', name: 'Documentary' },
    ];

    setFlags(flags);
    setCategories(categories);

    setData(data);
    setIsLoading(false);

    /*
    const f = ['Le Mans \'66', 'Avengers: Endgame', 'Ako si vycvičiť draka 3', 'Pulp Fiction: Historky z podsvetia', 'Bohemian Rhapsody', 'Spider-Man: Paralelné svety', 'Avengers: Nekonečná vojna', 'Ready Player One: Hra sa začína', 'Čierny panter', 'Thor: Ragnarok', 'Doktor Strange', 'Captain America: Občianska vojna', 'Deadpool', 'Avengers 2: Vek Ultrona', 'Dokonalý trik', 'Rivali', 'Temný rytier', 'Návrat Temného rytiera', 'The Matrix', 'Na hrane zajtrajška', 'Batman začína', 'Pán prsteňov: Dve veže', 'Pán prsteňov: Návrat kráľa', 'Ako vycvičiť draka', 'Avengers: Pomstitelia', 'Pán prsteňov: Spoločenstvo Prsteňa', 'Avatar', 'Kung Fu Panda 3', 'Memento', 'Don\'t Look Up', 'Shang-Chi: Legenda o desiatich prsteňoch', 'Kingsman: Zlatý kruh', 'Autá 3', 'Piaty element', 'Zrodila sa hviezda', 'Mission: Impossible - Fallout', 'Bright', 'Afrika na Pionieri', 'Tenet', 'Joker', 'Muži, ženy a deti', 'Dunkirk', 'Balerína', 'Náčelník', 'Drive', 'Hviezdny prach'];

    for (const d of f) {
      try {
        const type = 'FILM';

        await API.post(endpoints.film.add, { type, name: d });
      } catch (e) {
      }
    } */
  };

  const update = ({ id, value }: { id: string; value: UnsafeAny }) =>
    setFilm({ ...film, [id]: value });

  const onKeydown = async (e: KeyboardEvent) => {
    /** Allow only numbers and letters **/
    let isValid = !!e.code.match(/[0-9A-Za-z]/g);
    const key = e.code.replace(/(Key|Digit)/g, '');

    if (key.length > 1) {
      isValid = false;
    }

    if (isValid) {
      setFilm({ type: 'FILM', name: toUpper(key), part: 1 });
      setTimeout(() => setIsAddingOpen(true), 20);
    }
  };

  const setDefaults = (type: FilmType = 'FILM') => {
    setFilm({ type, name: '', part: 1 });
  };

  useEffect(() => {
    loadStatic();
  }, []);

  useEffect(() => {
    if (!isAddingOpen && !isFocused) {
      window.addEventListener('keydown', onKeydown);
    }

    return () => {
      !isAddingOpen && window.removeEventListener('keydown', onKeydown);
    };
  }, [isAddingOpen, isFocused]);

  const onSave = async () => {
    if (!film.name || film.name.length < 2) return;

    const name = film.name?.replace(/\s?:\s?/g, ' - ').replace(/\s+/g, ' ');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    film.seen = Time.getTime(lastSeenTypes.find((t) => t.id === film.lastSeenType)) as string;

    delete film.lastSeenType;

    try {
      if (film._id) {
        await API.put(endpoints.film.update(film._id as unknown as Identifier), {
          ...film,
          name,
          user: User.hasAccess(),
        });
      } else {
        await API.post(endpoints.film.add, { ...film, name, user: User.hasAccess() });
      }

      if (!Hash.build().type || Hash.build().type !== film.type) {
        Hash.push({ type: film.type });
      } else {
        await loadData();
      }

      setIsAddingOpen(false);
      Common.modalDelay(setDefaults);
    } catch (error) {
      API.err(error);
    }
  };

  const onRate = async (id: Identifier, rating: number) => {
    try {
      await API.put(endpoints.film.rate(id), { rating });
      await loadData();
    } catch (error) {
      API.err(error);
    }
  };

  const onFavorite = async (id: Identifier, favorite: boolean) => {
    try {
      await API.put(endpoints.film.favorite(id), { favorite });
      await loadData();
    } catch (error) {
      API.err(error);
    }
  };

  const onSaveSeen = async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const date = Time.getTime(lastSeenTypes.find((t) => t.id === film.lastSeenType));

    try {
      await API.put(endpoints.film.seen(film._id as unknown as Identifier), { date });

      setIsSeenOpen(false);
      Common.modalDelay(setDefaults);

      await loadData();
    } catch (error) {
      API.err(error);
    }
  };

  const onSaveInterval = async () => {
    try {
      await API.put(endpoints.film.interval(film._id as unknown as Identifier), film.interval);

      setIsIntervalOpen(false);
      Common.modalDelay(setDefaults);

      await loadData();
    } catch (error) {
      API.err(error);
    }
  };

  const username = User.username();

  const config = [
    { id: 'name', sort: 'name', label: 'Name' },
    { id: 'part', label: 'Part / Series', render: (data: number) => (data === 0 ? 'All' : data) },
    {
      id: 'rating',
      label: 'Rating',
      render: (data: UnsafeAny, row: Film) => {
        const percentage = sliders[row._id] || data;
        return (
          <div className="film-range">
            <div
              className={classNames('film-range-slider', { active: !!(sliders[row._id] || data) })}
            >
              <RangeSlider
                value={percentage || 50}
                onChange={(v) => {
                  setSliders({ ...sliders, [row._id]: v });
                  Common.typingCallBack(() =>
                    onRate(row._id as unknown as Identifier, v as number),
                  );
                }}
              />
            </div>
            <div
              className={classNames('film-range-percentage', {
                active: !!(sliders[row._id] || data),
              })}
            >
              {isNumber(percentage) ? `${percentage}%` : 'No rating'}
            </div>
          </div>
        );
      },
    },
    {
      id: 'favorite',
      label: 'Favorite',
      render: (data: UnsafeAny, row: Film) => (
        <Favorite
          onClick={() => onFavorite(row._id as unknown as Identifier, !data)}
          value={data}
        />
      ),
    },
    {
      id: 'lastSeen',
      label: 'Last seen',
      render: (data: UnsafeAny, row: Film) =>
        row.seen?.length ? (
          <Button
            onClick={() => {
              setFilm(row);
              setIsSeenOpen(true);
            }}
          >
            {Time.concreteFull(row.seen[row.seen.length - 1])}
          </Button>
        ) : (
          <Button
            onClick={() => {
              setFilm(row);
              setIsSeenOpen(true);
            }}
          >
            Never
          </Button>
        ),
    },
    {
      id: 'interval',
      label: 'Watch again int.',
      render: (data: UnsafeAny, row: Film) =>
        row.interval ? (
          <Button
            onClick={() => {
              setIsIntervalOpen(true);
              setFilm(row);
            }}
          >
            {`${row.interval.count} ${row.interval.type}`}
          </Button>
        ) : (
          <Button
            onClick={() => {
              setIsIntervalOpen(true);
              setFilm(row);
            }}
          >
            Not set
          </Button>
        ),
    },
    {
      id: 'actions',
      label: 'Actions',
      render: (data: UnsafeAny, row: Film) => (
        <Box>
          <IconButton
            onClick={() => {
              setIsAddingOpen(true);
              setFilm(row);
            }}
          >
            <Icon>edit</Icon>
          </IconButton>
          <IconButton
            onClick={() => {
              setIsSeenOpen(true);
            }}
          >
            <Icon>remove_red_eye</Icon>
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleSubmit = (e: ReactKeyboardEvent) => {
    if (e.charCode === 13 && film.name && film.name?.length > 1) {
      onSave();
    }
  };

  return (
    <div className="films-wrap">
      <SideMenu
        config={menuConfig}
        onClick={(item) => history.push(Hash.push(item.filter, true))}
        title="Films"
      />
      <div className="films-content">
        <Toolbar component={Paper}>
          <div className="films-header-main">
            <Box mx={1}>
              <SearchField
                onChange={setSearch}
                value={search}
                inputProps={{
                  onFocus: () => setIsFocused(true),
                  onBlur: () => setIsFocused(false),
                }}
              />
            </Box>
            {/** <TextField
                className="films-header-search-input"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              /> **/}
            <Box mx={1}>
              <Button
                icon="add"
                onClick={() => {
                  setDefaults();
                  setIsAddingOpen(true);
                }}
              >
                Add film
              </Button>
            </Box>
            <Box mx={1}>
              <Button
                icon="add"
                onClick={() => {
                  setDefaults('SERIES');
                  setIsAddingOpen(true);
                }}
              >
                Add series
              </Button>
            </Box>
          </div>
          <Box mx={1}>
            <Notification />
          </Box>
          <Box ml={2}>
            <Typography variant="body2">{username}</Typography>
          </Box>
        </Toolbar>
        <div className="films-body">
          <div className="films-body-list">
            <Table
              config={config}
              data={data}
              onSelect={console.log}
              defaultSort={{
                order: 'ASC',
                field: 'name',
              }}
              pagination={{
                onChange: loadData,
                total,
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        title={`${film._id ? 'Edit' : 'Add'} ${film.type?.toLowerCase()}`}
        open={isAddingOpen}
        onSubmit={() => onSave()}
        onExited={() => {
          setIsAddingOpen(false);
          Common.modalDelay(setDefaults);
        }}
        onClose={() => {
          setIsAddingOpen(false);
          Common.modalDelay(setDefaults);
        }}
        submitText={`${film._id ? 'Save' : 'Add'}`}
        disableSubmit={!film.name || film.name.length < 2}
      >
        <Box flexDirection="column" minWidth={550}>
          <Box my={1}>
            <TextField
              /* eslint-disable-next-line jsx-a11y/no-autofocus */
              autoFocus
              fullWidth
              label="Name"
              onChange={(value: UnsafeAny) => update({ id: 'name', value })}
              value={film.name || ''}
              onKeyPress={handleSubmit}
            />
          </Box>
          <Box my={1}>
            <Select
              label="Type"
              items={[
                { value: 'FILM', label: 'Film' },
                { value: 'SERIES', label: 'Series' },
              ]}
              value={film.type as string}
              onChange={(value: UnsafeAny) => update({ id: 'type', value })}
            />
          </Box>
          <Box my={1}>
            <TextField
              fullWidth
              label="Part"
              onChange={(value: UnsafeAny) => update({ id: 'part', value })}
              value={film.part as number}
              type="number"
              onKeyPress={handleSubmit}
            />
          </Box>
        </Box>
        <Box my={1}>
          <div className="add-film-rating">
            <div className="film-range">
              <div className={classNames('film-range-slider active')}>
                <RangeSlider
                  onChange={(value) => update({ id: 'rating', value })}
                  value={film.rating as number}
                />
              </div>
              <div className={classNames('film-range-percentage', { active: film.rating })}>
                {isNumber(film.rating) ? `${film.rating}%` : 'Set rating'}
              </div>
            </div>
            <Favorite
              onClick={() => update({ id: 'favorite', value: !film.favorite })}
              value={!!film.favorite}
            />
          </div>
        </Box>
        <Box my={1}>
          {!film._id && (
            <ButtonPicker
              label="Last seen"
              items={lastSeenTypes}
              onChange={(value) => update({ id: 'lastSeenType', value: value as UnsafeAny })}
              selected={film.lastSeenType || 'never'}
            />
          )}
        </Box>
        <Box my={1}>
          {flags.map((f) => (
            <Tag
              key={f.id}
              className="mui-tag-margin"
              onClick={() => {
                const newFlags = film.flags ? [...film.flags] : [];
                const index = newFlags.findIndex((flag) => flag === f.id);

                if (index === -1) {
                  newFlags.push(f.id);
                } else {
                  newFlags.splice(index, 1);
                }

                update({ id: 'flags', value: newFlags });
              }}
              active={includes(film.flags, f.id)}
            >
              {f.name}
            </Tag>
          ))}
        </Box>
        <Box my={1}>
          {categories.map((c) => (
            <Tag
              key={c.id}
              className="mui-tag-margin"
              onClick={() => {
                const newCategories = film.categories ? [...film.categories] : [];
                const index = newCategories.findIndex((category) => category === c.id);

                if (index === -1) {
                  newCategories.push(c.id);
                } else {
                  newCategories.splice(index, 1);
                }

                update({ id: 'categories', value: newCategories });
              }}
              active={includes(film.categories, c.id)}
            >
              {c.name}
            </Tag>
          ))}
        </Box>
      </Modal>
      <Modal
        title={`Add seen on ${film?.name}`}
        open={isSeenOpen}
        onSubmit={() => onSaveSeen()}
        onExited={() => {
          setIsSeenOpen(false);
          Common.modalDelay(setDefaults);
        }}
        onClose={() => {
          setIsSeenOpen(false);
          Common.modalDelay(setDefaults);
        }}
        submitText="Add"
      >
        <Box flexDirection="column" minWidth={550}>
          <Box my={1}>
            <ButtonPicker
              label="Last seen"
              items={lastSeenTypes}
              onChange={(value) => update({ id: 'lastSeenType', value })}
              selected={film?.lastSeenType || 'never'}
            />
            <Box my={2} justifyContent="center">
              <Collapse in={film?.lastSeenType === 'custom'}>
                <TextField
                  label="Date seen"
                  type="date"
                  onChange={(value: string) => update({ id: 'lastSeen', value })}
                  value={film?.lastSeen || new Date().toISOString()}
                />
              </Collapse>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        title={`${film.interval ? 'Edit' : 'Add'} interval on ${film?.name}`}
        open={isIntervalOpen}
        onSubmit={() => onSaveInterval()}
        onExited={() => {
          setIsIntervalOpen(false);
          Common.modalDelay(setDefaults);
        }}
        onClose={() => {
          setIsIntervalOpen(false);
          Common.modalDelay(setDefaults);
        }}
        submitText="Add"
      >
        <Box flexDirection="column" minWidth={550}>
          <Box my={1}>
            <IntervalSelect
              values={film?.interval}
              onChange={(change) => update({ id: 'interval', value: change.value })}
              onSubmit={() => onSaveInterval()}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withRouter(Films);
