export const teamImages = [
  { id: 1, teamId: 5, name: 'Mercedes-AMG', link: 'f1/f1-1.jpg' },
  { id: 2, teamId: 1, name: 'Red Bull', link: 'f1/f1-2.jpg' },
  { id: 3, teamId: 2, name: 'McLaren', link: 'f1/f1-5.jpg' },
  { id: 4, teamId: 17, name: 'Racing Point', link: 'f1/f1-10.jpg' },
  { id: 5, teamId: 13, name: 'Renault Point', link: 'f1/f1-6.jpg' },
  { id: 6, teamId: 3, name: 'Ferrari', link: 'f1/f1-3.jpg' },
  { id: 7, teamId: 7, name: 'AlphaTauri', link: 'f1/f1-4.jpg' },
  { id: 8, teamId: 18, name: 'Alfa Romeo', link: 'f1/f1-8.jpg' },
  { id: 9, teamId: 14, name: 'Haas', link: 'f1/f1-9.jpg' },
  { id: 10, teamId: 12, name: 'Williams', link: 'f1/f1-7.jpg' },
];
