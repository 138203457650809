import React, { useContext, useMemo, useState } from 'react';
import { ButtonBase, Dialog, Fade, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { sum } from 'lodash-es';

import { GamesContext } from '../../../../../context/gamesData';
import API from '../../../../../api';
import { endpoints } from '../../../../../api/endpoints';

import { Common, User } from '../../../../../functions';

import GamesLayout from '../../layout';

import Card from '../../components/Card';

import { translate } from '../../../../../localise';

import './style.css';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background: linear-gradient(#222, #333);
  },
`;

const StyledButtonBase = styled(ButtonBase)`
    color: #ccc;
    font-size: 20px;
    font-family: 'Varem', sans-serif;
    padding: 12px 20px 8px;
    line-height: 1;
    border-radius: 6px;
    margin-left: 10px;
    transition: .3s ease all;
    &:hover {
      color: white;
    }
`;

const StyledButtonBaseActive = styled(StyledButtonBase)`
  background: #ffffff22;
  &:hover {
    color: rgba(203,0,255,0.86);
    background: white;
  }
  &:disabled {
    opacity: 0.3;
  }
`;

const StyledTextField = styled(TextField)`
  .MuiInputLabel-root {
    color: #888;
    font-size: 15px;
    font-family: 'Varem', sans-serif;
  },
  .MuiInputLabel-root.Mui-focused {
    color: #bbb;
  }
  .MuiInput-input {
    color: #ccc;
    font-size: 25px;
    padding: 0 0 2px;
  }

  .MuiInput-input:focus {
    color: white;
  }
  
  .MuiInput-underline::before {
    border-bottom-color: #555;
  }
  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom-color: #666;
  }
  .MuiInput-underline::after {
    border-bottom-color:  rgba(203,0,255,0.86);
  }
`;

const Types = () => {
  const [data, dataLoaded] = useContext(GamesContext);
  const [isOpen, setIsOpen] = useState(null);
  const [values, setValues] = useState({});

  const gamingDayEdit = User.hasRight('games:edit');

  const gameTypes = data.gameTypes.map((gT) => {
    if (!data.gameStats?.pointsByGames) {
      return null;
    }

    const playedTimes = data.gameStats.pointsByGames[gT.id]?.playedTimes || 0;
    const points = data.gameStats.pointsByGames[gT.id]?.points || {};

    const pointsTotal = sum(Object.values(points));

    return {
      ...gT,
      pointsTotal,
      playedTimes,
    };
  }).filter(Boolean);


  const onCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => setValues({}), 300);
  };

  const onSave = async () => {
    const prepareObject = (obj) => {
      const newObj = { ...obj };

      delete newObj.createdAt;
      delete newObj.updatedAt;
      delete newObj._id;
      delete newObj.__v;

      return newObj;
    };

    try {
      if (values._id) {
        await API.put(endpoints.game.type.update(values._id), prepareObject(values));
      } else {
        await API.post(endpoints.game.type.add, values);
      }
    } catch (err) {
      API.err(err);
    }
  };

  const sortedGameTypes = useMemo(() => {
    return Common.sort(gameTypes, 'playedTimes', true).reverse();
  }, [gameTypes.length]);

  const updateValue = (field, value) => {
    const newValues = { ...values };

    if (field.type === 'number' && value !== '') {
      const val = Number(value);

      if (!isNaN(value) && field.min && field.min > val) {
        return;
      }

      if (!isNaN(value) && field.max && field.max < val) {
        return;
      }
    }

    if (field.name === 'name') {
      newValues.id = value.toUpperCase().replace(/\s/g, '_');
    }

    if (field.name === 'id') {
      value = value.toUpperCase().replace(/\s+/g, '_');
    }

    newValues[field.name] = value;

    setValues(newValues);
  };

  const field = (field) => (
    <div className="game-type-field" key={field.name}>
      <StyledTextField
        InputLabelProps={{
           shrink: true,
        }}
        variant="standard"
        fullWidth
        id={`game-field-${field.name}`}
        onChange={(e) => updateValue(field, e.target.value)}
        value={field.value || values[field.name]}
        label={field.label || translate.games.types[field.name] || field.name}
        placeholder={field.placeholder}
        type={field.type}
        min={field.min}
        max={field.max}
        autoComplete="off"
      />
    </div>
  );

  return (
    <GamesLayout loading={!dataLoaded} header="Game types" center>
      <StyledDialog
        className=""
        open={isOpen}
        onClose={onCloseModal}
      >
        <div className="game-type-form">
          <div className="game-type-form-title">
            {values._id ? translate.games.types.edit : translate.games.types.add}
          </div>
          {field({ name: 'name' })}
          {field({ name: 'id' })}
          {field({ name: 'minPlayers', min: 1, max: 100, type: 'number' })}
          {field({ name: 'maxPlayers', min: 1, max: 100, type: 'number', placeholder: translate.games.types.unlimited })}
          <div className="game-type-form-actions">
            <StyledButtonBase onClick={onCloseModal}>
              Cancel
            </StyledButtonBase>
            <StyledButtonBaseActive disabled={!values.id || !values.name} onClick={onSave}>
              Save
            </StyledButtonBaseActive>
          </div>
        </div>
      </StyledDialog>

      <div className="game-types-body">
        {sortedGameTypes.map((gT, index) => (
            <Fade
              key={gT._id}
              in={!!sortedGameTypes.length}
              mountOnEnter
              style={{ transitionDelay: `${240 * index + 'ms'}`, transitionDuration: '1250ms' }}
            >

              <div className="game-types-item">
                <Card key={gT._id} onClick={gamingDayEdit ? () => {
                  setValues(gT);
                  setIsOpen(true);
                } : false}>
                  <div className="game-type-item-body">
                    <div className="game-type-name">{gT.id.replace(/_/g, ' ')}</div>
                    <div>
                      <div className="game-type-players">{gT.minPlayers} - {gT.maxPlayers || 'INFINITY'}</div>
                      <div className="game-type-players">Players</div>
                    </div>
                    <div>
                      <div className="game-type-played-times">{gT.playedTimes}</div>
                      <div className="game-type-played-times-label">games played</div>
                    </div>
                    <div>
                      <div className="game-type-points-total">{gT.pointsTotal}</div>
                      <div className="game-type-points-total-label">points dealt</div>
                    </div>
                  </div>
                </Card>
              </div>
            </Fade>
          ),
        )}
        {gamingDayEdit &&
        <Fade
          in={!!gameTypes.length}
          mountOnEnter
          style={{transitionDelay: `${240 * gameTypes.length + 'ms'}`, transitionDuration: '1250ms'}}
        >
          <div className="game-types-item">
            <Card onClick={() => setIsOpen(true)}>
              <div/>
              <div>
                <div className="game-types-add"/>
                <div className="game-types-add-label">
                  Add
                </div>
              </div>
              <div/>
            </Card>
          </div>
        </Fade>
        }
      </div>
    </GamesLayout>
  );
};

export default Types;
