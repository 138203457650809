import React, { useState } from 'react';
import classNames from 'classnames';

import PageWrap from '../../utils/PageWrap';
import Timeline from '@common/Timeline';
import ScrollDown from '@layout/ScrollDown';

import about from '@assets/about';

import aboutImage from '@assets/img/about-new.jpg';
import aboutImage2 from '@assets/img/about-new-2.jpg';

import styles from './style.scss';

const About = (): JSX.Element => {
  const [unmount] = useState<string | null>(null);

  const [imgLoaded, setImgLoaded] = useState(false);
  const [img2Loaded, setImg2Loaded] = useState(false);

  return (
    <PageWrap unmount={unmount}>
      <div className={styles.wrap}>
        <div className={styles.aboutSection}>
          <div className={styles.partMain}>
            <div className={styles.aboutTitle}>
              <h1 className={styles.aboutTitleFirstName}>Dominik</h1>
              <h3 className={styles.aboutTitleSurname}>Láclavík</h3>
            </div>
          </div>
          <div className={styles.part}>
            <div className={styles.partMain}>
              <img
                className={classNames(styles.image, imgLoaded && styles.loaded)}
                onLoad={() => setImgLoaded(true)}
                src={aboutImage}
                alt="about_pic"
              />
            </div>
            <div className={styles.part}>
              <div className={styles.partContent}>
                <h6 className={styles.partTitle}>About me</h6>
                <p className={styles.partText}>{about.aboutMe}</p>
              </div>
            </div>
            <ScrollDown type={'SQUARED_BLACK'} />
          </div>
          <div className={styles.part}>
            <div className={styles.part}>
              <div className={styles.partContent}>
                <h6 className={styles.partTitle}>Exploring beautiful places in the world</h6>
                <p className={styles.partText}>{about.aboutExploring}</p>
              </div>
            </div>
            <div className={styles.partMain}>
              <img
                className={classNames(styles.imageBottom, img2Loaded && styles.loaded)}
                onLoad={() => setImg2Loaded(true)}
                src={aboutImage2}
                alt="about_pic_2"
              />
            </div>
          </div>
        </div>
        <div className={styles.timelineSection}>
          <div className={styles.timelineHeader}>
            <div className={styles.timelineTitle}>Timeline</div>
          </div>
          <Timeline config={about.timeline} />
        </div>
      </div>
    </PageWrap>
  );
};

export default About;
