import ReactGa from 'react-ga';
import { get as g, isNumber, kebabCase } from 'lodash-es';

import { Env } from './';
import { UnsafeAny } from '@ts/General';

let delayCallback: ReturnType<typeof setTimeout> | null = null;

type Link = {
  page?: string;
  link?: string;
  name?: string;
};

const link = (data: string | Link) => {
  let url;

  if (typeof data === 'object') {
    url = data.page || data.link || data.name;
  } else {
    url = data;
  }

  url = `/${kebabCase(url)}`;

  if (Env.isProduction()) {
    // eslint-disable-next-line import/no-named-as-default-member
    ReactGa.pageview(url);
  }

  return url;
};

const sort = (data: Record<string, unknown>[], sortBy: string): UnsafeAny[] =>
  data.sort((a, b) => {
    const aVal = (sortBy ? g(a, `${sortBy}`) : a) as number;
    const bVal = (sortBy ? g(b, `${sortBy}`) : b) as number;

    if (aVal < bVal) return -1;
    if (aVal > bVal) return 1;

    return 0;
  });

const dateFormat = (date: string | Date) => {
  const m = new Date(date);

  return (
    m.getUTCFullYear() +
    '/' +
    ('0' + (m.getUTCMonth() + 1)).slice(-2) +
    '/' +
    ('0' + m.getUTCDate()).slice(-2) +
    ' ' +
    ('0' + m.getUTCHours()).slice(-2) +
    ':' +
    ('0' + m.getUTCMinutes()).slice(-2) +
    ':' +
    ('0' + m.getUTCSeconds()).slice(-2)
  );
};

const getYear = () => {
  const m = new Date();
  return m.getUTCFullYear();
};

const typingCallBack = (callback: () => void, delay = 300) => {
  if (delayCallback) {
    clearTimeout(delayCallback);
  }

  delayCallback = setTimeout(callback, delay);
};

const modalDelay = (callback: () => void, delay = 150) => setTimeout(callback, delay);

const openInNewTab = (url: string) => {
  const win = window.open(url, '_blank');

  if (win) {
    win.focus();
  }
};

const getMB = (number?: string | number, fixedTo = 2) => {
  if (!Number(number)) {
    return number;
  }

  return (Number(number) / 1000).toFixed(fixedTo);
};

const getQueryParam = (name: string, url: string) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const checkType = (param: string) => {
  if (isNumber(param)) {
    return Number(param);
  }

  switch (param) {
    case 'true':
      return true;

    case 'false':
      return false;

    case 'null':
      return null;

    case 'undefined':
      return undefined;

    default:
      return param;
  }
};

export default {
  getMB,
  getYear,
  link,
  dateFormat,
  openInNewTab,
  sort,
  modalDelay,
  typingCallBack,
  getQueryParam,
  checkType,
};
