import React, { useState, KeyboardEvent } from 'react';

import API from '@api/index';

import { Time, User } from '@functions';

import { translate } from '@localise/index';
import { endpoints } from '@api/endpoints';

import { F1Post } from '@ts/F1';

import { Identifier } from '@ts/General';

import './style.css';

type Props = {
  post: F1Post;
  onAdd: () => void;
};

const Comments = ({ post, onAdd }: Props) => {
  const [newComment, setNewComment] = useState('');
  const [adding, setAdding] = useState(false);

  const user = User.hasAccess();

  const addComment = async () => {
    if (!newComment.length || adding) return;

    setAdding(true);

    try {
      await API.post(endpoints.f1.posts.addComment(post._id as unknown as Identifier), {
        content: newComment,
        user,
      });

      setNewComment('');
      onAdd();
    } catch (err) {
      console.error(err);
    }

    setAdding(false);
  };

  const handleSubmit = (e: KeyboardEvent) => {
    if (e.charCode === 13 && newComment.length > 1) {
      addComment();
    }
  };

  return (
    <div className="news-post-comments">
      {post.comments &&
        post.comments.map((comment) => (
          <div className="news-post-comment" key={comment._id}>
            <div className="news-post-comment-header">
              <div className="news-post-comment-user">
                <div className="news-post-comment-user-name">{comment.user.username}</div>
              </div>
              <div className="news-post-comment-time">{Time.concrete(comment.createdAt)}</div>
            </div>
            <div className="news-post-comment-content">{comment.content}</div>
          </div>
        ))}
      <div className="news-post-add-new-comment">
        <label className="news-post-add-new-comment-label">
          <input
            placeholder={translate.f1.posts.addComment}
            className="news-post-add-new-comment-input"
            value={newComment}
            onKeyPress={handleSubmit}
            onChange={(e) => setNewComment(e.target.value)}
          />
        </label>
        <button
          onClick={() => addComment()}
          disabled={newComment.length < 2 || adding}
          className={`news-post-add-new-comment-button ${newComment ? 'active' : ''}`}
        >
          {translate.f1.posts.comment}
        </button>
      </div>
    </div>
  );
};

export default Comments;
