import React, { useState } from 'react';
import { ButtonBase, Icon, Box, Collapse } from '@mui/material';

import { styled } from '@mui/material/styles';

import classNames from 'classnames';

import './style.css';

const DarkCircleIconButtonStyled = styled(ButtonBase)({
  color: '#ccc',
  minWidth: '44px',
  minHeight: '44px',
  fontFamily: 'Open sans',
  borderRadius: 100,
  background: '#222',
  border: `2px solid #CCC`,
  transition: `.25s ease all`,
  alignSelf: 'center',
  margin: 10,
  ['&:hover']: {
    color: 'white',
  },
  ['&:active']: {},
  ['&:focus']: {},
  ['&:disabled']: {
    opacity: 0.4,
  },
  ['&.active']: {
    border: `2px solid rgba(203,0,255,0.76)`,
  },
  ['&.off']: {
    background: 'transparent',
    color: '#aaa',
  },
});

type Option = {
  label: string;
  onClick: () => void;
  icon?: JSX.Element;
  disabled?: boolean;
};

type Props = {
  onClick?: (e: MouseEvent) => void;
  icon?: string;
  children?: string;
  active?: boolean;
  disabled?: boolean;
  options?: Option[];
  off?: boolean;
};

const DarkCircleIconButton = ({ onClick, icon, active, disabled, options, off }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box display="flex">
      <DarkCircleIconButtonStyled
        className={classNames({ active, off })}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        onClick={options ? () => setIsExpanded(!isExpanded) : onClick ? onClick : null}
        disabled={disabled}
      >
        <Icon>{isExpanded ? 'arrow_back' : icon}</Icon>
      </DarkCircleIconButtonStyled>
      {options &&
        options.map((opt, index) => {
          return (
            <Collapse in={isExpanded} key={index} orientation="horizontal">
              <DarkCircleIconButtonStyled
                className={classNames({ active, off })}
                onClick={opt.onClick}
                disabled={opt.disabled}
              >
                {opt.icon && <Icon>{opt.icon}</Icon>}
                <div className="dark-circle-icon-button-options-label">{opt.label}</div>
              </DarkCircleIconButtonStyled>
            </Collapse>
          );
        })}
    </Box>
  );
};

export default DarkCircleIconButton;
