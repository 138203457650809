import React, { ReactNode } from 'react';
import classNames from 'classnames';

import Icon from '../SVG';

import styles from './style.scss';

type Props = {
  size?: string;
  icon?: string;
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
};

const Mark = ({ icon, size, className = '', onClick, children }: Props) => {
  return (
    <div className={classNames(styles.mark, className)} onClick={onClick && onClick}>
      {children}
      {icon && <Icon width={size} height={size} icon={icon} />}
    </div>
  );
};

export default Mark;
