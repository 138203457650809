import React from 'react';
import { withRouter } from 'react-router-dom';
import { toLower } from 'lodash-es';
import { History } from 'history';

import { User } from '@functions/index';
import { translate } from '@localise/index';

import logo from '../../components/f1-logo.svg';

import './style.css';

const VIEW_NEWS = 'VIEW_NEWS';
const VIEW_VOTING = 'VIEW_VOTING';
const VIEW_RESULTS = 'VIEW_RESULTS';
const VIEW_DRIVERS = 'VIEW_DRIVERS';
const VIEW_TEAMS = 'VIEW_TEAMS';
const VIEW_CIRCUITS = 'VIEW_CIRCUITS';
const VIEW_ADMIN = 'VIEW_ADMIN';

type Props = {
  history: History;
};

const Header = ({ history }: Props) => {
  const menu = [
    { id: VIEW_NEWS, label: translate.f1.menu.news, path: '/f1' },
    { id: VIEW_VOTING, label: translate.f1.menu.voting, path: '/f1/vote' },
    { id: VIEW_RESULTS, label: translate.f1.menu.results, path: '/f1/results' },
    { id: VIEW_DRIVERS, label: translate.f1.menu.drivers, path: '/f1/drivers' },
    { id: VIEW_TEAMS, label: translate.f1.menu.teams, path: '/f1/teams' },
    { id: VIEW_CIRCUITS, label: translate.f1.menu.circuits, path: '/f1/circuits' },
    {
      id: VIEW_ADMIN,
      label: translate.f1.menu.admin,
      path: '/f1/admin',
      hidden: !User.hasRight('f1:edit'),
    },
  ];

  return (
    <div className="f1-header">
      <div onClick={() => history.push(menu[0].path)} className="f1-header-logo-wrap">
        <img alt="f1-header" src={logo} className="f1-header-logo" />
      </div>
      <div className="f1-nav-bar">
        {menu.map((item) => {
          if (item.hidden) return;

          return (
            <button
              id={toLower(item.label)}
              key={item.id}
              className="f1-nav-link"
              onClick={() => history.push(item.path)}
            >
              {item.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default withRouter(Header);
