import React, { MouseEvent } from 'react';
import { ButtonBase } from '@mui/material';

import { styled } from '@mui/material/styles';

import './style.css';

const DarkRoundedButtonStyled = styled(ButtonBase)({
  color: '#ccc',
  fontFamily: 'Arial',
  fontSize: 14,
  borderRadius: 100,
  background: '#222',
  border: `2px solid #CCC`,
  boxShadow: `0 2px 20px 0 rgba(0,0,0,0.50)`,
  transition: `.25s ease all`,
  textTransform: 'uppercase',
  flexDirection: 'column',
  letterSpacing: 2,
  whiteSpace: 'initial',
  padding: '10px 25px',
  ['&:hover']: {
    color: 'white',
  },
  ['&:active']: {},
  ['&:focus']: {},
  ['&:disabled']: {
    opacity: 0.4,
  },
  ['&.active']: {
    border: `3px solid rgba(203,0,255,0.76)`,
  },
});

type Props = {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  label?: string;
  subLabel?: string;
  highlight?: string;
  children?: string;
  active?: boolean;
  disabled?: boolean;
};

const DarkRoundedButton = ({ onClick, label, subLabel, highlight, active, disabled }: Props) => {
  return (
    <DarkRoundedButtonStyled
      className={active ? 'active' : ''}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      onClick={onClick ? onClick : null}
      disabled={disabled}
    >
      {highlight && <div className="dark-circle-button-highlight">{highlight}</div>}
      <div>{label}</div>
      {subLabel && <div className="dark-circle-button-sublabel">{subLabel}</div>}
    </DarkRoundedButtonStyled>
  );
};

export default DarkRoundedButton;
