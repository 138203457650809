import React from 'react';

import './style.css';

type Props = {
  percentage: number;
  done?: boolean;
  className?: string;
};

const Circle = ({ percentage = 0, done = false, className }: Props) => {
  const style = { strokeDashoffset: 1680 - (1680 / 100) * percentage };

  return (
    <svg className="progress-circle" height="540" width="540">
      <circle className="progress-circle-base" r="266" cx="270" cy="270" />
      <circle
        style={style}
        className={`progress-circle-fill ${done ? 'active' : ''} ${className || ''}`}
        r="266"
        cx="270"
        cy="270"
      />
    </svg>
  );
};

export default Circle;
