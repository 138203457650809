import React, { useState } from 'react';
import PageWrap from '../../../utils/PageWrap';

import config from '../../../../assets/wiki';

import Item from './Item';

import './style.scss';

const Wiki = () => {
  const [selectedItem, setSelectedItem] = useState(config.items[0].id);

  const menu = config.items.map((item, index) => (
    <div className="wiki-menu-item" key={index} onClick={() => setSelectedItem(item.id)}>
      {item.name}
    </div>
  ));

  const item = config.items.find((i) => i.id === selectedItem);

  return (
    <PageWrap>
      <div className="wiki-wrap">
        <div className="wiki-header">
          <div className="wiki-header-title">Knowledge Base</div>
        </div>
        <div className="wiki-container">
          <div className="wiki-menu">{menu}</div>
          <div className="wiki-content">
            {item ? <Item data={item} showExtras /> : 'No config found'}
          </div>
        </div>
      </div>
    </PageWrap>
  );
};

export default Wiki;
