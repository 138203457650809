import React from 'react';

import { circuitImage } from '@pages/F1/pages/Voting';

import { F1Circuit } from '@ts/F1';

import './style.css';

type Props = {
  data: F1Circuit;
  onClick?: (data: F1Circuit) => void;
  hidden?: boolean;
  simple?: boolean;
};

const Circuit = ({ data, onClick, hidden, simple }: Props) => {
  if (!data) return null;

  if (simple) {
    return (
      <div className="f1-circuit simple" onClick={() => onClick && onClick(data)}>
        <div className="f1-circuit-base">
          <div className="f1-circuit-info">
            <div className="f1-circuit-name">{data.name}</div>
            <div className="f1-circuit-city">{data.competition.location.city}</div>
          </div>
          <div className="f1-circuit-image-wrap">
            <img src={circuitImage(data.id)} className="f1-circuit-image" alt="circuit" />
          </div>
          <div className="f1-circuit-info">
            <div className="f1-circuit-country">{data.competition.location.country}</div>
          </div>
          <div className="f1-circuit-back" />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`f1-circuit ${hidden ? 'hidden' : ''}`}
      onClick={() => onClick && onClick(data)}
    >
      <div className="f1-circuit-base">
        <div className="f1-circuit-info">
          <div className="f1-circuit-name">{data.name}</div>
          <div className="f1-circuit-competition">{data.competition.name}</div>
        </div>
        <div className="f1-circuit-info">
          <div className="f1-circuit-city">{data.competition.location.city}</div>
          <div className="f1-circuit-country">{data.competition.location.country}</div>
        </div>
        <div className="f1-circuit-extra-info">
          <div className="f1-circuit-length">{data.length}</div>
          {data.opened && <div className="f1-circuit-opened">From {data.opened}</div>}
        </div>
      </div>
      <div className="f1-circuit-image-wrap">
        <img src={circuitImage(data.id)} className="f1-circuit-image" alt="circuit" />
      </div>
      <div className="f1-circuit-back" />
    </div>
  );
};

export default Circuit;
