import React from 'react';

import './style.css';

type Props = {
  color?: string;
  size?: string | number;
  noWrap?: boolean;
};

const Loading = ({ size = 30, color = '#444', noWrap }: Props) => {
  const style = {
    width: size,
    height: size,
    borderLeftColor: color,
    borderRightColor: color,
    marginTop: -size / 2 + 'px',
    marginLeft: -size / 2 + 'px',
  };

  return (
    <div
      className={`loading-wrap ${noWrap ? 'no-wrap' : ''}`}
      style={noWrap ? { width: size, height: size, padding: Number(size) / 3 + 'px' } : {}}
    >
      <div style={style} className="loading-status" />
    </div>
  );
};

export default Loading;
