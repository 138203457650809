export const type = {
  landscape: 'landscape',
  portrait: 'portrait',
};

export const flags = [
  'nature',
  'people',
  'dreams',
  'cities',
  'subway',
  'dark',
  'girls',
  'drone',
  'car',
];

export default [
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-455.jpg',
    id: 455,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-454.jpg',
    id: 454,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-453.jpg',
    id: 453,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-452.jpg',
    id: 452,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-451.jpg',
    id: 451,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-450.jpg',
    id: 0,
    type: 'landscape',
    people: true,
    girls: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-448.jpg',
    id: 449,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-447.jpg',
    id: 448,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
    dark: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-446.jpg',
    id: 447,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-445.jpg',
    id: 446,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-444.jpg',
    id: 445,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-443.jpg',
    id: 444,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-442.jpg',
    id: 443,
    type: 'landscape',
    dreams: true,
    girls: true,
    people: true,
    nature: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-441.jpg',
    id: 442,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-440.jpg',
    id: 441,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-439.jpg',
    id: 440,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-438.jpg',
    id: 439,
    type: 'portrait',
    dark: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-437.jpg',
    id: 438,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-436.jpg',
    id: 437,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-435.jpg',
    id: 436,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-434.jpg',
    id: 435,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-433.jpg',
    id: 434,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-432.jpg',
    id: 433,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-431.jpg',
    id: 432,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-430.jpg',
    id: 431,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-429.jpg',
    id: 430,
    type: 'landscape',
    dark: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-428.jpg',
    id: 429,
    type: 'portrait',
    girls: true,
    people: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-427.jpg',
    id: 428,
    type: 'landscape',
    dark: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-426.jpg',
    id: 427,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-425.jpg',
    id: 426,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-424.jpg',
    id: 425,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-423.jpg',
    id: 424,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-422.jpg',
    id: 423,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-421.jpg',
    id: 422,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-420.jpg',
    id: 421,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
    dark: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-419.jpg',
    id: 420,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-418.jpg',
    id: 419,
    type: 'portrait',
    nature: true,
    dreams: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-417.jpg',
    id: 418,
    type: 'portrait',
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-416.jpg',
    id: 417,
    type: 'portrait',
    girls: true,
    people: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-415.jpg',
    id: 416,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-414.jpg',
    id: 415,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-413.jpg',
    id: 414,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-412.jpg',
    id: 413,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-411.jpg',
    id: 412,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-410.jpg',
    id: 411,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-409.jpg',
    id: 410,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-408.jpg',
    id: 409,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-407.jpg',
    id: 408,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-406.jpg',
    id: 407,
    type: 'landscape',
    car: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-405.jpg',
    id: 406,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-404.jpg',
    id: 405,
    type: 'portrait',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-403.jpg',
    id: 404,
    type: 'portrait',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-402.jpg',
    id: 403,
    type: 'portrait',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-401.jpg',
    id: 402,
    type: 'portrait',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-400.jpg',
    id: 401,
    type: 'landscape',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-399.jpg',
    id: 400,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-398.jpg',
    id: 399,
    type: 'portrait',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-397.jpg',
    id: 398,
    type: 'portrait',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-396.jpg',
    id: 397,
    type: 'portrait',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-395.jpg',
    id: 396,
    type: 'landscape',
    nature: true,
    dreams: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-394.jpg',
    id: 395,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-393.jpg',
    id: 394,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-392.jpg',
    id: 393,
    type: 'portrait',
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-391.jpg',
    id: 392,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-390.jpg',
    id: 391,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-389.jpg',
    id: 390,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-388.jpg',
    id: 389,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-387.jpg',
    id: 388,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-386.jpg',
    id: 387,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-385.jpg',
    id: 386,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-384.jpg',
    id: 385,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-383.jpg',
    id: 384,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-382.jpg',
    id: 383,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-381.jpg',
    id: 382,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-380.jpg',
    id: 381,
    type: 'portrait',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-379.jpg',
    id: 380,
    type: 'landscape',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-378.jpg',
    id: 379,
    type: 'portrait',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-377.jpg',
    id: 378,
    type: 'landscape',
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-376.jpg',
    id: 377,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-375.jpg',
    id: 376,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-374.jpg',
    id: 375,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-373.jpg',
    id: 374,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-372.jpg',
    id: 373,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-371.jpg',
    id: 372,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-370.jpg',
    id: 371,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-369.jpg',
    id: 370,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-368.jpg',
    id: 369,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-367.jpg',
    id: 368,
    type: 'portrait',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-366.jpg',
    id: 367,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-365.jpg',
    id: 366,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-364.jpg',
    id: 365,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-363.jpg',
    id: 364,
    type: 'portrait',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-362.jpg',
    id: 363,
    type: 'portrait',
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-361.jpg',
    id: 362,
    type: 'landscape',
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-360.jpg',
    id: 361,
    type: 'portrait',
    car: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-359.jpg',
    id: 360,
    type: 'landscape',
    dreams: true,
    nature: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-358.jpg',
    id: 359,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-357.jpg',
    id: 358,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-356.jpg',
    id: 357,
    type: 'landscape',
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-355.jpg',
    id: 356,
    type: 'portrait',
    dark: true,
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-354.jpg',
    id: 355,
    type: 'landscape',
    nature: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-353.jpg',
    id: 354,
    type: 'landscape',
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-352.jpg',
    id: 353,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-351.jpg',
    id: 352,
    type: 'landscape',
    drone: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-350.jpg',
    id: 351,
    type: 'portrait',
    nature: true,
    people: true,
    girls: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-349.jpg',
    id: 350,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-348.jpg',
    id: 349,
    type: 'landscape',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-347.jpg',
    id: 348,
    type: 'landscape',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-346.jpg',
    id: 347,
    type: 'landscape',
    nature: true,
    drone: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-345.jpg',
    id: 346,
    type: 'portrait',
    drone: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-344.jpg',
    id: 345,
    type: 'landscape',
    car: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-343.jpg',
    id: 344,
    type: 'landscape',
    nature: true,
    girls: true,
    people: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-342.jpg',
    id: 343,
    type: 'landscape',
    dreams: true,
    dark: true,
    nature: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-341.jpg',
    id: 342,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-340.jpg',
    id: 341,
    type: 'portrait',
    nature: true,
    dreams: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-339.jpg',
    id: 340,
    type: 'portrait',
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-338.jpg',
    id: 339,
    type: 'landscape',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-337.jpg',
    id: 338,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-336.jpg',
    id: 337,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-335.jpg',
    id: 336,
    type: 'portrait',
    people: true,
    nature: true,
    girls: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-334.jpg',
    id: 335,
    type: 'landscape',
    nature: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-333.jpg',
    id: 334,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-332.jpg',
    id: 333,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-331.jpg',
    id: 332,
    type: 'landscape',
    dreams: true,
    nature: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-330.jpg',
    id: 331,
    type: 'portrait',
    dark: true,
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-329.jpg',
    id: 330,
    type: 'portrait',
    dreams: true,
    nature: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-328.jpg',
    id: 329,
    type: 'landscape',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-327.jpg',
    id: 328,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-326.jpg',
    id: 327,
    type: 'portrait',
    girls: true,
    people: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-325.jpg',
    id: 326,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-324.jpg',
    id: 325,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-323.jpg',
    id: 324,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-322.jpg',
    id: 323,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-321.jpg',
    id: 322,
    type: 'landscape',
    girls: true,
    people: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-320.jpg',
    id: 321,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-319.jpg',
    id: 320,
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-318.jpg',
    id: 319,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-317.jpg',
    id: 318,
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-316.jpg',
    id: 317,
    type: 'portrait',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-315.jpg',
    id: 316,
    type: 'portrait',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-314.jpg',
    id: 315,
    type: 'portrait',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-313.jpg',
    id: 314,
    type: 'portrait',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-312.jpg',
    id: 313,
    type: 'portrait',
    cities: true,
    dark: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-311.jpg',
    id: 312,
    type: 'portrait',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-310.jpg',
    id: 311,
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-309.jpg',
    id: 310,
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-308.jpg',
    id: 309,
    type: 'landscape',
    cities: true,
    girls: true,
    people: true,
    dreams: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-307.jpg',
    id: 308,
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
    dreams: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-306.jpg',
    id: 307,
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
    dreams: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-305.jpg',
    id: 306,
    type: 'landscape',
    cities: true,
    dark: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-304.jpg',
    id: 305,
    type: 'portrait',
    cities: true,
    dark: true,
    girls: true,
    people: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-303.jpg',
    id: 304,
    type: 'portrait',
    cities: true,
    dark: true,
    girls: true,
    people: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-302.jpg',
    id: 303,
    type: 'portrait',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-301.jpg',
    id: 302,
    type: 'portrait',
    dark: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-300.jpg',
    id: 301,
    type: 'portrait',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-299.jpg',
    id: 300,
    type: 'portrait',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-298.jpg',
    id: 299,
    type: 'portrait',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-297.jpg',
    id: 298,
    type: 'portrait',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-296.jpg',
    id: 297,
    type: 'portrait',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-295.jpg',
    id: 296,
    type: 'portrait',
    dark: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-294.jpg',
    id: 295,
    type: 'landscape',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-293.jpg',
    id: 294,
    type: 'portrait',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-292.jpg',
    id: 293,
    type: 'portrait',
    dark: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-291.jpg',
    id: 292,
    type: 'portrait',
    cities: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-290.jpg',
    id: 291,
    type: 'portrait',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-289.jpg',
    id: 290,
    type: 'portrait',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-288.jpg',
    id: 289,
    type: 'landscape',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-287.jpg',
    id: 288,
    type: 'portrait',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-286.jpg',
    id: 287,
    type: 'portrait',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-285.jpg',
    id: 286,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-284.jpg',
    id: 285,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-283.jpg',
    id: 284,
    type: 'portrait',
    cities: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-282.jpg',
    id: 283,
    type: 'portrait',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-281.jpg',
    id: 282,
    type: 'portrait',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-280.jpg',
    id: 281,
    type: 'portrait',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-279.jpg',
    id: 280,
    type: 'landscape',
    cities: true,
    dark: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-278.jpg',
    id: 279,
    type: 'landscape',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-277.jpg',
    id: 278,
    type: 'portrait',
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-276.jpg',
    id: 277,
    type: 'landscape',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-275.jpg',
    id: 276,
    type: 'portrait',
    nature: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-274.jpg',
    id: 275,
    type: 'portrait',
    car: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-273.jpg',
    id: 274,
    type: 'landscape',
    car: true,
    dark: true,
  } /* {
  'src': 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-272.jpg',
  'id': 273,
  'type': 'landscape',
  'car': true,
}*/,
  ,
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-271.jpg',
    id: 272,
    type: 'portrait',
    car: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-270.jpg',
    id: 271,
    type: 'portrait',
    car: true,
    dark: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-269.jpg',
    id: 270,
    type: 'landscape',
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-268.jpg',
    id: 269,
    type: 'landscape',
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-267.jpg',
    id: 268,
    type: 'landscape',
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-266.jpg',
    id: 267,
    type: 'landscape',
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-265.jpg',
    id: 266,
    type: 'landscape',
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-264.jpg',
    id: 265,
    type: 'portrait',
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-263.jpg',
    id: 264,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-262.jpg',
    id: 263,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-261.jpg',
    id: 262,
    type: 'landscape',
    girls: true,
    people: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-260.jpg',
    id: 261,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-259.jpg',
    id: 260,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-258.jpg',
    id: 259,
    type: 'landscape',
    girls: true,
    people: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-257.jpg',
    id: 258,
    type: 'portrait',
    girls: true,
    people: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-256.jpg',
    id: 257,
    type: 'portrait',
    car: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-255.jpg',
    id: 256,
    type: 'landscape',
    car: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-254.jpg',
    id: 255,
    type: 'portrait',
    car: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-253.jpg',
    id: 254,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-252.jpg',
    id: 253,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-251.jpg',
    id: 252,
    type: 'landscape',
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-250.jpg',
    id: 251,
    type: 'landscape',
    girls: true,
    people: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-249.jpg',
    id: 250,
    type: 'landscape',
    girls: true,
    people: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-248.jpg',
    id: 249,
    type: 'portrait',
    dark: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-247.jpg',
    id: 248,
    type: 'landscape',
    car: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-246.jpg',
    id: 247,
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-245.jpg',
    id: 246,
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-244.jpg',
    id: 245,
    type: 'portrait',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-243.jpg',
    id: 244,
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-242.jpg',
    id: 243,
    type: 'portrait',
    cities: true,
    girls: true,
    people: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-241.jpg',
    id: 242,
    type: 'landscape',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-240.jpg',
    id: 241,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-239.jpg',
    id: 240,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-238.jpg',
    id: 239,
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-237.jpg',
    id: 238,
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-236.jpg',
    id: 237,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-235.jpg',
    id: 236,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-234.jpg',
    id: 235,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-233.jpg',
    id: 234,
    type: 'portrait',
    people: true,
    subway: true,
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-232.jpg',
    id: 233,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-231.jpg',
    id: 232,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-230.jpg',
    id: 231,
    type: 'portrait',
    dark: true,
    cities: true,
    subway: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-229.jpg',
    id: 230,
    type: 'portrait',
    drone: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-228.jpg',
    id: 229,
    type: 'landscape',
    drone: true,
    dreams: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-227.jpg',
    id: 228,
    type: 'portrait',
    drone: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-226.jpg',
    id: 227,
    type: 'portrait',
    drone: true,
    dreams: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-225.jpg',
    id: 226,
    type: 'portrait',
    drone: true,
    dreams: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-224.jpg',
    id: 225,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-223.jpg',
    id: 224,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-222.jpg',
    id: 223,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-221.jpg',
    id: 222,
    type: 'landscape',
    nature: true,
    car: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-220.jpg',
    id: 221,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-219.jpg',
    id: 220,
    type: 'landscape',
    subway: true,
    cities: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-218.jpg',
    id: 219,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-217.jpg',
    id: 218,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-216.jpg',
    id: 217,
    type: 'landscape',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-215.jpg',
    id: 216,
    type: 'landscape',
    drone: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-214.jpg',
    id: 215,
    type: 'landscape',
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-213.jpg',
    id: 214,
    type: 'landscape',
    drone: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-212.jpg',
    id: 213,
    type: 'landscape',
    drone: true,
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-211.jpg',
    id: 212,
    type: 'landscape',
    drone: true,
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-210.jpg',
    id: 211,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-209.jpg',
    id: 210,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-208.jpg',
    id: 209,
    type: 'landscape',
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-207.jpg',
    id: 208,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-206.jpg',
    id: 207,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-205.jpg',
    id: 206,
    type: 'landscape',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-204.jpg',
    id: 205,
    type: 'landscape',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-203.jpg',
    id: 204,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-202.jpg',
    id: 203,
    type: 'landscape',
    nature: true,
    drone: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-201.jpg',
    id: 202,
    type: 'landscape',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-200.jpg',
    id: 201,
    type: 'landscape',
    drone: true,
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-199.jpg',
    id: 200,
    type: 'landscape',
    nature: true,
    drone: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-198.jpg',
    id: 199,
    type: 'landscape',
    drone: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-197.jpg',
    id: 198,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-196.jpg',
    id: 197,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-195.jpg',
    id: 196,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-194.jpg',
    id: 195,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-193.jpg',
    id: 194,
    type: 'landscape',
    nature: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-192.jpg',
    id: 193,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-191.jpg',
    id: 192,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-190.jpg',
    id: 191,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-189.jpg',
    id: 190,
    type: 'portrait',
    nature: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-188.jpg',
    id: 189,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-187.jpg',
    id: 188,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-186.jpg',
    id: 187,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-185.jpg',
    id: 186,
    type: 'portrait',
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-184.jpg',
    id: 185,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-183.jpg',
    id: 184,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-182.jpg',
    id: 183,
    type: 'landscape',
    nature: true,
    dreams: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-181.jpg',
    id: 182,
    type: 'landscape',
    nature: true,
    dreams: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-180.jpg',
    id: 181,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-179.jpg',
    id: 180,
    type: 'landscape',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-178.jpg',
    id: 179,
    type: 'portrait',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-177.jpg',
    id: 178,
    type: 'portrait',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-176.jpg',
    id: 177,
    type: 'landscape',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-175.jpg',
    id: 176,
    type: 'portrait',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-174.jpg',
    id: 175,
    type: 'landscape',
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-173.jpg',
    id: 174,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-172.jpg',
    id: 173,
    type: 'landscape',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-171.jpg',
    id: 172,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-170.jpg',
    id: 171,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-169.jpg',
    id: 170,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-168.jpg',
    id: 169,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-167.jpg',
    id: 168,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-166.jpg',
    id: 167,
    type: 'landscape',
    nature: true,
    dark: true,
    dreams: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-165.jpg',
    id: 166,
    type: 'landscape',
    dark: true,
    dreams: true,
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-164.jpg',
    id: 165,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-163.jpg',
    id: 164,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-162.jpg',
    id: 163,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-161.jpg',
    id: 162,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-160.jpg',
    id: 161,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-159.jpg',
    id: 160,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-158.jpg',
    id: 159,
    type: 'portrait',
    nature: true,
    people: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-157.jpg',
    id: 158,
    type: 'portrait',
    nature: true,
    dark: true,
    people: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-156.jpg',
    id: 157,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-155.jpg',
    id: 156,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-154.jpg',
    id: 155,
    type: 'landscape',
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-153.jpg',
    id: 154,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-152.jpg',
    id: 153,
    type: 'portrait',
    nature: true,
    dark: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-151.jpg',
    id: 152,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-150.jpg',
    id: 151,
    type: 'portrait',
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-149.jpg',
    id: 150,
    type: 'landscape',
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-148.jpg',
    id: 149,
    type: 'portrait',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-147.jpg',
    id: 148,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-146.jpg',
    id: 147,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-145.jpg',
    id: 146,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-144.jpg',
    id: 145,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-143.jpg',
    id: 144,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-142.jpg',
    id: 143,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-141.jpg',
    id: 142,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-140.jpg',
    id: 141,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-139.jpg',
    id: 140,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-138.jpg',
    id: 139,
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-137.jpg',
    id: 138,
    type: 'landscape',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-136.jpg',
    id: 137,
    type: 'portrait',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-135.jpg',
    id: 136,
    type: 'landscape',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-134.jpg',
    id: 135,
    type: 'portrait',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-133.jpg',
    id: 134,
    type: 'landscape',
    cities: true,
    dark: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-132.jpg',
    id: 133,
    type: 'landscape',
    cities: true,
    dark: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-131.jpg',
    id: 132,
    type: 'portrait',
    dark: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-130.jpg',
    id: 131,
    type: 'landscape',
    cities: true,
    dark: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-129.jpg',
    id: 130,
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-128.jpg',
    id: 129,
    type: 'landscape',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-127.jpg',
    id: 128,
    type: 'landscape',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-126.jpg',
    id: 127,
    type: 'landscape',
    dark: true,
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-125.jpg',
    id: 126,
    type: 'portrait',
    cities: true,
    girls: true,
    people: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-124.jpg',
    id: 125,
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
    dark: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-123.jpg',
    id: 124,
    type: 'landscape',
    cities: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-122.jpg',
    id: 123,
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-121.jpg',
    id: 122,
    type: 'portrait',
    nature: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-120.jpg',
    id: 121,
    type: 'landscape',
    girls: true,
    people: true,
    subway: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-119.jpg',
    id: 120,
    type: 'portrait',
    girls: true,
    people: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-118.jpg',
    id: 119,
    type: 'landscape',
    subway: true,
    cities: true,
    dark: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-117.jpg',
    id: 118,
    type: 'landscape',
    cities: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-116.jpg',
    id: 117,
    type: 'landscape',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-115.jpg',
    id: 116,
    type: 'landscape',
    nature: true,
    drone: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-114.jpg',
    id: 115,
    type: 'landscape',
    nature: true,
    drone: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-113.jpg',
    id: 114,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-112.jpg',
    id: 113,
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-111.jpg',
    id: 112,
    type: 'landscape',
    drone: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-110.jpg',
    id: 111,
    type: 'landscape',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-109.jpg',
    id: 110,
    type: 'landscape',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-108.jpg',
    id: 109,
    type: 'landscape',
    drone: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-107.jpg',
    id: 108,
    type: 'landscape',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-106.jpg',
    id: 107,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-105.jpg',
    id: 106,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-104.jpg',
    id: 105,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-103.jpg',
    id: 104,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-102.jpg',
    id: 103,
    type: 'landscape',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-101.jpg',
    id: 102,
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-100.jpg',
    id: 101,
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-099.jpg',
    id: '0991',
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
    dreams: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-098.jpg',
    id: '0981',
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-097.jpg',
    id: '0971',
    type: 'landscape',
    dark: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-096.jpg',
    id: '0961',
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-095.jpg',
    id: '0951',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-094.jpg',
    id: '0941',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-093.jpg',
    id: '0931',
    type: 'landscape',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-092.jpg',
    id: '0921',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-091.jpg',
    id: '0911',
    type: 'portrait',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-090.jpg',
    id: '0901',
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-089.jpg',
    id: '0891',
    type: 'landscape',
    cities: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-088.jpg',
    id: '0881',
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-087.jpg',
    id: '0871',
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-086.jpg',
    id: '0861',
    type: 'landscape',
    girls: true,
    people: true,
    subway: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-085.jpg',
    id: '0851',
    type: 'landscape',
    girls: true,
    people: true,
    subway: true,
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-084.jpg',
    id: '0841',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-083.jpg',
    id: '0831',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-082.jpg',
    id: '0821',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-081.jpg',
    id: '0811',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-080.jpg',
    id: '0801',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-079.jpg',
    id: '0791',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-078.jpg',
    id: '0781',
    type: 'landscape',
    cities: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-077.jpg',
    id: '0771',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-076.jpg',
    id: '0761',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-075.jpg',
    id: '0751',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-074.jpg',
    id: '0741',
    type: 'landscape',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-073.jpg',
    id: '0731',
    type: 'landscape',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-072.jpg',
    id: '0721',
    type: 'landscape',
    subway: true,
    cities: true,
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-071.jpg',
    id: '0711',
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
    subway: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-070.jpg',
    id: '0701',
    type: 'landscape',
    girls: true,
    people: true,
    cities: true,
    subway: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-069.jpg',
    id: '0691',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-068.jpg',
    id: '0681',
    type: 'landscape',
    cities: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-067.jpg',
    id: '0671',
    type: 'landscape',
    nature: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-066.jpg',
    id: '0661',
    type: 'landscape',
    people: true,
    girls: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-065.jpg',
    id: '0651',
    type: 'landscape',
    girls: true,
    people: true,
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-064.jpg',
    id: '0641',
    type: 'landscape',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-063.jpg',
    id: '0631',
    type: 'portrait',
    girls: true,
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-062.jpg',
    id: '0621',
    type: 'landscape',
    nature: true,
    people: true,
    girls: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-061.jpg',
    id: '0611',
    type: 'landscape',
    nature: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-060.jpg',
    id: '0601',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-059.jpg',
    id: '0591',
    type: 'landscape',
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-058.jpg',
    id: '0581',
    type: 'landscape',
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-057.jpg',
    id: '0571',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-056.jpg',
    id: '0561',
    type: 'landscape',
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-055.jpg',
    id: '0551',
    type: 'landscape',
    nature: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-054.jpg',
    id: '0541',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-053.jpg',
    id: '0531',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-052.jpg',
    id: '0521',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-051.jpg',
    id: '0511',
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-050.jpg',
    id: '0501',
    type: 'portrait',
    nature: true,
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-049.jpg',
    id: '0491',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-048.jpg',
    id: '0481',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-047.jpg',
    id: '0471',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-046.jpg',
    id: '0461',
    type: 'landscape',
    nature: true,
    car: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-045.jpg',
    id: '0451',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-044.jpg',
    id: '0441',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-043.jpg',
    id: '0431',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-042.jpg',
    id: '0421',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-041.jpg',
    id: '0411',
    type: 'landscape',
    dark: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-040.jpg',
    id: '0401',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-039.jpg',
    id: '0391',
    type: 'portrait',
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-038.jpg',
    id: '0381',
    type: 'portrait',
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-037.jpg',
    id: '0371',
    type: 'landscape',
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-036.jpg',
    id: '0361',
    type: 'landscape',
    people: true,
    girls: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-035.jpg',
    id: '0351',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-034.jpg',
    id: '0341',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-033.jpg',
    id: '0331',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-032.jpg',
    id: '0321',
    type: 'landscape',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-031.jpg',
    id: '0311',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-030.jpg',
    id: '0301',
    type: 'landscape',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-029.jpg',
    id: '0291',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-028.jpg',
    id: '0281',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-027.jpg',
    id: '0271',
    type: 'landscape',
    nature: true,
    dreams: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-026.jpg',
    id: '0261',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-025.jpg',
    id: '0251',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-024.jpg',
    id: '0241',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-023.jpg',
    id: '0231',
    type: 'portrait',
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-022.jpg',
    id: '0221',
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-021.jpg',
    id: '0211',
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-020.jpg',
    id: '0201',
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-019.jpg',
    id: '0191',
    type: 'portrait',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-018.jpg',
    id: '0181',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-017.jpg',
    id: '0171',
    type: 'landscape',
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-016.jpg',
    id: '0161',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-015.jpg',
    id: '0151',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-014.jpg',
    id: '0141',
    type: 'landscape',
    people: true,
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-013.jpg',
    id: '0131',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-012.jpg',
    id: '0121',
    type: 'landscape',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-011.jpg',
    id: '0111',
    type: 'portrait',
    girls: true,
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-010.jpg',
    id: '0101',
    type: 'landscape',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-009.jpg',
    id: '0091',
    type: 'landscape',
    people: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-008.jpg',
    id: '0081',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-007.jpg',
    id: '0071',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-006.jpg',
    id: '0061',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-005.jpg',
    id: '0051',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-004.jpg',
    id: '0041',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-003.jpg',
    id: '0031',
    type: 'landscape',
    cities: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-002.jpg',
    id: '0021',
    type: 'landscape',
    nature: true,
  },
  {
    src: 'https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-001.jpg',
    id: '0011',
    type: 'landscape',
    nature: true,
  },
];
