import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { History } from 'history';

import Circle from '@common/LoadingCircle';

import './style.css';

type Props = {
  history: History;
};

const NotFound = ({ history }: Props) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const redirect = setTimeout(() => history.push('/'), 2850);

    setTimeout(() => setPercentage(100), 30);

    return () => {
      clearTimeout(redirect);
    };
  });

  return (
    <div className="not-found">
      <div className="not-found-holder">
        <Circle percentage={percentage} className="not-found-circle" />
        <div className="not-found-text-404">404</div>
        <div className="not-found-text">THE PAGE WAS NOT FOUND</div>
      </div>
    </div>
  );
};

export default withRouter(NotFound);
