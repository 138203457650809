import React, { useContext, useState } from 'react';
import Counter from '@common/Counter';
import DarkRoundedButton from '@common/DarkRoundedButton';
import { DrinksContext } from '@context/drinksData';
import { Time } from '@functions';
import { Add, Check, Close } from '@mui/icons-material';
import { Box, ButtonBase, Collapse, Icon, IconButton, Menu, MenuItem } from '@mui/material';
import { StyledTextField } from '@pages/Drinks/pages/Types';
import { Drink, DrinkRequest, DrinkSession, DrinkType } from '@ts/Drink';
import { User } from '@ts/User';
import { addMinutes } from 'date-fns';
import { groupBy, maxBy, uniq } from 'lodash-es';

import styles from './style.scss';

type Props = {
  session: DrinkSession;
  player?: User;
  onUpdate: (drinks: Drink[]) => void;
};

const MENU_DRINK_TYPE = 'menuDrinkType';
const MENU_DRINK = 'menuDrink';
const MENU_DRINK_UPDATE = 'menuDrinkUpdate';

const SessionDrinks = ({ session, player, onUpdate }: Props): JSX.Element | null => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, dataLoaded] = useContext(DrinksContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [menuType, setMenuType] = useState<string | null>(null);

  const [updatingDrink, setUpdatingDrink] = useState<Drink | null>(null);
  const [timeEditExpanded, setTimeEditExpanded] = useState(false);

  if (!player) return null;

  const renderCounter = ({
    player,
    drinkType,
    count,
  }: {
    player: User;
    drinkType: DrinkType;
    count: number;
  }) => {
    const onRemoveDrink = () => {
      const drinks = [...(session.drinks as Drink[])];
      const index = drinks.findIndex(
        (d) => d.type._id === drinkType._id && player._id === d.player,
      );

      drinks.splice(index, 1);

      onUpdate(drinks);
    };

    const onAddDrink = () => {
      const drinks = [...(session.drinks as Drink[])];
      onUpdate([...drinks, { type: drinkType, player: player._id } as Drink]);
    };

    return (
      <div className={styles.item} key={drinkType.id}>
        <div className={styles.itemBody}>
          <div>
            <div className={styles.itemName}>{drinkType.name}</div>
          </div>
        </div>
        <Counter count={count} onAdd={onAddDrink} onRemove={onRemoveDrink} />
      </div>
    );
  };

  const renderDrink = ({ drink, index }: { drink: Drink; index: number }) => {
    return (
      <div className={styles.itemWrap} key={drink._id || index}>
        <div className={styles.item}>
          <ButtonBase
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setMenuType(MENU_DRINK_UPDATE);
              setUpdatingDrink(drink);
              setTimeEditExpanded(false);
            }}
            classes={{ root: styles.buttonEdit }}
          >
            <div className={styles.itemBody}>
              <div className={styles.itemHelper}>{index + 1}.</div>
              <div>
                <div className={styles.itemName}>{drink.type.name}</div>
                <div className={styles.itemTime}>{Time.formatDate(drink.createdAt, 'time')}</div>
              </div>
            </div>
          </ButtonBase>
          <div className={styles.itemActions}>
            <IconButton
              onClick={() =>
                session.drinks && onUpdate(session.drinks.filter((d) => d._id !== drink._id))
              }
            >
              <Close />
            </IconButton>
          </div>
        </div>
        {updatingDrink?._id === drink._id && (
          <Collapse in={timeEditExpanded}>
            <div className={styles.textFieldDate}>
              <StyledTextField
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                fullWidth
                type="datetime-local"
                onChange={(e) => {
                  updateDrink(undefined, e.target.value);
                }}
                value={
                  drink.createdAt && drink.createdAt.length > 16
                    ? new Date(drink.createdAt as string).toISOString().slice(0, 16)
                    : undefined
                }
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                label="Drink start time"
                autoComplete="off"
              />
            </div>
          </Collapse>
        )}
      </div>
    );
  };

  const renderPlayerDrinks = () => {
    if (player && session.drinks) {
      const sessionDrinkTypes = uniq(session?.drinks.map((d) => d.type._id));
      const playerDrinks = session.drinks.filter((d) => d.player === player._id);

      if (!session.isLive) {
        const groupedDrinks = groupBy(playerDrinks, 'type._id');

        return sessionDrinkTypes.map((g) => {
          const drinkType = data.drinkTypes.find((t: DrinkType) => t._id === g);

          return renderCounter({
            player: player,
            drinkType,
            count: groupedDrinks[g]?.length || 0,
          });
        });
      } else {
        return playerDrinks.map((drink, index) => renderDrink({ drink, index }));
      }

      return player?.username;
    }

    return null;
  };

  const updateDrink = (type?: DrinkType, createdAt?: string) => {
    if (updatingDrink) {
      const newDrinks: Drink[] = [...session.drinks];

      const index = newDrinks.findIndex((d) => d._id === updatingDrink._id);

      newDrinks[index] = {
        ...newDrinks[index],
        type: type || newDrinks[index].type,
        createdAt: createdAt || newDrinks[index].createdAt,
      };

      onUpdate(newDrinks);
      setAnchorEl(null);
    }
  };

  const addDrink = (type: DrinkType) => {
    const drinks: DrinkRequest[] = [];

    session?.players?.forEach((p) =>
      drinks.push({
        type: type,
        player: p._id,
      }),
    );

    onUpdate([...(session.drinks || []), ...drinks] as unknown as Drink[]);
    setAnchorEl(null);
  };

  const renderMenuContent = () => {
    if (menuType === MENU_DRINK_TYPE) {
      return data.drinkTypes.map((type: DrinkType) => {
        const isSelected = updatingDrink
          ? updatingDrink.type._id === type._id
          : session?.drinks?.some((d) => d.type._id === type._id);

        return (
          <MenuItem
            key={type._id}
            disabled={isSelected}
            onClick={() => (updatingDrink ? updateDrink(type) : addDrink(type))}
          >
            <Box display="flex" alignItems="center">
              <Box minWidth="36px" pt={1}>
                {isSelected && <Check fontSize="small" />}
              </Box>
              {type.name}
            </Box>
          </MenuItem>
        );
      });
    }

    if (menuType === MENU_DRINK) {
      return data.drinkTypes.map((type: DrinkType) => (
        <MenuItem
          key={type._id}
          onClick={() => {
            const drinks: DrinkRequest[] = [];
            const playerId = player._id;

            const lastDrink = maxBy(
              (session.drinks as Drink[]).filter((drink) => drink.player === playerId),
              'createdAt',
            );
            const playerTempo = session?.tempo?.find((t) => t.player === playerId);
            const createdAt = lastDrink
              ? addMinutes(
                  new Date(lastDrink.createdAt),
                  playerTempo?.minutesPerDrink ? playerTempo?.minutesPerDrink : 45,
                )
              : new Date();

            drinks.push({
              // id: createdAt?.toISOString(), // should use helper
              type: type,
              player: playerId,
              createdAt: createdAt?.toISOString(),
            });

            onUpdate([...(session.drinks || []), ...drinks] as unknown as Drink[]);
            setAnchorEl(null);
          }}
        >
          <Box display="flex" alignItems="center">
            <Box minWidth="36px" pt={1}>
              <Add fontSize="small" />
            </Box>
            {type.name}
          </Box>
        </MenuItem>
      ));
    }

    const updateActions = [
      {
        id: 'changeDrinkType',
        icon: 'swap_horiz',
        label: 'Change drink type',
        onClick: () => setMenuType(MENU_DRINK_TYPE),
      },
      {
        id: 'changeDrinkTime',
        icon: 'schedule',
        label: 'Change time',
        onClick: () => {
          setTimeEditExpanded(true);
          setAnchorEl(null);
        },
      },
    ];

    return updateActions.map((action) => (
      <MenuItem onClick={action.onClick} key={action.id}>
        <Icon className={styles.actionIcon}>{action.icon}</Icon>
        {action.label}
      </MenuItem>
    ));
  };

  return (
    <>
      {renderPlayerDrinks()}
      {session.isLive ? (
        <Box mt={16} display="flex" justifyContent="center">
          <DarkRoundedButton
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setMenuType(MENU_DRINK);
            }}
            label="Add drink"
          />
        </Box>
      ) : (
        <Box mt={16} display="flex" justifyContent="center">
          <DarkRoundedButton
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setMenuType(MENU_DRINK_TYPE);
            }}
            label="Add drink type"
          />
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl && !!menuType}
        onClose={() => {
          setAnchorEl(null);
          setUpdatingDrink(null);
        }}
      >
        {renderMenuContent()}
      </Menu>
    </>
  );
};

export default SessionDrinks;
