import React from 'react';
import { Time, User } from '@functions';
import { DrinkSession } from '@ts/Drink';

import styles from './style.scss';

type Props = {
  data: DrinkSession;
  onClose: () => void;
};

const SessionSummary = ({ data, onClose }: Props) => {
  return (
    <div onClick={() => onClose()}>
      <div className={styles.wrap}>
        <div className={styles.date}>
          <div className={styles.labelSecondary}>Started at</div>
          <div className={styles.valueSecondary}>{Time.formatDate(data.createdAt, 'time')}</div>
        </div>
        <div className={styles.stats}>
          {data.players.length > 1 && (
            <div className={styles.stats}>
              <div className={styles.stat}>
                <div className={styles.value}>{data.drinks.length}</div>
                <div className={styles.label}>Drinks total</div>
              </div>
              <div className={styles.statSecondary}>
                <div className={styles.value}>{data.minutesPerDrink}</div>
                <div className={styles.label}>Min per drink</div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.stats}>
          <div className={styles.stat}>
            <div className={styles.value}>
              {data.drinks.filter((d) => d.player === User.id()).length}
            </div>
            <div className={styles.label}>Drinks yours</div>
          </div>
          <div className={styles.statSecondary}>
            <div className={styles.value}>
              {data.tempo.find((t) => t.player === User.id())?.minutesPerDrink || '?'}
            </div>
            <div className={styles.label}>Min per drink</div>
          </div>
        </div>
        <div className={styles.date}>
          <div className={styles.labelSecondary}> Finished at</div>
          <div className={styles.valueSecondary}>
            {data.endTime && Time.formatDate(data.endTime, 'time')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionSummary;
