import { includes } from 'lodash-es';

const isLocalhost = () => includes(window.location.hostname, 'localhost');
const isProduction = () => !isLocalhost();

const isAdmin = includes(window.location.pathname, 'admin');

export default {
  isProduction,
  isLocalhost,
  isAdmin,
};
