import { sum } from 'lodash-es';
import { Common, Cookie } from './';
import { translate } from '@localise/index';
import { F1Driver, F1Result, F1Vote, F1RankingItem, F1Race } from '@ts/F1';

const QUALIFYING = 'QUALIFYING';
const RACE = 'RACE';

const getRanking = (results: F1Result[], votes: F1Vote[]) => {
  const items: F1RankingItem[] = [];

  results = results && (Common.sort(results, 'position') as unknown as F1Result[]);
  votes = votes && (Common.sort(votes, 'position') as unknown as F1Vote[]);

  const checkOrder = (result: F1Result, index: number) => {
    const currentDriver = results[index] as unknown as F1Driver;
    const nextDriver = results[index + 1] as unknown as F1Driver;

    if (nextDriver) {
      const combination = [result.driverId, nextDriver.driverId];

      const index = votes.findIndex((vote) => vote.driverId === result.driverId);
      const nextVotedDriver = votes[index + 1];

      if (index !== -1 && nextVotedDriver) {
        if (nextVotedDriver.driverId === combination[1]) {
          const getName = (driver: F1Driver) => driver.driverName.split(' ')[1].substring(0, 4);

          const driverCombination = `${getName(currentDriver)} - ${getName(nextDriver)}`;

          return { driverCombination };
        }
      }
    }

    return false;
  };

  (results || []).forEach((r: F1Result, index: number) => {
    const match = votes && votes.find((v) => v.driverId === r.driverId);
    const positionMatch = match && r.position === match.position;
    const orderMatch = match && checkOrder(r, index);

    if (orderMatch) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      items.push({ pts: 3, type: translate.f1.voting.table.order, ...orderMatch });
    }

    if (positionMatch) {
      items.push({
        pts: 25,
        type: translate.f1.voting.table.match,
        driverId: match.driverId,
        driverName: match.driverName,
      });
      return;
    }

    /** UNTIL 2022 RANK SYSTEM **/
    if (Number(getSeason()) < 2022) {
      if (match) {
        items.push({
          pts: 10,
          type: translate.f1.voting.table.voted,
          driverId: match.driverId,
          driverName: match.driverName,
        });
      }
    } else {
      /** SINCE 2022 RANK SYSTEM **/
      if (match) {
        const matchOnPosition = votes.findIndex((v) => v.driverId === r.driverId) + 1;
        const currentPosition = r.position;
        const difference = Math.abs(matchOnPosition - Number(currentPosition));

        // console.log(matchOnPosition, currentPosition, difference, (4 - difference) * 5);

        if (difference <= 3) {
          items.push({
            pts: (4 - difference) * 5,
            type: translate.f1.voting.table.voted,
            driverId: match.driverId,
            driverName: match.driverName,
          });
        }
      }
    }
  });

  return { items, total: sum(items.map((item) => item.pts)) };
};

const getType = (type: string, race: F1Race) => {
  if (type === RACE) {
    return translate.f1.race;
  }

  if (race && race.sprintFormat) {
    return translate.f1.sprintQualifying;
  }

  return translate.f1.qualifying;
};

const setSeason = (season: string | number) => Cookie.set('f1-season', season.toString());
const getSeason = () => Cookie.get('f1-season') || Common.getYear().toString();
const getCurrentSeason = () => Common.getYear().toString();

export default {
  getRanking,
  setSeason,
  getSeason,
  getType,
  getCurrentSeason,
  QUALIFYING,
  RACE,
};
