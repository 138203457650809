import Api, { uploadFile } from './api';
import { SimpleObject } from '@ts/General';
import { ApiError } from '@ts/Api';

const get = async (path: string) => await Api.get(path).send();

const put = async (path: string, data: SimpleObject = {}) => await Api.put(path).data(data).send();

const post = async <T>(path: string, data: T = {} as T) =>
  await Api.post(path)
    .data(data as SimpleObject)
    .send();

const del = async (path: string, data: SimpleObject = {}) =>
  await Api.delete(path).data(data).send();

const err = (e: ApiError) => {
  console.error(e);

  if (typeof e === 'object' && e !== null) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    alert(e.message);
  } else {
    alert(e);
  }
};

const API = {
  get,
  put,
  post,
  del,
  // Helpers
  err,
  uploadFile,
};

export default API;
