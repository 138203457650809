import React from 'react';

import YouTube from 'react-youtube';

import styles from './styles.scss';

type Project = {
  link?: string;
  label?: string;
  src?: string;
  videoId?: string;
  mainVideoId?: string;
  title: string;
  description: string;
};

type TimelineItem = {
  year: string | number;
  small?: boolean;
  projects?: Project[];
};

type Props = {
  config: TimelineItem[];
};

const Timeline = ({ config }: Props): JSX.Element | null => {
  const renderProject = (project: Project, i: number) => {
    let content;

    if (project.videoId) {
      content = (
        <div className={styles.videoWrap} key={i}>
          <YouTube videoId={project.videoId} className={styles.youtubeVideo} />
        </div>
      );
    }

    if (project.src) {
      content = (
        <a
          className={styles.projectLink}
          href={project.link}
          target="_blank"
          key={i}
          rel="noopener noreferrer"
        >
          <div className={styles.imageLabel}>{project.label}</div>
          <img src={project.src} className={styles.image} alt="timeline-item" />
        </a>
      );
    }

    return (
      <div className={styles.project} key={i}>
        <div className={styles.projectBody}>
          <h3 className={styles.projectTitle}>{project.title}</h3>
          <p className={styles.projectDescription}>{project.description}</p>
        </div>
        <div className={styles.projectMedia}>
          <div className={styles.projetMediaItem}>{content}</div>
        </div>
        {project.mainVideoId && (
          <div className={styles.projectMainVideo}>
            <div className={styles.videoWrap} key={i}>
              <YouTube videoId={project.mainVideoId} />
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderItems = () =>
    config.map((item, index) => {
      return (
        <div className={styles.year} key={index}>
          <div className={styles.yearSide}>
            <div className={styles.yearLabel}>{item.year}</div>
          </div>
          <div className={styles.yearContent}>
            {item.projects && item.projects.map(renderProject)}
          </div>
        </div>
      );
    });

  if (!config || !config.length) {
    return null;
  }

  return <div className={styles.timeline}>{renderItems()}</div>;
};

export default Timeline;
