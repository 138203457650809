import React from 'react';
import {
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MaterialButton,
  Dialog,
} from '@mui/material';

type Props = {
  title: string;
  open: boolean;
  onExited?: () => void;
  onClose?: () => void;
  onSubmit?: () => void;
  disableSubmit?: boolean;
  submitText?: string;
  cancelText?: string;
  children?: JSX.Element | JSX.Element[] | string;
  minWidth?: number;
};

const Modal = ({
  minWidth,
  open,
  onExited,
  title,
  onSubmit,
  onClose,
  children,
  submitText,
  cancelText,
  disableSubmit,
}: Props) => {
  return (
    <Dialog open={open} onClose={onExited} style={minWidth ? { minWidth } : {}}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Box mx={1} mb={1}>
          <MaterialButton onClick={onClose}>{cancelText || 'Cancel'}</MaterialButton>
        </Box>
        <Box mx={1} mb={1}>
          <MaterialButton
            onClick={onSubmit}
            variant="contained"
            color="primary"
            disabled={disableSubmit}
          >
            {submitText || 'Submit'}
          </MaterialButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
