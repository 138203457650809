import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Hooks, User } from '../../../../functions';

import { upperFirst } from 'lodash-es';

import API from '../../../../api';

import './style.css';

const StuffAdd = ({ data, categories, onUpdate }) => {
  const ref = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState({});
  const [sending, setSending] = useState(false);

  const config = [
    { id: 'name', label: 'Name', required: true },
    { id: 'type', label: 'Type', field: 'select', items: categories, required: true },
    { id: 'category', field: 'select', items: categories },
    { id: 'masterId', field: 'picker', label: 'Master ID', items: data.filter((i) => !i.masterId) },
    { id: 'priority', type: 'number' },
    { id: 'sequence', type: 'number' },
  ];

  const onChange = (e) => {
    const { value, name } = e.target;

    setValues({ ...values, [name]: value });
  };

  const addStuff = async () => {
    const data = { ...values, userKey: User.hasAccess() };

    setSending(true);

    try {
      await API.post('/stuff/add', data);

      setIsOpen(false);
      setValues({});
      onUpdate();
    } catch (e) {
      API.err(e);
    }

    setSending(false);
  };

  const isMissingField = config.filter((i) => i.required).some((i) => !values[i.id]);

  const onKeyPress = (e) => {
    if (e.charCode === 13 && !isMissingField) {
      addStuff();
    }
  };

  const renderField = (field) => {
    const value = values[field.id];
    const props = {
      id: field.id,
      className: 'stuff-add-field-input',
      name: field.id,
      value: value || '',
      onChange,
      onKeyPress,
    };

    if (field.field === 'select' || field.field === 'picker') {
      return (
        <select {...props} value={values[field.id] || (field.required ? 'none' : 'notSelected')}>
          {!value && field.required && <option value="none">Select</option>}
          {!field.required && <option value="notSelected">Not selected</option>}
          {(field.items || []).map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
        </select>
      );
    }

    return (
      <input
        {...props}
        type={field.type || 'text'}
      />
    );
  };

  if (!isOpen) {
    return <button className="stuff-add-button" onClick={() => setIsOpen(true)}>Add new</button>;
  }

  return (
    <div className="stuff-add-wrap">
      <div className="stuff-add" ref={ref}>
        <div className="stuff-add-title">Add stuff</div>
        <div className="stuff-add-fields">
          {config.map((field) => (
            <label className="stuff-add-field" key={field.id}>
              <div className="stuff-add-field-label">{field.label || upperFirst(field.id)}</div>
              {renderField(field)}
            </label>
          ))}
        </div>
        <div className="stuff-add-footer">
          <button
            className="stuff-add-confirm-button"
            onClick={addStuff}
            disabled={isMissingField || sending}
          >Add {values.name || 'Stuff'}</button>
        </div>
      </div>
    </div>
  );
};

StuffAdd.propTypes = {
  data: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default StuffAdd;
