import React, { useEffect, useState } from 'react';

import API from '@api/index';
import { endpoints } from '@api/endpoints';

import './style.css';

type Props = {
  onUpdate?: () => void;
};

const CatchPasting = ({ onUpdate }: Props) => {
  const [saved, setSaved] = useState(false);

  const onPaste = async (e: ClipboardEvent) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const text = (e.clipboardData || window.clipboardData).getData('text') || '';

    const isValid = text.length >= 3 && (text.match(/\d+/g) || text.match(/\w+/g));

    if (!isValid) {
      return;
    }

    let type;

    if (text.includes('http') || text.includes('www')) {
      type = 'LINK';
    }

    try {
      await API.post(endpoints.clip.add, { text, type });

      setSaved(true);

      if (onUpdate) {
        onUpdate();
      }
      setTimeout(() => setSaved(false), 900);
    } catch (error) {
      API.err(error);
    }
  };

  useEffect(() => {
    window.addEventListener('paste', (e) => onPaste(e as ClipboardEvent));

    return () => window.removeEventListener('paste', (e) => onPaste(e as ClipboardEvent));
  }, []);

  return <div className={`catch-pasting ${saved ? 'active' : ''}`} />;
};

export default CatchPasting;
