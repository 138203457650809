import React, { useContext, useState } from 'react';

import { F1 } from '@functions';

import { F1Context } from '@context/f1Data';

import { teamImages } from '../../data';
import F1Layout from '@pages/F1/layout';
import Season from '@pages/F1/components/Season';

import Driver from './Driver';

import f1Image from '@images/f1.jpg';

import { F1DriverVote } from '@ts/F1';
import { SimpleObject } from '@ts/General';

import './style.css';

const Drivers = () => {
  const [season, setSeason] = useState(F1.getSeason());

  console.log(season);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [data] = useContext(F1Context);

  const { driverRankings: drivers } = data;

  return (
    <F1Layout withoutAccess>
      <Season onChange={setSeason} />
      {!drivers && (
        <div className="f1-season-wrap">
          <img className="f1-season-image" src={f1Image} alt="season" />
          <div className="f1-season-text">Drivers are not yet confirmed</div>
        </div>
      )}
      {drivers && (
        <div className="f1-body-block">
          {drivers.map((driver: F1DriverVote) => {
            const team = teamImages.find((t) => t.teamId === driver.team?.id);

            return <Driver key={driver.position} driver={driver} team={team as SimpleObject} />;
          })}
        </div>
      )}
    </F1Layout>
  );
};

export default Drivers;
