import { Common } from './';

const build = (hash?: string) => {
  hash = hash || window.location.hash;

  const params: Record<string, string | string[]> = {};

  hash.split('&').forEach((param) => {
    const key = param.replace('#', '').split('=');

    if (typeof key[1] === 'string') {
      const values = key[1].split(',');

      if (values.length > 1) {
        params[key[0]] = [];

        values.forEach(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          params[key[0]].push(Common.checkType(key[1]));
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        params[key[0]] = Common.checkType(key[1]);
      }
    }
  });

  return params;
};

const push = (params = {}, reset = false) => {
  const actualParams = reset ? {} : build();

  const newParams: Record<string, unknown> = { ...actualParams, ...params };

  const hash = Object.keys(newParams)
    .map((p) => `${p}=${newParams[p]}`)
    .join('&');

  return `#${hash}`;
};

export default {
  build,
  push,
};
