import React, { MouseEvent } from 'react';

import Icon from '../SVG';

import './style.css';

type Props = {
  children: JSX.Element | string;
  icon?: string;
  iconColor?: string;
  onClick: (e: MouseEvent) => void;
};

const Tag = ({ icon, iconColor, children, onClick = () => true }: Props) => {
  return (
    <div onClick={onClick} className="tag-wrap">
      <div className="tag-title">{children}</div>
      {icon && <Icon width={10} height={10} icon={icon} color={iconColor} />}
    </div>
  );
};

export default Tag;
