import React, { ChangeEventHandler } from 'react';

import './style.css';

type Props = {
  value?: string | number;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  children?: JSX.Element | JSX.Element[];
  className?: string;
};

const Select = ({ value, className, onChange, children, ...props }: Props) => {
  return (
    <select
      className={`select-minimal ${className || ''}`}
      onChange={onChange}
      value={value}
      {...props}
    >
      {children}
    </select>
  );
};

export default Select;
