import React, { useContext, useEffect, useState } from 'react';

import API from '../../../../../api';

import GamesLayout from '../../layout';

import { GamesContext } from '../../../../../context/gamesData';
import { endpoints } from '../../../../../api/endpoints';
import { Common } from '../../../../../functions';
import { Collapse, Fade, Grow, Zoom } from '@mui/material';

import './style.css';

const Leaderboard = () => {
  const [data] = useContext(GamesContext);
  const [gameStats, setGameStats] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showBody, setShowBody] = useState(false);

  const loadData = async () => {
    try {
      const data = await API.get(endpoints.game.stats.allBySeason(1));

      setGameStats(data);
      setDataLoaded(true);
    } catch (err) {
      // No handle
    }

    console.log(gameStats);
  };

  const playersWithPoints = data.users.map((u) => ({ points: gameStats?.points[u._id] || 0, ...u }));
  const sortedPlayers = Common.sort(playersWithPoints, 'points').reverse();

  useEffect(() => {
    loadData();

    setTimeout(() => setShowBody(true), 1400);
  }, []);

  const renderCardBody = (pos) => {
    const player = sortedPlayers[pos - 1];

    if (!player) return null;

    return (
      <>
        <div/>
        <div className="leaderboard-card-body-pos">{pos}</div>
        <div>
          <div className="leaderboard-card-body-points-label">points</div>
          <div className="leaderboard-card-body-points">{player.points}</div>
        </div>
        <div className="leaderboard-card-body-name">{player.username}</div>
      </>
    );
  };

  return (
    <GamesLayout loading={!dataLoaded} header="Leaderboard" center>
      <div className="leaderboard-wrap">
        <div className="leaderboard-tops">
          <Fade in={dataLoaded} style={{ transitionDelay: '140ms', transitionDuration: '1250ms' }}>
            <div className="leaderboard-card md">
              <div className="leaderboard-card-body">
                {renderCardBody(2)}
              </div>
            </div>
          </Fade>
          <Fade in={dataLoaded} style={{ transitionDelay: '0ms', transitionDuration: '1250ms' }}>
            <div className="leaderboard-card lg">
              <div className="leaderboard-card-body">
                {renderCardBody(1)}
              </div>
            </div>
          </Fade>
          <Fade in={dataLoaded} style={{ transitionDelay: '240ms', transitionDuration: '1250ms' }}>
            <div className="leaderboard-card sm">
              <div className="leaderboard-card-body">
                {renderCardBody(3)}
              </div>
            </div>
          </Fade>
        </div>
        <Collapse in={showBody} timeout={{ enter: 1250 }}>
          <div className="leaderboard-body">
            {sortedPlayers.map((s, index) => index > 2 && (
              <Grow in={showBody} style={{ transformOrigin: '0 0 0' }} timeout={{ enter: 2500, appear: index * 500 }}>
                <div className="leaderboard-card xs" key={index}>
                  <div className="leaderboard-card-body">
                    {renderCardBody(index + 1)}
                  </div>
                </div>
              </Grow>
            ))}
          </div>
        </Collapse>
      </div>
    </GamesLayout>
  );
};

export default Leaderboard;
