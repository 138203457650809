import React, { useState } from 'react';
import classNames from 'classnames';
import { get } from 'lodash-es';

import PageWrap from '../../utils/PageWrap';

import imageAlps from '@images/analog-alps.webp';
import imageHome from '@images/analog-home.webp';
import imageIceland from '@images/analog-iceland.webp';

import styles from './style.scss';

type ItemConfig = {
  id: string;
  label: string;
  src: string;
  alt: string;
};

const AnalogPortfolio = (): JSX.Element => {
  const [unmount, setUnmount] = useState<string | null>(null);
  const [imagesLoaded, setImagesLoaded] = useState<Record<number, string>>({});

  const items: ItemConfig[] = [
    { id: 'alps', label: 'Alps', src: imageAlps, alt: 'analog_pic_1' },
    { id: 'iceland', label: 'Iceland', src: imageIceland, alt: 'analog_pic_2' },
    { id: 'home', label: 'Home', src: imageHome, alt: 'analog_pic_3' },
  ];

  const text = {
    title: 'Analog photography',
    subtitle: 'The story behind ',
    text:
      'I love to shoot on analog to capture the timeless texture and unpredictable colors that only film can deliver.\n' +
      '\n' +
      'The grain, depth, and unique drawing of each image create a distinct, artistic atmosphere that digital cameras can’t replicate.',
    footer: 'Most photos are taken with a Chinon CM-4',
  };

  return (
    <PageWrap unmount={unmount}>
      <div className={styles.wrap}>
        <div className={styles.parts}>
          {items.map((item) => (
            <div
              className={classNames(styles.part, styles.clickable)}
              key={item.id}
              onClick={() => setUnmount(`/portfolio/analog/${item.id}`)}
            >
              <div className={styles.partMain}>
                <img
                  className={classNames(
                    styles.image,
                    !!get(imagesLoaded, item.id) && styles.loaded,
                  )}
                  onLoad={() => setImagesLoaded((prev) => ({ ...prev, [item.id]: true }))}
                  src={item.src}
                  alt={item.alt}
                />
              </div>
              <div className={styles.partInfo}>
                <div>
                  <div className={styles.partInfoLabel}>{item.label}</div>
                  <div className={styles.partInfoHelper}>Show more</div>
                </div>
              </div>
            </div>
          ))}
          <div className={classNames(styles.partMain, styles.main)}>
            <div className={styles.partContent}>
              <div>
                <h6 className={styles.partTitle}>{text.title}</h6>
                <p className={classNames(styles.partSubtitle)}>{text.subtitle}</p>
                <p className={styles.partText}>{text.text}</p>
              </div>
              <p className={classNames(styles.partText, styles.muted)}>{text.footer}</p>
            </div>
          </div>
        </div>
      </div>
    </PageWrap>
  );
};

export default AnalogPortfolio;
