// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table-yCntS{margin-bottom:30px;color:#ccc}.table-yCntS thead td{min-height:30px;padding:8px}.table-yCntS tbody tr{border-bottom:1px solid #333;height:44px;cursor:pointer;margin-bottom:1px}.table-yCntS tr:hover{background:rgba(0,0,0,.1647058824)}.table-yCntS tbody td{text-align:left;vertical-align:middle;height:44px;padding:8px}.table-yCntS tbody td:first-child,table thead td:first-child{padding-left:20px}.table-yCntS tbody td:last-child{padding-right:20px}.tableNoData-LLM4L,.tableLoading-L-odC{text-align:center;height:140px}.tableNoData-LLM4L{font-size:24px;color:hsla(0,0%,100%,.0666666667);font-weight:lighter;text-transform:uppercase}.mark-IeQIZ{border:2px solid #333;background:#333;padding:3px 6px;border-radius:4px;color:#ddd;text-align:center}.tableWrap-5eYGs{background:#222;border:2px solid #333;border-radius:10px;box-shadow:0 0 30px rgba(0,0,0,.2666666667)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table-yCntS",
	"tableNoData": "tableNoData-LLM4L",
	"tableLoading": "tableLoading-L-odC",
	"mark": "mark-IeQIZ",
	"tableWrap": "tableWrap-5eYGs"
};
export default ___CSS_LOADER_EXPORT___;
