// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button-s6X3H,.buttonMain-Mjvsz{padding:10px 20px !important;flex:1}.button-s6X3H.active-FbEKN,.buttonMain-Mjvsz.active-FbEKN{background:#0af;color:#fff}.body-vIUIT{display:flex;flex-direction:column}.title-h750e{padding:10px 20px}.body-vIUIT{padding:10px 20px}.field-D5GIE{margin-bottom:10px !important}.counter-XEEon{display:flex;justify-content:space-between;align-items:center}.item---vxZ{display:flex;justify-content:space-between;align-items:center;border-radius:6px;background:rgba(0,0,0,.2666666667);font-size:14px;overflow:hidden}.itemBody-y8N3A{padding:10px 20px}.addItem-SNFH4{padding:10px;background:rgba(0,0,0,.2666666667);border-radius:6px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button-s6X3H",
	"buttonMain": "buttonMain-Mjvsz",
	"active": "active-FbEKN",
	"body": "body-vIUIT",
	"title": "title-h750e",
	"field": "field-D5GIE",
	"counter": "counter-XEEon",
	"item": "item---vxZ",
	"itemBody": "itemBody-y8N3A",
	"addItem": "addItem-SNFH4"
};
export default ___CSS_LOADER_EXPORT___;
