import { includes } from 'lodash-es';

const upper = () => {
  const path = window.location.pathname;

  let url = '/';

  if (includes(path, '/')) {
    const lastIndex = path.lastIndexOf('/');

    url += path.slice(0, lastIndex);
  }

  return url;
};

export default {
  upper,
};
