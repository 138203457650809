// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button-Um9WO{opacity:.85;font-size:12px;letter-spacing:1px;font-weight:600;text-transform:uppercase;padding:8px 12px;background:#00bfff;border-radius:100px;color:#fff}.button-Um9WO:before{content:\"\";position:absolute;top:50%;left:50%;width:20px;height:20px;margin-top:-12px;margin-left:-12px;border-radius:100px;transition:.2s linear opacity;opacity:0}.button-Um9WO.error-AnJP2{background:red !important;border-color:red}.button-Um9WO:before.loading-vw9GL{border:2px solid #fff;border-top-color:rgba(0,0,0,0);animation:rotate-8PhPy .8s linear infinite;transition:.3s .1s linear opacity;opacity:1}.button-Um9WO.loading-vw9GL .label-Z\\+Jw2{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button-Um9WO",
	"error": "error-AnJP2",
	"loading": "loading-vw9GL",
	"rotate": "rotate-8PhPy",
	"label": "label-Z+Jw2"
};
export default ___CSS_LOADER_EXPORT___;
