import React, { ChangeEvent } from 'react';

import './style.css';

type Props = {
  label: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox = ({ label, checked, onChange, ...props }: Props): JSX.Element => {
  return (
    <label className="checkbox-container">
      <span>{label}</span>
      <input type="checkbox" checked={checked} onChange={onChange} {...props} />
      <span className="checkmark" />
    </label>
  );
};

export default Checkbox;
