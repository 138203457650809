export default {
  networkSpeed: {
    update: 0.25, // s
    updateBy: 10, // %
    slowAfter: 79, // %
  },
  checkDragAfter: 3, // s
  expiresIn: 300, // s

  imageTypes: ['png', 'jpg', 'jpeg', 'gif'],

  spaceTotal: 1500, // mb
};
