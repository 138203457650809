import React, { useState, ChangeEvent, KeyboardEvent } from 'react';

import API from '@api/index';

import { User } from '@functions';

import Icon from '@common/Icon';
import Loading from '@common/Loading';

import { endpoints } from '@api/endpoints';
import { translate } from '@localise/index';

import './style.css';

type Props = {
  onAdd: () => void;
};

type ImageResponse = {
  name: string;
  src: string;
};

const NewPost = ({ onAdd }: Props) => {
  const user = User.hasAccess();

  const [content, setContent] = useState('');
  const [image, setImage] = useState<ImageResponse | null>(null);
  const [uploading, setUploading] = useState(false);
  const [posting, setPosting] = useState(false);

  const uploadImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (uploading) return;

    const formData = new FormData();

    if (e.target.files) {
      formData.append('file', e.target.files[0]);

      setUploading(true);

      try {
        const response = await API.uploadFile(endpoints.f1.posts.uploadFile, formData);

        setImage(response as ImageResponse);
      } catch (e) {
        API.err(e);
      }
    }

    setUploading(false);
  };

  const addPost = async () => {
    if (posting) return;

    const data = {
      content,
      user,
      image: '',
    };

    if (image) {
      data.image = `/f1/${image.name}`;
    }

    try {
      setPosting(true);
      await API.post(endpoints.f1.posts.add, data);

      setContent('');
      setImage(null);

      onAdd();
    } catch (e) {
      API.err(e);
    }

    setPosting(false);
  };

  const handleSubmit = (e: KeyboardEvent) => {
    if (e.charCode === 13 && (image || content)) {
      addPost();
    }
  };

  return (
    <div className="add-post-wrap">
      <label className="add-new-post-input-label">
        <input
          type="text"
          className="add-new-post-input"
          onChange={(e) => setContent(e.target.value)}
          value={content}
          placeholder={translate.f1.posts.add.contentPlaceholder}
          onKeyPress={handleSubmit}
        />
      </label>
      <div className="add-post-actions">
        <label className="add-post-label">
          <input
            type="file"
            className="hidden-input"
            onChange={(e) => e.target.value && uploadImage(e)}
          />
          <div
            className={`add-post-attach-file ${uploading ? 'loading' : ''} ${
              image ? 'uploaded' : ''
            }`}
          >
            <Icon className="add-post-attach-file-icon" size={18}>
              insert_photo
            </Icon>
            <span>
              {image ? translate.f1.posts.add.imageAttached : translate.f1.posts.add.attachImage}
            </span>
          </div>
          {uploading && <Loading noWrap size={14} color="#888" />}
        </label>
        <div className="news-add-confirmation">
          <button
            className="add-post-button"
            onClick={() => addPost()}
            disabled={!image && !content}
          >
            {translate.f1.posts.add.addPost}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewPost;
