import React, { useEffect, useState } from 'react';
import { get as g } from 'lodash-es';

import { Cookie } from '@functions';
import { name } from '@pages/Vision/pages/Detail';

import house from '@pages/Vision/house-svgrepo-com.svg';

import styles from './style.scss';
import classNames from 'classnames';

export type MenuItem = {
  id: string;
  parent?: string;
  extra?: string;
};

type Props = {
  items: MenuItem[];
  selectFirst?: boolean;
  onSelect: (id: string, item: MenuItem) => void;
  footer?: JSX.Element;
};

const Menu = ({ items, selectFirst, onSelect, footer }: Props) => {
  const [active, setActive] = useState<string | null>(null);

  useEffect(() => {
    if (selectFirst) {
      const active =
        g(
          items.find((i) => i.id === Cookie.get('menuItem')),
          'id',
        ) || items.find((i: MenuItem) => !i.parent)?.id;

      if (active) {
        setActive(active);
      }
    }
  }, [items?.length]);

  useEffect(() => {
    const activeItem = items.find((i) => i.id === active);

    if (activeItem) {
      onSelect(activeItem.id, activeItem);
    }
  }, [active]);

  const activeItem = items.find((i) => i.id === active);

  return (
    <div className={styles.menu}>
      <div className={styles.menuItems}>
        {items.map((item: MenuItem, index) => {
          const isActive = active === item.id;
          const isSubVisible =
            item.parent && (item.parent === active || item.parent === activeItem?.parent);
          const style = !item.parent
            ? { animation: `show-menu-item .7s ${index * 0.2}s ease forwards` }
            : {};
          return (
            <div
              key={index}
              style={style}
              className={classNames(
                styles.menuItem,
                isActive && styles.active,
                item.parent && styles.sub,
                isSubVisible && styles.visible,
                item.extra && styles.extra,
              )}
              onClick={() => {
                setActive(item.id);
                Cookie.set('menuItem', item.id);
              }}
            >
              {name(item.id)}
            </div>
          );
        })}
      </div>
      <div className="menuFoot">{footer}</div>
    </div>
  );
};
export default Menu;
