import React, { useEffect, useState } from 'react';

import styles from './style.scss';
import classNames from 'classnames';
import { camelCase, includes } from 'lodash-es';

export type ImageContentItem = {
  id: string;
  title: string;
  options?: {
    centerTitle?: boolean;
  };
  images: {
    widths?: string[];
    image: string | string[];
    title?: string;
    description?: string;
    type: 'HEIGHT' | 'WIDTH' | 'FULLWIDTH' | 'CUSTOM_FULLWIDTH';
    priority?: number;
    params?: string[];
    infoPosition?: 'BEHIND' | 'FRONT';
  }[];
};

type Props = {
  config: {
    getImage: (image: string) => string;
    items: ImageContentItem[];
  };
};

const ImagesContent = ({ config }: Props) => {
  const [loadOtherImages, setLoadOtherImages] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoadOtherImages(true);
    }, 1000);
  }, []);

  return (
    <div className={styles.wrap}>
      {config.items.map((item, index) => (
        <div key={index} className={styles.item}>
          <div className={classNames(styles.itemTitle, item.options?.centerTitle && styles.center)}>
            {item.title}
          </div>
          <div className={styles.itemContent}>
            {item.images.map((image, i) => {
              const info = (
                <div className={styles.imageInfo}>
                  {image.title && <div className={styles.imageTitle}>{image.title}</div>}
                  {image.description && (
                    <div className={styles.imageDescription}>{image.description}</div>
                  )}
                </div>
              );

              const placeInFront = image.infoPosition !== 'BEHIND' && image.type !== 'HEIGHT';

              if (image.priority !== 1 && !loadOtherImages) {
                return null;
              }

              const renderImage = Array.isArray(image.image) ? (
                <div
                  className={classNames(
                    styles.imageGroup,
                    image.type.includes('CUSTOM_FULLWIDTH') && styles.customWidth,
                  )}
                >
                  {image.image.map((img, i) => (
                    <img
                      key={i}
                      src={config.getImage(img)}
                      alt={image.title}
                      className={styles.image}
                      width={image.widths ? image.widths[i] : undefined}
                    />
                  ))}
                </div>
              ) : (
                <img
                  src={config.getImage(image.image as string)}
                  alt={image.title}
                  className={styles.image}
                />
              );

              return (
                <div
                  key={i}
                  className={classNames(styles.imageWrap, styles[camelCase(image.type)])}
                >
                  {!includes(image.params, 'LEFT') && (
                    <div className={styles.part1}>{placeInFront && info}</div>
                  )}
                  {renderImage}
                  <div className={styles.part2}>{!placeInFront && info}</div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImagesContent;
