import React, { KeyboardEvent, useEffect, useState } from 'react';
import { Dialog, Box, ButtonBase, Typography } from '@mui/material';
import classNames from 'classnames';

import { Common } from '@functions';

import TextField from '@material/TextField';
import Select, { SelectOption } from '@material/Select';

import { UnsafeAny } from '@ts/General';

import Counter from '@common/Counter';

import { Add, Close } from '@mui/icons-material';

import styles from './style.scss';

export type ConfigField = {
  id: string;
  label: string;
  type?: 'text' | 'number' | 'select' | 'custom' | 'adder' | 'multipicker' | 'counter';
  required?: boolean;
  items?: SelectOption[];
  hidden?: boolean | ((values: Record<string, unknown>) => boolean);
  render?: () => JSX.Element;
};

type Update = {
  id: string;
  value: UnsafeAny;
};

type FormModalOptions = {
  disableSubmit?: boolean;
  updateAdapter?: (id: string, value: UnsafeAny, newValues: Record<string, UnsafeAny>) => void;
};

type Props = {
  open: boolean;
  title: string;
  config: ConfigField[];
  defaults?: Record<string, unknown>;
  onChange?: (value: UnsafeAny) => void;
  onSubmit: (values: Record<string, unknown>) => void;
  onClose: (isBackDropClick: boolean) => void;
  submitText?: string;
  closeText?: string;
  options?: FormModalOptions;
};

const AddFormModal = ({
  open,
  onClose,
  title,
  config,
  defaults = {},
  onChange,
  onSubmit,
  submitText,
  closeText,
  options = {},
}: Props) => {
  const [values, setValues] = useState<Record<string, unknown>>({});
  const [tempName, setTempName] = useState<string>('');

  const disableSubmit = options.disableSubmit;

  useEffect(() => {
    if (open) {
      setValues(defaults);
    }
  }, [open]);

  const onUpdate = ({ id, value }: Update) => {
    const newValues = { ...values, [id]: value };

    if (options.updateAdapter) {
      options.updateAdapter(id, value, newValues);
    }

    setValues(newValues);
    if (onChange) onChange(newValues);
  };

  const handleSubmit = (e: KeyboardEvent) => {
    if (e.charCode === 13 && !disableSubmit) {
      onSubmit(values);
    }
  };

  const setDefaults = () => {
    setValues(defaults || {});
  };

  const renderField = (c: ConfigField) => {
    const props = {
      value: (values[c.id] || values[c.id] === 0 ? values[c.id] : '') as UnsafeAny,
      onChange: (eOrValue: UnsafeAny) =>
        onUpdate({ id: c.id, value: eOrValue?.target?.value || eOrValue }),
      onKeyPress: handleSubmit,
      fullWidth: true,
      autoFocus: c.id === 'name',
      label: c.label || c.id,
      className: styles.field,
    };

    if (c.items && !c.type) c.type = 'select';

    if (typeof c.hidden === 'function' ? c.hidden(values) : c.hidden) return null;

    switch (c.type) {
      case 'multipicker':
        return (
          <Box display="flex" gap={1} className={styles.field}>
            {c.items?.map((item) => (
              <ButtonBase
                key={item.id}
                classes={{
                  root: classNames(styles.button, values[c.id] === item.value && styles.active),
                }}
                onClick={() => onUpdate({ id: c.id, value: item.value })}
                sx={{ borderRadius: 2, p: 1, background: '#ffffff22' }}
              >
                {item.label}
              </ButtonBase>
            ))}
          </Box>
        );

      case 'counter':
        return (
          <div className={classNames(styles.counter, styles.field)}>
            <div className={styles.conterLabel}>
              <Typography>{props.label}</Typography>
            </div>
            <Counter
              count={props.value}
              onAdd={() => onUpdate({ id: c.id, value: props.value + 1 })}
              onRemove={() => onUpdate({ id: c.id, value: props.value - 1 })}
            />
          </div>
        );

      case 'number':
        return (
          <TextField
            /* eslint-disable-next-line jsx-a11y/no-autofocus */ {...props}
            type="number"
            options={{ shrink: true }}
          />
        );
      case 'select':
        return (
          <Select
            {...props}
            items={c.items as SelectOption[]}
            options={{ shrink: true, dark: true }}
          />
        );
      case 'adder':
        const onAddItem = () => {
          if (!tempName) return;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const newItems = [...(values[c.id] || []), { name: tempName }];
          onUpdate({ id: c.id, value: newItems });
          setTempName('');
        };

        const onRemoveItem = (index: number) => {
          const newItems = (values[c.id] as UnsafeAny[]).filter((_, i) => i !== index);
          onUpdate({ id: c.id, value: newItems });
        };

        return (
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            p={2}
            sx={{ background: '#ffffff22', borderRadius: 2 }}
          >
            <Typography>{props.label}</Typography>
            {(props.value || []).map((item: SelectOption, index: number) => (
              <div className={styles.item} key={item.name}>
                <div className={styles.itemBody}>{item.name}</div>
                <ButtonBase sx={{ px: 2, py: 1 }} onClick={() => onRemoveItem(index)}>
                  <Close />
                </ButtonBase>
              </div>
            ))}
            <Box display="flex" gap={1} className={styles.addItem}>
              <TextField
                fullWidth
                onChange={setTempName}
                value={tempName}
                label={'New item name'}
                options={{ shrink: true }}
                onKeyPress={(e: KeyboardEvent) => {
                  if (e.charCode === 13 && !disableSubmit) {
                    onAddItem();
                  }
                }}
              />
              <ButtonBase onClick={() => onAddItem()} sx={{ px: 2, borderRadius: 2 }}>
                <Add />
              </ButtonBase>
            </Box>
          </Box>
        );

      case 'custom':
        return c?.render ? c.render() : 'Not set';
      default:
        return (
          <TextField
            /* eslint-disable-next-line jsx-a11y/no-autofocus */ {...props}
            options={{ shrink: true }}
          />
        );
    }
  };

  return (
    <Dialog
      open={open}
      onSubmit={() => onSubmit(values)}
      onBackdropClick={() => {
        onClose(true);
        Common.modalDelay(setDefaults);
      }}
    >
      <Box flexDirection="column" minWidth={550}>
        <div className={styles.title}>{title}</div>
        <div className={styles.body}>{config.map((c) => renderField(c))}</div>
        <Box display="flex" gap={4}>
          <ButtonBase
            classes={{ root: styles.button }}
            onClick={() => {
              onClose(false);
              Common.modalDelay(setDefaults);
            }}
          >
            {closeText || 'Close'}
          </ButtonBase>
          <ButtonBase classes={{ root: styles.buttonMain }} onClick={() => onSubmit(values)}>
            {submitText || 'Submit'}
          </ButtonBase>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddFormModal;
