// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-vkq0P{display:flex;flex-direction:column;justify-content:space-evenly;align-items:center;color:#fff;min-height:90svh;text-transform:uppercase;font-size:12px;letter-spacing:1px}.date-Ir89-{display:flex}.labelSecondary-qYNmZ{opacity:.4;padding:0 6px}.stats-05sss{display:flex;align-items:center}.stat-X5irL,.statSecondary-xzAUk{display:flex;flex-direction:column;justify-content:center;align-items:center;border-radius:50%;margin:16px;min-width:120px;min-height:120px;padding:20px;border:2px solid #ddd}.label-EMMTl{font-size:10px}.value-auWPu{font-size:50px;font-weight:100;line-height:1}.statSecondary-xzAUk{min-width:100px;min-height:100px;opacity:.7;padding:16px;border-color:#555}.statSecondary-xzAUk .value-auWPu{font-size:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-vkq0P",
	"date": "date-Ir89-",
	"labelSecondary": "labelSecondary-qYNmZ",
	"stats": "stats-05sss",
	"stat": "stat-X5irL",
	"statSecondary": "statSecondary-xzAUk",
	"label": "label-EMMTl",
	"value": "value-auWPu"
};
export default ___CSS_LOADER_EXPORT___;
