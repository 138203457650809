import React, { useState, useRef, MouseEvent } from 'react';

import styles from './style.scss';
import classNames from 'classnames';

type Props = {
  text: string;
  children: React.ReactNode;
  onCopied?: () => void;
};

const CopyToClipboard = ({ text, children, onCopied }: Props) => {
  const inputRef = useRef(null);
  const [copied, setCopied] = useState<null | boolean>(null);

  const copy = (e: MouseEvent) => {
    let success = false;

    e.stopPropagation();
    e.preventDefault();

    if (!navigator.clipboard) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      inputRef.current.select();
      success = document.execCommand('copy');
    }

    if (!success) {
      navigator.clipboard.writeText(text).then(
        () => (success = true),
        (e) => console.error('Copy failed', e),
      );
    }

    if (success && onCopied) {
      onCopied();
    }

    setCopied(success);
  };

  return (
    <div onClick={copy} className={classNames(styles.copyToClipboard, copied && styles.copied)}>
      {children}
      <input value={text} ref={inputRef} className="hidden-input" onChange={() => true} />
    </div>
  );
};

export default CopyToClipboard;
