import React, { Component } from 'react';
import PropTypes from 'prop-types';
import API from '../../../../../api';

import { path } from '../../../../../api/api';
import { Common } from '../../../../../functions';

import LoadingCircle from '../../../../common/LoadingCircle';
import Icon from '../../../../common/SVG';

import settings from '../driveSettings';

import './style.css';

class UploadFile extends Component {
  static propTypes = {
    callBack: PropTypes.func.isRequired,
    dragEnd: PropTypes.func,
    drag: PropTypes.bool,
    path: PropTypes.string,
  };

  state = {
    data: null,
    unmount: false,
    drag: false,
    copied: false,
    percentage: 0,
    expiring: false,
  };

  componentDidMount() {
    this.uploadLabel.addEventListener('dragleave', () => this.setState({ drag: false }));

    setInterval(() => this.checkProgress(), settings.networkSpeed.update * 1000);
  }

  componentWillUnmount() {
    this.uploadLabel.removeEventListener('dragleave', () => this.setState({ drag: false }));
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.drag && this.props.drag) {
      setTimeout(() => {
        if (!this.props.drag) {
          this.setState({ drag: false });
        }
      }, settings.checkDragAfter * 1000);
    }

    if (prevProps.drag && !this.props.drag) {
      this.setState({ drag: true });

      if (this.uploading) return;

      if (this.uploaded) this.setState({ uploadActive: false, copied: false });
    }
  }

  checkProgress() {
    if (this.uploaded) {
      this.setState({ percentage: 100 });
    } else if (this.uploading) {
      let add = settings.networkSpeed.updateBy;

      if (this.state.percentage > settings.networkSpeed.slowAfter) add = (100-this.state.percentage) / 10;

      const percentage = this.state.percentage + add;

      this.setState({ percentage });
    } else {
      this.setState({ percentage: 0 });
    }
  }

  async uploadImage(e) {
    const { path } = this.props;

    const formData = new FormData();

    formData.append('file', e.target.files[0]);

    this.setState({ uploadActive: true, drag: false });

    try {
      const response = await API.uploadFile(`/files/upload.php?path=${path}`, formData);

      setTimeout(() => this.setState(
          { uploadActive: response, expiring: true },
          () => this.copyToClipboard(),
        ), 50);

      setTimeout(
        () => this.setState({ expiring: false, uploadActive: false, copied: false }),
        settings.expiresIn * 1000,
      );
      this.props.callBack();
    } catch (e) {
      API.err(e);
    }
  }

  get circle() {
    const { copied, percentage } = this.state;

    return <LoadingCircle percentage={percentage} done={copied}/>;
  }

  closeModal(e) {
    if (e.target.tagName !== 'LABEL') {
      return;
    }

    this.setState({ uploadActive: false, copied: false });
  }

  showFullImage() {
    Common.openInNewTab(this.link);
  }

  copyToClipboard() {
    let success;

    if (navigator.clipboard) {
      this.copyRef.select();
      success = document.execCommand('copy');
    }

    if (!success) {
      navigator.clipboard.writeText(this.link).then(
        () => success = true,
        (e) => console.error('Copy failed', e),
      );
    }

    this.setState({ copied: success });
  }

  get uploading() {
    return this.state.uploadActive === true;
  }

  get uploaded() {
    return this.state.uploadActive && encodeURIComponent(this.state.uploadActive.name);
  }

  get link() {
    return this.uploaded ? `${window.location.origin}/file${this.props.path}${this.uploaded}` : '';
  }

  get uploader() {
    const { uploadActive, drag, expiring } = this.state;

    const uploadText = this.uploading ? 'Uploading' : (this.uploaded ? 'Prepared' : 'Drop to upload');
    const info = typeof uploadActive === 'object' ? uploadActive : {};
    const isImage = typeof info.type === 'string' ? info.type.startsWith('image') : false;

    return (
      <label
        onClick={(e) => this.closeModal(e)}
        ref={(node) => this.uploadLabel = node}
        className={`upload-file ${(drag) ? 'drag' : ''} ${this.uploading ? 'uploading' : ''} ${this.uploaded ? 'uploaded' : ''}`}
      >
        {expiring && <div className="upload-expiring"/>}
        {this.circle}
        <div className="upload-close">
          <Icon width={24} height={24} icon="close" color="#555"/>
        </div>
        <div className="upload-preview">
          {isImage &&
          <div className="upload-preview-image-wrap">
            <img
              className="upload-preview-image"
              src={this.link}
            />
            <div onClick={() => this.showFullImage()} className="upload-preview-image-overlay"/>
          </div>
          }
          <div className="upload-preview-info">
            <div className="upload-preview-info-items">
              <div className="upload-preview-info-item">{info.name}</div>
              <div className="upload-preview-info-item">{info.type}</div>
              <div className="upload-preview-info-item">{Common.getMB(info.size / 1000)} MB</div>
            </div>
            <div className="upload-preview-actions">
              <a className="upload-preview-action">
                <Icon icon="link" width={20} height={20} color="#999"/>
                <div className="upload-preview-action-text" onClick={() => this.copyToClipboard()}>Direct Link</div>
              </a>
              <div className="upload-preview-action">
                <Icon icon="link" width={20} height={20} color="#999"/>
                <div className="upload-preview-action-text" onClick={() => this.copyToClipboard()}>Link</div>
              </div>
              <a
                href={`${path}/files/download.php?file=/uploads/${this.uploaded}`}
                target="_blank"
                rel="noopener noreferrer"
                className="upload-preview-action"
              >
                <Icon icon="download" width={20} height={20} color="#999"/>
                <div className="upload-preview-action-text">Download</div>
              </a>
            </div>
          </div>
        </div>
        <div className="upload-circle">
          <Icon icon="fileUpload" color={drag ? '#ff0000' : '#444'} width={160} height={160}/>
          <div className="upload-text">{uploadText}</div>
        </div>
        {drag &&
        <input
          type="file"
          className="upload-input-dragable"
          onChange={(e) => e.target.value && this.uploadImage(e)}
        />
        }
        <input
          value={this.link}
          className="hidden-input"
          ref={(node) => this.copyRef = node}
          onChange={() => true}
        />
      </label>
    );
  }

  render() {
    return (
      <>
        <label className="upload-button">
          <span>Upload</span>
          <input
            type="file"
            className="upload-input"
            onChange={(e) => e.target.value && this.uploadImage(e)}
          />
        </label>
        {this.uploader}
      </>
    );
  }
}

export default UploadFile;
