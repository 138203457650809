import * as React from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const Notification = () => {
  return (
    <IconButton aria-label="cart">
      <StyledBadge badgeContent={4} color="error">
        <Icon>notifications_active</Icon>
      </StyledBadge>
    </IconButton>
  );
};

export default Notification;
