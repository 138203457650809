import React, { useContext, useState } from 'react';

import { F1Context } from '@context/f1Data';

import { Cookie, Common, F1 } from '@functions';

import './style.css';

type Props = {
  onChange: (season: string) => void;
};

const Season = ({ onChange }: Props) => {
  const [value, setValue] = useState(F1.getSeason());

  const [selectedSeason, setSelectedSeason] = useContext(F1Context);

  console.log(selectedSeason);

  const handleChange = (season: string) => {
    setValue(season);
    F1.setSeason(season);

    setSelectedSeason(season);

    setTimeout(() => onChange(season), 50);
  };

  const start = 2020;
  const now = Common.getYear();
  const seasons = [];

  for (let i = start; i <= now; i++) {
    seasons.push(i.toString());
  }

  return (
    <div className="f1-picker">
      {seasons.map((s) => (
        <button
          key={s}
          className={`f1-picker-button ${s === value ? 'active' : ''}`}
          onClick={() => handleChange(s)}
          value={s}
        >
          {s}
        </button>
      ))}
    </div>
  );
};

export default Season;
