import React, { MouseEvent, useEffect, useState } from 'react';
import { endpoints } from '@api/endpoints';
import Mark from '@common/Table/Mark';
import { translate, useTranslate } from '@localise/index';
import { Clip } from '@ts/Clip';
import { History } from 'history';

import API from '../../../../api';
import { Time } from '../../../../functions';
import CatchKeyPress from '../../../common/CatchKeyPress';
import CatchPasting from '../../../common/CatchPasting';
import CopyToClipboard from '../../../common/CopyToClipboard';
import DarkNavigation, { NavConfig } from '../../../common/DarkNavigation';
import Icon from '../../../common/Icon';
import IconButton from '../../../common/IconButton';
import Table from '../../../common/Table';

import styles from './style.scss';

const navigation: NavConfig[] = [
  {
    id: 'dashboard',
    icon: 'list',
    label: 'Dashboard',
  },
  {
    id: 'archived',
    icon: 'flip_to_back',
    variant: 'secondary',
  },
  {
    id: 'deleted',
    icon: 'delete_outline',
    variant: 'secondary',
  },
];

type Props = {
  history: History;
};

const Clipboard = ({ history }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Clip[]>([]);
  const [menuSelected, setMenuSelected] = useState(navigation[0].id);

  const fetchData = async () => {
    setIsLoading(true);

    const data = await API.get(endpoints.clip.list);

    setData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const redirect = (link: string) => window.open(link);
  const onClickRow = (item: Clip) =>
    item.type === 'LINK'
      ? redirect(item.text)
      : redirect(`https://www.google.com/search?q=${item.text}`);

  const deleteClip = async (e: MouseEvent, clip: Clip) => {
    e.stopPropagation();

    try {
      await API.post(endpoints.clip.delete(clip._id));

      await fetchData();
    } catch (error) {
      API.err(error);
    }
  };

  const archiveClip = async (e: MouseEvent, clip: Clip) => {
    e.stopPropagation();

    try {
      await API.post(endpoints.clip.archive(clip._id));

      await fetchData();
    } catch (error) {
      API.err(error);
    }
  };

  const dataFilter = (): Clip[] => {
    switch (menuSelected) {
      case 'dashboard':
        return data.filter((i) => !i.deleted && !i.archived);
      case 'archived':
        return data.filter((i) => i.archived);
      case 'deleted':
        return data.filter((i) => i.deleted);
      default:
        return data;
    }
  };

  const filteredData = dataFilter();

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>
            <Icon size={24} className={styles.headerIcon}>
              model_training
            </Icon>
            <span>{translate.clipboard.title}</span>
          </div>
          <div className={styles.headerText}>
            {useTranslate('clipboard.numberOfClips', { count: data.length })}
          </div>
        </div>
        <div className={styles.container}>
          <DarkNavigation items={navigation} onChange={setMenuSelected} />
          <div className={styles.tableWrapDark}>
            <Table
              hideHeader
              loading={isLoading}
              data={filteredData!}
              onClickRow={onClickRow}
              noDataLabel={translate.clipboard.noClips}
              cells={[
                {
                  id: 'text',
                  width: '100%',
                  render: (data, row) =>
                    row.type === 'LINK' ? (
                      <div className={styles.itemLink}>{data}</div>
                    ) : (
                      <div className={styles.itemText}>{row.text}</div>
                    ),
                },
                {
                  id: 'actions',
                  render: (data, row) => (
                    <div className={styles.itemBack}>
                      <CopyToClipboard text={row.text}>
                        <IconButton size={15} icon="link" />
                      </CopyToClipboard>
                      {!row.deleted && (
                        <IconButton
                          icon={!row.archived ? 'archive' : 'restore'}
                          size={15}
                          onClick={(e) => archiveClip(e, row)}
                        />
                      )}
                      {!row.archived && (
                        <IconButton
                          icon={!row.deleted ? 'remove' : 'restore'}
                          className={styles.removeIcon}
                          size={15}
                          onClick={(e) => deleteClip(e, row)}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  id: 'dateCreated',
                  width: 30,
                  render: (_data, row) => (
                    <Mark className={styles.itemDate}>{Time.concrete(row.createdAt)}</Mark>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
      <CatchPasting onUpdate={fetchData} />
      <CatchKeyPress onRedirectBack onRedirect={history.push} />
    </>
  );
};

export default Clipboard;
