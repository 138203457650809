import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { includes } from 'lodash-es';
import { addDays, isAfter, isBefore } from 'date-fns';
import { Box } from '@mui/material';

import API from '../../../../../api';

import { F1Context } from '../../../../../context/f1Data';

import { F1, User, Common } from '../../../../../functions';
import { translate } from '../../../../../localise';
import { endpoints } from '../../../../../api/endpoints';

import F1Layout from '../../layout';
import Season from '../../components/Season';
import VoteTable from '../../components/VoteTable';
import VotePosition from '../../components/VotePosition';

import f1Image from '../../../../../assets/img/f1.jpg';

import './style.css';

const VOTE_QUALIFYING = 'QUALIFYING';
const QUALIFYING_RESULTS = 'QUALIFYING_RESULTS';
const VOTE_RACE = 'RACE';
const RACE_RESULTS = 'RACE_RESULTS';

export const circuitImage = (id) => `https://dominiklaclavik.com/uploads/f1/circuits/${id}.png`;

const Voting = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isVotingRaceVisible, setIsVotingRaceVisible] = useState(false);
  const [isSettingResultsOpen, setIsSettingResultsOpen] = useState(false);
  const [previousRaces, setPreviousRaces] = useState(null);
  const [currentRace, setCurrentRace] = useState(null);
  const [votes, setVotes] = useState(null);
  const [expandedItems, setExpandedItems] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const [autoVoteLoading, setAutoVoteLoading] = useState(false);

  const [data] = useContext(F1Context);

  const { driverRankings: drivers, circuits } = data;

  const users = data.users
    ? data.users.filter(
        (u) => User.hasRight('f1:vote', u) && (u.userKey !== 'jj' || data.season !== 2023),
      )
    : [];

  const user = User.hasAccess();

  const fetchData = async () => {
    setDataLoaded(false);

    const season = F1.getSeason();

    try {
      const races = await API.post(endpoints.f1.races.search, { season });

      const futureRaces = races.filter((r) =>
        isAfter(addDays(new Date(r.raceDate), 1), new Date()),
      );
      const previousRaces = races.filter((r) =>
        isBefore(addDays(new Date(r.raceDate), 1), new Date()),
      );

      const currentRace = window.location.hash.includes('lastRace')
        ? Common.sort(previousRaces, 'raceDate')[previousRaces.length - 1]
        : Common.sort(futureRaces, 'raceDate')[0];

      if (currentRace) {
        const votes = await API.post(endpoints.f1.votes.search, { season, race: currentRace._id });

        setVotes(votes);
      } else {
        setVotes([]);
      }

      setPreviousRaces(previousRaces);
      setCurrentRace(currentRace);
      setDataLoaded(true);

      const votes = await API.post(endpoints.f1.votes.search, { season });

      setVotes(votes);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchVotes = async () => {
    const season = F1.getSeason();

    try {
      const votes = await API.post(endpoints.f1.votes.search, { season });
      setVotes(votes);
    } catch (err) {
      console.error(err);
    }
  };

  const onAutoVote = async ({ race, user, type }) => {
    setTimeout(() => setAutoVoteLoading(true));

    const expectedPositions = [
      { driverId: 20, driverName: 'Lewis Hamilton', position: 1, type },
      { driverId: 25, driverName: 'Max Verstappen', position: 2, type },
      { driverId: 51, driverName: 'George Russell', position: 3, type },
      { driverId: 10, driverName: 'Sergio Perez', position: 4, type },
      { driverId: 49, driverName: 'Lando Norris', position: 5, type },
    ];

    await Promise.all(
      expectedPositions.map((c) => API.post(endpoints.f1.votes.add, { ...c, race, user })),
    )
      .then(() => {
        fetchVotes();
        setTimeout(() => setAutoVoteLoading(false), 10000);
      })
      .catch((err) => API.err(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!dataLoaded) {
    return <F1Layout loading={!dataLoaded} />;
  }

  const renderQualifying = (race, disableVote) => {
    const voted = votes.some(
      (v) => v.race._id === race._id && v.type === VOTE_QUALIFYING && v.user === user,
    );
    const hasResults = votes.some((v) => v.race._id === race._id && v.type === QUALIFYING_RESULTS);

    if (voted || disableVote) {
      return (
        <>
          <VoteTable
            race={race}
            votes={votes}
            type={VOTE_QUALIFYING}
            users={users}
            editMode={editMode}
            onUpdate={fetchVotes}
          />
          {User.hasRight('f1:edit') &&
            !hasResults &&
            (isSettingResultsOpen ? (
              <VotePosition
                drivers={drivers}
                type={QUALIFYING_RESULTS}
                race={race}
                onUpdate={fetchVotes}
                positions={7}
              />
            ) : (
              <button className="f1-button-tertiary" onClick={() => setIsSettingResultsOpen(true)}>
                {
                  translate.f1.voting.action[
                    currentRace &&
                      (currentRace.sprintFormat ? 'setSprintResults' : 'setQualifyingResults')
                  ]
                }
              </button>
            ))}
        </>
      );
    }

    return (
      <VotePosition drivers={drivers} type={VOTE_QUALIFYING} race={race} onUpdate={fetchVotes} />
    );
  };

  const renderRace = (race, disableVote) => {
    const voted = votes.some(
      (v) => v.race._id === race._id && v.type === VOTE_RACE && v.user === user,
    );
    const hasResults = votes.some((v) => v.race._id === race._id && v.type === RACE_RESULTS);
    const hasQualifyingResults = votes.some(
      (v) => v.race._id === race._id && v.type === QUALIFYING_RESULTS,
    );

    if (voted || disableVote) {
      return (
        <>
          <VoteTable
            race={race}
            votes={votes}
            type={VOTE_RACE}
            users={users}
            editMode={editMode}
            onUpdate={fetchVotes}
          />
          {User.hasRight('f1:edit') &&
            !hasResults &&
            hasQualifyingResults &&
            (isSettingResultsOpen ? (
              <VotePosition
                drivers={drivers}
                type={RACE_RESULTS}
                race={race}
                onUpdate={fetchVotes}
                positions={7}
              />
            ) : (
              <button className="f1-button-tertiary" onClick={() => setIsSettingResultsOpen(true)}>
                {translate.f1.voting.action.setRaceResults}
              </button>
            ))}
        </>
      );
    }

    return (
      <>
        {!isVotingRaceVisible && !hasQualifyingResults && (
          <button className="f1-button-secondary" onClick={() => setIsVotingRaceVisible(true)}>
            {translate.f1.voting.action.addRaceTipsPrematurely}
          </button>
        )}
        {(isVotingRaceVisible || hasQualifyingResults) && (
          <VotePosition
            drivers={drivers}
            type={VOTE_RACE}
            race={currentRace}
            onUpdate={fetchVotes}
          />
        )}
      </>
    );
  };

  return (
    <F1Layout>
      <Season
        onChange={() => {
          fetchData();
          setExpandedItems([]);
        }}
      />
      {!currentRace && !previousRaces.length && (
        <div className="f1-season-wrap">
          <img className="f1-season-image" src={f1Image} alt="season" />
          <div className="f1-season-text">{translate.f1.voting.seasonInPreparation}</div>
        </div>
      )}
      {currentRace && (
        <div className="current-session">
          <div className="f1-session-subtitle">{translate.f1.voting.currentSession}</div>
          <div className="f1-session-title">{currentRace.place}</div>
          <div>
            <div className="f1-session-block">
              {renderRace(currentRace)}
              {renderQualifying(currentRace)}
            </div>
            {User.hasRight('f1:edit') && (
              <Box>
                <div className="f1-session-edit">
                  <button className="f1-button-admin" onClick={() => setEditMode(!editMode)}>
                    {editMode
                      ? translate.f1.voting.action.cancelEditing
                      : translate.f1.voting.action.editVotesOrResults}
                  </button>
                  {/** <button
                    disabled={autoVoteLoading}
                    className="f1-button-admin"
                    onClick={() =>
                      onAutoVote({
                        race: currentRace._id,
                        user: '61686769adbde92bef30e448',
                        type: votes.some(
                          (v) => v.race._id === currentRace._id && v.type === QUALIFYING_RESULTS,
                        )
                          ? 'RACE'
                          : 'QUALIFYING',
                      })
                    }
                  >
                    Honza Autovote
                  </button> **/}
                </div>
              </Box>
            )}
          </div>
        </div>
      )}
      {previousRaces.length && previousRaces[0]?._id !== currentRace?._id && (
        <div className="previous-sessions">
          <div className="f1-session-subtitle">{translate.f1.voting.previousSession}</div>
          {previousRaces.map((race) => {
            const circuit = circuits.find((c) => c.id === race.circuit);

            if (!circuit) return <div />;

            const imageUrl = circuit && circuitImage(circuit.id);
            const isExpanded = includes(expandedItems, race._id);

            const onExpand = () => {
              let items = [...expandedItems];
              const index = items.findIndex((i) => i === race._id);

              if (index !== -1) {
                items.splice(index, 1);

                setExpandedItems(items);
              } else {
                items = [...items, race._id];

                setExpandedItems(items);
              }
            };

            return (
              <div className="f1-session-wrap" key={race._id}>
                <div className="f1-session">
                  <div className="f1-session-header">
                    <div className="f1-session-race-info">
                      <div>
                        <div className="f1-session-race-country">
                          {circuit.competition.location.country}
                        </div>
                        <div className="f1-session-race-place">{race.place}</div>
                        <div className="f1-session-race-city">
                          {circuit.competition.location.city}
                        </div>
                      </div>
                      <div>
                        <div className="f1-session-race-place">
                          {circuit.length || translate.unknown}
                        </div>
                      </div>
                    </div>
                    <div className="f1-session-race-image-wrap">
                      <img src={imageUrl} className="f1-session-race-image" />
                    </div>
                  </div>
                  <div className={classNames('f1-session-results', { hidden: !isExpanded })}>
                    {renderRace(race, true)}
                    {renderQualifying(race, true)}
                  </div>
                  <button className="f1-session-expander" onClick={onExpand}>
                    {translate.f1.voting.expandView[!isExpanded ? 'show' : 'hide']}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </F1Layout>
  );
};

Voting.propTypes = {
  drivers: PropTypes.array,
};

export default Voting;
