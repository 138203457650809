import React, { useState, useEffect, useRef } from 'react';
import { West } from '@mui/icons-material';
import classNames from 'classnames';
import { Common } from '../../../functions';
import { lowerCase, sortBy, upperFirst } from 'lodash-es';
import projects, { projectKeys } from '../../../assets/projects';
import PageWrap from '../../utils/PageWrap';
import Icon from '../../common/SVG';
import hooks from '@functions/hooks';

import styles from './style.scss';

type Project = {
  id: string;
  type: string;
  label: string;
  year?: number;
  link?: string;
  src?: string;
};

type ProjectWithSetting = Project & {
  show: boolean;
};

const Projects = () => {
  const [data, setData] = useState<null | Record<string, ProjectWithSetting[]>>(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unmount, setUnmount] = useState<string | null>(null);
  const [currIndex, setCurrIndex] = useState(0);
  const [currProject, setCurrProject] = useState(0);

  const ref = useRef(null);

  const scrollY = hooks.useScrollY(ref?.current);
  const activeGapMin = 10;

  const activeScroll = scrollY > activeGapMin;

  useEffect(() => {
    const allProjects = sortBy(
      projects.filter((p) => !p.disabled),
      'year',
    ).reverse();

    const divideTo: Record<string, ProjectWithSetting[]> = {};

    projectKeys.forEach((t) => (divideTo[t] = []));
    allProjects.forEach((p) => divideTo[p.type].push({ ...p, show: false } as ProjectWithSetting));

    setData(divideTo);

    const intervalId = setInterval(() => {
      if (!dataLoaded) {
        nextProject();
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [dataLoaded]);

  const nextProject = () => {
    // Check if there is something to show
    if (!data) return;

    // Get project type to render
    const key = projectKeys[projectKeys.length - currProject - 1];

    // Clone the projects state
    const updatedProjects = { ...data };

    // Show project
    updatedProjects[key][currIndex].show = true;

    // If it's the last in the array, jump to the next project type
    if (updatedProjects[key].length === currIndex + 1) {
      if (projectKeys.length === currProject + 1) {
        setDataLoaded(true);
      }

      setData(updatedProjects);
      setCurrProject(currProject + 1);
      setCurrIndex(0);
      return;
    }

    // Move to the next index
    setData(updatedProjects);
    setCurrIndex(currIndex + 1);
  };

  const label = (text: string, index?: number) => {
    if (index !== undefined) {
      return `0${index + 1}. ${upperFirst(lowerCase(text))}`;
    }

    return upperFirst(lowerCase(text));
  };

  const projectsList = () => {
    if (!data) return null;

    return Object.keys(data)
      .reverse()
      .map((key, index) => (
        <div className={styles.row} key={key}>
          <div className={styles.projectSection}>{label(key, index)}</div>
          <div className={styles.projects} key={`p-${key}`}>
            {data[key].map(project)}
          </div>
        </div>
      ));
  };

  const project = (item: ProjectWithSetting) => {
    // if (!item.show) return null;

    return (
      <div
        className={classNames(styles.project, !item.link && styles.error)}
        key={item.id}
        onClick={() => (item.link ? Common.openInNewTab(item.link) : false)}
      >
        <div className={styles.projectImage}>
          <img src={item.src} alt={item.src} className={styles.projectImg} />
        </div>
        <div className={styles.projectName}>{label(item.label || item.id)}</div>
        <div className={styles.projectType}>
          <Icon icon="tag" color="#ccc" height="12" width="12" />
          <span className={styles.projectTypeText}>{label(item.type)}</span>
        </div>
      </div>
    );
  };

  return (
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    <PageWrap unmount={unmount}>
      <div className={styles.wrap}>
        <div className={classNames(styles.side, activeScroll && styles.minified)}>
          <div className={styles.title}>Projects</div>
          <div className={styles.goBackWrap}>
            <button id="go-back" className={styles.goBack} onClick={() => setUnmount('/')}>
              <West fontSize="small" className={styles.goBackArrow} />
              Back to homepage
            </button>
          </div>
        </div>
        <div className={styles.body} ref={ref}>
          {projectsList()}
        </div>
      </div>
    </PageWrap>
  );
};

export default Projects;
