import React, { useState } from 'react';
import { ButtonBase } from '@mui/material';
import classNames from 'classnames';

import styles from './style.scss';

export type NavConfig = {
  label?: string;
  value: string;
};

type Props = {
  items: NavConfig[];
  onChange: (id: string) => void;
  identifier?: string;
};

const DarkMultiPicker = ({ items, onChange, identifier }: Props) => {
  const storageKey = `pick-${identifier}`;
  const [activeId, setActiveId] = useState(
    identifier ? localStorage.getItem(storageKey) || items[0].value : items[0].value,
  );

  const activeIndex = items.findIndex((item) => item.value === activeId);
  const left = (100 / items.length) * activeIndex + '%';

  return (
    <div className={styles.toolbar}>
      <div className={styles.toolbarBody}>
        <div className={styles.slider} style={{ width: 100 / items.length + '%', left: left }} />
        {items.map((item, i) => (
          <ButtonBase
            key={i}
            onClick={() => {
              onChange(item.value);
              setActiveId(item.value);
              localStorage.setItem(storageKey, item.value);
            }}
            classes={{
              root: classNames(styles.toolbarButton, activeIndex === i && styles.active),
            }}
          >
            {item.label}
          </ButtonBase>
        ))}
      </div>
    </div>
  );
};

export default DarkMultiPicker;
