import React, { useEffect, useState } from 'react';

import API from '@api/index';

import { General } from '@functions';

import { endpoints } from '@api/endpoints';

import PageWrap from '../../../../utils/PageWrap';

import CatchKeyPress from '@common/CatchKeyPress';

import List, { ListItem } from '@common/List';

import './style.css';

type Category = {
  name: string;
  type: string;
  id: string;
  _id: string;
};

const Visions = () => {
  const [unmount, setUnmount] = useState<string | null>(null);
  const [types, setTypes] = useState<Category[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await API.get(endpoints.vision.type.list);

        setTypes(data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <PageWrap unmount={unmount}>
      <div className="visions-wrap">
        <div className="visions-half">
          <div className="visions-text">Dominik Láclavík</div>
          <div className="visions-text">
            <div>Visions</div>
            <button
              className="visions-text-extend"
              onClick={() => setUnmount('/version#module=vision')}
            >
              {General.version({ module: 'vision' })}
            </button>
          </div>
        </div>
        <div className="visions-half end">
          <div />
          <List
            data={types as ListItem[]}
            onItemClick={(item) => setUnmount(`/vision/${(item as Category)._id}`)}
          />
          <div />
        </div>
      </div>
      <CatchKeyPress
        onRedirect={setUnmount}
        onRedirectBack
        items={types.map((t) => ({ ...t, path: `/vision/${t._id}` }))}
      />
    </PageWrap>
  );
};

export default Visions;
