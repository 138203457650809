import { imgPath } from '@api/api';

const config = {
  // skillIcons: ['html', 'css', 'js'],
  quotes: [
    'There is never a good time to do a wrong thing and there is never a wrong time to do a right thing',
    'Every day is another chance to change your life',
    'Dreams without goals are just dreams',
    "If you don't read the newspapers you're uninformed, if you do read it you're misinformed",
  ],
  aboutMe:
    "I'm a passionate mountain explorer and avid photographer, capturing the beauty of nature both digitally and on analog film. With a deep appreciation for life's fleeting moments, I savor every experience along the way. By profession, I'm a dedicated programmer, but my heart belongs to the mountains and the art of storytelling through photography.",
  aboutExploring:
    "Traveling isn't just an interest; it's a profound passion that defines me. Year after year, I find myself drawn to the magnificent peaks of the Alps and the Dolomites, making them my cherished destinations. I've also ventured far and wide to places like Iceland and Norway, captivated by their stunning landscapes.\n" +
    '\n\n' +
    'As I look to the future, my wanderlust is set on exploring North America, New Zealand, and Scotland. Yet, amidst these exciting prospects, the mountains of my homeland in Slovakia remain my ultimate sanctuary. Their enduring beauty and timeless allure continue to beckon me, emphasizing the deep connection I have with these natural wonders.',
  timeline: [
    {
      year: 2022,
      projects: [
        {
          title: 'Iceland road trip',
          description:
            'Embark on an unforgettable journey through the breathtaking landscapes of Iceland as I share my 21-day adventure on this captivating island. With a borrowed car as my trusty companion, I traversed over 4600 kilometers, exploring every corner of this remarkable country.\n' +
            '\n' +
            "From the mesmerizing glaciers to the dramatic waterfalls, Iceland's natural beauty left me in awe at every turn. But what truly sets my travel experience apart is the stunning footage captured from above. Most of the visuals in my video were taken by a drone, providing a unique and mesmerizing perspective of Iceland's stunning terrain.\n" +
            '\n' +
            "Join me on this epic road trip as we uncover the hidden gems, encounter the friendly locals, and witness the magic of Iceland through my lens. It's a journey you won't want to miss.",
          // src: imgPath + '/about/island-project.png',
          // link: 'https://drive.google.com/open?id=1o67Twye3_kMJWlc2cybZGYCplTk5O-sL&usp=sharing',
          label: 'Iceland road trip map with waypoints',
          videoId: 'gF2uNs-XrT8',
        },
      ],
    },
    {
      year: 2019,
      projects: [
        {
          title: 'EU Road trip',
          description:
            "Embark on a captivating European journey through Germany, the Netherlands, France, Switzerland, Italy, and Austria. We explored by car, taking in Germany's scenic villages and the Netherlands' iconic windmills and canals. In France, Paris's elegance and a visit to Disneyland added magic to our journey.\n" +
            '\n' +
            "As we ventured deeper into Europe, we encountered nature's wonders, from tranquil Swiss lakes to stunning waterfalls. The Swiss Alps beckoned us for thrilling hikes with breathtaking views. Finally, Italy and Austria welcomed us with their rich culture and delicious cuisine.\n" +
            '\n' +
            'Experience this unforgettable journey, filled with diverse landscapes, amazing moments, and the joy of exploration.',
          src: imgPath + '/about/eu-trip.jpg',
          link: 'https://drive.google.com/open?id=1TVKfMO3AHODi4YhUlgQRvVgaYWhA2Jrn&usp=sharing',
          label: 'EU trip',
        },
      ],
    },
    {
      year: 2018,
      projects: [
        {
          title: 'Norway road trip',
          description:
            "My 14-day road trip across Norway was an incredible journey through stunning landscapes and charming cities. We covered over 8000 kilometers, passing through six European countries before finally reaching Norway. While we explored cities like Stavanger, Bergen, and Oslo, the true highlights were the natural wonders.\n\nThe fjords, with their majestic cliffs and clear waters, were awe-inspiring. However, the absolute best experiences were the hikes in the Lofoten Islands, offering breathtaking views that were simply unforgettable. Norway's unique beauty made every mile driven worth it, creating cherished memories of our trip.",
          src: imgPath + '/about/book.png',
          label: 'Norway trip',
        },
      ],
    },
    {
      year: 2017,
      projects: [
        {
          title: 'Alps',
          description:
            "Our road trip to the Alps has become a beloved tradition, and this year marked our second adventure to these breathtaking mountains. The journey began with an exhilarating drive along the winding roads to Grossglockner, setting the tone for the adventure ahead. Most of our time was spent exploring the Dolomites, where hiking took center stage.\n\nHighlights included the stunning views at Seceda, the iconic Tre Cime, and the tranquility of Lago di Bries. As we returned to these familiar peaks, we couldn't help but be captivated once again by the raw beauty of the Alps, making us eager for our next annual visit.",
          videoId: 'ZUAOmUkoMTs',
          label: 'Alps road trip',
        },
        {
          title: 'Web inspiration project',
          description:
            "Every creation and endeavor I've undertaken has consistently propelled me forward, leading to fresh ideas and newfound inspiration. It took me a few years of dedicated effort to reach desired inspiration. With each addition I make to the site, my motivation only deepens, fueling my creativity.\n\nMy sole aim is to share my work and encourage you to embark on your own journey of crafting something exceptional, something that surpasses your own expectations.",
          videoId: '7ImjCewjbOo',
          label: 'Web inspiration',
        },
      ],
    },
    {
      year: 2016,
      projects: [
        {
          title: 'Alps',
          description:
            "Our road trip through the Alps was first long adventure. We began with the scenic journey to Grossglockner, taking in the stunning mountainous landscapes along the way. However, the heart of our trip was in the Dolomites, where we spent most of our time hiking amidst the picturesque surroundings.\n\nWe explored some of the biggest valleys, including Ultental, Marteltal, Val Senales, and Sulden, each offering its own unique charm and natural beauty. As our journey came to an end, we couldn't resist the allure of the breathtaking Passo dello Stelvio, adding the perfect finishing touch to our unforgettable road trip through the northern Italy.",
          videoId: 'UeCwsuqQ9u8',
          label: 'Alps Trip',
        },
      ],
    },
    {
      time: 'Future',
      small: true,
    },
  ],
};

export default config;
