import React, { Component } from 'react';
import { includes } from 'lodash-es';

import API from '../../../../api';

import { Common, Cookie, Hash } from '../../../../functions';

import PageWrap from '../../../utils/PageWrap';
import UploadFile from './UploadFile';
import Icon from '../../../common/SVG';
import File from './File';

import settings from './driveSettings';

import './style.css';

class Files extends Component {
  state = {
    data: null,
    unmount: false,
    drag: false,
    activeSkill: 0,
    percentage: 0,
    path: '/',
  };

  componentDidMount() {
    this.checkAccessKey();

    this.loadData(Common.getQueryParam('path') || undefined);

    this.newFile.addEventListener('dragenter', () => this.setState({ drag: true }));
    this.newFile.addEventListener('dragleave', () => this.setState({ drag: false }));
  }

  componentWillUnmount() {
    this.newFile.removeEventListener('dragenter', () => this.setState( { drag: true }));
    this.newFile.removeEventListener('dragleave', () => this.setState({ drag: false }));
  }

  async loadData(path = '/') {
    try {
      const data = await API.get(`/files/list.php?path=${path}`);
      const stats = await API.get(`/files/stats.php`);

      this.setState({ path });

      const files = data.files.filter((f) => includes(f, '.'));
      const folders = data.files.filter((f) => !includes(f, '.'));

      this.setState({ files, folders, stats });
    } catch (e) {
      API.err(e);
    }
  }

  get accessKey() {
    return !!Cookie.get('accessKey');
  }

  checkAccessKey() {
    if (Hash.build().accessKey) {
      Cookie.set('accessKey', Hash.build().accessKey);
    }
  }

  goBack() {
    const divide = this.state.path.split('/').filter(Boolean);

    if (divide.length === 1) {
      this.loadData('/');
    } else {
      this.loadData(`/${divide.slice(0, divide.length - 1).join('/')}`);
    }
  }

  get space() {
    const { stats } = this.state;

    const taken = Common.getMB(stats ? (stats.taken) : 0);

    return {
      taken,
      free: settings.spaceTotal - taken,
      total: settings.spaceTotal,
      takenPercentage: taken / settings.spaceTotal * 100,
    };
  }

  items(type) {
    const { path } = this.state;

    if (!this.state[type]) {
      return null;
    }

    return this.state[type].map((file, index) => {
      const newPath = `${path}${path.length > 1 ? '/' : ''}${file}`;

      const onRedirect = () => {
        this.setState({ files: [], folders: [] });
        this.loadData(newPath);
      };
      const onRemove = () => this.removeFile(file);

      return <File
        file={file}
        path={path}
        onRemove={onRemove}
        onRedirect={onRedirect}
        key={index}
        withAccess={this.accessKey}
      />;
    });
  }

  async removeFile(file) {
    let path = this.state.path;

    if (path.length > 1) {
      path += '/';
    }

    try {
      await API.get(`/files/delete.php?file=${path}${file}`);
      this.loadData(this.state.path);
    } catch (e) {
      API.err(e);
    }
  }

  render() {
    const { unmount, path } = this.state;

    return (
      <PageWrap unmount={unmount}>
        <div className="files-wrap" ref={(node) => this.newFile = node}>
          <div className="row">
            <div className="files-header">
              {path !== '/' &&
              <div className="files-header-arrow" onClick={() => this.goBack()}>
                <Icon icon="arrowBack" width={20} height={20}/>
              </div>
              }
              <div className="files-title">Custom file storage {path}</div>
            </div>
            <div className="row">
              <div className="upload-stats">
                <div className="upload-stat-space">
                  <div className="upload-stat-bar">
                    <div className="upload-stat-bar-active" style={{ width: `${this.space.takenPercentage}%` }}/>
                  </div>
                  <div className="upload-stat-info">
                    <div className="upload-stat-info-taken">{this.space.taken} MB</div>
                    <div className="upload-stat-info-taken">{this.space.total} MB Total</div>
                  </div>
                </div>
              </div>
              <UploadFile callBack={() => this.loadData()} drag={this.state.drag} path={path}/>
            </div>
          </div>
            <div className="files">
              {this.items('folders')}
              {this.items('files')}
            </div>
            {/** <div className="files-no-access">
              <div>Restricted access</div>
            </div> **/}
        </div>
      </PageWrap>
    );
  }
}

export default Files;
