import { ImageContentItem } from '@pages/AnalogPortfolio/ImagesContent';

const alps = {
  getImage: (image: string) =>
    `https://dominiklaclavik.com/assets/images/portfolio/analog/alps-analog-${image}.jpg`,
  items: [
    {
      id: 'dolomites',
      title: 'Dolomites',
      images: [
        {
          image: '21',
          title: 'Croda da Lago',
          description: 'Place in the heart of Dolomites',
          type: 'WIDTH',
          infoPosition: 'BEHIND',
          priority: 1,
        },
        {
          image: '37',
          title: 'Tre cime',
          description:
            'Probably most popular place in dolomites available by car with easy hikes around',
          type: 'HEIGHT',
        },
        { image: '19', type: 'HEIGHT' },
        { image: '13', type: 'HEIGHT' },
        { image: '36', type: 'HEIGHT' },
        {
          image: '33',
          type: 'HEIGHT',
          title: 'Dürrenstein\nPrato Piazza',
          description: 'Place in south part of dolomites, less people.',
        },
        { image: '28', type: 'HEIGHT' },
        {
          image: '29',
          type: 'HEIGHT',
          title: 'Misurina',
          description: 'Near by village where road to Tre Cime starts - heart of dolomites.',
        },
        {
          image: '16',
          type: 'WIDTH',
        },
      ],
    },
    {
      id: 'sudtirol',
      title: 'Südtirol',
      images: [
        {
          image: '9',
          title: 'Marteltal',
          description:
            'Very unique place with breathtaking views, including waterfalls, canon and lot of hikes around, most popular is hike to Marteller Hůtte (2610m.n.m).\n',
          type: 'WIDTH',
          infoPosition: 'BEHIND',
          priority: 1,
        },
        { image: ['6', '5', '4', '8'], type: 'GRID' },
        {
          image: '3',
          type: 'HEIGHT',
        },
        {
          image: '7',
          type: 'FULLWIDTH',
        },
      ],
    },
    {
      id: 'austrianAlps',
      title: 'Austrian alps',
      images: [
        {
          image: '24',
          title: 'Grossglockner High Alpine Road',
          description:
            'This road to the Dolomites feels like a path to heaven. Whenever it’s open, it’s our route of choice through the Alps.',
          type: 'HEIGHT',
          infoPosition: 'BEHIND',
          params: ['LEFT'],
          priority: 1,
        },
        {
          image: '23',
          type: 'WIDTH',
        },
        {
          image: '25',
          type: 'WIDTH',
        },
        {
          image: '20',
          type: 'HEIGHT',
          description: 'Few photo stops on road to grossgnocner glacier',
          infoPosition: 'BEHIND',
        },
        {
          image: '31',
          type: 'HEIGHT',
        },
      ],
    },
  ] as ImageContentItem[],
};

const homeland = {
  getImage: (image: string) =>
    `https://dominiklaclavik.com/assets/images/portfolio/analog/home-analog-${image}.jpg`,
  items: [
    {
      id: 'austrianAlps',
      title: '',
      images: [
        {
          image: '1',
          type: 'WIDTH',
        },
        {
          image: ['2', '3'],
          widths: ['32%', '65%'],
          type: 'CUSTOM_FULLWIDTH',
        },
        {
          image: '19',
          type: 'WIDTH',
        },
        {
          image: '17',
          type: 'WIDTH',
        },
        {
          image: '18',
          type: 'WIDTH',
        },
        {
          image: ['15', '16'],
          type: 'WIDTH',
        },
        {
          image: '10',
          type: 'WIDTH',
        },
        {
          image: '11',
          type: 'WIDTH',
        },
      ],
    },
  ],
};

const iceland = {
  getImage: (image: string) =>
    `https://dominiklaclavik.com/assets/images/portfolio/analog/iceland-analog-${image}.jpg`,
  items: [
    {
      id: 'landmannalaugar',
      title: 'Landmannalaugar',
      options: {
        centerTitle: true,
      },
      images: [
        {
          image: ['102', '96'],
          widths: ['32%', '65%'],
          type: 'CUSTOM_FULLWIDTH',
          priority: 1,
        },
        {
          image: ['99', '103'],
          widths: ['65%', '32%'],
          type: 'CUSTOM_FULLWIDTH',
        },
        {
          image: ['121', '113'],
          widths: ['32%', '65%'],
          type: 'CUSTOM_FULLWIDTH',
        },
        {
          image: ['127', '124', '110', '109'],
          widths: ['22%', '22%', '22%', '22%'],
          type: 'CUSTOM_FULLWIDTH',
        },
        {
          image: '122',
          title: 'RAINBOW MOUNTAIN IS BEAUTIFUL PLACE FULL OF COLORS WITH EASY HIKES',
          type: 'HEIGHT',
          infoPosition: 'BEHIND',
          // params: ['LEFT'],
          priority: 1,
        },
        {
          image: '98',
          type: 'WIDTH',
        },
      ],
    },
  ],
};

export default {
  alps,
  iceland,
  homeland,
};
