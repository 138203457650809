import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { path as apiPath } from '../../../../../api/api';

import { Image } from '../../../../../functions';

import CopyToClipboard from '../../../../common/CopyToClipboard';
import TinyButton from '../../../../common/TinyButton';
import Loading from '../../../../common/Loading';
import Menu from '../../../../common/Menu';
import Icon from '../../../../common/SVG';

import './style.css';

const File = ({ onRemove, file, withAccess, path, onRedirect, history }) => {
  const [copied, setCopied] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuButtonRef = useRef(null);

  const settings = Image.info({ path, file });

  useEffect(() => setIsLoaded(!settings.isImage), []);

  const image = (
    <>
      <img
        src={settings.src}
        className={`file-preview-image ${isLoaded ? 'loaded' : ''}`}
        onLoad={() => setIsLoaded(true)}
      />
      {!isLoaded && <Loading/>}
    </>
  );

  const size = 80;
  const cut = settings.pathname.length > 50 ? settings.pathname.slice(0, 50) : settings.pathname;

  const content = (
    <>
      <div className="file-image">
        {settings.isImage ?
          image
          : <Icon icon={settings.isFolder ? 'folder' : 'file'} color="#363636" width={size} height={size}/>
        }
        {settings.isImage && <div className="file-image-overlay"/>}
        {!settings.isImage && <div className="file-type">{settings.fileType}</div>}
      </div>
      <div className={`file-name ${!settings.fileType ? 'folder' : ''}`}>
        <div className="file-name-text">{cut}</div>
      </div>
      {settings.fileType &&
      <div className="file-info">
        <TinyButton className="file-type-mini">
          {settings.fileType.slice(0, 4)}
        </TinyButton>
        <div className="file-info-row">
          <CopyToClipboard text={settings.src} onCopied={() => setCopied(true)}>
            <TinyButton active={copied} onClick={() => true}>
              <Icon icon="link" width={16} height={10} color={copied ? '#3895D3' : '#777'}/>
            </TinyButton>
          </CopyToClipboard>
          {withAccess &&
            <div ref={menuButtonRef}>
              <TinyButton
                className="file-button-margin"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setIsMenuOpen(!isMenuOpen);
                }}
              >
                <span>•••</span>
                <Menu
                  isOpen={isMenuOpen}
                  attachRef={menuButtonRef}
                  onClose={() => setIsMenuOpen(false)}
                  items={[
                    { id: 'remove', onClick: () => onRemove(settings), label: 'Remove', icon: 'remove' },
                  ]}
                />
              </TinyButton>
            </div>
          }
        </div>
      </div>
      }
    </>
  );

  /** Image **/

  if (settings.isImage) {
    return <div className="file" onClick={() => history.push(`/file${path}/${file}`)}>{content}</div>;
  }

  /** Folder **/
  if (settings.isFolder) {
    return <div className="file" onClick={onRedirect}>{content}</div>;
  }

  /** Other files - direct download **/
  return (
    <a
      className="file" href={`${apiPath}/files/download.php?file=/uploads${path}${file}`}
      rel="noopener noreferrer"
      target="_blank"
    >{content}</a>
  );
};

File.propTypes = {
  history: PropTypes.object.isRequired,
  file: PropTypes.string,
  path: PropTypes.string,
  onRedirect: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  withAccess: PropTypes.bool,
};

export default withRouter(File);
