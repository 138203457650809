import React, { useContext, useState } from 'react';

import API from '@api/index';
import { F1Context } from '@context/f1Data';
import { endpoints } from '@api/endpoints';
import { Common } from '@functions';

import F1Layout from '@pages/F1/layout';
import Button from '@pages/F1/components/Button';

import NewEvent from './NewEvent';

import { ApiError } from '@ts/Api';

import './style.css';

const Admin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const [message, setMessage] = useState(false);

  const season = Common.getYear().toString();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setSeason, setDataLoaded] = useContext(F1Context);

  const updateData = async () => {
    setLoading(true);

    try {
      await API.post(endpoints.f1.data.refresh, { season: season });

      setDataLoaded(false);
      setMessage(true);
    } catch (err) {
      setMessage(false);
      setError(err);
    }

    setLoading(false);
  };

  return (
    <F1Layout>
      <NewEvent season={season} />
      <div className="f1-admin-body">
        <div className="f1-admin-update main">
          <Button
            onClick={updateData}
            loading={loading}
            disabled={loading}
            className="f1-admin-update-button"
          >
            Update data from provider
          </Button>
        </div>
        <div className="f1-admin-update-message">
          {message ? 'Data updated' : ''}
          {error}
        </div>
      </div>
    </F1Layout>
  );
};

export default Admin;
