import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Common, Cookie } from '@functions';

import { F1Context } from '@context/f1Data';

import { teamImages } from '../../data';

import F1Layout from '../../layout';
import Season from '../../components/Season';
import Team from './Team';

import { F1Team } from '@ts/F1';

import './style.css';

const Teams = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [season, setSeason] = useState(Cookie.get('f1-season') || Common.getYear());

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [data] = useContext(F1Context);

  const { teamRankings } = data;

  return (
    <F1Layout withoutAccess>
      <Season onChange={setSeason} />
      <div className="f1-teams-wrap">
        {(teamRankings || []).map((ranking: F1Team) => {
          const image = teamImages.find((t) => t.teamId === ranking.team?.id);

          if (!image) return;

          return <Team key={ranking.team.id} team={{ ...ranking, image }} />;
        })}
      </div>
    </F1Layout>
  );
};

export default Teams;
