import React, { useEffect, useState } from 'react';

import { Fade } from '@mui/material';

import API from '../../../../../api';

import GamesLayout from '../../layout';
import SessionWrap from '../../components/SessionWrap';

import './style.css';
import { User } from '../../../../../functions';

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // const [season, setSeason] = useState(Common.getYear().toString());
  const [currentGameSession, setCurrentGameSession] = useState(null);

  const [data, setData] = useState(null);

  const gamingDayEdit = User.hasRight('games:edit');

  const loadData = async () => {
    try {
      const gameSession = await API.get('/anim/game-sessions/current');

      setCurrentGameSession(gameSession);
    } catch (err) {
      // No handle
    }

    try {
      const gameTypes = await API.get('/anim/game-types');

      setData({ gameTypes });
    } catch (err) {
      setError(error);
    }
  };

  const startSession = async () => {
    if (loading) return;

    try {
      setLoading(true);

      const gameSession = await API.post('/anim/game-sessions', { season: 1, session: 24 });

      setCurrentGameSession(gameSession);
    } catch (err) {
      setError(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (gamingDayEdit) {
      loadData();
    }
  }, []);

  return (
    <GamesLayout menuHidden={loading || !!currentGameSession} noPadding>
      <Fade in timeout={{ enter: 1250 }}>
        {gamingDayEdit ?
          <div className="games-dashboard">
            <div className="games-dashboard-title">
              Gaming day
            </div>
            <SessionWrap
              onStart={startSession}
              gameSession={currentGameSession}
              onUpdate={(s) => setCurrentGameSession(s)}
            />
            <div/>
          </div>
          :
          <div className="games-dashboard">
            <div className="games-dashboard-title">
              Welcome
            </div>
          </div>
        }
      </Fade>
    </GamesLayout>
  );
};

export default Dashboard;
