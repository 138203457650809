import React, { useContext, useState } from 'react';
import { includes, uniq, sortBy, toLower } from 'lodash-es';

import { F1Context } from '../../../../../context/f1Data';

import F1Layout from '../../layout';
import Circuit from '../../components/Circuit';
import Icon from '../../../../common/Icon';
import IconButton from '../../../../common/IconButton';

import './style.css';

const Circuits = () => {
  const [inputValue, setInputValue] = useState('');
  const [filteredCountries, setFilteredCountries] = useState([]);

  const [data] = useContext(F1Context);

  const { circuits } = data;

  /** Filter by country **/
  const countries = sortBy(uniq((circuits || []).map((c) => c.competition.location.country)));

  const filterByCountry = (country) => {
    const newFilteredCountries = [...filteredCountries];
    const index = newFilteredCountries.findIndex((c) => c === country);

    if (index !== -1) {
      newFilteredCountries.splice(index, 1);
    } else {
      newFilteredCountries.push(country);
    }

    setFilteredCountries(newFilteredCountries);
  };

  const isHidden = (circuit) => {
    if (filteredCountries.length && !includes(filteredCountries, circuit.competition.location.country)) {
      return true;
    }

    if (inputValue === '') return false;

    const compareInfo = [circuit.competition.location.country, circuit.competition.location.city, circuit.opened, circuit.name].filter(Boolean);

    return !compareInfo.some((info) => includes(toLower(info), toLower(inputValue)));
  };

  return (
    <F1Layout withoutAccess>
      <div className="f1-circuits">
        <div className="f1-body-title">Circuits</div>
        <div className="f1-circuits-head">
          <div className="f1-input-wrap">
            <label className="f1-input-label">
              <Icon size={20} color="#E00600">search</Icon>
              <input
                className="f1-input"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </label>
            <IconButton
              size={20}
              icon="close"
              color="#E00600"
              onClick={() => setInputValue('')}
              className={`f1-circuits-input-close ${inputValue.length ? 'active' : ''}`}
            />
          </div>
        </div>
        <div className="f1-circuits-countries">
          {countries.map((country) =>
            <button
              key={country}
              onClick={() => filterByCountry(country)}
              className={`f1-toggle-button ${includes(filteredCountries, country) ? 'active' : ''}`}
            >{country}</button>,
          )}
        </div>
        <div className="f1-circuits-body">
         {circuits && circuits.map((data) => (
           <Circuit
             key={data.id}
             data={data}
             hidden={isHidden(data)}
           />
         ))}
        </div>
      </div>
    </F1Layout>
  );
};

export default Circuits;
