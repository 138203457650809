import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Image } from '../../../../functions';

import Icon from '../../../common/SVG';

import { path } from '../../../../api/api';

import './style.css';

class FilePreview extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    dataLoaded: false,
    copied: false,
  };

  get filename() {
    return window.location.pathname.replace('/file', '');
  }

  get settings() {
    return Image.info({ fullPath: this.filename });
  }

  copyToClipboard() {
    let success;

    if (navigator.clipboard) {
      this.copyRef.select();
      success = document.execCommand('copy');
    }

    if (!success) {
      navigator.clipboard.writeText().then(
        () => success = true,
        (e) => console.error('Copy failed', e),
      );
    }

    this.setState({ copied: success });
  }

  get link() {
    return window.location.href;
  }

  goBack() {
    const paths = window.location.pathname
      .replace('/file', '')
      .split('/')
      .filter(Boolean);
    const oldPath = paths
      .map((p, i) => i + 1 < paths.length && p)
      .filter(Boolean)
      .join('/');

    this.props.history.push(`/files?path=/${oldPath}`);
  }

  render() {
    const { dataLoaded, copied } = this.state;
    const size = 16;

    return (
      <div className={`preview-wrap ${dataLoaded ? 'loaded' : ''} ${copied ? 'copied' : ''}`}>
        <div className="preview-image-loader"/>
        <div className="preview-image-wrap" onClick={() => window.location.href = this.settings.src}>
          <img
            src={`${this.settings.src}`}
            className="preview-image"
            onLoad={() => this.setState({ dataLoaded: true })}
          />
        </div>
        <div className="preview-actions">
          <a className="preview-action" href={`${path}/files/download.php?file=/uploads/${this.filename}`} target="_blank" rel="noopener noreferrer">
            <Icon icon="download" width={size} height={size} color="#ccc"/>
          </a>
          <div className="preview-action preview-item-copy" onClick={() => this.copyToClipboard()}>
            <Icon icon="link" width={size} height={size} color="#ccc"/>
          </div>
        </div>
        <div className="preview-icon-close">
          <div className="preview-action" onClick={() => this.goBack()}>
            <Icon icon="close" width={20} height={20} color="#fff"/>
          </div>
        </div>
        <input
          value={window.location.href}
          className="hidden-input"
          ref={(node) => this.copyRef = node}
          onChange={() => true}
        />
      </div>
    );
  }
}

export default FilePreview;
