import React, { Component } from 'react';

import MyGalleryTagger from '../../../common/MyGalleryTagger';

import PageWrap from '../../../utils/PageWrap';

import existImages, { type } from '../../../../assets/images';

const MAP = {
  NEW: 'new',
  EXIST: 'exist',
};

const settings = {
  map: MAP.EXIST,
  settings: {
    mapFrom: 0,
    mapTo: 1000,
  },
};

class ImageTagger extends Component {
  static propTypes = {
  };

  state = {
    dataLoaded: false,
    unmount: false,
  };

  images() {
    if (settings.map === MAP.EXIST) {
      return existImages;
    }

    const images = [];
    const total = settings.mapFrom;

    for (let i = total; i > settings.mapTo; i--) {
      const index = i < 10 ? `00${i}` : (i < 100 ? `0${i}` : i);

      images.push({
        src: `https://dominiklaclavik.com/assets/images/portfolio/previews/IMG-${index}.jpg`,
        id: index + 1,
        type: type.landscape,
      });
    }

    return images;
  }

  render() {
    const { unmount } = this.state;

    return (
      <PageWrap unmount={unmount}>
        <MyGalleryTagger images={this.images()}/>
      </PageWrap>
    );
  }
}

export default ImageTagger;

