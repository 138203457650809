// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-SUF8E{position:relative;display:flex;flex-direction:column}.aboutSection-gyuJo{position:relative;display:flex;background:#fff;height:100dvh}.aboutTitle-bvlkA{color:#fff;font-size:30px;text-transform:uppercase;letter-spacing:10px;padding:30px 44px}.aboutTitleSurname-RrBX3,.aboutTitleFirstName-jhs-0{font-weight:normal}.aboutTitleSurname-RrBX3{opacity:.5}.part-V3DOb,.partMain-xh7xt{position:relative;overflow:hidden;display:flex;flex-direction:column}.partMain-xh7xt{flex:1.3;background:#222}.partContent-OgEpj{padding:40px 52px;justify-self:center}.partTitle-OVIAh{font-size:18px;color:#444;margin-bottom:30px}.partText-1eIvZ{font-size:14px;opacity:.5;white-space:pre-wrap}.part-V3DOb{flex:1}.image-U5NJs,.imageBottom-0j\\+o0{transition:.3s ease all;opacity:0}.image-U5NJs.loaded-jWGNS,.imageBottom-0j\\+o0.loaded-jWGNS{opacity:1}.image-U5NJs{width:100%;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%)}.imageBottom-0j\\+o0{width:100%;position:absolute;left:50%;bottom:0;transform:translateX(-50%)}.timelineSection-PTkie{background:#f4f4f8}.timelineHeader-REb0N{display:flex;justify-content:center;align-items:center;min-height:400px}.timelineTitle-4\\+9pS{font-size:100px;color:#333;font-weight:bold;text-align:center}@media(max-width: 1360px){.aboutSection-gyuJo{flex-wrap:wrap;height:initial}.aboutSection-gyuJo>.partMain-xh7xt{flex:0 1 100%}.aboutSection-gyuJo>.part-V3DOb{flex:0 1 50%;min-height:1100px}.scrollBanner-K7VWu{display:none}}@media(max-width: 960px){.aboutSection-gyuJo{flex-direction:column;height:initial}.aboutSection-gyuJo>.part-V3DOb{min-height:initial}.partMain-xh7xt{min-height:35dvh}.part-V3DOb .partMain-xh7xt{min-height:100dvw}}@media(max-width: 640px){.aboutTitle-bvlkA{font-size:24px}.timelineHeader-REb0N{min-height:240px}.timelineTitle-4\\+9pS{font-size:50px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-SUF8E",
	"aboutSection": "aboutSection-gyuJo",
	"aboutTitle": "aboutTitle-bvlkA",
	"aboutTitleSurname": "aboutTitleSurname-RrBX3",
	"aboutTitleFirstName": "aboutTitleFirstName-jhs-0",
	"part": "part-V3DOb",
	"partMain": "partMain-xh7xt",
	"partContent": "partContent-OgEpj",
	"partTitle": "partTitle-OVIAh",
	"partText": "partText-1eIvZ",
	"image": "image-U5NJs",
	"imageBottom": "imageBottom-0j+o0",
	"loaded": "loaded-jWGNS",
	"timelineSection": "timelineSection-PTkie",
	"timelineHeader": "timelineHeader-REb0N",
	"timelineTitle": "timelineTitle-4+9pS",
	"scrollBanner": "scrollBanner-K7VWu"
};
export default ___CSS_LOADER_EXPORT___;
