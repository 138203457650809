import React from 'react';
import { FormControl, Select as MaterialSelect, MenuItem, InputLabel } from '@mui/material';

export type SelectOption = {
  id?: number | string;
  _id?: string;
  value?: string | number;
  label?: string;
  name?: string;
};

type SelectOptions = {
  shrink?: boolean;
  dark?: boolean;
};

type Props = {
  label: string;
  className?: string;
  onChange: (value: string | number) => void;
  value?: string | number;
  items: SelectOption[];
  options?: SelectOptions;
};

const Select = ({ onChange, items, value, label, className, options = {} }: Props) => {
  return (
    <FormControl
      variant="standard"
      style={{ width: '100%', background: !options.dark ? '#ddd' : 'inherit' }}
      classes={{ root: className }}
    >
      {label && <InputLabel shrink={options.shrink}>{label}</InputLabel>}
      <MaterialSelect
        value={value}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        label={label}
        variant="standard"
      >
        {items.map((item) => (
          <MenuItem
            key={item.value || item.id || item._id}
            value={item.value || item.id || item._id}
          >
            {item.label || item.name}
          </MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  );
};

export default Select;
