import React, { useEffect, useState, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import { includes } from 'lodash-es';

import API from '@api/index';
import { endpoints } from '@api/endpoints';

import { Common, Cookie, F1 } from '../functions';
import { F1ContextType, F1YearData } from '@ts/F1';
import { SimpleObject } from '@ts/General';

const firstSeason = 2020;
const currentSeason = F1.getCurrentSeason();

const prepareDataObject = () => {
  const defaults = {
    teamRankings: [],
    driverRankings: [],
    circuits: [],
    competitions: [],
    teams: [],
  };

  const data: F1ContextType = { users: [] };

  for (let i = firstSeason; i <= Number(currentSeason); i++) {
    data[i] = defaults;
  }

  return data;
};

export const F1Context = createContext(prepareDataObject());

type Props = { location: Location; children: JSX.Element | string };

const F1DataProvider = ({ location, children }: Props) => {
  const [data, setData] = useState(prepareDataObject);
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState(
    Cookie.get('f1-season') || Common.getYear().toString(),
  );

  const loadSeason = async (season: string, lastData = data) => {
    if (loading) return;

    setLoading(true);

    try {
      const response = await API.post(endpoints.f1.data.getLast, { season });

      if (!lastData.users.length) {
        lastData.users = await API.get(endpoints.user.list);
      }

      const parsedData: SimpleObject = {};

      Object.keys(response).forEach((key) => {
        try {
          parsedData[key] = JSON.parse(response[key]);
        } catch (err) {
          parsedData[key] = response[key];
        }
      });

      lastData = { ...lastData, [season]: parsedData as F1YearData };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!lastData[season].driverRankings?.length) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lastData[season].driverRankings = [
          {
            position: 1,
            driver: {
              id: 25,
              name: 'Max Verstappen',
              abbr: 'VER',
              number: 1,
              image: 'https://media.api-sports.io/formula-1/drivers/25.png',
            },
            team: {
              id: 1,
              name: 'Red Bull Racing',
              logo: 'https://media.api-sports.io/formula-1/teams/1.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 2,
            driver: {
              id: 34,
              name: 'Charles Leclerc',
              abbr: 'LEC',
              number: 16,
              image: 'https://media.api-sports.io/formula-1/drivers/34.png',
            },
            team: {
              id: 3,
              name: 'Scuderia Ferrari',
              logo: 'https://media.api-sports.io/formula-1/teams/3.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 3,
            driver: {
              id: 10,
              name: 'Sergio Perez',
              abbr: 'PER',
              number: 11,
              image: 'https://media.api-sports.io/formula-1/drivers/10.png',
            },
            team: {
              id: 1,
              name: 'Red Bull Racing',
              logo: 'https://media.api-sports.io/formula-1/teams/1.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 4,
            driver: {
              id: 51,
              name: 'George Russell',
              abbr: 'RUS',
              number: 63,
              image: 'https://media.api-sports.io/formula-1/drivers/51.png',
            },
            team: {
              id: 5,
              name: 'Mercedes-AMG Petronas',
              logo: 'https://media.api-sports.io/formula-1/teams/5.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 5,
            driver: {
              id: 24,
              name: 'Carlos Sainz Jr',
              abbr: 'SAI',
              number: 55,
              image: 'https://media.api-sports.io/formula-1/drivers/24.png',
            },
            team: {
              id: 3,
              name: 'Scuderia Ferrari',
              logo: 'https://media.api-sports.io/formula-1/teams/3.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 6,
            driver: {
              id: 20,
              name: 'Lewis Hamilton',
              abbr: 'HAM',
              number: 44,
              image: 'https://media.api-sports.io/formula-1/drivers/20.png',
            },
            team: {
              id: 5,
              name: 'Mercedes-AMG Petronas',
              logo: 'https://media.api-sports.io/formula-1/teams/5.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 7,
            driver: {
              id: 49,
              name: 'Lando Norris',
              abbr: 'NOR',
              number: 4,
              image: 'https://media.api-sports.io/formula-1/drivers/49.png',
            },
            team: {
              id: 2,
              name: 'McLaren Racing',
              logo: 'https://media.api-sports.io/formula-1/teams/2.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 8,
            driver: {
              id: 28,
              name: 'Esteban Ocon',
              abbr: 'OCO',
              number: 31,
              image: 'https://media.api-sports.io/formula-1/drivers/28.png',
            },
            team: {
              id: 13,
              name: 'Alpine F1 Team',
              logo: 'https://media.api-sports.io/formula-1/teams/13.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 9,
            driver: {
              id: 4,
              name: 'Fernando Alonso',
              abbr: 'ALO',
              number: 14,
              image: 'https://media.api-sports.io/formula-1/drivers/4.png',
            },
            team: {
              id: 13,
              name: 'Alpine F1 Team',
              logo: 'https://media.api-sports.io/formula-1/teams/13.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 10,
            driver: {
              id: 5,
              name: 'Valtteri Bottas',
              abbr: 'BOT',
              number: 77,
              image: 'https://media.api-sports.io/formula-1/drivers/5.png',
            },
            team: {
              id: 18,
              name: 'Alfa Romeo',
              logo: 'https://media.api-sports.io/formula-1/teams/18.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 11,
            driver: {
              id: 19,
              name: 'Sebastian Vettel',
              abbr: 'VET',
              number: 5,
              image: 'https://media.api-sports.io/formula-1/drivers/19.png',
            },
            team: {
              id: 17,
              name: 'Aston Martin F1 Team',
              logo: 'https://media.api-sports.io/formula-1/teams/17.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 12,
            driver: {
              id: 14,
              name: 'Daniel Ricciardo',
              abbr: 'RIC',
              number: 3,
              image: 'https://media.api-sports.io/formula-1/drivers/14.png',
            },
            team: {
              id: 2,
              name: 'McLaren Racing',
              logo: 'https://media.api-sports.io/formula-1/teams/2.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 13,
            driver: {
              id: 2,
              name: 'Kevin Magnussen',
              abbr: 'MAG',
              number: 20,
              image: 'https://media.api-sports.io/formula-1/drivers/2.png',
            },
            team: {
              id: 14,
              name: 'Haas F1 Team',
              logo: 'https://media.api-sports.io/formula-1/teams/14.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 14,
            driver: {
              id: 36,
              name: 'Pierre Gasly',
              abbr: 'GAS',
              number: 10,
              image: 'https://media.api-sports.io/formula-1/drivers/36.png',
            },
            team: {
              id: 7,
              name: 'Scuderia AlphaTauri Honda',
              logo: 'https://media.api-sports.io/formula-1/teams/7.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 15,
            driver: {
              id: 31,
              name: 'Lance Stroll',
              abbr: 'STR',
              number: 18,
              image: 'https://media.api-sports.io/formula-1/drivers/31.png',
            },
            team: {
              id: 17,
              name: 'Aston Martin F1 Team',
              logo: 'https://media.api-sports.io/formula-1/teams/17.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 16,
            driver: {
              id: 80,
              name: 'Mick Schumacher',
              abbr: 'MSC',
              number: 47,
              image: 'https://media.api-sports.io/formula-1/drivers/80.png',
            },
            team: {
              id: 14,
              name: 'Haas F1 Team',
              logo: 'https://media.api-sports.io/formula-1/teams/14.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 17,
            driver: {
              id: 82,
              name: 'Yuki Tsunoda',
              abbr: 'TSU',
              number: 22,
              image: 'https://media.api-sports.io/formula-1/drivers/82.png',
            },
            team: {
              id: 7,
              name: 'Scuderia AlphaTauri Honda',
              logo: 'https://media.api-sports.io/formula-1/teams/7.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 18,
            driver: {
              id: 83,
              name: 'Guanyu Zhou',
              abbr: null,
              number: 24,
              image: 'https://media.api-sports.io/formula-1/drivers/83.png',
            },
            team: {
              id: 18,
              name: 'Alfa Romeo',
              logo: 'https://media.api-sports.io/formula-1/teams/18.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 19,
            driver: {
              id: 50,
              name: 'Alexander Albon',
              abbr: 'ALB',
              number: 23,
              image: 'https://media.api-sports.io/formula-1/drivers/50.png',
            },
            team: {
              id: 12,
              name: 'Williams F1 Team',
              logo: 'https://media.api-sports.io/formula-1/teams/12.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 20,
            driver: {
              id: 61,
              name: 'Nicholas Latifi',
              abbr: 'LAT',
              number: 6,
              image: 'https://media.api-sports.io/formula-1/drivers/61.png',
            },
            team: {
              id: 12,
              name: 'Williams F1 Team',
              logo: 'https://media.api-sports.io/formula-1/teams/12.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 21,
            driver: {
              id: 88,
              name: 'Nyck De Vries',
              abbr: null,
              number: null,
              image: 'https://media.api-sports.io/formula-1/drivers/88.png',
            },
            team: {
              id: 12,
              name: 'Williams F1 Team',
              logo: 'https://media.api-sports.io/formula-1/teams/12.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
          {
            position: 22,
            driver: {
              id: 6,
              name: 'Nico Hulkenberg',
              abbr: 'HUL',
              number: 27,
              image: 'https://media.api-sports.io/formula-1/drivers/6.png',
            },
            team: {
              id: 17,
              name: 'Aston Martin F1 Team',
              logo: 'https://media.api-sports.io/formula-1/teams/17.png',
            },
            points: 0,
            wins: null,
            behind: null,
            season: 2022,
          },
        ];
      } else if (Number(season) === 2023) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lastData[season].driverRankings.push({
          position: 18,
          driver: {
            id: 34,
            name: 'Charles Leclerc',
            abbr: 'LEC',
            number: 16,
            image: 'https://media.api-sports.io/formula-1/drivers/34.png',
          },
          team: {
            id: 3,
            name: 'Scuderia Ferrari',
            logo: 'https://media.api-sports.io/formula-1/teams/3.png',
          },
          points: 0,
          wins: null,
          behind: null,
          season: 2023,
        });
      }

      setData(lastData);

      if (Number(season) === firstSeason) {
        setDataLoaded(true);
        return;
      }

      loadSeason((Number(season) - 1).toString(), lastData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (includes(location.pathname, 'f1') && !dataLoaded) {
      loadSeason(currentSeason);
    }
  }, [location.pathname, dataLoaded]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const selectedSeasonData = data[selectedSeason] as F1YearData;

  const seasonData = { ...selectedSeasonData, users: data.users };

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <F1Context.Provider value={[seasonData, setSelectedSeason, setDataLoaded]}>
        {children}
      </F1Context.Provider>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withRouter(F1DataProvider);
