import React from 'react';

import { User } from '../../../../functions';

import Authorization from '../components/Authorization';
import Loading from '../../../common/Loading';

import Header from './Header';

import './style.css';

type Props = {
  children: JSX.Element | JSX.Element[];
  withoutAccess?: boolean;
  loading?: boolean;
};

const F1Layout = ({ children, withoutAccess, loading }: Props) => {
  const body = withoutAccess || User.hasAccess() ? children : <Authorization />;

  return (
    <div className="f1-container">
      <div className="f1-grid" />
      <div className="f1-wrap">
        <Header />
        <div className="f1-body">{loading ? <Loading size={50} color="#E00600" /> : body}</div>
      </div>
    </div>
  );
};

export default F1Layout;
