import React, { useState } from 'react';

import { General } from '../../../../functions';

import CatchKeyPress from '../../../common/CatchKeyPress';
import List from '@common/List';
import Icon from '../../../common/Icon';

import PageWrap from '../../../utils/PageWrap';

import './style.css';
import { SimpleObject, UnsafeAny } from '@ts/General';

const config = [
  {
    name: 'Legends',
    link: 'https://www.youtube.com/playlist?list=PLpsRIeTvSUDE1b7DTBmZnmXZyM6SmxTzF',
  },
  {
    name: 'Trap Nation',
    link: 'https://www.youtube.com/playlist?list=PLpsRIeTvSUDFkZt9Zon34LoVKCGB-AsmD',
  },
  {
    name: 'Car feelings',
    link: 'https://www.youtube.com/playlist?list=PLpsRIeTvSUDHwBNmBMfl_0xPy7H5oxCgj',
  },
  {
    name: 'Good feelings',
    link: 'https://www.youtube.com/playlist?list=PLpsRIeTvSUDHvlX6PefOwiktKh_kZFiwF',
  },
  {
    name: 'Heartbreaking',
    link: 'https://www.youtube.com/playlist?list=PLpsRIeTvSUDHCpCE01RY8BzlwdwRAkQwd',
  },
  {
    name: 'Soundtracks',
    link: 'https://www.youtube.com/playlist?list=PLpsRIeTvSUDE4Oor6txD7sM8CchxLMF9a',
  },
  {
    name: 'Another',
    link: 'https://www.youtube.com/playlist?list=PLpsRIeTvSUDFvATob2eCWp4hLupg2cD4i',
  },
  {
    name: 'Stories',
    link: 'https://www.youtube.com/playlist?list=PLpsRIeTvSUDEeAY8RvRopMtAZAl3Ng2CJ',
  },
  {
    name: 'Nostagic',
    link: 'https://www.youtube.com/playlist?list=PLpsRIeTvSUDFM63JC7K9zzFr_LlJf2S3f',
  },
  {
    name: 'Special',
    link: 'https://www.youtube.com/playlist?list=PLpsRIeTvSUDEW0RPFfXjTOC0pWrn2qQ42',
  },
];

const Music = () => {
  const [unmount, setUnmount] = useState<string | null>(null);

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <PageWrap unmount={unmount}>
        <div className="visions-wrap">
          <div className="visions-half">
            <div className="visions-text">Dominik Láclavík</div>
            <div className="visions-text">
              <div>Music Playlists</div>
              <div
                className="visions-text-extend"
                onClick={() => setUnmount('/version#module=music')}
              >
                {General.version({ module: 'music' })}
              </div>
            </div>
          </div>
          <div className="visions-half end">
            <div />
            <List
              data={config}
              onItemClick={(item: SimpleObject) =>
                ((window as UnsafeAny).location.href = item.link)
              }
              defaultImage={
                <Icon color="inherit" size={40}>
                  equalizer
                </Icon>
              }
              sm
            />
            <div />
          </div>
        </div>
        <CatchKeyPress
          onAction={(item) => ((window as UnsafeAny).location.href = item.link)}
          onRedirectBack={() => setUnmount('/')}
          items={config}
        />
      </PageWrap>
    </>
  );
};

export default Music;
