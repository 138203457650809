import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@mui/material';

import { styled } from '@mui/material/styles';

const ButtonBaseStyled = styled(ButtonBase)({
  color: '#ccc',
  minWidth: '200px',
  fontFamily: 'Varem',
  fontSize: 26,
  borderRadius: 10,
  background: '#ffffff22',
  padding: '15px 20px',
  border: `3px solid #00000028`,
  transition: `.25s ease all`,
  ['&:hover']: {
    color: 'white',
  },
  ['&:active']: {

  },
  ['&:focus']: {

  },
  ['&.selected']: {
    color: 'white',
    border: `3px solid rgba(203,0,255,0.76)`,
    boxShadow: `0 2px 20px 0 #FF00F299`,
  },
});

const Player = ({ data, onClick, selected }) => {
  return (
    <ButtonBaseStyled className={selected ? 'selected' : ''} onClick={onClick ? onClick : null}>
      <div>{data.username}</div>
    </ButtonBaseStyled>
  );
};

Player.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default Player;
