import React from 'react';
import get from 'lodash/get';

import Loading from '@common/Loading';
import { UnsafeAny } from '@ts/General';

import styles from './style.scss';

type Cell<T> = {
  id: string;
  label?: string;
  width?: string | number;
  render?: (value: UnsafeAny, item: T) => React.ReactNode;
};

type Props<T> = {
  hideHeader?: boolean;
  data: T[];
  cells: Cell<T>[];
  onClickRow?: (item: T, index: number) => void;
  loading?: boolean;
  noDataLabel?: string;
};

const Index = <T,>({
  hideHeader,
  data,
  cells,
  onClickRow,
  loading,
  noDataLabel = 'No data',
}: Props<T>) => {
  const header = (
    <thead>
      <tr>
        {cells.map((cell) => (
          <td key={String(cell.id)}>{cell.label || String(cell.id)}</td>
        ))}
      </tr>
    </thead>
  );

  return (
    <table className={styles.table}>
      {!hideHeader && header}
      <tbody>
        {!data.length && (
          <tr>
            {loading ? (
              <td width="100%" className={styles.tableLoading}>
                <Loading />
              </td>
            ) : (
              <td width="100%" className={styles.tableNoData}>
                {noDataLabel}
              </td>
            )}
          </tr>
        )}
        {data.map((item, index) => (
          <tr key={index} onClick={() => onClickRow && onClickRow(item, index)}>
            {cells.map((cell) => (
              <td key={cell.id?.toString()} width={cell.width}>
                {cell.render
                  ? cell.render(get(item, cell.id), item)
                  : get(item, cell.id)?.toString()}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Index;
