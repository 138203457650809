import React, { ChangeEvent } from 'react';

import './style.css';
type Props = {
  onChange: (e: ChangeEvent) => void;
  value: number | string;
  placeholder?: string;
  required?: boolean;
  error?: boolean;
  loading?: boolean;
  disabled?: boolean;
  label?: string | JSX.Element;
  className?: string;
};

const Range = ({
  onChange,
  value,
  className,
  error,
  placeholder,
  required,
  disabled,
  ...props
}: Props) => {
  const input = (
    <input
      type="range"
      className={`range blue ${error ? 'error' : ''} ${className || ''}`}
      onChange={onChange}
      value={value}
      disabled={disabled}
      placeholder={required && placeholder ? `${placeholder} *` : placeholder}
      {...props}
    />
  );

  return input;
};

export default Range;
