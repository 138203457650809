import React, { useContext, useState } from 'react';
import { toUpper, uniq } from 'lodash-es';

import API from '@api/index';
import { F1Context } from '@context/f1Data';
import { endpoints } from '@api/endpoints';

import { F1, User } from '../../../../../functions';
import { translate, useTranslate } from '@localise/index';

import Modal from '@common/Modal';
import Button from '../Button';
import Circuit from '../Circuit';
import Driver from '../Driver';

import Select from '@material/Select';

import './style.css';
import { F1Circuit, F1DriverInfo, F1DriverVote, F1Race } from '@ts/F1';
import { User as UserType } from '@ts/User';

type Props = {
  drivers: F1DriverVote[];
  positions: number;
  type: string;
  onUpdate: () => void;
  race: F1Race;
};

const VotePosition = ({ drivers, positions = 5, type = 'DEFAULT', onUpdate, race }: Props) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<number | null>(null);
  const [expectedPositions, setExpectedPositions] = useState<F1DriverVote[]>([]);
  const [selectedUser, setSelectedUser] = useState(User.hasAccess());

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [data] = useContext(F1Context);

  const users = data.users ? data.users.filter((u: UserType) => User.hasRight('f1:vote', u)) : [];

  const { circuits } = data;

  if (!circuits) return null;

  const addTips = async () => {
    const uniqueDrivers = uniq(expectedPositions.map((c) => c.driverId));

    if (uniqueDrivers.length !== expectedPositions.length) {
      return alert(translate.f1.voting.error.DRIVER_SELECTED_TWICE);
    }

    if (loading) {
      return;
    }

    setLoading(true);

    await Promise.all(
      expectedPositions.map((c) =>
        API.post(endpoints.f1.votes.add, { ...c, race: race, user: selectedUser }),
      ),
    ).then(() => {
      onUpdate();
      setTimeout(() => setLoading(false), 10000);
    });

    return false;
  };

  const setDriverPosition = (driver: F1DriverInfo, position: number) => {
    const newExpectation = { driverId: driver.id, driverName: driver.name, position, type: type };

    const newExpectedPositions = [...expectedPositions];

    const index = newExpectedPositions.findIndex(
      (pos) => pos.position === position && pos.type === type,
    );

    if (index !== -1) {
      newExpectedPositions[index] = newExpectation;
    } else {
      newExpectedPositions.push(newExpectation);
    }

    setExpectedPositions(newExpectedPositions);
    setIsModalOpen(null);
  };

  const positionItems = [];

  for (let i = 1; i <= positions; i++) {
    const expectedPosition = expectedPositions.find(
      (pos) => pos.type === type && pos.position === i,
    ); // add competition
    const data =
      expectedPosition && drivers.find((pos) => pos.driver?.id === expectedPosition.driverId);

    positionItems.push(
      <div key={i} className="f1-driver select">
        <div className="f1-driver-position">{i}</div>
        {data ? (
          <div className="f1-driver-base" onClick={() => setIsModalOpen(i)}>
            <div className="f1-driver-info">
              <div className="f1-driver-name">{data.driver?.name}</div>
              <div className="f1-driver-team-name">{data.team?.name}</div>
            </div>
            <div className="f1-driver-points">
              <span>{data.points}</span>
              <div className="f1-driver-points-label">{translate.f1.unit.points}</div>
            </div>
          </div>
        ) : (
          <div className="f1-driver-empty" onClick={() => setIsModalOpen(i)}>
            {translate.f1.voting.action.selectDriver}
          </div>
        )}
      </div>,
    );
  }

  const circuit = circuits.find((c: F1Circuit) => c.id === race.circuit);

  return (
    <div className="f1-vote-wrap">
      <div className="f1-vote-info">
        <Circuit data={circuit} simple />
      </div>
      <div className="f1-vote-body">
        <div className="f1-title">{toUpper(F1.getType(type, race))}</div>
        <div className="f1-vote-tips">
          {User.hasRight('f1:edit') && (
            <Select
              label="User"
              onChange={(value) => setSelectedUser(value as string)}
              value={selectedUser}
              items={users}
              className="f1-vote-select"
            />
          )}
          {positionItems}
        </div>
        <div className="f1-vote-center">
          <Button
            disabled={expectedPositions.length !== positions || loading}
            loading={loading}
            onClick={() => addTips()}
          >
            {useTranslate('f1.voting.action.saveTips', { type: F1.getType(type, race) })}
          </Button>
        </div>
      </div>
      <Modal
        title={translate.f1.voting.action.selectDriver}
        titleExtension={useTranslate('f1.voting.position', { isModalOpen, type })}
        open={!!isModalOpen}
        onExited={() => setIsModalOpen(null)}
        content={
          <>
            {drivers &&
              drivers.map((data) => (
                <Driver
                  key={data.position}
                  data={data}
                  onClick={(driver) => isModalOpen && setDriverPosition(driver, isModalOpen)}
                  selectable
                />
              ))}
          </>
        }
      />
    </div>
  );
};

export default VotePosition;
