// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-FykeM{padding:80px;max-width:800px}.name-3YKhY{font-size:36px;font-weight:bolder}.block-Ol-5Y:nth-child(even){background:#f2f2f4}.promotion-LJrij,.description-WRKus,.name-3YKhY,.icons-cTxAK{margin-bottom:12px}.promotion-LJrij{font-weight:bolder}.promotion-LJrij,.description-WRKus{white-space:pre-wrap}.more-bicbF{margin-bottom:4px}.tags-fF5Nm{display:flex;margin:14px 0}.tag-qoT-L{font-size:11px;text-transform:uppercase;margin-right:10px;color:#bbb;font-weight:bold;padding:2px 5px;border-radius:4px;border:1px solid #ddd}.pages-UGvqu{font-size:11px;text-transform:uppercase;color:#bbb;font-weight:bold}.page-lj\\+\\+v{color:#00bfff;margin:0 6px;text-transform:initial;font-weight:normal;font-size:14px}.page-lj\\+\\+v:hover{text-decoration:underline}.tags-fF5Nm{display:flex;margin:14px 0}.links-9EmZW{display:flex;margin:10px 0}.link-PXQng{margin-right:20px}.links-9EmZW a{color:#00bfff}.links-9EmZW a:hover{text-decoration:underline}.bar-block-K8wHv{display:flex;margin-right:20px;font-weight:bolder;font-size:11px;letter-spacing:1px;text-transform:uppercase;color:#999;align-items:center;width:300px;justify-content:space-between}.barDots-jgdzu{display:flex;width:140px;justify-content:space-between}.barDot-GEZ6h{width:10px;height:10px;border-radius:10px;background:#ddd;box-sizing:border-box}.barDot-GEZ6h.filled-sHMlm{background:#00bfff}.bodyBlock-\\+Xija{display:flex;flex-direction:column;padding:8px 0}.bodyBlockTitle-KCa52{font-weight:bold}.bodyBlockList-jQNGh{padding:10px}.bodyBlockListItem-SQ4Rn{display:flex;align-items:center;font-size:14px}.bodyBlockListItemDot-VBTqi{width:4px;height:4px;margin:2px 6px 0;border-radius:10px;background:#666}.bodyBlockListItemDescription-DC9r3{color:#888;margin:0 0 0 10px;font-size:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-FykeM",
	"name": "name-3YKhY",
	"block": "block-Ol-5Y",
	"promotion": "promotion-LJrij",
	"description": "description-WRKus",
	"icons": "icons-cTxAK",
	"more": "more-bicbF",
	"tags": "tags-fF5Nm",
	"tag": "tag-qoT-L",
	"pages": "pages-UGvqu",
	"page": "page-lj++v",
	"links": "links-9EmZW",
	"link": "link-PXQng",
	"bar-block": "bar-block-K8wHv",
	"barDots": "barDots-jgdzu",
	"barDot": "barDot-GEZ6h",
	"filled": "filled-sHMlm",
	"bodyBlock": "bodyBlock-+Xija",
	"bodyBlockTitle": "bodyBlockTitle-KCa52",
	"bodyBlockList": "bodyBlockList-jQNGh",
	"bodyBlockListItem": "bodyBlockListItem-SQ4Rn",
	"bodyBlockListItemDot": "bodyBlockListItemDot-VBTqi",
	"bodyBlockListItemDescription": "bodyBlockListItemDescription-DC9r3"
};
export default ___CSS_LOADER_EXPORT___;
