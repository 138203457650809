import React, { ChangeEvent, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Checkbox,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";

const ASC = "ASC";
const DESC = "DESC";

export const Table = ({
  className,
  config,
  data,
  noDataLabel,
  pagination,
  defaultSort,
  onSelect,
}): JSX.Element => {
  const [rowsPerPage, setRowsPerPage] = useState(
    pagination?.defaultLimit || 10
  );
  const [page, setPage] = useState(pagination?.defaultOffset || 0);
  const [sortId, setSortId] = useState(defaultSort?.field || null);
  const [sortDir, setSortDir] = useState(defaultSort?.order || DESC);

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (pagination && pagination.total) {
      pagination.onChange({
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        sort: sortId ? { field: sortId, order: sortDir } : undefined,
      });
    }
  }, [page, rowsPerPage, sortId, sortDir]);

  useEffect(() => {
    setPage(page);
  }, [sortId, sortDir]);

  const setSort = (identifier: string) => {
    if (sortId === identifier) {
      setSortDir(sortDir === ASC ? DESC : ASC);
    } else {
      setSortId(identifier);
      setSortDir(DESC);
    }
  };

  if (!data) {
    return <CircularProgress color="secondary" />;
  }

  const onSelectRow = (row) => {
    const newSelectedRows = [...selectedRows];

    const index = selectedRows.findIndex((r) => r._id === row._id);

    if (index === -1) {
      newSelectedRows.push(row);
    } else {
      const newSelectedRows = [...selectedRows];

      newSelectedRows.splice(index, 1);
    }

    setSelectedRows(newSelectedRows);
    onSelect(newSelectedRows);
  };

  return data.length ? (
    <Paper sx={{ width: "100%" }}>
      <MaterialTable size="small">
        <TableHead>
          <TableRow>
            {onSelect && <TableCell />}
            {config.map((rowConfig) => {
              const identifier =
                typeof rowConfig.sort === "string"
                  ? rowConfig.sort
                  : rowConfig.id;

              if (rowConfig.hidden) {
                return null;
              }

              return (
                <TableCell key={rowConfig.id}>
                  {rowConfig.sort ? (
                    <div
                      onClick={() =>
                        rowConfig.sort ? setSort(identifier) : false
                      }
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "1px 8px",
                        background: "#ffffff11",
                        borderRadius: "4px",
                        cursor: "pointer",
                        opacity: sortId === identifier ? "1" : "0.7",
                      }}
                    >
                      <span>{rowConfig.label || rowConfig.id}</span>
                      {sortId === identifier && (
                        <span
                          style={{
                            transition: "0.3s ease all",
                            transform: `rotate(${
                              sortDir === DESC ? 180 : 0
                            }deg)`,
                          }}
                        >
                          &uarr;
                        </span>
                      )}
                    </div>
                  ) : (
                    rowConfig.label || rowConfig.id
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((row, idx) => (
              <TableRow key={row.id || row.hash || idx}>
                {onSelect && (
                  <TableCell>
                    <Checkbox
                      checked={selectedRows.some((r) => r._id === row._id)}
                      onChange={() => onSelectRow(row)}
                    />
                  </TableCell>
                )}
                {config.map((rowConfig) => {
                  return (
                    !rowConfig.hidden && (
                      <TableCell key={rowConfig.id}>
                        {rowConfig.render
                          ? rowConfig.render(row[rowConfig.id], row, data, idx)
                          : row[rowConfig.id]}
                      </TableCell>
                    )
                  );
                })}
              </TableRow>
            ))}
        </TableBody>
      </MaterialTable>
      <div>
        {!!pagination && (
          <TablePagination
            count={pagination.total || 0}
            onPageChange={(_event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event: ChangeEvent<HTMLInputElement>) => {
              setRowsPerPage(Number(event.target.value));
              setPage(0);
            }}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 100]}
          />
        )}
      </div>
    </Paper>
  ) : (
    <Typography className="table-no-data" variant="h5">
      {noDataLabel || "No data"}
    </Typography>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  config: PropTypes.array,
  data: PropTypes.array,
  noDataLabel: PropTypes.string,
  defaultSort: PropTypes.object,
  pagination: PropTypes.object,
  onSelect: PropTypes.func,
};

export default Table;
