import React from 'react';
import { Box } from '@mui/material';
import { groupBy } from 'lodash-es';

import API from '@api/index';
import { endpoints } from '@api/endpoints';
import { translate } from '@localise/index';

import { Common, F1, Time } from '@functions';

import { F1Race, F1Vote, F1RaceType, F1Result, F1Driver } from '@ts/F1';
import { User } from '@ts/User';

import { SimpleObject } from '@ts/General';

import './style.css';

type Props = {
  onUpdate: () => void;
  votes: F1Vote[];
  users: User[];
  type: F1RaceType;
  race: F1Race;
  editMode?: boolean;
};

type Column = User & {
  width?: number;
  label?: string;
};

const VoteTable = ({ votes, type, users = [], race, editMode, onUpdate }: Props) => {
  const filteredVotes = votes.filter((v) => v.type === type && v.race._id === race._id);
  const tableData = groupBy(Common.sort(filteredVotes, 'position'), 'user');

  const dataLength = Object.values(tableData || {})[0]?.length || 4;

  const results = Common.sort(
    votes.filter((v) => v.type === `${type}_RESULTS` && v.race._id === race._id),
    'position',
  ) as F1Result[];

  const columns: Column[] = [{ id: '' } as Column, ...users];

  if (results.length) {
    columns.push({ id: 'results', label: translate.f1.voting.table.results } as Column);
  }

  // Create rows
  const data = Array.from(Array(dataLength).keys()).map((i, index) => ({ position: index + 1 }));

  if (!votes || columns.length < 2) return null;

  const driver = (driver?: F1Driver, params: SimpleObject = {}) => {
    if (!driver) {
      return <div className="driver-not-voted">{translate.notAvailable}</div>;
    }

    const name = driver.driverName.split(' ');

    return (
      <div className={params.small ? 'driver-name-small' : 'driver-name'}>
        <div className="driver-firstname">{name[0]}</div>
        <div className="driver-surname">{name[1]}</div>
      </div>
    );
  };

  if (!filteredVotes.length || filteredVotes.length === results.length) {
    return null;
  }

  const onRemoveVotes = async (id: string) => {
    try {
      if (id === 'results') {
        await API.del(endpoints.f1.votes.delete, { race: race._id, type: `${type}_RESULTS` });
      } else {
        await API.del(endpoints.f1.votes.delete, { user: id, race: race._id, type });
      }

      onUpdate();
    } catch (err) {
      API.err(err);
    }
  };

  return (
    <div className="vote-table">
      <div className="vote-table-type">{F1.getType(type, race)}</div>
      <table>
        <thead>
          <tr>
            {columns.map((col, index) => {
              const dateVoted =
                col?.id === 'results'
                  ? results[0] && results[0].createdAt
                  : tableData[col._id] && tableData[col._id][0] && tableData[col._id][0].createdAt;

              return (
                <th
                  key={index}
                  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                  // @ts-ignore
                  width={`${col.width || 0}%`}
                  className={col.id === 'results' ? `th-result` : undefined}
                >
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <div className="vote-table-head">
                      <div className="vote-table-username">
                        {col && (col.username || col.label)}
                      </div>
                      {dateVoted && (
                        <div className="vote-table-time">{Time.concrete(dateVoted as string)}</div>
                      )}
                    </div>
                    {index !== 0 && dateVoted && editMode && (
                      <button
                        className="f1-button-admin sm"
                        onClick={() => onRemoveVotes(col._id || col.id)}
                      >
                        Remove
                      </button>
                    )}
                  </Box>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {columns.map((col, colIndex) =>
                col.id === '' ? (
                  <th key={colIndex}>{row.position}</th>
                ) : (
                  <th key={colIndex} className={col.id === 'results' ? `th-result` : ''}>
                    {driver(
                      (col._id
                        ? tableData[col._id] && tableData[col._id][row.position - 1]
                        : results[row.position - 1]) as F1Driver,
                    )}
                  </th>
                ),
              )}
            </tr>
          ))}
          {!!results.length && (
            <tr>
              {columns.map((col, colIndex) => {
                if (!col._id) {
                  return <th />;
                }

                const ranking = F1.getRanking(results, tableData[col._id] as F1Vote[]);

                if (colIndex === 0) {
                  return (
                    <th key={colIndex} className="power-ranking-text">
                      {translate.f1.voting.table.powerRank}
                    </th>
                  );
                }

                if (col.id === 'results') {
                  return (
                    <th key={colIndex} className="th-result">
                      {driver(results[data.length] as unknown as F1Driver, { small: true })}
                      {driver(results[data.length + 1] as unknown as F1Driver, { small: true })}
                    </th>
                  );
                }

                return (
                  <th key={colIndex}>
                    <div className="power-ranking-wrap">
                      <div className="power-ranking">
                        {ranking.total}&nbsp;
                        <div className="power-ranking-points">{translate.f1.unit.pts}</div>
                      </div>
                    </div>
                    {Common.sort(ranking.items, 'pts').map((item, index) => {
                      const driverName = item.driverName as string;
                      const driverNameSplit: string = driverName && driverName.split(' ')[1];

                      return (
                        <div className="power-ranking-type" key={index}>
                          <span>
                            {item.pts} {translate.f1.unit.pts} - {item.type}&nbsp;
                          </span>
                          <span className="power-ranking-type-driver">
                            {(item.driverCombination || driverNameSplit) as string}
                          </span>
                        </div>
                      );
                    })}
                  </th>
                );
              })}
            </tr>
          )}
        </tbody>
      </table>
      {!data.length && (
        <div className="table-content">
          <div>{translate.noItems}</div>
        </div>
      )}
    </div>
  );
};

export default VoteTable;
