import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';

import './style.css';

const ButtonBaseStyled = styled(ButtonBase)({
  'color': '#ddd',
  'padding': '10px',
  'borderRadius': '6px',
  'fontFamily': 'Varem',
  'width': '100%',
  'height': '100%',
  '&:hover': {
    color: 'white',
    backgroundColor: '#ffffff11',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    color: 'white',
    backgroundColor: '#ffffff11',
    borderColor: '#005cbf',
  },
  '&:focus': {
    backgroundColor: '#ffffff11',
  },
});

const Card = ({ children, onClick, disabled }) => {
  return (
    <div className="game-card">
      <div className="game-card-content">
        <ButtonBaseStyled onClick={onClick} disabled={disabled}>
          {children}
        </ButtonBaseStyled>
      </div>
    </div>
  );
};

Card.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  children: PropTypes.any,
};

export default Card;
