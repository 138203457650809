import { SimpleObject } from '@ts/General';
// eslint-disable-next-line import/no-duplicates
import { add, differenceInMinutes, format, isBefore, isSameDay, sub, subDays } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { sk } from 'date-fns/locale';

const parse = (n: number) => parseInt(n.toString(), 10);

const concrete = (string: string) => {
  if (!string) return string;

  const now = new Date();
  const toCompare = new Date(string);

  if (isBefore(now, toCompare)) {
    return '-';
  }

  const minutes = differenceInMinutes(now, toCompare);

  if (minutes > 518400) {
    return format(toCompare, 'd.M.yyyy');
  } else if (minutes > 86400) {
    // more than ONE month
    return parse(minutes / 43200) + ' months';
  } else if (minutes > 43200) {
    // more than month
    return parse(minutes / 43200) + ' month';
  } else if (minutes > 1440) {
    // more than day
    return parse(minutes / 1440) + 'd';
  } else if (minutes > 60) {
    // more than hour
    return parse(minutes / 60) + 'h';
  } else if (minutes > 0) {
    // more than minute
    return minutes + 'm';
  } else {
    return 'just now';
  }
};

const concreteFull = (date: string | Date) => {
  if (!date) return date;

  const now = new Date();
  const toCompare = new Date(date);

  if (isBefore(now, toCompare)) {
    return '-';
  }

  if (isSameDay(toCompare, subDays(now, 1))) {
    return 'Yesterday';
  }

  if (isSameDay(toCompare, now)) {
    return 'Today';
  }

  const minutes = differenceInMinutes(now, toCompare);

  if (minutes > 518400) {
    return format(toCompare, 'd.M.yyyy');
  } else if (minutes > 86400) {
    // more than ONE month
    return parse(minutes / 43200) + ' months ago';
  } else if (minutes > 43200) {
    // more than month
    return parse(minutes / 43200) + ' month ago';
  } else if (minutes > 1440) {
    // more than day
    return parse(minutes / 1440) + ' days ago';
  }
};
type GetTimeConfig = {
  sub?: boolean | SimpleObject;
  add?: boolean;
};

const getTime = (config: GetTimeConfig): string | null => {
  if (!config) return null;

  let date = new Date();

  if (config.sub) {
    date = sub(date, config.sub as Duration);
  } else if (config.add) {
    date = add(date, config.add as Duration);
  } else {
    return null;
  }

  return date.toISOString();
};

type DateTimeFormat = 'date' | 'dateTime' | 'time' | 'day' | 'api' | string;

export const formatDate = (d: Date | string, dateFormat: DateTimeFormat = 'dateTime') => {
  const date = new Date(d);

  let formatter;

  switch (dateFormat) {
    case 'date':
      formatter = 'dd.MM.yyyy';
      break;
    case 'dateTime':
      formatter = 'dd.MM.yyyy HH:mm';
      break;
    case 'time':
      formatter = 'HH:mm';
      break;
    case 'day':
      formatter = 'MMM dd';
      break;
    case 'api':
      formatter = 'yyyy-MM-ddTHH:mm:ss';
      break;
    default:
      formatter = dateFormat;
  }

  return format(date, formatter);
};

export default {
  concrete,
  concreteFull,
  getTime,
  formatDate,
};
