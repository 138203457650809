import React from 'react';

import { web } from '@api/api';

import './style.css';

export type ListItem = {
  name?: string;
  image?: string;
  link?: string;
};

type Props = {
  data: ListItem[];
  onItemClick: (item: ListItem) => void;
  sm?: boolean;
  xs?: boolean;
  extra?: (item: ListItem) => JSX.Element | string;
  hideCondition?: (item: ListItem) => void;
  defaultImage?: JSX.Element | string;
};

const List = ({ data, defaultImage, sm, xs, onItemClick, extra, hideCondition }: Props) => {
  return (
    <div className="list-scrollable">
      <div className={`list-wrap ${xs ? 'xs' : sm ? 'sm' : ''}`}>
        {data.map((item) => {
          const image = item.image
            ? `${web}${item.image}`
            : typeof defaultImage === 'string' && defaultImage;
          const isHidden = hideCondition && hideCondition(item);

          return (
            <button
              className={`list-item ${isHidden ? 'hidden' : ''}`}
              key={item.name}
              onClick={() => onItemClick(item)}
            >
              {image ? (
                <img className="list-item-image" alt="list-img" src={image} />
              ) : (
                defaultImage
              )}
              <div className="list-item-info">
                <span className="list-item-info-name">{item.name}</span>
                {extra && <span className="list-item-info-extra">{extra(item)}</span>}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default List;
