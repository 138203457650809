import React, { useEffect, useState } from 'react';
import { ButtonBase, Dialog, Fade, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import API from '../../../../../api';
import { endpoints } from '@api/endpoints';

import Adapters from '@functions/adapters';

import PickLayout from '../../layout';
import DarkListItem from '../../../../common/DarkListItem';
import DarkCircleIconButton from '../../../../common/DarkCircleIconButton';

import { History } from 'history';

import { PickType } from '@ts/Pick';
import { UnsafeAny } from '@ts/General';

import { translate } from '@localise/index';
import { User } from '@functions';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background: linear-gradient(#111, #222);
  }

  .MuiDialog-container {
    background: rgba(255, 255, 255, 0.8);
  }

  @media (max-width: 600px) {
    .MuiDialog-paper {
      flex: 1;
      margin: 0;
    }
  }
`;

export const StyledButtonBase = styled(ButtonBase)`
  color: #ccc;
  font-size: 16px;
  font-family: 'Varem', sans-serif;
  padding: 8px 20px;
  letter-spacing: 1px;
  border-radius: 6px;
  margin-left: 10px;
  transition: 0.3s ease all;
  text-transform: uppercase;

  &:hover {
    color: white;
  }
`;

export const StyledButtonBaseActive = styled(StyledButtonBase)`
  background: #ffffff22;

  &:hover {
    background: black;
  }

  &:disabled {
    opacity: 0.3;
  }
`;

export const StyledTextField = styled(TextField)`
  .MuiInputLabel-root {
    color: #888;
    font-size: 15px;
    font-family: 'Varem', sans-serif;
  }

  .MuiInputLabel-root .Mui-focused {
    color: #bbb;
  }

  .MuiInput-input {
    color: #ccc;
    font-size: 18px;
    padding: 0 0 2px;
  }

  .MuiInput-input:focus {
    color: white;
  }

  .MuiInput-input::placeholder {
    color: #555;
  }

  .MuiInput-underline::before {
    border-bottom-color: #555;
  }

  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom-color: #666;
  }

  .MuiInput-underline::after {
    border-bottom-color: white;
  }
`;

type Props = {
  history: History;
};

const Types = ({ history }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState<Partial<PickType>>({ chance: 100 });

  const [data, setData] = useState<PickType[]>([]);
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const data = await API.get(endpoints.pick.type.list);

      setData(data.filter((t: PickType) => t.user === User.id()));
      setPageLoaded(true);
    } catch (err) {
      API.err(err);
    }
  };

  const onCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => setValues({ chance: 100 }), 300);
  };

  const onSave = async () => {
    try {
      if (values._id) {
        await API.put(
          endpoints.pick.type.update(values._id as string),
          Adapters.cleanObject(values),
        );
      } else {
        await API.post(endpoints.pick.type.add, { ...values, user: User.id() });
      }

      loadData();
      setValues({ ...values });
      setIsOpen(false);
    } catch (err) {
      API.err(err);
    }
  };

  const updateValue = (field: UnsafeAny, value: UnsafeAny) => {
    const newValues = { ...values };

    if (field.type === 'number' && value !== '') {
      const val = Number(value);

      if (!isNaN(val) && field.min && field.min > val) {
        return;
      }

      if (!isNaN(val) && field.max && field.max <= val) {
        return;
      }
    }

    if (field.name === 'name') {
      newValues.id = value.toUpperCase().replace(/\s/g, '_');
    }

    if (field.name === 'id') {
      value = value.toUpperCase().replace(/\s+/g, '_');
    }

    newValues[field.name] = value;

    setValues(newValues);
  };

  const field = (field) => (
    <div className="drink-type-field" key={field.name}>
      <StyledTextField
        InputLabelProps={{
          shrink: true,
        }}
        variant="standard"
        fullWidth
        id={`pick-field-${field.name}`}
        onChange={(e) => updateValue(field, e.target.value)}
        value={field.value || values[field.name]}
        label={field.label || (translate.pick.types[field.name] as string) || field.name}
        placeholder={field.placeholder}
        type={field.type}
        autoComplete="off"
      />
    </div>
  );

  return (
    <PickLayout loading={!pageLoaded} header={translate.pick.types.title}>
      <StyledDialog className="" open={isOpen} onClose={onCloseModal}>
        <div className="drink-type-form">
          <div className="drink-type-form-title">
            {values._id ? translate.pick.types.edit : translate.pick.types.add}
          </div>
          {field({ name: 'name' })}
          {field({ name: 'category', placeholder: 'NG' })}
          {field({ name: 'chance', min: 0, max: 100, type: 'number' })}
          <div className="drink-type-form-actions">
            <StyledButtonBase onClick={onCloseModal}>Cancel</StyledButtonBase>
            <StyledButtonBaseActive disabled={!values.id || !values.name} onClick={onSave}>
              Save
            </StyledButtonBaseActive>
          </div>
        </div>
      </StyledDialog>
      <div className="drink-types-body">
        {data.map((type, index) => (
          <Fade
            key={type._id}
            in={!!data.length}
            mountOnEnter
            style={{ transitionDelay: `${240 * index + 'ms'}`, transitionDuration: '1250ms' }}
          >
            <div className="drink-type-item">
              <DarkListItem
                label={type.name}
                onClick={() => {
                  setValues(type);
                  setIsOpen(true);
                }}
              />
            </div>
          </Fade>
        ))}
        <Fade
          in
          mountOnEnter
          style={{
            transitionDelay: `${240 * data.length + 'ms'}`,
            transitionDuration: '1250ms',
          }}
        >
          <div className="drink-type-item">
            <DarkListItem label="Add" fade onClick={() => setIsOpen(true)} />
          </div>
        </Fade>
      </div>
      <Fade in>
        <div className="drink-types-footer">
          <DarkCircleIconButton icon="arrow_back" onClick={() => history.push('/drinks')} />
        </div>
      </Fade>
    </PickLayout>
  );
};

export default Types;
