import React, { useState } from 'react';
import Icon from '@common/Icon';
import { ButtonBase } from '@mui/material';
import classNames from 'classnames';
import { upperFirst } from 'lodash-es';

import styles from './style.scss';

export type NavConfig = {
  id: string;
  icon: string;
  label?: string;
  variant?: 'primary' | 'secondary';
};

type Props = {
  items: NavConfig[];
  onChange: (id: string) => void;
};

const DarkNavigation = ({ items, onChange }: Props) => {
  const [activeId, setActiveId] = useState(items[0].id);
  return (
    <div className={styles.navigation}>
      {items.map((item) => (
        <ButtonBase
          className={classNames(
            styles.navigationItem,
            item.id === activeId && styles.active,
            item.variant === 'secondary' && styles.secondary,
          )}
          key={item.id}
          onClick={() => {
            onChange(item.id);
            setActiveId(item.id);
          }}
        >
          <Icon className={styles.navigationItemIcon} size={20}>
            {item.icon}
          </Icon>
          <span className={styles.navigationItemLabel}>{item.label || upperFirst(item.id)}</span>
        </ButtonBase>
      ))}
    </div>
  );
};

export default DarkNavigation;
