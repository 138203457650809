import React, { useRef, MouseEvent, RefObject } from 'react';

import { Hooks } from '@functions/index';

import Icon from '../SVG';

import './style.css';

type Item = {
  id: number;
  onClick?: (e: MouseEvent) => void;
  label: string;
  icon?: string;
};

type Props = {
  isOpen: boolean;
  items: Item[];
  attachRef: RefObject<HTMLInputElement>;
  onClose?: () => void;
};

const MenuItem = ({ isOpen, attachRef, items = [], onClose }: Props) => {
  const ref = useRef(null);

  if (!attachRef || !isOpen) return null;

  Hooks.clickOutside(ref, onClose);

  const dimensions = attachRef.current?.getBoundingClientRect();

  if (dimensions) {
    const left = dimensions.x + dimensions.width / 2;
    const top = dimensions.y + dimensions.height / 2;

    return (
      <div className={`menu-popup ${isOpen ? 'open' : ''}`} style={{ left, top }} ref={ref}>
        {isOpen &&
          items.map((item) => (
            <button key={item.id} className="menu-popup-item" onClick={item.onClick}>
              {item.icon && <Icon icon={item.icon} width={12} height={12} color="#555" />}
              <div className="menu-popup-item-label">{item.label}</div>
            </button>
          ))}
      </div>
    );
  }

  return null;
};

export default MenuItem;
