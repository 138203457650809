import { includes, lowerCase } from 'lodash-es';

import { uploadPath } from '@api/api';

import settings from '@pages/extra/Files/driveSettings';

type Args = {
  file?: string;
  path?: string;
  fullPath: string;
};

const info = ({ file, path, fullPath }: Args) => {
  if (!file) {
    const parts = fullPath.split('/');
    file = parts[parts.length - 1];
  }

  const pathname = file || '';
  fullPath = fullPath || `${path}/${file}`;

  const divide = pathname.split('.');
  const src = `${uploadPath}${fullPath}`;

  const fileType = divide.length > 1 ? divide[divide.length - 1] : undefined;

  const isImage = includes(settings.imageTypes, lowerCase(fileType));
  const isFolder = !fileType;

  return {
    fileType,
    isImage,
    isFolder,
    src,
    fullPath,
    pathname,
  };
};

export default {
  info,
};
