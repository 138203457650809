// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-FCT4j{padding:30px;display:flex;flex:1}.content-fmJms{display:flex;justify-content:center;align-items:start;gap:50px;flex:1}.body-uAZvS{display:flex;gap:50px;flex-wrap:wrap}.group-9Xgfz{background:#222;box-shadow:0 2px 20px 0 rgba(0,0,0,.5);border-radius:28px;padding:20px 30px;color:#fff;text-transform:uppercase;min-width:300px;letter-spacing:1px;box-sizing:border-box}.group-9Xgfz.white-dUHMr{background:#dedede;box-shadow:0 2px 60px 0 rgba(0,0,0,.26);border-radius:28px}.actions-EhVwM{display:flex;gap:20px;justify-content:center;text-transform:uppercase}.adminButton-AqVMN{padding:6px 10px !important;border-radius:6px !important;color:gray !important}@media(max-width: 1024px){.content-fmJms{flex-direction:column}.body-uAZvS,.content-fmJms{gap:30px}}@media(max-width: 600px){.wrap-FCT4j{padding:30px;gap:20px}.content-fmJms{align-items:center}.body-uAZvS,.content-fmJms{gap:12px;align-items:center}.group-9Xgfz{width:100%}}@media(max-width: 400px){.wrap-FCT4j{padding:15px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-FCT4j",
	"content": "content-fmJms",
	"body": "body-uAZvS",
	"group": "group-9Xgfz",
	"white": "white-dUHMr",
	"actions": "actions-EhVwM",
	"adminButton": "adminButton-AqVMN"
};
export default ___CSS_LOADER_EXPORT___;
