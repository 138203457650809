import { get as g } from 'lodash-es';
import { Env } from '../functions';

import settings from '../assets/settings';

import en from './en';

export const translate = en;

export const useTranslate = (key: string, properties: Record<string, unknown>) => {
  try {
    let t = g(en, key);

    Object.keys(properties).map((prop) => {
      t = t.replace('${' + prop + '}', properties[prop]);
    });

    return t;
  } catch (error) {
    if (!Env.isProduction() && settings.debug.translation) {
      throw new Error(`Missing translate "${key}"`);
    }

    return `Missing translate "${key}"`;
  }
};
