import React, { useState } from 'react';
import PropTypes from 'prop-types';

import VoteItem from '../VoteItem';

import './style.css';

const Voter = ({ data, onRate, onAdd }) => {
  const [activeHover, setActiveHover] = useState(null);

  const onHover = (percentage) => setActiveHover(percentage);
  const onLeave = () => setActiveHover(null);

  const isActive = (p, isGroup) => {
    if (isGroup) p = p + 9;
    else p = p - 1;

    return activeHover ? activeHover > p : (data && data.rating) > p;
  };

  const renderedPercentage = [];

  for (let g = 0; g < 100; g = g + 10) {
    const lines = [];

    for (let i = 0; i < 10; i++) {
      const percentage = g + i + 1;

      lines.push(
        <div
          key={i}
          className={`voter-liner-group-line ${isActive(percentage) ? 'active' : ''}`}
          onMouseEnter={() => onHover(percentage)}
          onClick={() => onRate(percentage)}
        />,
      );
    }

    renderedPercentage.push(<div className={`voter-liner-group ${isActive(g, true) ? 'active' : ''}`} key={g}>{lines}</div>);
  }

  return (
    <VoteItem title={data.name}>
      <div className="voter-line" onMouseLeave={onLeave}>
        {renderedPercentage}
      </div>
      <div
        className={`voter-percentage ${(activeHover || !!data.rating) ? 'active' : ''}`}
        onClick={() => onRate(100)}
        onMouseEnter={() => onHover(100)}
        onMouseLeave={onLeave}
      >
        <span>{activeHover || data.rating}%</span>
      </div>
    </VoteItem>
  );
};

Voter.propTypes = {
  data: PropTypes.object,
  onRate: PropTypes.func,
  onAdd: PropTypes.func,
};

export default Voter;

