import React, { useState, useEffect } from 'react';

import API from '../../../../../api';
import { uploadPath } from '@api/api';
import { endpoints } from '@api/endpoints';

import { Cookie, User } from '@functions';

import Icon from '@common/SVG';

import Button from '../Button';
import Toolbar from '../Toolbar';
import VisionItem from '../VisionItem';

import { visionItems } from '../../../../../../api/visions';

import { CategoryWithExtension } from '@pages/Vision/pages/Detail';

import styles from './style.scss';
import './style.css';

export type Vision = {
  _id: string;
  id: string;
  category: string;
  type: string;
  name: string;
  image: string;
  title?: string;
  description?: string;
  link?: string;
  rating: number;
};

type Props = {
  type: string;
  activeCategory?: string;
  update: () => void;
  selectCategory: (categoryId: string) => void;
  categories: CategoryWithExtension[];
};

const VisionContent = ({ activeCategory, update, selectCategory, categories, type }: Props) => {
  const [data, setData] = useState<Vision[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [index, setIndex] = useState(0);
  const [drag, setDrag] = useState(false);
  const [uploadActive, setUploadActive] = useState(false);
  const [saving, setSaving] = useState(false);
  const [prepared, setPrepared] = useState([]);
  const [edit, setEdit] = useState(false);
  const [images, setImages] = useState([]);
  const [interval, setInterval] = useState(null);

  const category = categories.find((c) => c.id === activeCategory);

  useEffect(() => {
    if (category) {
      loadData();
    }
  }, [category]);

  const loadData = async (forced = false) => {
    console.log(forced);

    try {
      let data;
      try {
        data = await API.post(endpoints.vision.search, { type });
      } catch (err) {
        data = visionItems;
      }

      /**
       const types = await API.get(endpoints.vision.type.list);
       const users = await API.get(endpoints.user.list);
       const categories = await API.get(endpoints.vision.category.list);

       const processData = data.rows.map((d) => {
       const user = users.find((u) => u.userKey === d.userKey);
       const category = categories.find((u) => u.id === d.category);

       return {
       ...d,
       createdAt: d.dateCreated,
       name: upperFirst(lowerCase(d.id)),
       type: '619a978ccb97f40f7ddcb17f',
       user: user._id,
       category: category._id,
       rating: d.rating || null,
       };
       });

       console.log(processData);


       for (const d of processData) {
       try {
       await API.post(endpoints.vision.add, d);
       } catch (e) {
       }
       }
       **/

      setData(data);
      setDataLoaded(true);

      // anime
    } catch (error) {
      API.err(error);
    }
  };

  /**
  componentDidMount() {
    this.uploadWrap.addEventListener('dragenter', () => {
      this.setState({ drag: true });

      setTimeout(() => this.setState({ drag: false }), 3750);
    });
    this.uploadLabel.addEventListener('dragleave', () => this.setState({ drag: false }));

    if (this.props.category) {
      this.loadData();
    }

    this.interval = setInterval(() => this.nextImage(), 100);
  }

  componentWillUnmount() {
    this.uploadWrap.removeEventListener('dragenter', () => {
      this.setState({ drag: true });

      setTimeout(() => this.setState({ drag: false }), 5000);
    });
    this.uploadLabel.removeEventListener('dragleave', () => this.setState({ drag: false }));

    if (this.interval) {
      clearInterval(this.interval);
    }
  }  **/

  const filteredData = () => {
    if (!category || !data) {
      return [];
    }

    let relatedVisions = [...data];

    if (category.allCategories) {
      if (category.filter) {
        relatedVisions = relatedVisions.filter(category.filter);
      }

      if (category.sort) {
        relatedVisions = category.sort(relatedVisions);
      }

      return relatedVisions;
    }

    return relatedVisions.filter((v) => v.category === category._id);
  };

  /*
  updateImages(withoutReset) {
    if (withoutReset) {
      this.setState({ images: this.visions });
    } else {
      this.setState({ index: 0, images: [] });
    }
  }

  nextImage() {
    if (!this.state.dataLoaded) return;

    const { index } = this.state;
    const images = [...this.state.images];

    if (index < this.visions.length) {
      const image = this.visions[index];
      images.push(image);
    }

    if (this.category.forced) {
      if (index !== 0) {
        this.setState({ index: 0 });
      }
      return;
    }

    this.setState({ index: index + 1, images });
  }

  uploadImage(e) {
    this.setState({ uploadActive: true });

    if (!e.target.files || !e.target.files.length) {
      return;
    }

    Object.values(e.target.files).forEach(async (file) => {
      const formData = new FormData();

      formData.append('file', file);

      this.setState({ uploadActive: true });

      try {
        const response = await API.uploadFile(`/files/upload-vision.php`, formData);

        this.prepareVision(response);

        this.setState({ uploadActive: false, drag: false });
      } catch (e) {
        API.err(e);
      }
    });
  }

  prepareVision(image) {
    this.setState({ prepared: [...this.state.prepared, image] });
  }

  removeVision(index) {
    const prepared = [...this.state.prepared];

    prepared.splice(index, 1);

    this.setState({ prepared });
  }

  updateVision(index, update) {
    const prepared = [...this.state.prepared];

    prepared[index] = { ...prepared[index], ...update };

    this.setState({ prepared });
  }

  async addNewVision(index) {
    if (this.state.saving !== false) return;

    const vision = this.state.prepared[index];

    if (!vision.category) {
      alert('Category is required');
      return;
    }

    this.setState({ saving: index });

    vision.image = `${vision.name}`;
    vision.type = this.type;
    vision.user = User.hasAccess();

    try {
      await API.post(endpoints.vision.add, vision);

      this.setState({ saving: false });
      this.loadData();
      this.removeVision(index);
    } catch (error) {
      this.setState({ saving: false });
      API.err(error);
    }
  }
  */

  const splitImages = (divide: number) => {
    const images = filteredData();

    const imagesArray: Vision[][] = [];
    let pushTo = 0;

    for (let i = 0; i < divide; i++) {
      imagesArray.push([]);
    }

    for (let i = 0; i < images.length; i++) {
      imagesArray[pushTo].push(images[i]);
      pushTo++;
      if (pushTo >= divide) {
        pushTo = 0;
      }
    }

    return imagesArray;
  };

  const getImageLink = (vision: Vision) => {
    return `${uploadPath}/vision/${vision.name || vision.image}`.replace(/\s/g, '%20');
  };

  console.log(category);

  const renderGrid = () => {
    if (!category) return null;

    const blocks: JSX.Element[] = [];

    if (category.overview) {
      const height = ((window.innerWidth - 300) / 4) * 0.6;

      categories
        .filter((f) => !f.extra)
        .forEach((c) => {
          const image = data.find((v) => v.category === c._id);

          blocks.push(
            <div
              className={styles.categoryBlock}
              key={c._id}
              style={{ height: `${height}px` }}
              onClick={() => selectCategory(c.id)}
            >
              <div className={styles.categoryTitle}>{c.name}</div>
              <div className={styles.categoryOverviewImageWrap}>
                {image && (
                  <img
                    alt={image.name}
                    className={styles.categoryOverviewImage}
                    src={getImageLink(image)}
                  />
                )}
              </div>
            </div>,
          );
        });

      return <div className={styles.itemsOverview}>{blocks}</div>;
    } else if (category.forced) {
      categories
        .filter((f) => !f.extra)
        .forEach((c) => {
          const images = data.filter((v) => v.category === c._id);
          const height = (window.innerWidth - 400) / 5;

          blocks.push(
            <div className={styles.byCategoryBlock} key={c._id}>
              <div className={styles.byCategoryTitle} onClick={() => selectCategory(c.id)}>
                <div>{c.name}</div>
                <div className={styles.byCategoryExtra}>({images.length})</div>
              </div>
              <div className={styles.byCategoryItems}>
                {images.map((vision) => (
                  <VisionItem
                    key={vision._id}
                    image={getImageLink(vision)}
                    data={vision}
                    style={{ height: `${height}px` }}
                    onUpdate={() => loadData(true)}
                    squared
                  />
                ))}
              </div>
            </div>,
          );
        });

      return <div className={styles.categoryRows}>{blocks}</div>;
    } else {
      const divide = Math.floor(window.innerWidth / 350);
      const splitVisions = splitImages(divide);

      for (let i = 0; i < divide; i++) {
        blocks.push(
          <div key={i} className={styles.visionsColumn}>
            {splitVisions[i].map((vision) => (
              <VisionItem
                key={vision._id}
                image={getImageLink(vision)}
                data={vision}
                onUpdate={() => loadData(true)}
              />
            ))}
          </div>,
        );
      }

      return <div className={styles.visionsList}>{blocks}</div>;
    }
  };
  /*
  const renderAddModal = () => {
    const { categories } = this.props;
    const { prepared, saving } = this.state;

    return (
      <div className={`vision-posts-add ${prepared.length ? 'open' : ''}`}>
        {prepared.map((vision, index) => (
          <div className="vision-post-add" key={vision.name}>
            <img src={this.getImageLink(vision)} className="vision-post-image" />
            <div className="vision-post-add-contain">
              <VisionBody
                editing
                categories={categories}
                data={vision}
                onEdit={(update) => this.updateVision(index, update)}
              />
              <div className="vision-post-add-footer">
                <Button onClick={() => this.addNewVision(index)} loading={index === saving}>
                  Add image
                </Button>
              </div>
            </div>
          </div>
        ))}
        <button
          onClick={() => this.setState({ prepared: [] })}
          className="vision-post-add-close-btn"
        >
          <Icon icon="close" height={20} width={20} color="#555" />
        </button>
      </div>
    );
  }

  const renderDragAndDrop = () => {
    const { drag, uploadActive } = this.state;

    return (
      <label
        className={`vision-drag-label ${drag ? 'drag' : ''}`}
        ref={(node) => (this.uploadLabel = node)}
      >
        <input
          type="file"
          className="vision-drag-input"
          multiple
          onChange={(e) => e.target.value && this.uploadImage(e)}
        />
        <span>{uploadActive ? 'Uploading' : 'Drag && drop files to upload'}</span>
      </label>
    );
  }
*/
  return (
    <div
      // ${drag || uploadActive ? 'drag' : ''}
      className={styles.wrap}
      // ref={(node) => (this.uploadWrap = node)}
    >
      {/** renderDragAndDrop **/}
      {/** renderAddModal **/}
      <Toolbar categories={categories} update={update} type={type} />
      {dataLoaded && <div className={styles.contentScroll}>{renderGrid()}</div>}
    </div>
  );
};

export default VisionContent;
