export const type = {
  web: 'webConcepts',
  arduino: 'arduinoProjects',
  myOldWeb: 'ownOlderWebPages',
  page: 'pageConcepts',
  experiments: 'smallExperiments',
};

export const extraTypes = {
  fullWeb: 'fullWeb',
  util: 'util',
};

export const projectKeys = [
  type.web,
  type.page,
  type.arduino,
  type.myOldWeb,
  type.experiments,
].reverse();

export default [
  /** {
    id: 'humans-of-brno',
    type: type.web,
    link: 'https://dominiklaclavik.com/archive/projects/hob/humansofbrno.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/humans-of-brno.jpg',
  } **/
  {
    id: 'prinet',
    type: type.web,
    extraTypes: [extraTypes.fullWeb],
    link: 'https://prinet.sk',
    src: 'https://dominiklaclavik.com/assets/images/projects/prinet.jpg',
  },
  {
    id: 'ask-to-pick',
    type: type.web,
    extraTypes: [extraTypes.fullWeb],
    link: 'https://asktopick.com/login',
    src: 'https://dominiklaclavik.com/assets/images/projects/ask-to-pick.jpg',
  },
  {
    id: 'annette',
    type: type.web,
    extraTypes: [extraTypes.fullWeb],
    link: 'https://dominiklaclavik.com/archive/annette/index.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/annette.jpg',
  },
  {
    id: 'arduino-1',
    type: type.arduino,
    label: 'Rover 5',
    year: 2014,
    link: 'https://dominiklaclavik.com/archive/projects/rover5/rover5.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/arduino-1.jpg',
  },
  {
    id: 'arduino-3',
    type: type.arduino,
    label: '12V Control',
    year: 2014,
    link: 'https://dominiklaclavik.com/archive/projects/arduino/12vcontrol/12vcontrol.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/arduino-3.jpg',
  },
  {
    id: 'arduino-4',
    type: type.arduino,
    label: 'Outdoor lighting',
    year: 2014,
    link: 'https://dominiklaclavik.com/archive/projects/arduino/outdoorlighting/ol.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/arduino-4.jpg',
  },
  {
    id: 'arduino-2',
    type: type.arduino,
    label: 'Arduino projects',
    year: 2014,
    link: 'https://dominiklaclavik.com/archive/projects/arduino/arduino.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/arduino-2.jpg',
  },
  {
    id: 'gabika',
    type: type.page,
    label: 'Loader with Gabi',
    year: 2016,
    link: 'https://dominiklaclavik.com/archive/projects/special/loaderwithgabi/lwg.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/gabika.jpg',
  },
  {
    id: 'html-1',
    type: type.myOldWeb,
    label: '',
    year: 2014,
    src: 'https://dominiklaclavik.com/assets/images/projects/html-1.jpg',
    link: 'https://dominiklaclavik.com/archive/projects/html/html.html',
  },
  {
    id: 'html-2',
    type: type.myOldWeb,
    year: 2014,
    disabled: true,
    src: 'https://dominiklaclavik.com/assets/images/projects/html-2.jpg',
  },
  {
    id: 'html-3',
    type: type.myOldWeb,
    year: 2014,
    disabled: true,
    src: 'https://dominiklaclavik.com/assets/images/projects/html-3.jpg',
  },
  {
    id: 'html-4',
    type: type.myOldWeb,
    year: 2014,
    disabled: true,
    src: 'https://dominiklaclavik.com/assets/images/projects/html-4.jpg',
  },
  {
    id: 'html-5',
    type: type.myOldWeb,
    year: 2014,
    src: 'https://dominiklaclavik.com/assets/images/projects/html-5.jpg',
    link: 'https://dominiklaclavik.com/archive/projects/special/aurora/aurora.html',
  },
  {
    id: 'lens-1',
    label: 'Lenses',
    type: type.myOldWeb,
    year: 2014,
    link: 'https://dominiklaclavik.com/archive/projects/lenses/flektogon/flektogon.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/lens-1.jpg',
  },
  {
    id: 'lens-2',
    type: type.myOldWeb,
    year: 2014,
    link: 'https://dominiklaclavik.com/archive/projects/lenses/hoya/hoya.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/lens-2.jpg',
  },
  {
    id: 'lens-3',
    type: type.myOldWeb,
    year: 2014,
    link: 'https://dominiklaclavik.com/archive/projects/lenses/sigma/sigma.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/lens-3.jpg',
  },
  {
    id: 'lens-4',
    type: type.myOldWeb,
    year: 2014,
    link: 'https://dominiklaclavik.com/archive/projects/lenses/flektogon/flektogon.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/lens-4.jpg',
  },
  {
    id: 'object-1',
    type: type.experiments,
    label: 'Infinity night sky',
    year: 2015,
    link: 'https://dominiklaclavik.com/archive/projects/tiny/infinitysky/infinitysky.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/object-1.jpg',
  },
  {
    id: 'object-2',
    type: type.experiments,
    label: 'Particle system',
    year: 2015,
    link: 'https://dominiklaclavik.com/archive/projects/tiny/particlesystem/particlesystem.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/object-2.jpg',
  },
  {
    id: 'object-3',
    type: type.experiments,
    label: 'Object 3D torus',
    year: 2015,
    link: 'https://dominiklaclavik.com/archive/projects/tiny/torus/torus.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/object-3.jpg',
  },
  {
    id: 'object-4',
    type: type.experiments,
    label: 'Object 3D cube',
    year: 2015,
    link: 'https://dominiklaclavik.com/archive/projects/tiny/cube/cube.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/object-4.jpg',
  },
  {
    id: 'object-5',
    type: type.experiments,
    label: 'Object 3D cone',
    year: 2015,
    link: 'https://dominiklaclavik.com/archive/projects/tiny/cone/cone.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/object-5.jpg',
  },
  {
    id: 'old-page-404',
    type: type.page,
    extraTypes: [extraTypes.util],
    year: 2015,
    src: 'https://dominiklaclavik.com/assets/images/projects/old-page-404.jpg',
    link: 'https://dominiklaclavik.com/archive/404.html',
  },
  {
    id: 'old-page-contact',
    type: type.myOldWeb,
    year: 2015,
    link: 'https://dominiklaclavik.com/archive/contact.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/old-page-about.jpg',
  },
  {
    id: 'old-page-home',
    type: type.myOldWeb,
    year: 2015,
    src: 'https://dominiklaclavik.com/assets/images/projects/old-page-home.jpg',
    link: 'https://dominiklaclavik.com/archive/index.html',
  },
  {
    id: 'old-page-in-development',
    type: type.page,
    extraTypes: [extraTypes.util],
    year: 2015,
    src: 'https://dominiklaclavik.com/assets/images/projects/old-page-in-development.jpg',
    disabled: true,
  },
  {
    id: 'old-page-portfolio',
    type: type.myOldWeb,
    year: 2014,
    link: 'https://dominiklaclavik.com/archive/portfolio.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/old-page-portfolio.jpg',
  },
  {
    id: 'old-page-projects',
    type: type.myOldWeb,
    year: 2015,
    link: 'https://dominiklaclavik.com/archive/projects.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/old-page-projects.jpg',
  },
  {
    id: 'old-page-whoami',
    type: type.page,
    year: 2014,
    link: 'https://dominiklaclavik.com/archive/projects/special/about/about.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/old-page-whoami.jpg',
  },
  {
    id: 'portfolio-analog',
    type: type.page,
    year: 2016,
    link: 'https://dominiklaclavik.com/archive/portfolio/analogphotography.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/portfolio-analog.jpg',
  },
  {
    id: 'portfolio-cities',
    type: type.page,
    year: 2016,
    src: 'https://dominiklaclavik.com/assets/images/projects/portfolio-cities.jpg',
    disabled: true,
  },
  {
    id: 'portfolio-martello',
    type: type.myOldWeb,
    year: 2015,
    link: 'https://dominiklaclavik.com/archive/portfolio/jspl/martello/martello.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/portfolio-martello' + '.jpg',
  },
  {
    id: 'portfolio-nature',
    type: type.myOldWeb,
    year: 2014,
    link: 'https://dominiklaclavik.com/archive/portfolio/landscapes.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/portfolio-nature.jpg',
  },
  {
    id: 'portfolio-night',
    type: type.myOldWeb,
    year: 2016,
    link: 'https://dominiklaclavik.com/archive/portfolio/nightscapes.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/portfolio-night.jpg',
  },
  {
    id: 'portfolio-people',
    type: type.myOldWeb,
    year: 2014,
    link: 'https://dominiklaclavik.com/archive/portfolio/peopleandevents.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/portfolio-people.jpg',
  },
  {
    id: 'portfolio-tatratea',
    type: type.page,
    year: 2016,
    link: 'https://dominiklaclavik.com/archive/portfolio/tatratea.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/portfolio-tatratea.jpg',
  },
  {
    id: 'repair',
    type: type.myOldWeb,
    year: 2013,
    link: 'https://dominiklaclavik.com/archive/projects/apple/apple.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/repair.jpg',
  },
  {
    id: 'sony',
    label: 'Sony NEX-5R',
    type: type.myOldWeb,
    year: 2013,
    link: 'https://dominiklaclavik.com/archive/projects/lenses/nex5r/nex5r.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/sony.jpg',
  },
  {
    id: 'videos',
    type: type.myOldWeb,
    year: 2014,
    link: 'https://dominiklaclavik.com/archive/projects/videos/videos.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/videos.jpg',
  },
  {
    id: 'watches',
    type: type.page,
    year: 2016,
    link: 'https://dominiklaclavik.com/archive/projects/special/screensavers/projectwatches.html',
    src: 'https://dominiklaclavik.com/assets/images/projects/watches.jpg',
  },
];
