import React from 'react';
import PropTypes from 'prop-types';

import { camelCase, kebabCase } from 'lodash-es';

import { imgPath } from '../../../../../../api/api';

import config, { privacyPolicy } from '../../../../../../assets/apps';

import './style.css';

const getLink = (app, extension) => {
  if (!app) {
    return `${imgPath}/develop/no-icon.png`;
  }

  const id = kebabCase(app.id || app.name);

  if (extension) {
    return `${imgPath}/develop/${id}-${extension}.png`;
  }

  return `${imgPath}/develop/${id}.png`;
};

const getIcons = (app, extension) => {
  const src = getLink(app, extension);

  return [
    <img
      key={app.name}
      src={src}
      className="develop-app-icon"
      onError={(e) => e.target.src = getLink()}
    />,
  ];
};

const renderDots = (app, key) => {
  const level = app[key];

  const dots = [];

  for (let i = 1; i <= 10; i++) {
    dots.push(<div key={i} className={`bar-dot ${i < level ? 'filled' : ''}`}/>);
  }

  return (
    <div className="bar-block">
      <div className="bar-text">{camelCase(key)}</div>
      <div className="bar-dots">{dots}</div>
    </div>
  );
};

const App = ({ app, showExtras }) => (
  <div className="develop-app">
    <div className="develop-app-icons">{getIcons(app, app.preferExtension)}</div>
    <div className="develop-app-name">{app.name}</div>
    {app.promotion && <div className="develop-app-promotion">{app.promotion}</div>}
    <div className="develop-app-description">{app.description}</div>
    {app.tags && <div className="develop-app-tags">
      {app.tags.map((tag) => <div key={tag} className="develop-app-tag">{tag}</div>)}
    </div>
    }
    {showExtras && config.mapExtra.map(
      (type) => <div key={type} className="develop-app-more">{renderDots(app, type)}</div>,
    )}
    {app.web && <div className="develop-app-pages">
      Web ideas:&nbsp;&nbsp;{app.web.map((web) => <div className="develop-app-page" key={web}>{web}</div>)}
    </div>
    }
    <div className="develop-app-functions"></div>
    <div className="develop-app-check"></div>
    <div className="develop-app-vote"></div>
    {!!privacyPolicy[app.id] && <div className="develop-app-links">
      {showExtras && <div className="develop-app-link">
        <button onClick={history.push(`/apps/${kebabCase(app.id)}`)}>Show app</button>
      </div>
      }
      <div className="develop-app-link">
        <button onClick={history.push(`/apps/privacy/${kebabCase(app.id)}`)}>Privacy policy</button>
      </div>
    </div>
    }
  </div>
);

App.propTypes = {
  app: PropTypes.object.isRequired,
  showExtras: PropTypes.bool,
};

export default App;
