import React, { useEffect, useState, KeyboardEvent } from 'react';
import { toLower } from 'lodash-es';

import API from '@api/index';

import { Cookie } from '@functions';

import Icon from '@common/Icon';

import f1Image from '@assets/img/f1.jpg';

import { endpoints } from '@api/endpoints';

import { SimpleObject } from '@ts/General';

import './style.css';

const Authorization = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const [authorized, setAuthorized] = useState<SimpleObject | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (inputValue.length === 2) {
      authorize();
    }
  }, [inputValue]);

  const authorize = async () => {
    try {
      setLoading(true);

      const data = await API.post(endpoints.user.authorize, {
        key: toLower(inputValue),
        right: 'f1:vote',
      });

      setAuthorized(data);
    } catch (err) {
      setError(true);
      setLoading(false);
      setInputValue('');
    }
  };

  const setKey = () => {
    if (authorized) {
      Cookie.set('userKey', authorized.userKey as string);
      Cookie.set('userId', authorized._id as string);
      Cookie.set('user', JSON.stringify(authorized));

      setTimeout(() => location.reload(), 50);
    }
  };

  const handleSubmit = (e: KeyboardEvent) => {
    if (e.charCode === 13 && inputValue.length > 1) {
      authorize();
    }
  };

  return (
    <div className="f1-authorization-wrap">
      <div className="f1-authorization-image-wrap">
        <img alt="authorization" className="f1-authorization-image" src={f1Image} />
      </div>
      <div className="f1-authorization">
        {!authorized ? (
          <>
            <div className="f1-authorization-title">Set up your initials to authorize</div>
            <div className="f1-authorization-input-wrap">
              <label className="f1-authorization-input-label">
                <Icon size={20} color="#fff">
                  vpn_key
                </Icon>
                <input
                  className="f1-authorization-input"
                  value={inputValue}
                  placeholder="Enter your initials"
                  onKeyPress={handleSubmit}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    if (error) setError(false);
                  }}
                />
              </label>
              <button
                onClick={authorize}
                disabled={loading}
                className={`f1-authorization-button ${inputValue.length > 1 ? 'active' : ''}`}
              >
                Authorize
              </button>
            </div>
            {error && <div className="f1-authorization-error">Invalid initials!</div>}
          </>
        ) : (
          <>
            <div className="f1-authorization-title">Authorized as {authorized.username}</div>
            <button onClick={setKey} className="f1-authorization-button active continue">
              Continue
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Authorization;
