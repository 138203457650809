import React from 'react';
import {
  addYears,
  differenceInMonths,
  endOfYear,
  format,
  getMonth,
  getYear,
  isSameMonth,
  isSameYear,
  setMonth,
  startOfYear,
  subYears,
} from 'date-fns';

import styles from './style.scss';
import { ButtonBase } from '@mui/material';
import classNames from 'classnames';

type Props = {
  onChange: (date: Date) => void;
  minStart: Date;
  value: Date;
};

const MonthSelect = ({ onChange, minStart, value }: Props) => {
  const firstMonth = isSameYear(minStart, value) ? getMonth(minStart) : 0;

  const months = Array.from({ length: 12 - firstMonth }, (v, k) => k + firstMonth).map((m) => {
    return setMonth(new Date(value), m);
  });

  const isFirstYear = isSameYear(minStart, value);

  const selectedClass = (date: Date) => isSameMonth(date, value) && styles.selected;
  const visibleClass = (date: Date) =>
    Math.abs(differenceInMonths(date, value)) < 2 && styles.visible;

  const offset = getMonth(value) - firstMonth - 1;

  const prevYear = endOfYear(subYears(value, 1));
  const nextYear = startOfYear(addYears(value, 1));

  return (
    <div className={styles.wrap}>
      <div className={styles.scroller}>
        <div className={styles.monthSelect} style={{ transform: `translateY(${offset * -44}px)` }}>
          <ButtonBase
            onClick={() => onChange(prevYear)}
            className={classNames(
              styles.yearButton,
              isFirstYear && styles.enabled,
              visibleClass(prevYear),
            )}
          >
            {getYear(prevYear)}
          </ButtonBase>
          {months.map((date, i) => (
            <ButtonBase
              key={i}
              onClick={() => {
                onChange(date);
              }}
              className={classNames(styles.monthButton, selectedClass(date), visibleClass(date))}
            >
              {format(date, 'MMM yy')}
            </ButtonBase>
          ))}
          <ButtonBase
            className={classNames(styles.yearButton, styles.enabled, visibleClass(nextYear))}
            onClick={() => onChange(nextYear)}
          >
            {getYear(nextYear)}
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};
export default MonthSelect;
