// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-xzMx1{display:flex;flex-direction:column;font-size:15px;background:#ccc;gap:20px;padding:20px;box-sizing:border-box;min-height:100svh}.header-TxBun{display:flex;align-items:center;justify-content:space-between;min-height:42px;padding:0 20px;background:#222;color:#eee;border-radius:10px;box-shadow:0 2px 20px 0 rgba(0,0,0,.5)}.headerTitle-\\+FbXH{display:flex;align-items:center}.headerIcon-Sgjjl{margin:1px 10px 0 0}.headerText-jTeTO{font-size:12px;text-transform:uppercase}.container-QtT1Y{display:flex;align-items:flex-start;gap:20px}.tableWrapDark-HlxwL{background:#222;color:#eee;border-radius:10px;box-shadow:0 2px 20px 0 rgba(0,0,0,.5);width:100%;overflow-x:auto}.itemText-C5C2j{color:#eee;font-weight:normal}.itemLink-5tVCv{color:#3895d3;text-decoration:underline;font-weight:normal}.itemSubText-kYuSZ{opacity:.5;font-family:\"Open Sans\",sans-serif}.itemLink-5tVCv,.itemText-C5C2j{text-overflow:ellipsis;max-width:60vw;overflow:hidden;display:-webkit-box;-webkit-line-clamp:2;line-clamp:2;-webkit-box-orient:vertical}.itemLink-5tVCv{white-space:nowrap}.itemBack-xbKt3{display:flex;gap:15px;align-items:center}@media(max-width: 1024px){.container-QtT1Y{flex-direction:column}.navigation-fyKCS{align-self:center}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-xzMx1",
	"header": "header-TxBun",
	"headerTitle": "headerTitle-+FbXH",
	"headerIcon": "headerIcon-Sgjjl",
	"headerText": "headerText-jTeTO",
	"container": "container-QtT1Y",
	"tableWrapDark": "tableWrapDark-HlxwL",
	"itemText": "itemText-C5C2j",
	"itemLink": "itemLink-5tVCv",
	"itemSubText": "itemSubText-kYuSZ",
	"itemBack": "itemBack-xbKt3",
	"navigation": "navigation-fyKCS"
};
export default ___CSS_LOADER_EXPORT___;
