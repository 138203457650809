import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Loading = ({ loading, color = '#9b9b00' }) => {
  const style = {
    backgroundColor: color,
  };

  return (
    <div className={`vote-loading-wrap ${loading ? 'active' : ''}`}>
      <div style={style} className="vote-loading-bar"/>
      <div style={style} className="vote-loading-bar"/>
      <div style={style} className="vote-loading-bar"/>
    </div>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool,
  color: PropTypes.string,
};

export default Loading;

