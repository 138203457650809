import request, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Env } from '../functions';
import { includes, isObject, isArray } from 'lodash-es';
import settings from '../assets/settings';
import { SimpleObject, UnsafeAny } from '@ts/General';

const validRequest = (resp: AxiosResponse) => {
  // console.log(resp.data, !resp.data, isArray(resp.data), isObject(resp.data));
  // console.log('valid', (resp.status === 200 || resp.status === 201) && (!resp.data || isArray(resp.data) || isObject(resp.data)));

  return (
    (resp.status === 200 || resp.status === 201) &&
    (!resp.data || isArray(resp.data) || isObject(resp.data) || resp.data === '\n')
  ); // wtf
};

const ALLOWED_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const forceUseProduction = false;

export const web = 'https://dominiklaclavik.com';
export const imgPath = 'https://dominiklaclavik.com/assets/images';
export const uploadPath = 'https://dominiklaclavik.com/uploads';
export const filePath = 'https://dominiklaclavik.com/file';
export const path =
  Env.isProduction() || forceUseProduction
    ? 'https://dominiklaclavik.com/api'
    : 'http://127.0.0.1:8080/api';
export const apiPath =
  Env.isProduction() || forceUseProduction
    ? 'https://api-unity-n6ohk.ondigitalocean.app'
    : 'http://localhost:8080';
export const postfix = '.php';

// https://api-unity.com

type This = {
  path: string;
  params: AxiosRequestConfig;
  postfix: string;
};

const Api = function (this: This, params: AxiosRequestConfig = {}) {
  this.path = includes(params.url, '/anim') ? apiPath : path;
  this.params = params;
  this.postfix = includes(params.url, '/anim') ? '' : postfix;
};

Api.prototype.headers = function (this: This, header: string) {
  return new (Api as UnsafeAny)({ ...this.params, header });
};

Api.prototype.data = function (data: SimpleObject) {
  return new (Api as UnsafeAny)({ ...this.params, data });
};

Api.prototype.get = function (url: string) {
  return new (Api as UnsafeAny)({ ...this.params, method: ALLOWED_METHODS.GET, url });
};

Api.prototype.post = function (url: string) {
  return new (Api as UnsafeAny)({ ...this.params, method: ALLOWED_METHODS.POST, url });
};

Api.prototype.put = function (url: string) {
  return new (Api as UnsafeAny)({ ...this.params, method: ALLOWED_METHODS.PUT, url });
};

Api.prototype.delete = function (url: string) {
  return new (Api as UnsafeAny)({ ...this.params, method: ALLOWED_METHODS.DELETE, url });
};

Api.prototype.send = function () {
  return new Promise((resolve, reject) => {
    const { method, headers, data, url } = this.params;

    const req = {
      baseURL: this.path,
      method,
      url: includes(url, '.php') ? url : url + this.postfix,
      headers: url.includes('/anim')
        ? {
            'X-API-KEY': settings.apiKey,
            ...(headers || {}),
          }
        : headers || {},
      data: data || {},
      withCredentials: true,
      xhrFields: {
        withCredentials: true,
      },
    };

    if (localStorage.getItem('anim-session')) {
      req.headers.authorization = `Bearer ${
        JSON.parse((localStorage.getItem('anim-session') as string | undefined) || '{}').token
      }`;
    }

    request(req)
      .then((response) => (validRequest(response) ? resolve(response.data) : reject(response)))
      .catch((response) => reject(response));
  });
};

export const uploadFile = (url: string, file: UnsafeAny) =>
  new Promise((resolve, reject) => {
    request
      .post(path + (includes(url, '.php') ? url : url + postfix), file)
      .then((response) => (validRequest(response) ? resolve(response.data) : reject(response)))
      .catch((response) => reject(response));
  });

export default new (Api as UnsafeAny)();
