import React, { MouseEvent } from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';

type Props = {
  value: boolean;
  onClick: (e: MouseEvent) => void;
};

const Favorite = ({ value, onClick }: Props) => {
  return (
    <IconButton onClick={onClick}>
      <Icon color={value ? 'primary' : 'disabled'}>{value ? 'star' : 'star_outline'}</Icon>
    </IconButton>
  );
};

export default Favorite;
