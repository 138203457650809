import * as React from 'react';
import Slider from '@mui/material/Slider';

import './style.css';

type Props = {
  value: number;
  onChange: (value: string | number) => void;
};

const RangeSlider = ({ value, onChange }: Props) => {
  return (
    <div className="material-range-slider">
      <Slider
        step={5}
        marks
        min={5}
        max={100}
        value={value || 0}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        onChange={(e) => onChange(e?.target?.value || 0)}
      />
    </div>
  );
};

export default RangeSlider;
