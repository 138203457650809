import React, { useEffect, useState, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import { includes } from 'lodash-es';

import API from '@api/index';
import { endpoints } from '@api/endpoints';

import { Cookie, User } from '@functions';

import { User as UserType } from '@ts/User';
import { GameContext } from '@ts/Game';
import { Identifier } from '@ts/General';

const defaults = {
  users: [],
  gameTypes: [],
  gameStats: {},
} as GameContext;

export const GamesContext = createContext(defaults);

type Props = { location: Location; children: JSX.Element | string };

const GamesDataProvider = ({ location, children }: Props) => {
  const [data, setData] = useState(defaults);
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState(Cookie.get('game-season') || 1);

  const loadData = async (season?: number) => {
    if (loading) return;

    console.log(season);

    setLoading(true);

    try {
      const gameTypes = await API.get(endpoints.game.type.list);
      const users = await API.get(endpoints.user.list);

      const filteredUsers = users.filter((u: UserType) => User.hasRight('games:play', u));

      setData({ gameTypes, users: filteredUsers });

      const gameStats = await API.get(
        endpoints.game.stats.allBySeason(selectedSeason as unknown as Identifier),
      );

      setData({ gameTypes, users: filteredUsers, gameStats });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (includes(location.pathname, '/games') && !dataLoaded) {
      console.log(location.pathname);

      loadData();
    }
  }, [location.pathname, dataLoaded]);

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <GamesContext.Provider value={[data, setSelectedSeason, setDataLoaded, dataLoaded]}>
        {children}
      </GamesContext.Provider>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withRouter(GamesDataProvider);
