import React from 'react';
import { ButtonBase } from '@mui/material';

import { styled } from '@mui/material/styles';

import classNames from 'classnames';

import './style.css';
import DarkListItemCircleButton from '@common/DarkListItemCircleButton';

const DarkListItemDetailedStyled = styled(ButtonBase)({
  color: '#ddd',
  fontFamily: 'Open sans',
  fontSize: 14,
  fontWeight: 600,
  background: '#222',
  transition: `.25s ease all`,
  textTransform: 'uppercase',
  letterSpacing: 2,
  minHeight: '54px',
  whiteSpace: 'initial',
  padding: '8px 16px',
  alignItems: 'initial',
  // borderRadius: 12,
  borderBottom: '2px solid #333',
  ['&:hover']: {
    color: 'white',
  },
  ['&:active']: {},
  ['&:focus']: {},
  ['&:disabled']: {
    opacity: 0.4,
  },
  ['&.active']: {
    background: '#222',
  },
  ['&.fade']: {
    background: '#ddd',
    color: '#222',
    borderBottom: 'none',
  },
  ['&.white']: {
    background: 'transparent',
    color: '#222',
    borderBottom: 'none',
  },
  ['&.rounded']: {
    margin: '0 20px',
    borderRadius: 100,
    marginBottom: '4px',
  },
});

type Props = {
  onClick?: (e: MouseEvent) => void;
  label?: string;
  date?: string;
  subLabel?: string;
  children?: string;
  active?: boolean;
  fade?: boolean;
  white?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  count?: number;
  countHelper?: string;
  actions?: DarkListItemAction[];
  items?: DarkListItemType[];
};

type DarkListItemAction = {
  onClick: () => void;
  icon: string;
};

export type DarkListItemType = {
  label: string;
  value: number | string;
};

const DarkListItemDetailed = ({
  onClick,
  date,
  label,
  subLabel,
  count,
  countHelper,
  active,
  disabled,
  fade,
  white,
  rounded,
  actions,
  items,
}: Props) => {
  return (
    <DarkListItemDetailedStyled
      className={classNames('dark-list-item-detailed', { active, fade, white, rounded })}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      onClick={onClick ? onClick : null}
      disabled={disabled}
    >
      <div className="dark-list-item-detailed-count">
        <div>{count}</div>
        {countHelper && <div className="dark-list-item-detailed-count-helper">{countHelper}</div>}
      </div>
      <div className="dark-list-item-detailed-body">
        <div className="dark-list-item-detailed-label">{label}</div>
        <div className="dark-list-item-detailed-sub-label">{subLabel && <div>{subLabel}</div>}</div>
        <div className="dark-list-item-detailed-date">{date}</div>
      </div>
      {items && (
        <div className="dark-list-item-detailed-items">
          {items.map((item, index: number) => {
            return (
              <div className="dark-list-item-detailed-items-item" key={index}>
                <div className="dark-list-item-detailed-items-item-label">{item.label}</div>
                <div className="dark-list-item-detailed-items-item-value">
                  <span>{item.value}</span>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {actions && (
        <div className="dark-list-item-detailed-actions">
          {actions.map((action, index: number) => {
            return (
              <DarkListItemCircleButton
                {...action}
                key={index}
                className="dark-list-item-detailed-actions-button"
              />
            );
          })}
        </div>
      )}
    </DarkListItemDetailedStyled>
  );
};

export default DarkListItemDetailed;
