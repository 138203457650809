type Cleanable = {
  createdAt?: string;
  updatedAt?: string | null;
  _id?: string;
  __v?: number;
};

const cleanObject = <T extends Cleanable>(obj: T): Partial<T> => {
  const newObj = { ...obj };

  delete newObj.createdAt;
  delete newObj.updatedAt;
  delete newObj._id;
  delete newObj.__v;

  return newObj;
};

export default {
  cleanObject,
};
