import React, { useState, useEffect, MouseEvent } from 'react';
import { ButtonBase, Icon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import './style.css';

const MONTHS = 'months';
const YEARS = 'years';

const types = [MONTHS, YEARS];

const ButtonBaseCountStyled = styled(ButtonBase)({
  color: '#ddd',
  minHeight: 40,
  padding: '0 14px',
  borderRadius: '6px',
  'font-size': 40,
  minWidth: 100,
  backgroundColor: '#ffffff11',
});

const ButtonBaseTypeStyled = styled(ButtonBase)({
  color: '#ddd',
  minHeight: 40,
  padding: '0 14px',
  borderRadius: '6px',
  'font-size': 20,
  letterSpacing: 2,
  minWidth: 150,
  textTransform: 'uppercase',
  backgroundColor: '#ffffff11',
});

const ButtonBaseArrowHorizontalStyled = styled(ButtonBase)({
  color: '#ddd',
  padding: '14px 0',
  margin: '20px 2px',
  borderRadius: '6px',
  backgroundColor: '#ffffff11',
  '&:hover': {
    color: 'white',
    backgroundColor: '#ffffff11',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    color: 'white',
    backgroundColor: '#ffffff11',
    borderColor: '#005cbf',
  },
  '&:focus': {
    backgroundColor: '#ffffff22',
  },
});

const ButtonBaseArrowVerticalStyled = styled(ButtonBase)({
  color: '#ddd',
  padding: '0 14px',
  margin: '2px 20px',
  borderRadius: '6px',
  backgroundColor: '#ffffff11',
  '&:hover': {
    color: 'white',
    backgroundColor: '#ffffff11',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    color: 'white',
    backgroundColor: '#ffffff11',
    borderColor: '#005cbf',
  },
  '&:focus': {
    backgroundColor: '#ffffff22',
  },
});

type Props = {
  values?: { count: number; type?: string };
  defaultCount?: number;
  defaultType?: string;
  onChange: (change: { value: { count: number; type: string } }) => void;
  onSubmit: () => void;
  onClick?: (e: MouseEvent) => void;
  className?: string;
  disabled?: boolean;
};

const IntervalSelect = ({
  values,
  defaultCount = values?.count || 2,
  defaultType = values?.type || YEARS,
  onChange,
  onSubmit, // Enter
  onClick,
  className,
  disabled,
}: Props) => {
  const [count, setCount] = useState(defaultCount);
  const [type, setType] = useState(defaultType);

  const [never, setNever] = useState<boolean | string>(defaultType);

  useEffect(() => {
    if (values === null) {
      setNever(true);
    }
  }, []);

  useEffect(() => {
    onChange({ value: { count, type } });
  }, [count, type]);

  const onKeydown = (e: KeyboardEvent) => {
    switch (e.keyCode) {
      case 37:
        moveCount(-1);
        break;
      case 39:
        moveCount(1);
        break;
      case 38:
        moveType(-1);
        break;
      case 40:
        moveType(1);
        break;
      case 13:
        if (onSubmit) onSubmit();
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeydown);

    return () => window.removeEventListener('keydown', onKeydown);
  }, [type, count]);

  const moveType = (jump: number) => {
    const index = types.findIndex((t) => t === type);
    const newIndex = index + jump;

    if (newIndex === types.length) {
      setType(types[0]);
    } else if (newIndex < 0) {
      setType(types[types.length - 1]);
    } else {
      setType(types[newIndex]);
    }
  };

  const moveCount = (jump: number) => {
    const newCount = count + jump;

    if (newCount < 1) {
      return;
    }

    setCount(newCount);
  };

  const t = (
    <>
      <div className="interval-select">
        <div className="interval-select-option">
          <Typography variant="caption" align="center">
            Count
          </Typography>
          <div className="interval-select-horizontal">
            <ButtonBaseArrowHorizontalStyled onClick={() => moveCount(-1)}>
              <Icon>keyboard_arrow_left</Icon>
            </ButtonBaseArrowHorizontalStyled>
            <ButtonBaseCountStyled className={className} onClick={onClick} disabled={disabled}>
              <div className="interval-select-label">{count}</div>
            </ButtonBaseCountStyled>
            <ButtonBaseArrowHorizontalStyled onClick={() => moveCount(1)}>
              <Icon>keyboard_arrow_right</Icon>
            </ButtonBaseArrowHorizontalStyled>
          </div>
        </div>
        <div className="interval-select-option">
          <Typography variant="caption" align="center">
            Type
          </Typography>
          <ButtonBaseArrowVerticalStyled onClick={() => moveType(-1)}>
            <Icon>keyboard_arrow_up</Icon>
          </ButtonBaseArrowVerticalStyled>
          <ButtonBaseTypeStyled className={className} onClick={onClick} disabled={disabled}>
            <div className="interval-select-label">{type}</div>
          </ButtonBaseTypeStyled>
          <ButtonBaseArrowVerticalStyled onClick={() => moveType(1)}>
            <Icon>keyboard_arrow_down</Icon>
          </ButtonBaseArrowVerticalStyled>
        </div>
      </div>
      {never && 'NEVER'}
    </>
  );

  return t;
};

export default IntervalSelect;
