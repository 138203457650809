import React, { MouseEvent } from 'react';

import './style.css';

type Props = {
  onClick: (e: MouseEvent) => void;
  children: JSX.Element | string;
  error?: boolean;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
};

const Button = ({ onClick, className, disabled, error, loading, children, ...props }: Props) => {
  return (
    <button
      className={`f1-button ${error ? 'error' : ''} ${loading ? 'loading' : ''} ${
        className || ''
      } ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
      {...props}
    >
      <span>{children}</span>
    </button>
  );
};

export default Button;
