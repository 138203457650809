import React, { useState, useEffect } from 'react';
import { lowerCase, upperFirst } from 'lodash-es';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';

import API from '@api/index';

import { Common } from '@functions';

import Menu, { MenuItem } from '@layout/Menu';
import { endpoints } from '@api/endpoints';
import Authorization from '@pages/utils/Authorization';

import Content from '../../components/Content';

export const name = (id: string) => upperFirst(lowerCase(id));
import { UnsafeAny } from '@ts/General';
import house from '@pages/Vision/house-svgrepo-com.svg';

import styles from './style.scss';

export type CategoryWithExtension = {
  id: string;
  allCategories?: boolean;
  forced?: boolean;
  overview?: boolean;
  sort?: (data: UnsafeAny[]) => UnsafeAny[];
  filter?: (filter: UnsafeAny) => boolean;
  parent?: string;
  extra?: string;

  // Original category
  _id?: string;
  name?: string;
  type?: string;
};

const extraItems: CategoryWithExtension[] = [
  {
    id: 'overview',
    allCategories: true,
    sort: (data) => Common.sort(data, 'rating').reverse(),
    forced: true,
    overview: true,
  },
  {
    id: 'topRated',
    allCategories: true,
    sort: (data) => Common.sort(data, 'rating').reverse(),
    filter: (item) => item.rating >= 85,
  },
  {
    id: 'newest',
    allCategories: true,
    sort: (data) => Common.sort(data, 'dateCreated').reverse().slice(0, 25),
  },
  {
    id: 'byCategories',
    allCategories: true,
    forced: true,
    sort: (data) => Common.sort(data, 'rating'),
  },
];

const prepareMenu = (data: CategoryWithExtension[]): CategoryWithExtension[] => {
  const preparedItems = [...extraItems.map((i) => ({ ...i, extra: true })), ...data].map((i) => ({
    ...i,
    name: i.parent ? `${name(i.parent)} - ${name(i.id)}` : name(i.id),
  }));

  const items = preparedItems.filter((i) => !i.parent);

  preparedItems
    .filter((i) => i.parent)
    .forEach((i) => {
      const index = items.findIndex((item) => item.id === i.parent);

      items.splice(index + 1, 0, i);
    });

  return items as CategoryWithExtension[];
};

const Vision = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [menu, setMenu] = useState<CategoryWithExtension[]>([]);
  const [active, setActive] = useState('');

  const match = useRouteMatch();

  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-ignore */
  const type = match.params.type;

  useEffect(() => {
    loadData();
  }, [type]);

  const loadData = async () => {
    try {
      const allData = await API.get(endpoints.vision.category.list);

      const data = allData.filter((d: CategoryWithExtension) => d.type === type);

      const menu = prepareMenu(data);

      setMenu(menu);
      setDataLoaded(true);
    } catch (e) {
      API.err(e);
    }
  };

  const loader = (
    <div className={classNames(styles.loader, dataLoaded && styles.loaded)}>
      <div className={styles.loaderCircle} />
    </div>
  );

  return (
    <div className={styles.wrap}>
      {loader}
      {dataLoaded && (
        <div className={styles.content}>
          <Menu
            selectFirst
            items={menu as MenuItem[]}
            onSelect={setActive}
            footer={
              <div className={styles.nav}>
                <img src={house} className={styles.navImage} alt="House" />
              </div>
            }
          />
          <Content
            type={type}
            activeCategory={active}
            categories={menu}
            update={() => loadData()}
            selectCategory={setActive}
          />
          <Authorization />
        </div>
      )}
    </div>
  );
};

export default Vision;
