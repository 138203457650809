import React, { MouseEvent } from 'react';
import classNames from 'classnames';

import './style.css';

type Props = {
  onClick?: (e: MouseEvent) => void;
  className?: string;
  children?: JSX.Element | string;
  active?: boolean;
  stopEvents?: boolean;
};

const TinyButton = ({ onClick, className, children, active }: Props) => {
  return (
    <div
      className={classNames('file-button', className, onClick && 'clickable', active && 'active')}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default TinyButton;
