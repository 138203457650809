import React from 'react';
import { WikiConfig } from '@ts/Wiki';

const config: WikiConfig = {
  items: [
    {
      id: 'git',
      name: 'GIT',
      description:
        'Git is a free and open source distributed version control system designed to handle everything from small to very large projects with speed and efficiency.',
      features: {
        title: 'Why to use git?',
        list: [
          'History of code always available',
          'Working on few features at one time',
          'While using online git all your changes and files has backup',
          'Easy accessible to anyone (no zip files)',
          'Accessible online',
          'Repository could contain different settings as idea code styles, ignored files - basically all project settings',
        ],
      },
      usage: {
        title: 'Basic commands',
        list: [
          { text: 'git config', description: 'Config your name and email just for log purposes' },
          {
            text: 'git remote add [link to repository]',
            description: 'Connect local folder to git (online) repository',
          },
          {
            text: 'git clone [link to repository]',
            description: 'Clone git (online) repository to your local',
          },
          { text: 'git add [files]', description: 'Add files to git' },
          {
            text: 'git commit -m [message]',
            description: 'Commit changes to your local git repository',
          },
          { text: 'git push', description: 'Push committed changes to online git repository' },
          { text: 'git checkout [branch name]', description: 'Checkout branch' },
        ],
      },
      tags: ['Git', 'Repository', 'Branch', 'Local'],
      link: ['https://git-scm.com/'],
    },
    {
      id: 'webHosting',
      name: 'WebHosting',
      description: 'Just a service providing storage for your website',
      features: {
        title: 'Why to use web hosting?',
        list: [
          'Storage should be stable, fast and connected directly stable internet point',
          'Storage should has backup, files or database loss is not acceptable',
        ],
      },
      usage: {
        title: 'How to use storage',
        description:
          'Login credentials & link to server should be available/creatable/editable on web hosting. Usually uses FTP/SFTP protocol.',
        list: [
          {
            text: 'Manual upload to storage',
            description: 'Manually connect and move files to storage',
          },
          {
            text: 'Deploy with own script',
            description: 'Script will connect to storage and replace exact files',
          },
          {
            text: 'Automatic deploy',
            description:
              'Changes are automatically deployed after new commit to branch by some service (f.e. Bitbucket)',
          },
        ],
      },
      tags: ['Deploy', 'FTP', 'WebHosting', 'Domain', 'WebSite'],
      link: ['https://www.websupport.cz/webhosting-prehled'],
    },
    {
      id: 'jsTypes',
      name: 'JS Types',
      description: 'Basic JS types',
      features: {
        desc: 'Simplest way how to get type of property variable "typeof"',
        list: ['boolean', 'number', 'string', 'array', 'object', 'function'],
      },
      usage: {
        title: 'Example of JS types',
        list: [
          { text: 'boolean', description: <code>const value = true;</code> },
          // eslint-disable-next-line react/no-unescaped-entities
          { text: 'string', description: <code>const value = 'Some string';</code> },
          { text: 'number', description: <code>const value = 404;</code> },
          // eslint-disable-next-line react/no-unescaped-entities
          { text: 'array', description: <code>const value = ['newYork', 'surany'];</code> },
          // eslint-disable-next-line react/no-unescaped-entities

          {
            text: 'object',
            // eslint-disable-next-line react/no-unescaped-entities
            description: <code>const value = &#123; city&#58; 'surany' &#125;;</code>,
          },
          {
            text: 'function',
            description: <code>const value = (a) ={'>'} &#123; return a.values() &#125;;</code>,
          },
        ],
      },
    },
    {
      id: 'jsObjects',
      name: 'JS Objects',
      features: {
        title: 'Significant command while working with object, returns array',
        list: [
          'Object.entries(yourObject)',
          'Object.values(yourObject)',
          'Object.keys(yourObject)',
        ],
      },
      usage: {
        title: 'How to work with object',
        list: [
          {
            text: 'Create object with name "myObject"',
            description: <code>const style = &#123;&#125;;</code>,
          },
          {
            text: 'Add / Update object property "color"',
            // eslint-disable-next-line react/no-unescaped-entities
            description: <code>style.color = 'red';</code>,
          },
          {
            text: 'Get object property "color" as new variable',
            description: <code>const mySelectedColor = style.color;</code>,
          },
          { text: 'Remove object property "color"', description: <code>delete style.color;</code> },
          { text: '' },
          {
            text: 'More complex object "complex" ',
            description: (
              <code>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                const complex = &#123; car: &#123; audi: &#123; r8: &#123; engine: 'v12', hp: '632'
                &#125; &#125; &#125; &#125;;
              </code>
            ),
          },
          {
            text: 'Get number of horsepower as new variable',
            description: <code>const hpOfAudiR8 = complex.car.audi.r8.hp</code>,
          },
          { text: '' },
          {
            text: 'Create object with name "initialValues"',
            // eslint-disable-next-line react/no-unescaped-entities
            description: <code>const initialValues = &#123; name: 'apple', count: 23 &#125;;</code>,
          },
          {
            text: 'Create object with name "values"',
            description: <code>const values = &#123; count: 12, lastPieces: true &#125;;</code>,
          },
          {
            text: 'Combination of object initialValues and values',
            description: <code>const data = &#123; ...initialValues, ...values &#125;</code>,
          },
          {
            text: 'Combination of objects above will result with data',
            // eslint-disable-next-line react/no-unescaped-entities
            description: <code>&#123; name: 'apple', count: 12, lastPieces: true &#125;</code>,
          },
        ],
      },
    },
    {
      id: 'jsArray',
      name: 'JS Array',
      features: {
        title: 'Significant command while working with array',
        list: [
          'yourArray.map()',
          'yourArray.forEach()',
          'yourArray.find()',
          'yourArray.filter()',
          'yourArray.some()',
          'yourArray.every()',
          'yourArray.slice()',
          'yourArray.splice()',
        ],
      },
      usage: {
        title: 'How to work with array',
        list: [
          {
            text: 'Create object with name "features"',
            description: <code>const features = [];</code>,
          },
          {
            text: 'Add to array "features" some string',
            // eslint-disable-next-line react/no-unescaped-entities
            description: <code>features.push('fastScroll');</code>,
          },
          { text: '' },
          {
            text: 'Create array with name "colors" and some values',
            // eslint-disable-next-line react/no-unescaped-entities
            description: <code>const colors = ['red', blue];</code>,
          },
          {
            text: 'Get object property on specific index 1 as new variable',
            description: <code>const mySelectedColor = colors[1];</code>,
          },
          {
            text: 'Find item of specific color',
            description: (
              // eslint-disable-next-line react/no-unescaped-entities
              <code>const mySelectedColor = colors.find((color) ={'>'} color === 'red');</code>
            ),
          },
          {
            text: 'Find index of item of specific color',
            description: (
              // eslint-disable-next-line react/no-unescaped-entities
              <code>const mySelectedColor = colors.findIndex((color) ={'>'} color === 'red');</code>
            ),
          },
          {
            text: 'Map "colors" array and return as html',
            description: (
              <code>colors.map((color) ={'>'} &#60;div&#62;&#123;color&#125;&#60;/div&#62;)</code>
            ),
          },
          {
            text: 'Return true if all colors in "colors" are red',
            // eslint-disable-next-line react/no-unescaped-entities
            description: <code>const allRed = colors.every((color) ={'>'} color === 'red')</code>,
          },
          {
            text: 'Return true if some of colors in "colors" are red',
            // eslint-disable-next-line react/no-unescaped-entities
            description: <code>const allRed = colors.some((color) ={'>'} color === 'red')</code>,
          },
          { text: 'Remove object property "color"', description: <code>delete style.color;</code> },
          { text: '' },
          {
            text: 'More complex array "complex" ',
            description: <code>const complex = [[12, 23],[32,95], [23, 213, 25, 73], [12]];</code>,
          },
          {
            text: 'Get first parameter on second index in array "complex"',
            description: <code>const param = a[1][0]</code>,
          },
        ],
      },
    },
    {
      id: 'jsFunctions',
      name: 'JS Functions',
      features: {
        title: 'There is a lot of ways how to write JS functions, most common:',
        list: [
          'const sum = (x, y) => { return x + y; } // anonymous functions',
          'function getType(a) { return x + y }',
        ],
      },
    },
    {
      id: 'jsObjectsAndArray',
      name: 'JS Objects & Array',
      description: (
        <p>
          Main difference between object and array is that object has key.
          <br />
          So accessing object is usually easier than accessing array
        </p>
      ),
      features: {
        title:
          'If you are working with data (APIs, Json files), most common structures begins with types of array and object, then on lower are provided other values types as strings, numebrs, boolean types... Example:',
        list: [
          <code key={1}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            &#123; rows: [&#123; car: 'Audi', color: 'red' &#125;, &#123; car: 'BMW', color: 'gold'
            &#125;], total: 1 &#125;
          </code>,
        ],
      },
      tags: ['Key', 'Value', 'Index', 'Mapping'],
      link: [
        'https://www.w3schools.com/js/js_objects.asp',
        'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object',
        '',
      ],
    },
  ],
};

export default config;
