import React, { useState, useEffect } from 'react';
import { includes, upperCase, upperFirst } from 'lodash-es';

import PageWrap from '../../utils/PageWrap';

import { Cookie } from '../../../functions';

import highTatras from '@assets/backgrounds/HighTatras.jpg';
import hamburg from '@assets/backgrounds/Hamburg.jpg';
import norwayLofoten from '@assets/backgrounds/NorwayLofoten.jpg';
import annette from '@assets/backgrounds/Annette.jpg';
import pragueSubway from '@assets/backgrounds/PragueSubway.jpg';

import CatchPasting from '../../common/CatchPasting';
import CatchKeyPress from '../../common/CatchKeyPress';
import Icon from '../../common/SVG';

export const subDomains = [
  'files',
  'votes',
  'visions',
  'music',
  'clipboard',
  'life',
  'games',
  'picks',
  'films',
  'f1',
].reverse();

import './style.css';

const whoamiItems = ['Photographer', 'Programmer', 'Nature lover'];

const config = [
  { id: 'dreams', text: 'Dreams', background: norwayLofoten },
  { id: 'nature', text: 'Nature', background: highTatras, overlay: true },
  { id: 'cities', text: 'Cities', background: hamburg, overlay: true },
  { id: 'girls', text: 'Girls', background: annette, overlay: true },
  { id: 'subway', text: 'Subway', background: pragueSubway },
];

const Home = () => {
  const [unmount, setUnmount] = useState<string | null>(null);

  const [active, setActive] = useState(0);
  const [activated, setActivated] = useState([0]);
  const [activeWhoamiItem, setActiveWhoamiItem] = useState(0);
  const [userKey, setUserKey] = useState<string | null>(null);

  const [smallRes, setSmallRes] = useState(false);
  const [imageAnimation, setImageAnimation] = useState(false);
  const [movedManually, setMovedManually] = useState(false);

  const [moving, setMoving] = useState(false);

  useEffect(() => {
    setSmallRes(window.innerWidth < 1000);
  }, []);

  useEffect(() => {
    setUserKey(Cookie.get('userKey') as string);

    // Resize handler
    window.addEventListener('resize', handleResize);

    setTimeout(() => activated.length === 1 && setImageAnimation(true), 150);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [smallRes]);

  useEffect(() => {
    const interval = setInterval(() => !smallRes && nextImage(), 8000);

    return () => {
      clearInterval(interval);
    };
  }, [active, movedManually, smallRes]);

  useEffect(() => {
    const interval = setInterval(() => nextWhoamiItem(), 3000);

    return () => {
      clearInterval(interval);
    };
  }, [activeWhoamiItem]);

  const nextImage = () => {
    setMovedManually(false);

    let next = active + 1;

    if (next === config.length) {
      next = 0;
    }

    setActive(next);
  };

  const nextWhoamiItem = () => {
    let next = activeWhoamiItem + 1;
    if (next === whoamiItems.length) {
      next = 0;
    }

    setActiveWhoamiItem(next);
  };

  const handleResize = () => {
    if (window.innerWidth < 1000) {
      setSmallRes(true);
    } else {
      setSmallRes(false);
    }
  };

  const loadOtherImages = () => {
    const newActivated = [...activated];

    for (let i = 1; i < config.length; i++) {
      newActivated.push(i);
    }

    setActivated(newActivated);
  };

  const activeIndex = active;

  const renderBackground = (
    <div className="background">
      {config.map(
        (s, index) =>
          includes(activated, index) && (
            <img
              key={s.id}
              src={s.background}
              alt="background"
              onLoad={() => loadOtherImages()}
              className={`background-image ${activeIndex === index ? 'active' : ''} ${
                imageAnimation ? 'animate' : ''
              }`}
            />
          ),
      )}
      <div className={`background-overlay ${config[activeIndex].overlay ? 'active' : ''}`} />
    </div>
  );

  const renderPortfolioItems = () => {
    const movePx = smallRes ? 0 : active * 376;

    const nullHelper = (number: string | number) => (Number(number) > 10 ? number : `0${number}`);
    const style = { transform: `translateX(-${movePx}px)` };

    return (
      <div className="home-title-wrap" style={style}>
        {config.map((s, index) => (
          <div
            key={s.id}
            id={`portfolio-${s.id}`}
            onMouseEnter={() => move(undefined, index)}
            className={`home-title ${activeIndex === index ? 'active' : ''}`}
            onClick={() => onRedirect(s.id)}
          >
            <div>{s.text}</div>
            <div className="home-title-extension">{nullHelper(index + 1)}</div>
          </div>
        ))}
      </div>
    );
  };

  const onRedirect = (id: string) => setUnmount(`/portfolio/${id}`);

  const move = (dir?: 'right' | 'left', index?: number) => {
    if (moving || smallRes) return;

    let movedTo = 0;

    if (dir) {
      const isNext = dir === 'right';
      movedTo = isNext ? active + 1 : active - 1;

      if (movedTo < 0) return;
      if (movedTo > config.length - 1 && isNext) return;
    } else if (index !== undefined) {
      movedTo = index;

      if (movedTo < 0) return;
      if (movedTo > config.length - 1) return;
    }

    setActive(movedTo);
    setMoving(true);
    setMovedManually(true);

    setTimeout(() => setMoving(false), dir ? 800 : 150);
  };

  const renderWhoami = (
    <div className="home-whoami">
      {whoamiItems.map((i, index) => {
        const active = activeWhoamiItem === index;

        return (
          <span className={`home-whoami-item ${active ? 'active' : ''}`} key={i}>
            {i}
          </span>
        );
      })}
    </div>
  );

  const renderSubDomains = !userKey ? null : (
    <div className="home-row">
      {subDomains.map((v) => (
        <button
          id={v}
          key={v}
          className="home-text clickable custom-btn"
          onClick={() => setUnmount(`/${v}`)}
        >
          {upperCase(v)}
        </button>
      ))}
    </div>
  );

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <PageWrap unmount={unmount} isMain>
        <div className="home-wrap">
          <div className="home-row">
            <div className="home-text">
              Wlcm<span className="blink">_</span>
            </div>
            {renderSubDomains}
          </div>
          <div className="home-body">{renderPortfolioItems()}</div>
          <div className="home-row">
            <button className="home-text clickable" id="about" onClick={() => setUnmount('/about')}>
              <span>Dominik Láclavík</span>
              <span className="divider">·</span>
              {renderWhoami}
            </button>
            <button
              className="home-text clickable"
              id="projects"
              onClick={() => setUnmount('/projects')}
            >
              <span>Projects</span>
              <div className="home-text-icon">
                <Icon icon="arrow" color="#ddd" width={10} height={10} />
              </div>
            </button>
          </div>
          {renderBackground}
        </div>
        {!smallRes && <div className="static-page-left" onMouseMove={() => move('left')} />}
        {!smallRes && <div className="static-page-right" onMouseMove={() => move('right')} />}
        <CatchPasting />
        <CatchKeyPress
          onRedirect={(path) => setUnmount(path)}
          items={subDomains.map((d) => ({ path: `/` + d, name: upperFirst(d) }))}
        />
      </PageWrap>
    </>
  );
};

export default Home;
