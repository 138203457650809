import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade } from '@mui/material';

import Loading from '../../../common/Loading';

import Menu from './Menu';

import './style.css';

const GamesLayout = ({ children, loading, menuHidden, header, noPadding, center }) => {
  return (
      <div className="games-container">
        {/** <div className="games-grid"/> **/}
        <div className="games-wrap">
          <div className={classNames('games-menu-wrap', { ['games-menu-hidden']: menuHidden })}>
            <Menu/>
          </div>
          <div className="games-body">
            {header &&
              <Fade in={!loading}>
                <div className="games-header">{header}</div>
              </Fade>
            }
            {loading
              ? <Loading size={50} color="#444"/>
              : <Fade in={!loading}>
                  <div className={classNames('games-content', { ['no-padding']: noPadding, center })}>
                    {children}
                  </div>
                </Fade>
            }
            <div/>
          </div>
        </div>
      </div>
  );
};

GamesLayout.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
  menuHidden: PropTypes.bool,
  header: PropTypes.any,
  noPadding: PropTypes.bool,
  center: PropTypes.bool,
};

export default GamesLayout;
